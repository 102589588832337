import React from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import Ax from "../../../components/hoc/Ax";
import AppBaseComponent from '../../../components/AppBaseComponent';
import AssetCard from "../../includes/assetCard/AssetCard";
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import tapIcon from "../../../services/TapIcon";
import Status from "../../../components/ui/Status";
import {Modal} from "bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import {toast} from "react-toastify";
import swal from "sweetalert";
import TapSelect from "../../../components/ui/TapSelect";
import { Helmet } from 'react-helmet';


class AssetCompliance extends AppBaseComponent {

    constructor(props) {
        super(props);
        this.addComplianceFormInitialState   =      {
            asset_id               :       "",
            checksheet_name        :       "",
            renewal_type            :      "due_date",
            renewal_date            :      null,
            remarks                 :      ""
        }
        this.state                  =        {
            card_loading            :       true,
            id                      :        null,
            assetComplianceList     :       [],
            compliance_loading      :       false,
            warranty_loading        :       false,
            assetWarrantyList       :       false,
            addCustom               :       false,
            update_remark           :       false,
            updateWarrantyForm      :       {},
            addComplianceForm       :      {...this.addComplianceFormInitialState}
        }
    }

    id = this.props.asset_enc_id;


    componentDidMount() {
        this.getComplianceData(this.props.asset_enc_id)
        this.getWarrantyData(this.props.asset_enc_id)
        this.addCustomComplianceModal     =       new Modal(document.getElementById('addCustomComplianceModal'), {keyboard: false, backdrop :false});
        this.updateWarrantyModal       =        new Modal(document.getElementById('updateWarrantyModal'), {keyboard: false, backdrop :false});
    }

    getComplianceData                =     (id)    =>  {
        this.setState({compliance_loading : true})
        HttpAPICall.withAthorization('GET', process.env.REACT_APP_IAM_API_SERVER + '/asset/compliances/' + id,
            this.props.access_token)
            .then((response) => {
                let respData                =   response.data;
                this.setState({
                    assetComplianceList     :    respData,
                });
            }).then(() => {
            this.setState({compliance_loading: false});
        });
    }

    getWarrantyData                  =     (id)    =>   {
        HttpAPICall.withAthorization('GET', process.env.REACT_APP_IAM_API_SERVER + '/asset/warranty/' + id,
            this.props.access_token)
            .then((response) => {
                let respData                =   response.data;
                this.setState({
                    assetWarrantyList     :    respData,
                });
            }).then(() => {

        });
    }

    updateWarrantyForm               =      (event)    =>   {
        event.preventDefault();
        const access_token = localStorage.getItem('access_token');
        let frmData = {
            asset_id      :    this.id,
            ...this.state.updateWarrantyForm
        };

        HttpAPICall.withAthorization('PUT', process.env.REACT_APP_IAM_API_SERVER + '/asset/warranty/' + this.id ,
            this.props.access_token, null, {...frmData}, (response) => {
                toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                this.updateWarrantyModal.hide();
                this.getWarrantyData(this.id)
                this.setState({updateWarrantyForm       :      { }})

            });
    }

    //function for update warranty Modal
    updateWarrantyModalJsx                  =   ()  =>  {
        return (
            <div className="modal fade" id="updateWarrantyModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Update Warranty Details</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.updateWarrantyForm} id="addModalForm">
                            <div className="modal-body">
                                {
                                    this.state.assetWarrantyList && this.state.assetWarrantyList.length > 0 && this.state.assetWarrantyList.filter(i => i.key !== "").map((i,k) => {
                                        let name  = `${i.key}`;
                                        let input = null;
                                        if(i.type == 'text') {
                                            input = <input
                                                name={name}
                                                type="text"
                                                value={this.state.updateWarrantyForm[name]}
                                                onChange={(e) => this.formInputHandler(e,'updateWarrantyForm')}
                                                className="form-control"
                                                autoComplete="off"
                                                placeholder={`Please enter ${i.name}`}
                                            />
                                        } else if(i.type == 'number') {
                                            input = <input
                                                name={name}
                                                type="text"
                                                value={this.state.updateWarrantyForm[name]}
                                                onChange={(e) => this.formInputHandler(e,'updateWarrantyForm')}
                                                className="form-control"
                                                autoComplete="off"
                                                placeholder={`Please enter ${i.name}`}
                                            />
                                        } else if(i.type == 'date') {
                                            input = <DatePicker
                                                selected={
                                                    this.state.updateWarrantyForm[name]
                                                        ? moment(this.state.updateWarrantyForm[name]).toDate()
                                                        : false
                                                }
                                                name={name}
                                                onChange={(value, event) => {   this.formDateHandler(name, value, 'updateWarrantyForm')}}
                                                dateFormat="dd-MMM-yyyy"
                                                className={"form-control"}
                                                showMonthDropdown
                                                showYearDropdown
                                                scrollMonthYearDropdown
                                                placeholderText={`Please Enter ${i.name}`}
                                            />
                                        } else if(i.type == 'dropdown') {
                                            let options  = i.options.map((t) => { return {value: t, label: t}});
                                            input = <TapSelect
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'updateWarranty', name)}
                                                options={options}
                                                isSearchable={true}
                                                isClearable={true}
                                                value={options.find(o => o.value == this.state.updateWarrantyForm[name])}
                                                placeholder={`Please Select ${i.name}`}
                                            />
                                        }

                                        return (<div key={k} className="row align-items-center mb-3 mt-3">
                                            <div className="col-4"><label className="form-label">{i.name}</label></div>
                                            <div className="col-8">{input}</div>
                                        </div>);
                                    })
                                }
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                <button type="submit" className="btn btn-primary">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    updateWarrantyModalInit                =    ()   =>  {
        this.updateWarrantyModal.show();
        // let assetBasicDetails                =   this.state.assetComplianceDetails.filter((i) => i.name == "Waranty details");
        let updateWarrantyForm             =   [];
        if(this.state.assetWarrantyList.length > 0){
            this.state.assetWarrantyList.filter(i => i.key !== "").map((k) => {
                let name                           =   k.key;
                updateWarrantyForm[name]           =   k.renewal_date === null ? null : k.renewal_date;
            })
            this.setState({
                updateWarrantyForm         :  {
                    ...updateWarrantyForm
                }
            });
        }
    }

    addCompliance                    =      (e)     =>  {
        e.preventDefault()
        if(this.state.addCustom === false && this.state.addComplianceForm.id !== null){
            let frmData                 =        {
                asset_id                :         this.id,
                id                      :         this.state.addComplianceForm.id ,
                checksheet_name         :         this.state.addComplianceForm.checksheet_name,
                renewal_type            :        this.state.addComplianceForm.renewal_type,
                renewal_date            :        this.state.addComplianceForm.renewal_date !== null ? moment(this.state.addComplianceForm.renewal_date).format('YYYY-MM-DD') : null ,
                remarks                 :        this.state.addComplianceForm.remarks
            }
            HttpAPICall.withAthorization('PUT', process.env.REACT_APP_IAM_API_SERVER + '/asset/compliance' ,
                this.props.access_token, null, {...frmData}, (response) => {
                    toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                    this.addCustomComplianceModal.hide();
                    this.getComplianceData(this.id)
                    this.setState({addComplianceForm       :      {...this.addComplianceFormInitialState}})
            });
        }else if(this.state.addCustom === false && this.state.addComplianceForm.id == null){
            let frmData                 =        {
                asset_id               :         this.id,
                checksheet_name         :        this.state.addComplianceForm.checksheet_name,
                renewal_type            :        this.state.addComplianceForm.renewal_type == null ? "na" : this.state.addComplianceForm.renewal_type  ,
                renewal_date            :        this.state.addComplianceForm.renewal_date,
                remarks                 :        this.state.addComplianceForm.remarks
            }
            HttpAPICall.withAthorization('POST', process.env.REACT_APP_IAM_API_SERVER + '/asset/compliance' ,
                this.props.access_token, null, {...frmData}, (response) => {
                    toast.success(response.data.message, {position: toast.POSITION.TOP_RIGHT});
                    this.addCustomComplianceModal.hide();
                    this.getComplianceData(this.id)
                    this.setState({addComplianceForm       :      {...this.addComplianceFormInitialState}})

                });
        } else{
            let frmData                 =        {
                asset_id               :        this.id,
                checksheet_name         :        this.state.addComplianceForm.checksheet_name,
                renewal_type            :        this.state.addComplianceForm.renewal_type,
                renewal_date            :        this.state.addComplianceForm.renewal_date,
                remarks                 :        this.state.addComplianceForm.remarks
            }
            HttpAPICall.withAthorization('POST', process.env.REACT_APP_IAM_API_SERVER + '/asset/compliance' ,
                this.props.access_token, null, {...frmData}, (response) => {
                    toast.success(response.data.message, {position: toast.POSITION.TOP_RIGHT});
                    this.addCustomComplianceModal.hide();
                    this.getComplianceData(this.id)
                    this.setState({addComplianceForm       :      {...this.addComplianceFormInitialState}})

                });
        }
    }

    //function for update Complaince Modal
    addCustomComplianceModalJsx                  =   ()  =>  {
        return (
            <div className="modal fade" id="addCustomComplianceModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">{
                                this.state.addCustom === true ?  "Add Custom Compliance"   : "Update"
                            } </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form id="addModalForm" onSubmit={this.addCompliance}>
                            <div className="modal-body">
                                {this.state.update_remark === false ? <Ax>
                                        <div  className="row align-items-center mb-3 mt-3">
                                        <div className="col-4"><label className="form-label">Compliance Name</label></div>
                                        <div className="col-8">
                                            <input
                                                name="checksheet_name"
                                                type="text"
                                                className="form-control"
                                                disabled={this.state.addComplianceForm.key === "pre_defined"}
                                                value={this.state.addComplianceForm.checksheet_name}
                                                placeholder="Please Enter Checksheet Name"
                                                onChange={(e) => this.formInputHandler(e,"addComplianceForm")}
                                            />
                                        </div>
                                    </div>
                                        <div  className="row align-items-center mb-3 mt-3">
                                        <div className="col-4"><label className="form-label">Due Date</label></div>
                                        <div className="col-8">
                                            <div className="row">
                                                <div className="col-4">
                                                    <label><input type="radio" id="due_date" name="renewal_type" value="due_date"
                                                                  onChange={(e) => this.formInputHandler(e,"addComplianceForm")}
                                                                  checked={this.state.addComplianceForm.renewal_type === "due_date"}/> Due Date</label>
                                                </div>
                                                <div className="col-4">
                                                    <label ><input type="radio" id="na" name="renewal_type" value="na"
                                                                   onChange={(e) => this.formInputHandler(e,"addComplianceForm")}
                                                                   checked={this.state.addComplianceForm.renewal_type === "na"}
                                                    /> Not Applicable</label>
                                                </div>
                                                <div className="col-4">
                                                    <label><input type="radio" id="life_time" name="renewal_type" value="life_time"
                                                                  onChange={(e) => this.formInputHandler(e,"addComplianceForm")}
                                                                  checked={this.state.addComplianceForm.renewal_type === "life_time"}
                                                    /> Life Time</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        <div  className="row align-items-center mb-3 mt-3">
                                        <div className="col-4"><label className="form-label">Date of Renewal</label></div>
                                        <div className="col-8 add_calender_section">
                                            <DatePicker
                                                selected={
                                                    this.state.addComplianceForm.renewal_date
                                                        ? moment(this.state.addComplianceForm.renewal_date).toDate()
                                                        : false
                                                }
                                                name="renewal_date"
                                                onChange={(value, event) => {   this.formDateHandler("renewal_date", value, 'addComplianceForm')}}
                                                // onChange={(value, event) => {
                                                //     this.setState({
                                                //         addComplianceForm: { ...this.state.addComplianceForm, renewal_date : moment(value).format('YYYY-MM-DD') }
                                                //     });
                                                // }}
                                                dateFormat="dd-MMM-yyyy"
                                                className={"form-control"}
                                                showMonthDropdown
                                                showYearDropdown
                                                autoComplete='off'
                                                scrollMonthYearDropdown
                                                disabled={this.state.addComplianceForm.renewal_type !== "due_date"}
                                                placeholderText={`Please Enter Renewal Date`}
                                            />
                                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon}
                                                               alt         =      "requisition_date"
                                                               className   =      "add_calender-icon"
                                            />
                                        </div>
                                    </div>
                                        <div  className="row align-items-center mb-3 mt-3">
                                            <div className="col-4"><label className="form-label">Remarks</label></div>
                                            <div className="col-8">
                                                <input
                                                    name="remarks"
                                                    type="text"
                                                    className="form-control"
                                                    value={this.state.addComplianceForm.remarks}
                                                    placeholder="Please Enter Remarks"
                                                    onChange={(e) => this.formInputHandler(e,"addComplianceForm")}
                                                />
                                            </div>
                                        </div>
                                </Ax> :
                                    <div  className="row align-items-center mb-3 mt-3">
                                        <div className="col-4"><label className="form-label">Remarks</label></div>
                                        <div className="col-8">
                                            <input
                                                name="remarks"
                                                type="text"
                                                className="form-control"
                                                value={this.state.addComplianceForm.remarks}
                                                placeholder="Please Enter Remarks"
                                                onChange={(e) => this.formInputHandler(e,"addComplianceForm")}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                <button type="submit" className="btn btn-primary">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    addComplianceInit                =       ()     =>    {
        this.setState({addCustom : true,update_remark : false})
        this.addCustomComplianceModal.show()
        this.setState({addComplianceForm       :      {...this.addComplianceFormInitialState}})
    }

    updateComplainceInit          =         (name,key)       =>     {
        this.addCustomComplianceModal.show()
        let complianceDetails ;
        if(key === "pre_defined"){
         complianceDetails =   this.state.assetComplianceList[0].data.find(function (group) {
                return group.checksheet_name == name;
            })
        }else{
            complianceDetails =   this.state.assetComplianceList[1].data.find(function (group) {
                return group.checksheet_name == name;
            })
        }
        this.setState({
            addComplianceForm            :            {
                id                       :            complianceDetails.id,
                checksheet_name          :            complianceDetails.checksheet_name,
                renewal_type             :            complianceDetails.renewal_type,
                renewal_date             :            complianceDetails.renewal_date,
                remarks                  :            complianceDetails.remarks,
                key                      :            key
            },addCustom : false,update_remark : false})

    }

    updateRemarksInit             =           (name,key)             =>  {
        this.addCustomComplianceModal.show()
        let complianceDetails ;
        if(key === "pre_defined"){
            complianceDetails =   this.state.assetComplianceList[0].data.find(function (group) {
                return group.checksheet_name == name;
            })
        }else{
            complianceDetails =   this.state.assetComplianceList[1].data.find(function (group) {
                return group.checksheet_name == name;
            })
        }
        this.setState({
            addComplianceForm            :            {
                id                       :            complianceDetails.id,
                checksheet_name          :            complianceDetails.checksheet_name,
                renewal_type             :            complianceDetails.renewal_type,
                renewal_date             :            complianceDetails.renewal_date,
                remarks                  :            complianceDetails.remarks,
                key                      :            key
            },addCustom : false,update_remark : true})

    }

    //function todelete
    deleteCompliance                   =   (id)  =>  {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('DELETE', process.env.REACT_APP_IAM_API_SERVER + '/asset/compliance/' + id, this.props.access_token, {},{} ,(response) => {
                        toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                        this.getComplianceData(this.id)
                    }).then(() => {

                        });
                }
            });
    }


    render() {
        let  id = this.props.asset_enc_id
        return (
            <Ax>
                <Helmet><title>Asset Complaince</title></Helmet>

                        {this.state.compliance_loading
                            ? <Loader />
                            :
                            <div className="page_containt">
                                <div className="pageTbl p-3 " style={{ height: "100%" }}>
                                    <div className="tab_content_wrapper mt-2 mb-3"><span className="content_heading">Warranty Details</span></div>
                                    <table className="table table-bordered">
                                        <tbody>

                                            {this.state.assetWarrantyList.length > 0 && this.state.assetWarrantyList?.map((item, index) => {
                                                return (<tr key={index}><td className="details-label" style={{ "width": "30%" }}>{item.name}</td>
                                                    <td className="details-name" style={{ "width": "60%" }}>{item.display === "NA" ? "NA" : <span><tapIcon.FontAwesomeIcon icon={tapIcon.faCircle} color={item.color_code} /> {item.display}</span>}</td>
                                                    {index === 0 ? <td className="text-center" rowSpan={this.state.assetWarrantyList.length} style={{ "width": "10%", verticalAlign: "middle" }} >
                                                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                            <tapIcon.imageIcon icon={tapIcon.ListActionIcon} />
                                                        </button>
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li>
                                                                <a role="button"
                                                                    onClick={() => {
                                                                        if (this.props?.permissions?.permissions?.includes('iam-compliance-edit')) {
                                                                            return this.updateWarrantyModalInit()
                                                                        }
                                                                    }}
                                                                    className={['dropdown-item', this.props?.permissions?.permissions?.includes('iam-compliance-edit') ? '' : 'disabled'].join(' ')}
                                                                    title={!this.props?.permissions?.permissions?.includes('iam-compliance-edit') ? 'You do not have permission to perform this action' : ''}
                                                                    style={{ "pointerEvents": "all" }}
                                                                >Update </a>

                                                            </li>
                                                        </ul>
                                                    </td> : null}
                                                </tr>)
                                            })}
                                        </tbody>
                                    </table>

                                    {this.state?.assetComplianceList?.map((bd, i) => {
                                        return (<Ax>
                                            <div className="tab_content_wrapper mt-2" key={i}>
                                                <span className="content_heading">{bd.name}</span>
                                                {bd.key === "custom" ? 
                                                <button className="btn btn-primary float-end" disabled={this.props?.permissions?.permissions?.includes('iam-compliance-add') ? false : true} onClick={this.addComplianceInit}>Add Custom</button>
                                                    : null}
                                            </div>
                                            <table className="table table-hover table-bordered mt-2 table-sm ">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="table-head-row" style={{ width: "30%" }} >Compliance Name</th>
                                                        <th scope="col" className="table-head-row" style={{ width: "20%" }} >Due Date</th>
                                                        <th scope="col" className="table-head-row" style={{ width: "40%" }} >Remarks</th>
                                                        <th scope="col" className="table-head-row" style={{ width: "10%" }} >Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {bd.data?.map((item, index) => {
                                                        return (<tr key={item.key}>
                                                            <td className="details-label">{item.checksheet_name}</td>
                                                            <td className="details-name">{item.id === null ? "NA" : <Status color={item.status_color}>{item.display}</Status>}</td>
                                                            <td className="details-label">{item.remarks !== null ? item.remarks : "-"}</td>
                                                            <td className="text-center" style={{ verticalAlign: "middle" }}>
                                                                <span className="dropdown">
                                                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                                    </button>
                                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                        <li>
                                                                            <a role="button"
                                                                                onClick={() => {
                                                                                    if (this.props?.permissions?.permissions?.includes('iam-compliance-edit')) {
                                                                                        return  this.updateComplainceInit(item.checksheet_name, bd.key)
                                                                                    }
                                                                                }}
                                                                                className={['dropdown-item', this.props?.permissions?.permissions?.includes('iam-compliance-edit') ? '' : 'disabled'].join(' ')}
                                                                                title={!this.props?.permissions?.permissions?.includes('iam-compliance-edit') ? 'You do not have permission to perform this action' : ''}
                                                                                style={{ "pointerEvents": "all" }}
                                                                            >Update Compliance</a>
                                                                        </li>
                                                                        <li>
                                                                            <a role="button"
                                                                                onClick={() => {
                                                                                    if (this.props?.permissions?.permissions?.includes('iam-compliance-edit')) {
                                                                                        return  this.updateRemarksInit(item.checksheet_name, bd.key)
                                                                                    }
                                                                                }}
                                                                                className={['dropdown-item', this.props?.permissions?.permissions?.includes('iam-compliance-edit') ? '' : 'disabled'].join(' ')}
                                                                                title={!this.props?.permissions?.permissions?.includes('iam-compliance-edit') ? 'You do not have permission to perform this action' : ''}
                                                                                style={{ "pointerEvents": "all" }}
                                                                            >Update Remarks</a>
                                                                        </li>
                                                                        <li>
                                                                            <a role="button"
                                                                                onClick={() => {
                                                                                    if (this.props?.permissions?.permissions?.includes('iam-compliance-delete')) {
                                                                                        return  this.deleteCompliance(item.id)
                                                                                    }
                                                                                }}
                                                                                className={['dropdown-item', this.props?.permissions?.permissions?.includes('iam-compliance-delete') ? '' : 'disabled'].join(' ')}
                                                                                title={!this.props?.permissions?.permissions?.includes('iam-compliance-delete') ? 'You do not have permission to perform this action' : ''}
                                                                                style={{ "pointerEvents": "all" }}
                                                                            >Delete Compliance</a>
                                                                        </li>
                                                                       
                                                                    </ul>
                                                                </span>

                                                            </td>
                                                        </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </Ax>)
                                    })
                                    }
                                </div>
                            </div>}
                   
                {this.addCustomComplianceModalJsx()}
                {this.updateWarrantyModalJsx()}
            </Ax>
        )
    }
}



const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
        permissions             :       state.app && state.app.acl_info ? state.app.acl_info : null
    }
}

export default connect(mapStateToProps)(AssetCompliance);
