import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { connect } from "react-redux";
import Ax from "../../../components/hoc/Ax";
import moment from "moment";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import { Collapse } from 'bootstrap';
import {DisplayListPagination, DisplaySearchCriteria} from "../../../components/TapUi/index";
import {Link} from "react-router-dom";
import DatePicker from "react-datepicker";
import DownloadFile from "../../../services/DownloadFile";
import InputAssetSearch from "../../includes/ui/InputAssetSearch";
import TapSelect from "../../../components/ui/TapSelect";
import Status from '../../../components/ui/Status';
import { Helmet } from 'react-helmet';
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class ComplianceReport extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        this.reportUrl              =   IAM_API_BASE_URL_2 + '/report/asset_consolidated';
        this.formDatareportUrl      =   IAM_API_BASE_URL_2 + '/report/asset_consolidated/form_data';
        
        let today                  =   new Date()
        this.reportFilterFormInitialState   =   {
            site_group_ids                    :      [],
            'asset_group_ids'               :    [],
            search_asset_type_ids                     :       [],
            search_asset_ids                  :       [],
            search_site_ids                   :       [],
            asset_status                      :        [],
            basic_checksheet_name             :       '',
            compliance_status                 :       '',
            due_date_end                      :       '',
            due_date_start                    :       '',  
        };
        
        this.reportFilterFormLabel          =   {
            site_group_ids                  :       'Site Groups : ',
            search_item_tags                :       'Item Tags : ',
            search_item_ids                 :       'Items : ', 
            'asset_group_ids'                :   'Asset Type Groups : ',
            search_asset_type_ids                    :       'Asset Type : ',
            asset_status                    :       'Asset Status : ',
            search_asset_ids                :       'Assets : ',
            search_site_ids                 :       'Sites : ', 
            basic_checksheet_name            :       'Compliance Name : ',
            compliance_status               :       'Status : ',
            due_date_start                   :       'Due Date Between  :  ',
        };
         
        this.state                          =   {
            formDataLoaded                      :   false,
            reportDownloadBtn                   :   false,
            reportDownloading                   :   false,
            all_warehouses                      :   [],
            reportFilterForm                    :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm           :   {...this.reportFilterFormInitialState},
            listing_loading                     :   false,
            listingData                         :   [],
            listingMeta                         :   null,  
            allItemTags                         :   [],
            manufactures                        :   [],
            allStatusOptions                    :   [],
            searchedAssets                      :   [],
            iam_asset_types                     :   [],
            iam_user_sites                      :   [],  
            allSiteGroupsList                   :   [],
            all_periods                         :      [],
            iam_status_list                     :      [],
            allAssetTypesGroupsList            :   [],
            reportData                          :   null,                  
        };

    }

    componentDidMount() {
        this.initilaizeAppDataToFilterForm(this.props);
    }
    
    componentWillReceiveProps(nextProps){
        if(nextProps.iam_user_sites !== this.props.iam_user_sites) {
            this.initilaizeAppDataToFilterForm(nextProps);
        }
    }
    
    initilaizeAppDataToFilterForm           =   (pr)  =>  {

        const report_name   =   'compliance_report';
        const reportData    =   pr?.report_listing.length > 0 ? pr.report_listing.filter(d => d.key === report_name) : [];
        this.setState({
            reportData                  :   reportData.length > 0 ? reportData[0] : null,
        })

       
        HttpAPICall.withAthorization('GET', this.formDatareportUrl,
            this.props.access_token, null, null, (response) => {
               
                this.setState({
                    access_token                :   pr.access_token,
                    formDataLoaded              :   true,
                    iam_status_list             :   pr.iam_status_list ? pr.iam_status_list.map((s) => { return {value: s.key, label: s.name}}) : [],
                    iam_asset_types             :   pr.iam_asset_types && pr.iam_asset_types.length > 0 ? pr.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
                    iam_user_sites              :   pr.iam_user_sites  && pr.iam_user_sites.length > 0 ? pr.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
                    allItemTags                 :   pr.item_tags && pr.item_tags.length > 0 ? pr.item_tags.map((s) => { return {value: s.id, label: s.name}; }) : [],  
                    allSiteGroupsList           :   response.data && response.data.site_groups && response.data.site_groups.length > 0 ? response.data.site_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_sites: oc.site_ids }) }) : [],
                    allStatusOptions            :   response.data && response.data.consolidated_status && response.data.consolidated_status.length > 0 ? response.data.consolidated_status.map((s) => { return { value: s.status, label: s.status_text }; }) : [],
                    allAssetTypesGroupsList     :   pr.asset_groups && pr.asset_groups.length > 0 ? pr.asset_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_asset_types: oc.asset_type_ids }) }) : [],
                }, () => {
                    let status = [];
                    let ParamObject = new URLSearchParams(this.props.location.search);
                    let search_site_ids = [];
                    let search_asset_type_ids = [];
                    if(this.state.iam_user_sites && this.state.iam_user_sites.length > 0 || this.state.iam_asset_types && this.state.iam_asset_types.length > 0 || this.state.iam_status_list && this.state.iam_status_list.length > 0  ){
                        if (ParamObject.get('status') || ParamObject.get('search')) {
                            if (ParamObject.get('status')) {
                                status = ParamObject.get('status');
                            }
                            if (ParamObject.get('search_site_ids')) {
                                let site = this.state.iam_user_sites.find(us => us.value == ParamObject.get('search_site_ids'));
                                    search_site_ids = [site.value];
                                
                            }
                            if (ParamObject.get('search_asset_type_ids')) {
                                let assetType = this.state.iam_asset_types.find(us => us.value == ParamObject.get('search_asset_type_ids'));
                                if (assetType) {
                                    search_asset_type_ids = [assetType.value];
                                }
                            }
                              
    
                            this.setState({
                             
                                reportFilterForm        :       {
                                    ...this.state.reportFilterForm,
                                    compliance_status     :        status,
                                    search_site_ids     :       search_site_ids,
                                    search_asset_type_ids   :    search_asset_type_ids,
                                }
                            }, () => {
    
                                this.reportFilterFormSubmit();
                            });
                        }else {
                             this.reportFilterhandler()
                        }
                    }

                });
            });
    }
    
    reportFilterFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
        event && this.reportFilterhandler(true);
       
        let serachFormKeys                  =   Object.keys(this.state.reportFilterForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                  
                    if(key == 'site_group_ids') {
                        show_val            =   this.state.allSiteGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ')
                    }
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'asset_group_ids') {
                        show_val            =   this.state.allAssetTypesGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_type_ids') {
                        show_val            =   this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'compliance_status') {
                        show_val            =   this.state.allStatusOptions.filter((s) => value == s.value).map(s => s.label).join(', ');
                    }
                    if(key == 'asset_status') {
                        show_val            =   this.state.iam_status_list.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'basic_checksheet_name') {
                        show_val            =  this.state.reportFilterForm.basic_checksheet_name   ;
                    }
                    if(key == 'due_date_start') {
                        let  start_range         =      moment(this.state.reportFilterForm.due_date_start).format('DD-MMM-YYYY');
                        let end_range            =      moment(this.state.reportFilterForm.due_date_end).format('DD-MMM-YYYY')
                            let display_date     =      `(${start_range} - ${end_range})`
                            show_val                 =     display_date;
                    }
                   
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
           this.loadListingTblData(1);
        });
    }
    
    loadListingTblData                      =   (page=1) => {
        this.setState({
            listing_loading             :   true,
            listingData                 :   [],
            listingMeta                 :   null,
            reportDownloadBtn           :   false,
            reportDownloading           :   false
        });
        let params                          =   {page:page, per_page:50,...this.state.submittedReportFilterForm};
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, params, {}, (response) => {
            let respData                =   response.data;
            this.setState({
                listingData                 :   respData.data,
                reportDownloadBtn           :   true,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            });
        }, (err) => {
            this.reportFilterhandler();
        }).then(() => {
            this.setState({listing_loading: false});
        });
    }

    reportDownloadHandler                   =   ()  =>  {
        this.setState({ reportDownloading: true});
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, {...this.state.submittedReportFilterForm, download : 'download'} , {} , (response) => DownloadFile.file(response.data.file_path)).then(() => this.setState({reportDownloading: false}));
    }
    
    reportFilterhandler                     =   (collapse = true)  =>  {
        collapse && new Collapse(document.getElementById('reportFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }
 
    tapDateChange           =       (selectedOption)        =>  {
        
        if (selectedOption && selectedOption.length > 0) {
            this.setState({
                reportFilterForm : {
                    ...this.state.reportFilterForm ,
                    due_date_start    :       selectedOption[0] ? selectedOption[0] : '',
                    due_date_end      :       selectedOption[1] ? selectedOption[1] : '',
                }
            });
        }
    }

    closeReport                             =       ()      =>  {
        this.props.history.goBack()
    }

    reportSearchClear                  =        ()             =>        {
        this.setState({
            formSearchedElems               :           [],
            searchedAssets                  :            [],
            reportFilterForm                :           {...this.reportFilterFormInitialState},
            submittedReportFilterForm       :           {...this.reportFilterFormInitialState},
        }, () => {
            this.loadListingTblData(1);
        });
    }
    
    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-sm-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faPallet} /> Compliance Listing</h3>
                <div className="text-end mt15">
                    {this.state.reportDownloadBtn ? (<button disabled={this.state.reportDownloading || this.state.reportData?.download_access === 'N'} type="button" onClick={this.reportDownloadHandler} className="btn btn-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /> Download Detailed Report {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>) : null}
                    <button type="button" disabled={!this.state.formDataLoaded} onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                    <button type="button" className="btn btn-secondary" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                    </button>
                </div>
            </div>
        </div>);
    }
    
    reportFilterFormJsx                     =   ()  =>  {
        return (<div id="reportFilterForm" className="bg-white collapse " >
               <form onSubmit={this.reportFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Site Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allSiteGroupsList}
                            changeEvent={(selectedOption) => {

                                this.setState({
                                    reportFilterForm: {
                                        ...this.state.reportFilterForm,
                                        site_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                        search_site_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_sites).flat() : []
                                    }
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allSiteGroupsList.filter(s => this.state.reportFilterForm.site_group_ids.includes(s.value))}
                            placeholder="Select Site Group"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Site Name</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_user_sites}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_site_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            menuPlacement="bottom"
                            value={this.state.iam_user_sites.filter(s => this.state.reportFilterForm.search_site_ids.includes(s.value))}
                            placeholder="Select Sites"
                        />
                    </div>
                </div>

                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Name</label>
                    <div className="col-sm-6">
                        <InputAssetSearch
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_ids');
                                this.setState({ searchedAssets: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                            }}
                            isMulti={true}
                            value={this.state.searchedAssets && this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allAssetTypesGroupsList}
                            changeEvent={(selectedOption) => {

                                this.setState({
                                    reportFilterForm: {
                                        ...this.state.reportFilterForm,
                                        asset_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                        search_asset_type_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_asset_types).flat() : []
                                    }
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allAssetTypesGroupsList.filter(s => this.state.reportFilterForm.asset_group_ids.includes(s.value))}
                            placeholder="Select Asset Type Group"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_asset_types}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_type_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_asset_types.filter(s => this.state.reportFilterForm.search_asset_type_ids.includes(s.value))}
                            placeholder="Select Asset Type"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Status</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_status_list}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'asset_status');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_status_list.filter(s => this.state.reportFilterForm.asset_status.includes(s.value))}
                            placeholder="Select Asset Status"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Compliance Name</label>
                    <div className="col-sm-6">
                        <input
                            name="basic_checksheet_name"
                            type="text"
                            value={this.state.reportFilterForm.basic_checksheet_name}
                            onChange={(e) => this.formInputHandler(e, 'reportFilterForm')}
                            className="form-control"
                            autoComplete="off"
                            placeholder="Service Checksheet Name / Alias Name"
                        />
                    </div>
                </div>


                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Compliance Status</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allStatusOptions}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'reportFilterForm', 'compliance_status')}
                            value={this.state.allStatusOptions.find(u => this.state.reportFilterForm?.compliance_status == u.value)}
                            isSearchable={true}
                            menuPlacement="top"
                            isClearable={true}
                            placeholder="Select Status"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end ">Due Date</label>
                    <div className="col-sm-6 add_calender_section">
                        <DatePicker
                            selectsRange={true}
                            monthsShown={2}
                            dateFormat="dd-MMM-yyyy"
                            className={'form-control'}
                            startDate={this.state.reportFilterForm.due_date_start}
                            endDate={this.state.reportFilterForm.due_date_end}
                            name="date"
                            maxDate={new Date()}
                            onChange={(value, event) => {
                                this.tapDateChange(value)
                            }}
                            autoComplete="off"
                            placeholder="Select Due Date"
                        />
                        <tapIcon.imageIcon icon={tapIcon.CalenderIcon} className="add_calender-icon" />
                    </div>
                </div>

                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4" >Show Report</button>
                    </div>
                    <hr />
                </div>
            </form>
        </div>);
    }
    
    reportListingDisplayJsx                 =   ()  =>  {
       
        return (<div className="bg-white">
            { this.state.listing_loading ? <Loader /> : null }
           { !this.state.listing_loading && this.state.reportDownloadBtn == true
                ? this.reportTableJsx() : null
            }
       
        </div>)
    }
    
    reportTableJsx                          =   ()  =>  {
        return (<Ax>
            <table className="table table-bordered  table-sm align-middle bg-white mt-2">
                <thead className="align-middle table-secondary">
                    <tr>
                        <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                        <th scope="col" style={{ width: "20%" }}>Asset</th>
                        <th scope="col" style={{ width: "15%" }}>Site Name</th>
                        <th scope="col" style={{ width: "20%" }}>Basic Checksheet Name</th>
                        <th scope="col" style={{ width: "10%" }} className='text-center'>Due Date</th>
                        <th scope="col" style={{ width: "20%" }}>Remarks</th>
                        <th scope="col" style={{ width: "10%" }}  className='text-center' > Status</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.listingData && this.state.listingData.length > 0
                        ? (this.state.listingData.map((data,index) => {
                            return (
                                <tr>
                                    <td className='text-center'>{this.state.listingMeta ? this.state.listingMeta.from + index : index } </td>
                                    <td>{data.asset_id ? <Link to={`/asset/compliance/${data.asset_id}`} target="_blank">{data.name ? data.name :  "-"}</Link> : "-"}</td>
                                    <td>{data.site ? data.site :  "-"}</td>
                                    <td>{data.checksheet_name ? data.checksheet_name :  "-"}</td>
                                    <td className='text-center'>{data.display ? data.display : '' } </td>
                                    <td>{data.remarks ? data.remarks :  "-"}</td>
                                    <td  className='text-center'>{data.status_text ? <Status color={data.status_color}>{data.status_text}</Status> :  "-"}</td>
                                    
                                </tr>
                            )
                        }))
                        : <tr><td className="text-center" colSpan={9}>No Record Found</td></tr>
                    }
                </tbody>
            </table>
        </Ax>);
    }

    
    render                                  =   ()  =>  {
        return (<ApplicationLayout>
            <Helmet><title>Compliance - Report</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12 pb-2">
                        {this.state.formDataLoaded
                            ? (<Ax>
                                {this.reportFilterFormJsx()}
                                <DisplaySearchCriteria searchedElems={this.state.formSearchedElems}  onClearFilter={this.reportSearchClear} />
                                {this.reportListingDisplayJsx()}

                            </Ax>)
                            : <Loader />
                        }

                        {this.state.reportDownloadBtn == true
                            ? <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected + 1)} />
                            : null
                        }
                    </div>
                </div>
            </div>

        </ApplicationLayout>);
    }
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_user_sites          :   state.app && state.app.user_sites && state.app.user_sites.length > 0 ? state.app.user_sites : [],
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        iam_status_list         :   state.app && state.app.status_list ? state.app.status_list : [],
        asset_groups             :   state.app && state.app.asset_groups ? state.app.asset_groups : [],
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps)(ComplianceReport);