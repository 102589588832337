import React from "react";
import { Link } from "react-router-dom";

const AssetHeaderTabs = ({ id, activeTab, permissions }) => {
    return (
        <ul className="nav nav-tabs">
            <li>
                <Link to={`/asset/dashboard/${id}`} role="button" >
                    <button className={`btn nav-link text-capitalize ${activeTab === 'dashboard' ? 'active' : ''}`}>Dashboard</button>
                </Link>
            </li>
            <li>
                <Link to={`/asset/details/${id}`} role="button" >
                    <button className={`btn nav-link text-capitalize ${activeTab === 'details' ? 'active' : ''}`}>Details</button>
                </Link>
            </li>
            <li>
                <Link to={`/asset/compliance/${id}`} role="button" >
                    <button className={`btn nav-link text-capitalize ${activeTab === 'compliance' ? 'active' : ''}`}>Compliance</button>
                </Link>
            </li>
            <li>
                <Link to={`/asset/document/${id}`} role="button" >
                    <button className={`btn nav-link text-capitalize ${activeTab === 'document' ? 'active' : ''}`}>Document</button>
                </Link>
            </li>
            {permissions && permissions.group_sub_modules.find(s => s.sub_module === "iam-rental") &&
                <li>
                    <Link to={`/asset/rental/${id}`} role="button"  >
                        <button className={`btn nav-link text-capitalize ${activeTab === 'rental' ? 'active' : ''}`}>Commercial</button>
                    </Link>
                </li>
            }
            <li>
                <Link to={`/asset/subassembly/${id}`} role="button"  >
                    <button className={`btn nav-link text-capitalize ${activeTab === 'subassembly' ? 'active' : ''}`}>Sub Assembly</button>
                </Link>
            </li>
        </ul>

    );
};

export default AssetHeaderTabs;
