import React from 'react';
import { connect } from 'react-redux';
import HttpAPICall from '../../../services/HttpAPICall';
import { APP_BASE_URL_2 } from '../../../services/TapApiUrls';
import Ax from '../../../components/hoc/Ax';
import tapIcon from '../../../services/TapIcon';
import { Modal } from 'bootstrap';
import Loader from '../../../components/ui/Loader/Loader';
import TapSelect from '../../../components/ui/TapSelect';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import moment from "moment";
import AppBaseComponent from '../../../components/AppBaseComponent';
import uuid from 'react-uuid';
import InputPurchaseSearch from '../../includes/ui/InputPurchaseSearch';
import InputInventoryTransferSearch from '../../includes/ui/InputInventoryTransferSearch';
import InputPurchaseOrderSearch from '../../includes/ui/InputPurchaseOrderSearch';

class LogisticEditModal extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.initialLinkingData         =   {
            purchase_transaction_ids        :   [],
            inv_transfer_transaction_ids    :   [],
            purchase_order_transaction_ids  :   [],
        };

        this.state                      =   {
            editFormSubmitting              :   false,
            loading_form                    :   false,
            addNewDistrict                  :   false,
            addNewPinCode                   :   false,

            paymentMode                     :   [],
            transportersList                :   [],
            cnfAgentsList                   :   [],
            handlersList                    :   [],

            divisionsList                   :   [],
            modalType                       :   '', 
            logisticDetail                  :   {...this.initialLinkingData},   
            transport_days                  :   '',   
            
            // linkingData                     :   {...this.initialLinkingData},
            searchedPurchase                :   [],
            searchedInvTransfer             :   [],
            searchedOrderPurchase           :   [],
            
            transaction                     :   {},
            expense                         :   {},
            cnf                             :   {},
            billing                         :   {},
            consigment                      :   {},
            tracking                        :   {},
            purchaseInventory               :   {},
            
            
        };

        this.domesticLoadType   =   [      
            {value: 'FULL TRUCK CONSIGNMENT', label: 'FULL TRUCK CONSIGNMENT'},
            {value: 'PARTIAL CONSIGNMENT', label: 'PARTIAL CONSIGNMENT'},
            {value: 'COURIER CONSIGNMENT', label: 'COURIER CONSIGNMENT'}
        ];
        this.internationalLoadType   =   [      
            {value: 'AIR CARGO', label: 'AIR CARGO'},
            {value: 'SEA CARGO', label: 'SEA CARGO'}
        ];
        
        this.modalId            =   uuid();   
        this.logisticUrl        =   APP_BASE_URL_2 + '/logistic';
       
    }
    
  
    componentDidMount(){
        this.addModal                  =   new Modal(document.getElementById(this.modalId), {keyboard: false, backdrop :false});
    }

    showModalInit           =   (type)  =>  {
        
        const logisticDetail    =   this.props.LogisticDetail;
        
        this.setState({
            loading_form    :   true,
            modalType       :   type,
            logisticDetail  :   {...logisticDetail},
            
        });
        
        if(type === 'Transaction') {
            this.setState({
                transaction : {
                    division_id : logisticDetail.division_id,
                    logistic_type : logisticDetail.logistic_type   
                }
            }, () => {
                this.addModal.show();
            });
        }
        if(type === 'Tracking') {
            this.setState({
                tracking : {
                    dispatch_date : logisticDetail.dispatch_date,   
                    delivery_date : logisticDetail.delivery_date,   
                    receiver_name : logisticDetail.receiver_name,   
                    delivery_date_at_godown : logisticDetail.delivery_date_at_godown,   
                    delivery_date_at_site : logisticDetail.delivery_date_at_site,   
                }
            }, () => {
                this.addModal.show();
            });
        }
        if(type === 'Consigment') {
            this.setState({
                consigment : {
                    load_type : logisticDetail.load_type,
                    consigment_number : logisticDetail.consigment_number,   
                    booking_place : logisticDetail.booking_place,   
                    transporter_id : logisticDetail.transporter_id,   
                    vehicle_number : logisticDetail.vehicle_number,   
                    weight : logisticDetail.weight,   
                    number_of_boxes : logisticDetail.number_of_boxes,    
                }
            }, () => {
                this.addModal.show();
            });
        }
        if(type === 'Billing') {
            this.setState({
                billing : {
                    gst_invoice_number : logisticDetail.gst_invoice_number,
                    invoice_date : logisticDetail.invoice_date,   
                    invoice_value : logisticDetail.invoice_value,   
                    machinery_description : logisticDetail.machinery_description,   
                    payment_mode : logisticDetail.payment_mode,   
                    payment_date : logisticDetail.payment_date,   
                    eway_bill_number : logisticDetail.eway_bill_number,   
                }
            }, () => {
                this.addModal.show();
            });
        }
        if(type === 'C&F') {
            this.setState({
                cnf : {
                    cnf_agent_id    :   logisticDetail.cnf_agent_id,
                    handler_id      :   logisticDetail.handler_id,      
                    boe_number      :   logisticDetail.boe_number,      
                }
            }, () => {
                this.addModal.show();
            });
        }
        if(type === 'Expense') {
            this.setState({
                expense : {
                    logistic_cost : logisticDetail.logistic_cost,      
                    handling_cost : logisticDetail.handling_cost,
                    cha_cost : logisticDetail.cha_cost,      
                }
            }, () => {
                this.addModal.show();
            });
        }

        if(type === 'Purchase & Inventory') {
            this.setState({
                purchaseInventory: {
                    purchase_transaction_ids : logisticDetail.purchase_transaction_ids,
                    purchase_order_transaction_ids : logisticDetail.purchase_order_transaction_ids,
                    inv_transfer_transaction_ids : logisticDetail.inv_transfer_transaction_ids,
                },
                searchedPurchase:   logisticDetail.purchase_transaction_ids && logisticDetail.purchase_transaction_ids.map((p,i) => {
                    return {
                        transaction_id : p,
                        label : p,
                        value : p
                    }
                }),
                searchedOrderPurchase:   logisticDetail.purchase_order_transaction_ids && logisticDetail.purchase_order_transaction_ids.map((p,i) => {
                    return {
                        transaction_id : p,
                        label : p,
                        value : p
                    }
                }),
                searchedInvTransfer:   logisticDetail.inv_transfer_transaction_ids && logisticDetail.inv_transfer_transaction_ids.map((p,i) => {
                    return {
                        transaction_id : p,
                        label : p,
                        value : p
                    }
                }),
            }, () => {
                this.addModal.show();
            })
        }

        if(['Transaction', 'Consigment', 'Billing', 'C&F'].includes(type)) {
            HttpAPICall.withAthorization('GET', `${this.logisticUrl}/form_data`, this.props.access_token, null, null, (response) => {
                const formData                   =   response.data;
                this.setState({
                     paymentMode                 :   formData.payment_mode,
                     logisticStatus              :   formData.status.map((s) => { return { value: s.id, label: s.name}}), 
                     transportersList            :   formData.transporter.map((s) => { return { value: s.id, label: `${s.name}`}}),       
                     cnfAgentsList               :   formData.cnfAgent.map((s) => { return { value: s.id, label: `${s.name}`}}),       
                     divisionsList               :   formData.division.map((s) => { return { value: s.id, label: `${s.name}`}}),       
                     handlersList                :   formData.handler.map((s) => { return { value: s.id, label: `${s.name}`}}),       
                });
            }).then(() => { this.setState({ loading_form: false}) });
        }else {
            this.setState({ loading_form: false});
        }
        
    }


    //submit Edit Form
    editLogisticFormSubmit      =   (e) =>  {
       e.preventDefault();
       this.setState({editFormSubmitting : true});        
                    
        let data                        =   {id : this.state.logisticDetail.id, transaction_id: this.state.logisticDetail.transaction_id}
        if(this.state.modalType === "Purchase & Inventory") {
            data                =   {...data, ...this.state.purchaseInventory}
        }
        if(this.state.modalType === "Transaction") {
            data                =   {...data, ...this.state.transaction}
        }
        if(this.state.modalType === "Tracking") {
            const transportDays             =   this.transportDays();
            data                =   {...data, ...this.state.tracking, transport_days: transportDays}
        }
        if(this.state.modalType === "Consigment") {
            data                =   {...data, ...this.state.consigment}
        }
        if(this.state.modalType === "Billing") {
            data                =   {...data, ...this.state.billing}
        }
        if(this.state.modalType === "C&F") {
            data                =   {...data, ...this.state.cnf}
        }
        if(this.state.modalType === "Expense") {
            const total_cost    =   this.totalCost();
            data                =   {...data, ...this.state.expense, total_cost}
        }

        HttpAPICall.withAthorization('PUT', `${this.logisticUrl}/edit`, this.props.access_token, null, data, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.props.getLogisticViewDetails(this.state.logisticDetail.transaction_id);
            this.addModal.hide();
        }).then(() => this.setState({ editFormSubmitting: false }));

    }

    totalCost   =   ()  =>  {
        const arrInc            =   ['', null];
        const logisticDetail    =   this.state.expense;

        const handlingCost  =   arrInc.includes(logisticDetail.handling_cost)  ? 0 : parseFloat(logisticDetail.handling_cost);
        const logisticCost  =   arrInc.includes(logisticDetail.logistic_cost)  ? 0 : parseFloat(logisticDetail.logistic_cost);
        
        let totalCost       =   handlingCost  + logisticCost;
        if(this.state.logisticDetail.logistic_type === "International") {
            const chaCost   =   arrInc.includes(logisticDetail.cha_cost)  ? 0 : parseFloat(logisticDetail.cha_cost);
            totalCost       +=  chaCost;
        }
        return totalCost;
    }

    transportDays = () => {

        const oneDay = 24 * 60 * 60 * 1000;
        const dateAtSite = new Date(this.state.tracking.delivery_date_at_site);
        const dispatchDate = new Date(this.state.tracking.dispatch_date);

        let days = 0;
        if(dateAtSite !== '' && dispatchDate !== '' && dateAtSite > dispatchDate) {
            days = parseInt((dateAtSite - dispatchDate) / (oneDay), 10); 
        }

        return days;

    }
   

    //*********************************ITEM ADD JSX*********************** */
    modalJsx                     =   ()  =>  {
        const logisticDetail            =   this.state.logisticDetail;
        const transportDays             =   this.transportDays();
        const totalCost                 =   this.totalCost();

        return (
            <div className="modal fade" id={this.modalId} tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content  ">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addModalLabel">Edit {this.state.modalType}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <div className="modal-body ">
                            {
                            this.state.loading_form 
                            ? 
                            <Loader/>
                            : 
                            <form onSubmit={this.editLogisticFormSubmit} id="editAddressForm" method="post" encType="multipart/form-data">
                                <Ax>
                                    {
                                        this.state.modalType === "Transaction"
                                        ?
                                        <Ax>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-2">
                                                    <label htmlFor="site_id" className="form-label">Logistic Type</label>
                                                </div>
                                                <div className="col-sm-8">
                                                    <div className="row">
                                                        <div className="col-sm-5">
                                                            <input
                                                                name="logistic_type"
                                                                type="radio"
                                                                value={"Domestic"}
                                                                required={true}
                                                                onChange={(e) => this.formInputHandler(e, 'transaction')}
                                                                className="form-check-input"
                                                                checked={this.state.transaction.logistic_type === 'Domestic' ? true : false}
                                                                id="domestic"
                                                            />
                                                            <label className="form-check-label mx-2" htmlFor="domestic">Domestic</label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <input
                                                                name="logistic_type"
                                                                type="radio"
                                                                required={true}
                                                                value={"International"}
                                                                onChange={(e) => this.formInputHandler(e, 'transaction')}
                                                                className="form-check-input"
                                                                checked={this.state.transaction.logistic_type === 'International' ? true : false}
                                                                id="international"
                                                            />
                                                            <label className="form-check-label mx-2" htmlFor="international">International</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row align-items-center mt-3">
                                                <div className="col-sm-2 col-form-label col-form-label-sm">
                                                    <label htmlFor="site_id" className="form-label ">Division</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <TapSelect
                                                        changeEvent={(selectedOption) => {
                                                            this.tapSelectChange(selectedOption, 'transaction', 'division_id');
                                                        }}
                                                        options={this.state.divisionsList}
                                                        value={this.state.divisionsList.find(s => s.value == this.state.transaction.division_id)}
                                                        isSearchable={true}
                                                        isClearable={false}
                                                        placeholder="Select Division"
                                                    />
                                                </div>
                                            </div> 
                                        </Ax>
                                        :
                                        this.state.modalType === "Tracking"
                                        ?
                                        <Ax>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-2">
                                                    <label htmlFor="site_id" className="form-label ">Date of Actual Dispatch</label>
                                                </div>
                                                <div className="col-sm-4 add_calender_section">
                                                    <DatePicker
                                                        selected={
                                                            this.state.tracking.dispatch_date
                                                                ? moment(this.state.tracking.dispatch_date, 'YYYY-MM-DD').toDate()
                                                                : false
                                                        }
                                                        name="dispatch_date"
                                                        onChange={(value, event) => this.formDateHandler('dispatch_date', value, 'tracking')}
                                                        dateFormat="dd-MMM-yyyy"
                                                        className={"form-control"}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        autoComplete="off"
                                                        scrollMonthYearDropdown
                                                        placeholderText={`Dispatch Date`}
                                                    />
                                                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="Dispatch Date" className="add_calender-icon" />
                                                </div>
                                                <div className="col-sm-2">
                                                    <label htmlFor="site_id" className="form-label ">Expected Delivery Date</label>
                                                </div>
                                                <div className="col-sm-4 add_calender_section">
                                                    <DatePicker
                                                        selected={
                                                            this.state.tracking.delivery_date
                                                                ? moment(this.state.tracking.delivery_date, 'YYYY-MM-DD').toDate()
                                                                : false
                                                        }
                                                        onChange={(value, event) => this.formDateHandler('delivery_date', value, 'tracking')}
                                                        name="delivery_date"
                                                        dateFormat="dd-MMM-yyyy"
                                                        className={"form-control"}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        autoComplete="off"
                                                        scrollMonthYearDropdown
                                                        placeholderText={`Enter Delivery Date`}
                                                    />
                                                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="Delivery Date" className="add_calender-icon" />
                                                </div>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-2">
                                                    <label htmlFor="site_id" className="form-label ">Delivery Date at Godown</label>
                                                </div>
                                                <div className="col-sm-4 add_calender_section">
                                                    <DatePicker
                                                        selected={
                                                            this.state.tracking.delivery_date_at_godown
                                                                ? moment(this.state.tracking.delivery_date_at_godown, 'YYYY-MM-DD').toDate()
                                                                : false
                                                        }
                                                        onChange={(value, event) => this.formDateHandler('delivery_date_at_godown', value, 'tracking')}
                                                        name="delivery_date_at_godown"
                                                        dateFormat="dd-MMM-yyyy"
                                                        className={"form-control"}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        autoComplete="off"
                                                        scrollMonthYearDropdown
                                                        placeholderText={`Enter Delivery Date at Godown`}
                                                    />
                                                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="Delivery Date at Godown" className="add_calender-icon" />
                                                </div>
                                                <div className="col-sm-2">
                                                    <label htmlFor="site_id" className="form-label ">Delivery Date at Site</label>
                                                </div>
                                                <div className="col-sm-4 add_calender_section">
                                                    <DatePicker
                                                        selected={
                                                            this.state.tracking.delivery_date_at_site
                                                                ? moment(this.state.tracking.delivery_date_at_site, 'YYYY-MM-DD').toDate()
                                                                : false
                                                        }
                                                        onChange={(value, event) => this.formDateHandler('delivery_date_at_site', value, 'tracking')}
                                                        name="delivery_date_at_site"
                                                        dateFormat="dd-MMM-yyyy"
                                                        className={"form-control"}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        autoComplete="off"
                                                        scrollMonthYearDropdown
                                                        placeholderText={`Enter Delivery Date at Site`}
                                                    />
                                                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="Delivery Date at Site" className="add_calender-icon" />
                                                </div>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-2">
                                                    <label htmlFor="site_id" className="form-label ">Transport Days</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <input
                                                        name="transport_days"
                                                        type="text"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        value={transportDays}
                                                        disabled={true}
                                                        placeholder="Enter Transport Days"
                                                    />
                                                </div>
                                                <div className="col-sm-2">
                                                    <label htmlFor="site_id" className="form-label ">Receiver Name</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <input
                                                        name="receiver_name"
                                                        type="text"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        onChange={(e) => this.formInputHandler(e, 'tracking')}
                                                        value={this.state.tracking.receiver_name}
                                                        placeholder="Enter Receiver Name"
                                                    />
                                                </div>
                                            </div>
                                        </Ax>
                                        :
                                        this.state.modalType === "Consigment"
                                        ?
                                        <Ax>          
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-2">
                                                    <label htmlFor="site_id" className="form-label ">Type of Load</label>
                                                </div>
                                                <div className="col-sm-6">
                                                    <TapSelect
                                                        changeEvent={(selectedOption) => {
                                                            this.tapSelectChange(selectedOption, 'consigment', 'load_type');
                                                        }}
                                                        options={logisticDetail.logistic_type === 'International' ? this.internationalLoadType : this.domesticLoadType}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        required={true}
                                                        value={
                                                            logisticDetail.logistic_type === 'International' 
                                                            ? 
                                                            this.internationalLoadType.find(s => s.value == this.state.consigment.load_type)
                                                            :
                                                            this.domesticLoadType.find(s => s.value == this.state.consigment.load_type)
                                                        }
                                                        placeholder="Select Load Type"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-2">
                                                    <label htmlFor="site_id" className="form-label ">Consigment Number</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <input
                                                        name="consigment_number"
                                                        type="text"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        onChange={(e) => this.formInputHandler(e, 'consigment')}
                                                        value={this.state.consigment.consigment_number}
                                                        placeholder="Enter Consigment Number"
                                                    />
                                                </div>
                                                <div className="col-sm-2">
                                                    <label htmlFor="site_id" className="form-label ">Booking Place</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <input
                                                        name="booking_place"
                                                        type="text"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        onChange={(e) => this.formInputHandler(e, 'consigment')}
                                                        value={this.state.consigment.booking_place}
                                                        placeholder="Enter Booking Place"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-2">
                                                    <label htmlFor="site_id" className="form-label ">Courier/Transporter Name</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <TapSelect
                                                        changeEvent={(selectedOption) => {
                                                            this.tapSelectChange(selectedOption, 'consigment', 'transporter_id');
                                                        }}
                                                        options={this.state.transportersList}
                                                        value={this.state.transportersList.find(s => s.value == this.state.consigment.transporter_id)}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        placeholder="Select Material Handler"
                                                    />
                                                </div>
                                                <div className="col-sm-2">
                                                    <label htmlFor="site_id" className="form-label ">Vehicle Number</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <input
                                                        name="vehicle_number"
                                                        type="text"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        onChange={(e) => this.formInputHandler(e, 'consigment')}
                                                        value={this.state.consigment.vehicle_number}
                                                        placeholder="Enter Vehicle Number"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-2">
                                                    <label htmlFor="site_id" className="form-label ">Weight</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <input
                                                        name="weight"
                                                        type="text"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        onChange={(e) => this.formInputHandler(e, 'consigment')}
                                                        value={this.state.consigment.weight}
                                                        placeholder="Enter Weight"
                                                    />
                                                </div>
                                                <div className="col-sm-2">
                                                    <label htmlFor="site_id" className="form-label ">No. of Boxes</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <input
                                                        name="number_of_boxes"
                                                        type="text"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        onChange={(e) => this.formInputHandler(e, 'consigment')}
                                                        value={this.state.consigment.number_of_boxes}
                                                        placeholder="Enter No. of Boxes"
                                                    />
                                                </div>
                                            </div> 
                                        </Ax>
                                        :
                                        this.state.modalType === "Billing"
                                        ?
                                        <Ax>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-2">
                                                    <label htmlFor="site_id" className="form-label ">Invoice Number</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <input
                                                        name="gst_invoice_number"
                                                        type="text"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        value={this.state.billing.gst_invoice_number}
                                                        onChange={(e) => this.formInputHandler(e, 'billing')}
                                                        placeholder="Enter Invoice Number"
                                                    />
                                                </div>
                                                <div className="col-sm-2">
                                                    <label htmlFor="site_id" className="form-label ">Invoice Date</label>
                                                </div>
                                                <div className="col-sm-4 add_calender_section">
                                                    <DatePicker
                                                        selected={
                                                            this.state.billing.invoice_date
                                                                ? moment(this.state.billing.invoice_date, 'YYYY-MM-DD').toDate()
                                                                : false
                                                        }
                                                        onChange={(value, event) => this.formDateHandler('invoice_date', value, 'billing')}
                                                        name="invoice_date"
                                                        dateFormat="dd-MMM-yyyy"
                                                        className={"form-control"}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        autoComplete="off"
                                                        scrollMonthYearDropdown
                                                        placeholderText={`Enter Invoice Date`}
                                                    />
                                                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="Invoice Date" className="add_calender-icon" />
                                                </div>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-2">
                                                    <label htmlFor="site_id" className="form-label ">Invoice Value</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <input
                                                        name="invoice_value"
                                                        type="text"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        onChange={(e) => this.formInputHandler(e, 'billing')}
                                                        value={this.state.billing.invoice_value}
                                                        placeholder="Enter Invoice Value"
                                                    />
                                                </div>
                                                <div className="col-sm-2">
                                                    <label htmlFor="site_id" className="form-label ">Eway Bill Number</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <input
                                                        name="eway_bill_number"
                                                        type="text"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        value={this.state.billing.eway_bill_number}
                                                        onChange={(e) => this.formInputHandler(e, 'billing')}
                                                        placeholder="Enter Eway Bill Number"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-2">
                                                    <label htmlFor="site_id" className="form-label ">Payment Mode</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <TapSelect
                                                        changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'billing', 'payment_mode');}}
                                                        options={this.state.paymentMode}
                                                        value={this.state.paymentMode.find(s => s.value == this.state.billing.payment_mode)}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        placeholder="Select Payment Mode"
                                                    />
                                                </div>
                                                <div className="col-sm-2">
                                                    <label htmlFor="site_id" className="form-label ">Payment Date</label>
                                                </div>
                                                <div className="col-sm-4 add_calender_section">
                                                    <DatePicker
                                                        selected={
                                                            this.state.billing.payment_date
                                                                ? moment(this.state.billing.payment_date, 'YYYY-MM-DD').toDate()
                                                                : false
                                                        }
                                                        onChange={(value, event) => this.formDateHandler('payment_date', value, 'billing')}
                                                        name="payment_date"
                                                        dateFormat="dd-MMM-yyyy"
                                                        className={"form-control"}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        autoComplete="off"
                                                        scrollMonthYearDropdown
                                                        placeholderText={`Enter Payment Date`}
                                                    />
                                                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="Payment Date" className="add_calender-icon" />
                                                </div>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-2">
                                                    <label htmlFor="site_id" className="form-label ">Machinery/Material Description</label>
                                                </div>
                                                <div className="col-sm-10">
                                                    <input
                                                        name="machinery_description"
                                                        type="text"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        onChange={(e) => this.formInputHandler(e, 'billing')}
                                                        value={this.state.billing.machinery_description}
                                                        placeholder="Enter Machinery / Material Description"
                                                    />
                                                </div>
                                            </div> 
                                        </Ax>
                                        : 
                                        this.state.modalType === "C&F"
                                        ?
                                        <Ax>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-2">
                                                    <label htmlFor="site_id" className="form-label ">C&F Agent</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <TapSelect
                                                        changeEvent={(selectedOption) => {
                                                            this.tapSelectChange(selectedOption, 'cnf', 'cnf_agent_id');
                                                        }}
                                                        options={this.state.cnfAgentsList}
                                                        value={this.state.cnfAgentsList.find(s => s.value == this.state.cnf.cnf_agent_id)}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        placeholder="Select C&F Agent"
                                                    />
                                                </div>
                                                <div className="col-sm-2">
                                                    <label htmlFor="site_id" className="form-label ">Material Handler Name</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <TapSelect
                                                        changeEvent={(selectedOption) => {
                                                            this.tapSelectChange(selectedOption, 'cnf', 'handler_id');
                                                        }}
                                                        options={this.state.handlersList}
                                                        value={this.state.handlersList.find(s => s.value == this.state.cnf.handler_id)}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        placeholder="Select Material Handler"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-2">
                                                    <label htmlFor="site_id" className="form-label ">BOE Number</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <input
                                                        name="boe_number"
                                                        type="text"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        value={this.state.cnf.boe_number}
                                                        onChange={(e) => this.formInputHandler(e, 'cnf')}
                                                        placeholder="Enter BOE Number"
                                                    />
                                                </div>
                                            </div> 
                                        </Ax>
                                        :
                                        this.state.modalType === "Expense"
                                        ?
                                        <Ax>
                                            <div className="row align-items-center my-3">
                                                <div className="col-sm-2">
                                                    <label htmlFor="site_id" className="form-label ">Logistic Cost</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <input
                                                        name="logistic_cost"
                                                        type="text"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        onChange={(e) => {
                                                            this.formInputHandler(e, 'expense');
                                                        }}
                                                        value={this.state.expense.logistic_cost}
                                                        placeholder="Enter Logistic Cost"
                                                    />
                                                </div>
                                                {
                                                    logisticDetail.logistic_type === "International" &&
                                                    <Ax>
                                                        <div className="col-sm-2">
                                                            <label htmlFor="site_id" className="form-label ">CHA Cost</label>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <input
                                                                name="cha_cost"
                                                                type="text"
                                                                className="form-control"
                                                                autoComplete="off"
                                                                onChange={(e) => {
                                                                    this.formInputHandler(e, 'expense');
                                                                }}
                                                                value={this.state.expense.cha_cost}
                                                                placeholder="Enter CHA Cost"
                                                            />
                                                        </div>
                                                    </Ax>
                                                }
                                                <div className="col-sm-2 my-3">
                                                    <label htmlFor="site_id" className="form-label ">Handling Cost</label>
                                                </div>
                                                <div className="col-sm-4 my-3">
                                                    <input
                                                        name="handling_cost"
                                                        type="text"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        onChange={(e) => {
                                                            this.formInputHandler(e, 'expense');
                                                        }}
                                                        value={this.state.expense.handling_cost}
                                                        placeholder="Enter Handling Cost"
                                                    />
                                                </div>
                                                <div className="col-sm-2 my-3">
                                                    <label htmlFor="site_id" className="form-label ">Total Cost</label>
                                                </div>
                                                <div className="col-sm-4 my-3">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        disabled={true}
                                                        value={totalCost}
                                                        placeholder="Enter Total Cost"
                                                    />
                                                </div>
                                            </div>
                                        </Ax>
                                        :
                                        this.state.modalType === "Purchase & Inventory"
                                        ?
                                        <div className="row align-items-center mt-3">
                                            <div className="col-sm-3 col-form-label col-form-label-sm">
                                                <label htmlFor="site_id" className="form-label ">Purchase Order</label>
                                            </div>
                                            <div className="col-sm-8">
                                            <InputPurchaseOrderSearch
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'purchaseInventory', 'purchase_order_transaction_ids');
                                                    this.setState({ searchedOrderPurchase: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                                }}
                                                isMulti={true}
                                                menuPlacement="top"
                                                value={this.state.searchedOrderPurchase && this.state.searchedOrderPurchase.length > 0 ? this.state.searchedOrderPurchase : []}
                                            />
                                            </div>
                                            <div className="col-sm-3 col-form-label col-form-label-sm mt-3">
                                                <label htmlFor="site_id" className="form-label ">Purchase</label>
                                            </div>
                                            <div className="col-sm-8">
                                            <InputPurchaseSearch
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'purchaseInventory', 'purchase_transaction_ids');
                                                    this.setState({ searchedPurchase: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                                }}
                                                isMulti={true}
                                                menuPlacement="top"
                                                value={this.state.searchedPurchase && this.state.searchedPurchase.length > 0 ? this.state.searchedPurchase : []}
                                            />
                                            </div>
                                            <div className="col-sm-3 col-form-label col-form-label-sm mt-3">
                                                <label htmlFor="site_id" className="form-label ">Inventory Transfer</label>
                                            </div>
                                            <div className="col-sm-8">
                                            <InputInventoryTransferSearch
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'purchaseInventory', 'inv_transfer_transaction_ids');
                                                    this.setState({ searchedInvTransfer: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                                }}
                                                isMulti={true}
                                                menuPlacement="top"
                                                value={this.state.searchedInvTransfer && this.state.searchedInvTransfer.length > 0 ? this.state.searchedInvTransfer : []}
                                            />
                                            </div>
                                        </div> 
                                        :
                                        null
                                    }
                                </Ax>
                            </form>
                            }
                        </div>
                        <div className="modal-footer">
                            <button type="button" disabled={this.state.editFormSubmitting} className="btn btn-secondary mx-2" data-bs-dismiss="modal">Cancel</button>
                            <button type="submit" disabled={this.state.editFormSubmitting} className="btn btn-primary" form="editAddressForm">
                                Submit {this.state.editFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    

    render() {
        return (<Ax>{this.modalJsx()}</Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};

export default connect(mapStateToProps,null, null, { forwardRef: true })(LogisticEditModal);