import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import { connect } from "react-redux";
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import { Collapse, Modal } from 'bootstrap';
import {DisplayListPagination, DisplaySearchCriteria} from "../../../components/TapUi/index";
import {Link} from "react-router-dom";
import DownloadFile from "../../../services/DownloadFile";
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import TapSelect from "../../../components/ui/TapSelect";
import Status from '../../../components/ui/Status';
import { Helmet } from 'react-helmet';
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class AssetWiseComplianceReport extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.reportUrl              =   process.env.REACT_APP_IAM_API_SERVER + '/report/asset_compliances';
        this.formDatareportUrl      =   IAM_API_BASE_URL_2 + '/report/asset_consolidated/form_data';

        
        this.reportFilterFormInitialState   =   {
            search_asset_ids                    :   [],
            site_group_ids                      :   [],
            search_site_ids                     :   [],
            'asset_group_ids'                   :    [],
            search_asset_type_ids               :   [],
            search_asset_matrix_ids             :   [],
            search_manufacturer_ids             :   [],
            search_models                       :   [],
        };
        
        this.reportFilterFormLabel          =   {
            search_asset_ids                    :   'Assets : ',
            site_group_ids                      :   'Site Groups : ',
            search_site_ids                     :   'Sites : ',
            'asset_group_ids'                   :   'Asset Type Groups : ',
            search_asset_type_ids               :   'Asset Type : ',
            search_manufacturer_ids             :   'Manufacturer : ',
            search_models                       :   'Models : ',
        };
         
        this.state                          =   {
            formDataLoaded                      :   false,
            reportDownloadBtn                   :   false,
            reportDownloading                   :   false,
            all_warehouses                      :   [],
            reportFilterForm                    :   {...this.reportFilterFormInitialState},
            submittedReportFilterForm           :   {...this.reportFilterFormInitialState},
            listing_loading                     :   false,
            searchedAssets                      :   [],
            listingData                         :   [],
            listingMeta                         :   null,
            iam_asset_types                     :   [],
            iam_user_sites                      :   [],
            iam_manufactures                    :   [],
            iam_models                          :   [], 
            listingHeader                       :   [] ,
            customChecksheetList                :   [],
            assetData                           :   null,
            allSiteGroupsList                   :   [],
            allAssetTypesGroupsList                 :   [],
            reportData                          :   null,                  
        };

    }
    
    
    componentDidMount() {
        this.initilaizeAppDataToFilterForm(this.props);
        this.customCheksheetModal              =     new Modal(document.getElementById('customCheksheetModal'), {keyboard: false, backdrop :false});
    }
    
    componentWillReceiveProps(nextProps){
        if(nextProps.iam_user_sites !== this.props.iam_user_sites) {
            this.initilaizeAppDataToFilterForm(nextProps);
        }
    }
    
    initilaizeAppDataToFilterForm           =   (props)  =>  {

        const report_name   =   'asset_wise_compliance_report';
        const reportData    =   props?.report_listing.length > 0 ? props.report_listing.filter(d => d.key === report_name) : [];
        this.setState({
            reportData                  :   reportData.length > 0 ? reportData[0] : null,
        })

        HttpAPICall.withAthorization('GET', this.formDatareportUrl, this.props.access_token, null, null, (response) => {
                this.setState({
                    access_token                :   props.access_token,
                    formDataLoaded              :   true,
                    iam_asset_types             :   props.iam_asset_types ? props.iam_asset_types.map((at) => { return {value: at.id, label: at.asset_type_name}}) : [],
                    iam_models                  :   props.iam_models ? props.iam_models.map((m) => { return {value: m.id, label: m.model_name}}) : [],
                    iam_user_sites              :   props.iam_user_sites ? props.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}}) : [],
                    iam_manufactures            :   props.iam_manufactures ? props.iam_manufactures.map((m) => { return {value: m.id, label: m.manufacturer_name}}) : [],
                    allSiteGroupsList           :   response.data && response.data.site_groups && response.data.site_groups.length > 0 ? response.data.site_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_sites: oc.site_ids }) }) : [],
                    allAssetTypesGroupsList     :   props.asset_groups && props.asset_groups.length > 0 ? props.asset_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_asset_types: oc.asset_type_ids }) }) : [],
                }, () => {

                    this.reportFilterhandler()

                });
        });
           
    }

    reportFilterFormSubmit                  =   (event=null)  =>  {
        event && event.preventDefault();
        event && this.reportFilterhandler(true);
        
        let serachFormKeys                  =   Object.keys(this.state.reportFilterForm);
        let searchedElems                   =   [];
        let itemSearchParams                =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.reportFilterFormLabel[key];
            let value                       =   this.state.reportFilterForm[key];
            if(value && value.toString().length > 0) {
                itemSearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'site_group_ids') {
                        show_val            =   this.state.allSiteGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ')
                    }
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'asset_group_ids') {
                        show_val            =   this.state.allAssetTypesGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_asset_type_ids') {
                        show_val            =   this.state.iam_asset_types.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_user_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_manufacturer_ids') {
                        show_val            = this.state.iam_manufactures.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_models') {
                        show_val            = this.state.iam_models.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedReportFilterForm           :   {...this.state.reportFilterForm},
            formSearchedElems                   :   searchedElems
        }, () => {
             this.loadListingTblData(1);
        });
    }
    
    loadListingTblData                      =   (page=1) => {
        this.setState({
            listing_loading             :   true,
            listingData                 :   [],
            listingMeta                 :   null,
            reportDownloadBtn           :   false,
            reportDownloading           :   false
        });
        let params                          =   {page:page, ...this.state.submittedReportFilterForm};
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, params, {}, (response) => {
            let respData                =   response.data;
            this.setState({
                listingData                 :   respData.data,
                listingHeader               :   respData.pre_defined_compliances,
                reportDownloadBtn           :   true,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            });
        }, (err) => {}).then(() => {
            this.setState({listing_loading: false});
        });
    }

    
    reportDownloadHandler                   =   ()  =>  {
        this.setState({ reportDownloading: true});
        HttpAPICall.withAthorization('GET', this.reportUrl, this.props.access_token, { download : 'download',...this.state.submittedReportFilterForm} , {} , (response) => DownloadFile.file(response.data.file_path)).then(() => this.setState({reportDownloading: false}));
    }
    
    reportFilterhandler                     =   (collapse = true)  =>  {
        collapse && new Collapse(document.getElementById('reportFilterForm'));
        if(document.getElementById('pageTbl')) {
            document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        }
    }

    closeReport                             =       ()      =>  {
        this.props.history.goBack()
    }

    showCustomChecksheetModalInit       =   (data)      =>      {
        if(data){
            this.setState({
                customChecksheetList        :       data.custom_compliances,
                assetData                   :       data
            })
        }
        this.customCheksheetModal.show()
    }
    
    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-sm-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCubes} /> {this.state.reportData?.name}</h3>
                <div className="text-end mt15">
                   {this.state.reportDownloadBtn ? (<button disabled={this.state.reportDownloading || this.state.reportData?.download_access === 'N'} type="button" onClick={this.reportDownloadHandler} className="btn btn-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /> Download Detailed Report {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin"/>) : ''}</button>) : null} 
                    <button type="button" onClick={this.reportFilterhandler} className="btn btn-secondary">Change Filter</button>
                    <button type="button" className="btn btn-secondary" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                    </button>
                </div>
            </div>
        </div>);
    }
    
    reportFilterFormJsx = () => {
        return (<div id="reportFilterForm" className="bg-white collapse " >
            <form onSubmit={this.reportFilterFormSubmit} className={['p-3', 'filter_form'].join(' ')}>

                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset</label>
                    <div className="col-sm-6">
                        <InputAssetSearch
                            isMulti={true}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_ids');
                                this.setState({
                                    searchedAssets: selectedOption ? selectedOption : null
                                }, () => { });
                            }}
                            value={this.state.searchedAssets && this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allAssetTypesGroupsList}
                            changeEvent={(selectedOption) => {

                                this.setState({
                                    reportFilterForm: {
                                        ...this.state.reportFilterForm,
                                        asset_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                        search_asset_type_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_asset_types).flat() : []
                                    }
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allAssetTypesGroupsList.filter(s => this.state.reportFilterForm.asset_group_ids.includes(s.value))}
                            placeholder="Select Asset Type Group"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Asset Type Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allAssetTypesGroupsList}
                            changeEvent={(selectedOption) => {

                                this.setState({
                                    reportFilterForm: {
                                        ...this.state.reportFilterForm,
                                        asset_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                        search_asset_type_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_asset_types).flat() : []
                                    }
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allAssetTypesGroupsList.filter(s => this.state.reportFilterForm.asset_group_ids.includes(s.value))}
                            placeholder="Select Asset Type Group"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Asset Type</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_asset_types}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_asset_type_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_asset_types.filter(s => this.state.reportFilterForm.search_asset_type_ids.includes(s.value))}
                            placeholder="Select Asset Type"
                        />
                    </div>
                </div>

                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Manufacturer</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_manufactures}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_manufacturer_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_manufactures.filter(s => this.state.reportFilterForm.search_manufacturer_ids.includes(s.value))}
                            placeholder="Select Manufacturer"
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <label className="col-sm-3 col-form-label text-end">Model</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_models}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_models');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_models.filter(s => this.state.reportFilterForm.search_models.includes(s.value))}
                            placeholder="Select Models"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Site Group</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.allSiteGroupsList}
                            changeEvent={(selectedOption) => {

                                this.setState({
                                    reportFilterForm: {
                                        ...this.state.reportFilterForm,
                                        site_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                        search_site_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_sites).flat() : []
                                    }
                                })
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.allSiteGroupsList.filter(s => this.state.reportFilterForm.site_group_ids.includes(s.value))}
                            placeholder="Select Site Group"
                        />
                    </div>
                </div>
                <div className="row my-3">
                    <label className="col-sm-3 col-form-label text-end">Site Name</label>
                    <div className="col-sm-6">
                        <TapSelect
                            options={this.state.iam_user_sites}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'reportFilterForm', 'search_site_ids');
                            }}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            value={this.state.iam_user_sites.filter(s => this.state.reportFilterForm.search_site_ids.includes(s.value))}
                            placeholder="Select Sites"
                        />
                    </div>
                </div>


                <div className="row">
                    <div className="pb-3 col-9 text-end">
                        <button type="submit" disabled={this.state.listing_loading ? true : false} className="btn btn-primary px-4">Show Report</button>
                    </div>
                    <hr />
                </div>
            </form>
        </div>);
    }
    
    reportListingDisplayJsx                 =   ()  =>  {
        return (<div className="bg-white">
        
            { this.state.listing_loading
                ? <Loader />
                : (this.state.listingMeta ? this.reportTableJsx() : null)
            }
        </div>)
    }
    
    reportTableJsx                          =   ()  =>  {
     
        return (<Ax>
            <table className="table table-bordered  table-sm align-middle bg-white mt-2">
                <thead className="align-middle table-secondary">
                    <tr className="text-center">
                        <th scope="col" style={{ width: "5%" }} >S.No</th>
                        <th scope="col" style={{ width: "16%" }} className="text-start">Asset</th>
                        {this.state.listingHeader && this.state.listingHeader.length > 0
                            && this.state.listingHeader.map(header => { return (<th scope="col" style={{ width: "12%" }} >{header}</th>) })}

                        <th scope="col" style={{ width: "5%" }}>More</th>
                    </tr>
                </thead>
                <tbody>

                    {this.state.listingData.length > 0
                        ? (this.state.listingData.map((data, index) => {

                            return (
                                <tr>
                                    <td className='text-center'>{this.state.listingMeta ? this.state.listingMeta.from + index : index} </td>
                                    <td>{data.asset_id ? <Link to={`/asset/compliance/${data.asset_id}`} target="_blank">{data.name ? data.name : "-"} </Link> : "-"}</td>
                                    {this.state.listingHeader && this.state.listingHeader.length > 0
                                        && this.state.listingHeader.map(fc => {
                                            let asset_bcs_data = data.fiexd_compliances.find((abcs) => abcs.checksheet_name == fc);

                                            if (asset_bcs_data) {
                                                { return (<td style={{ color: asset_bcs_data.status_color_code }} className="text-center va_middle">{asset_bcs_data.display}</td>) }
                                            } else {
                                                { return (<td className="text-center va_middle"></td>) }
                                            }
                                        })}
                                        <td className='text-center'>{data.custom_compliances && data.custom_compliances.length > 0 
                                        ?  <tapIcon.FontAwesomeIcon icon={tapIcon.faTimesCircle} className="rotate"  color={"black"} onClick={() => this.showCustomChecksheetModalInit(data)}/>  
                                        :""}</td>

                                </tr>
                            )
                        }))
                        : <tr><td className="text-center" colSpan={9}>No Record Found</td></tr>
                    }

                </tbody>
            </table>
        </Ax>);
    }

    customChecksheetModalJsx                     =   ()  =>  {
        return (
            <div className="modal fade" id="customCheksheetModal" tabIndex="-1">
                <div className="modal-dialog modal-md modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchItemModalLabel">Custom Checksheets</h5>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close
                            </button>

                        </div>
                        <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "26%" }}> Asset Name (Code)   </td>
                                        <th>{this.state.assetData ? `${this.state.assetData.name} (${this.state.assetData.asset_code})` : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td>Current Site </td><th>{this.state.assetData ? `${this.state.assetData.site} ` : "-"}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-body">
                            <table className='table table-sm my-2  table-bordered text-sm'>
                                <thead className='bg-light'>
                                    <tr className='text-center'>
                                        <th className='text-start'>Name</th>
                                        <th style={{ width: '35%' }}>Due Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.customChecksheetList && this.state.customChecksheetList?.map(cs => {
                                        return (<tr className='text-center'>
                                            <td className='text-start'>{cs.checksheet_name ? cs.checksheet_name : "-"}</td>
                                            <td><Status color={cs.status_color_code}>{cs.display ? cs.display : "-"}</Status></td>
                                        </tr>)
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    render                                  =   ()  =>  {
        return (<ApplicationLayout>
             <Helmet><title>Asset Wise Compliance - Report</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12 pb-2">
                        {this.state.formDataLoaded
                            ? (<Ax>
                                {this.reportFilterFormJsx()}
                                <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} />
                                {this.reportListingDisplayJsx()}

                            </Ax>)
                            : <Loader />
                        }

                        {this.state.reportDownloadBtn == true
                            ? <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected + 1)} />
                            : null
                        }
                    </div>
                </div>
            </div>
            {this.customChecksheetModalJsx()}
        </ApplicationLayout>);
    }
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_asset_types         :   state.app && state.app.asset_type ? state.app.asset_type : [],
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        iam_manufactures        :   state.app && state.app.manufactures ? state.app.manufactures : [],
        iam_models              :   state.app && state.app.models ? state.app.models : [],
        asset_groups             :   state.app && state.app.asset_groups ? state.app.asset_groups : [],
        report_listing          :   state.app && state.app.report_listing && state.app.report_listing.length > 0 ? state.app.report_listing : []
    };
};

export default connect(mapStateToProps)(AssetWiseComplianceReport);