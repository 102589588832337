import React from 'react';
import AppBaseComponent from '../../../components/AppBaseComponent';
import { connect } from 'react-redux';
import Ax from '../../../components/hoc/Ax';
import HttpAPICall from '../../../services/HttpAPICall';
import { APP_BASE_URL, APP_BASE_URL_2 } from '../../../services/TapApiUrls';
import { toast } from 'react-toastify';
import TapIcon from '../../../services/TapIcon';
import {Link} from "react-router-dom";
import LogisticEditModal from './LogisticEditModal';
import TapSelect from '../../../components/ui/TapSelect';
import OriginEditModal from './OriginEditModal';
import DestinationEditModal from './DestinationEditModal';
import DocumentModal from './DocumentModal';
import swal from 'sweetalert';
import DateService from '../../../services/DateService';
import InventoryTransferDetail from '../inventoryTransfer/InventoryTransferDetail';
import { Modal } from 'bootstrap';
import PurchaseDetail from '../purchase/PurchaseDetail';
import PurchaseOrderDetailModal from '../purchaseOrder/PurchaseOrderDetailModal';

class LogisticDetail extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.state                  =   {
            logisticStatus              :   [],
            addCommentFormSubmitting    :   false,
            logisticStatusData          :   {status : this.props.logisticViewData.status ? this.props.logisticViewData.status.id : '', notes : this.props.logisticViewData.notes, logistic_id : this.props.logisticViewData.id},
            purchaseTransactionId       :   '',
            inventoryTransactionId      :   '',
            poTransactionId             :   '',
            allAllowedStatus            :   [],
        }

        this.LogisticEditModalRef       =   React.createRef(); 
        this.originEditModalRef         =   React.createRef(); 
        this.destinationEditModalRef    =   React.createRef(); 
        this.documentModalRef           =   React.createRef(); 
        this.PODetailModalRef           =   React.createRef();

        this.logisticDocDeleteUrl       =   APP_BASE_URL + '/logistic/logistic_document/delete';
        this.logisticUpdateStatusUrl    =   APP_BASE_URL_2 + '/logistic/update_status';

    }

    componentDidMount() { 
        this.initilaizeFormData();
        this.viewInventoryTransactionModal         =   new Modal(document.getElementById('viewInventoryTransactionModal'), {keyboard: false, backdrop :false});
        this.viewPurchaseTransactionModal         =   new Modal(document.getElementById('viewPurchaseTransactionModal'), {keyboard: false, backdrop :false});
    }

    initilaizeFormData                  =   () =>  {

        this.setState({
            allAllowedStatus     :   this.props.logisticViewData && this.props.logisticViewData.user_status && this.props.logisticViewData.user_status.length > 0 ? this.props.logisticViewData.user_status.map((s) => { return {value: s.id, label: s.name}; }) : [] ,
        });
    }

    deleteLogistocDocument              =   (doc_id)  =>  {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
                HttpAPICall.withAthorization('DELETE', this.logisticDocDeleteUrl + '/' + doc_id, this.props.access_token, {},{} ,(response) => {
                    toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                    this.props.getLogisticViewDetails(this.props.logisticViewData.transaction_id);
                }).then(() => { });
            }
        });
    }

    handleLogisticEditModal =   (type) => {
        this.LogisticEditModalRef.current.showModalInit(type);
    }

    handleOriginEditModal =   (type) => {
        this.originEditModalRef.current.showModalInit(type);
    }

    handleDestinationEditModal =   (type) => {
        this.destinationEditModalRef.current.showModalInit(type);
    }

    handleDocumentModal =   () => {
        this.documentModalRef.current.showModalInit();
    }

    closeView               =   () => {
        this.props.closeView();
    }

    LogisticDetailJsx       =   ()  => {
        const logisticViewData  =   this.props.logisticViewData;
        const canEdit           =   logisticViewData.can_edit;
        let originType          =   'Origin Detail';
        let destinationType     =   'Destination Detail';

        let status              =   logisticViewData.status ? logisticViewData.status.name : null;
        
        let origin_type_detail  =   '';
        if(logisticViewData.origin_type_detail) {
            origin_type_detail  = logisticViewData.origin_type_detail ? `${logisticViewData.origin_type_detail.name} (${logisticViewData.origin_type_detail.code})` : ''
        }

        let destination_type_detail  =   '';
        if(logisticViewData.destination_type_detail) {
            destination_type_detail  = logisticViewData.destination_type_detail ? `${logisticViewData.destination_type_detail.name} (${logisticViewData.destination_type_detail.code})` : ''
        }
        if(logisticViewData.origin_type) {
            if(logisticViewData.origin_type === 'warehouse') {
                originType  = 'Warehouse';
            }else if(logisticViewData.origin_type === 'vendor') {
                originType  = 'Vendor';
            }else if(logisticViewData.origin_type === 'customer') {
                originType  = 'Customer';
            }else if(logisticViewData.origin_type === 'manual_entry') {
                originType  = 'Origin Detail';
            } 
        }

        if(logisticViewData.destination_type) {
            if(logisticViewData.destination_type === 'warehouse') {
                destinationType  = 'Warehouse';
            }else if(logisticViewData.destination_type === 'vendor') {
                destinationType  = 'Vendor';
            }else if(logisticViewData.destination_type === 'customer') {
                destinationType  = 'Customer';
            }else if(logisticViewData.destination_type === 'manual_entry') {
                destinationType  = 'Destination Detail';
            } 
        }   

        return (
            <Ax>     
                <div className="tab_content_header">
                    <table className="table table-bordered bg-white" >
                        <thead className="table-secondary">
                            <tr>
                                <td style={{ width: "25%"}}>ID</td>
                                <td style={{ width: "25%" }}>Date</td>
                                <td style={{ width: "25%" }}>Status</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="fs14 fw500" scope="col">{logisticViewData.transaction_id}</td>
                                <td className="fs14 fw500" scope="col">{logisticViewData.transaction_date ? DateService.dateTimeFormat(logisticViewData.transaction_date, 'DD-MMM-YYYY') : ''}</td>
                                <td className="fs14 fw500" scope="col">
                                    {logisticViewData.status ? logisticViewData.status?.name : ''}
                                </td>  
                            </tr>
                        </tbody>
                    </table>
                    <div className="tab_content_wrapper mt-4">
                        <span className="content_heading">Transaction Details</span>
                        {
                            (status !== "Cancel" && canEdit !== "N" && (this.props.permissions && this.props.permissions.includes('logistic_edit'))) &&
                            <span className="text-end dropdown">
                                <button type="button" onClick={() => this.handleLogisticEditModal("Transaction")} className="btn btn-light">
                                <TapIcon.FontAwesomeIcon className="text-muted" icon={TapIcon.faPencilAlt} />
                                </button>
                            </span>
                        }
                    </div>
                    <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                        <tbody>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "25%" }}>Logistic Type</td>
                                <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData.logistic_type ? logisticViewData.logistic_type : ''}</td>
                                <td className="fs14 fw-normal" style={{ "width": "25%" }}>Division</td>
                                <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData.division_name ? logisticViewData.division_name : ''}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="tab_content_wrapper mt-4">
                        <span className="content_heading">Origin Details</span>
                        {
                            (status !== "Cancel" && canEdit !== "N" && (this.props.permissions && this.props.permissions.includes('logistic_edit'))) &&
                            <span className="text-end dropdown">
                            <button type="button" onClick={() => this.handleOriginEditModal("Origin")} className="btn btn-light">
                            <TapIcon.FontAwesomeIcon className="text-muted" icon={TapIcon.faPencilAlt} />
                            </button>
                            </span>
                        }
                    </div>
                    <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                        <tbody>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "25%" }}>{originType}</td>
                                <td className="fs14 fw500" style={{ "width": "75%" }}>{origin_type_detail}</td>
                            </tr>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "25%" }}>Address</td>
                                <td className="fs14 fw500" style={{ "width": "75%" }}>
                                    {
                                        logisticViewData.display_origin_address 
                                        ? 
                                        `${logisticViewData.display_origin_address.address1 ? logisticViewData.display_origin_address.address1 + ", " : ""}${logisticViewData.display_origin_address.address2 ? logisticViewData.display_origin_address.address2 + ", " : ""}${logisticViewData.display_origin_address.landmark ? logisticViewData.display_origin_address.landmark + ", " : ""}${logisticViewData.display_origin_address.city ? logisticViewData.display_origin_address.city + ", " : ""}${logisticViewData.display_origin_address.country ? logisticViewData.display_origin_address.country + ", " : ""}${logisticViewData.display_origin_address.state ? logisticViewData.display_origin_address.state + ", " : ""}${logisticViewData.display_origin_address.district ? logisticViewData.display_origin_address.district + ", " : ""}${logisticViewData.display_origin_address.pincode ? logisticViewData.display_origin_address.pincode + ", " : ""}`
                                        :
                                        ''
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="tab_content_wrapper mt-4">
                        <span className="content_heading">Destination Details</span>
                        {
                            (status !== "Cancel" && canEdit !== "N" && (this.props.permissions && this.props.permissions.includes('logistic_edit'))) &&
                            <span className="text-end dropdown">
                            <button type="button" onClick={() => this.handleDestinationEditModal("Destination")} className="btn btn-light">
                            <TapIcon.FontAwesomeIcon className="text-muted" icon={TapIcon.faPencilAlt} />
                            </button>
                            </span>
                        }
                    </div>
                    <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                        <tbody>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "25%" }}>{destinationType}</td>
                                <td className="fs14 fw500" style={{ "width": "75%" }}>{destination_type_detail}</td>
                            </tr>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "25%" }}>Address</td>
                                <td className="fs14 fw500" style={{ "width": "75%" }}>
                                    {
                                        logisticViewData.display_destination_address 
                                        ?
                                        `${logisticViewData.display_destination_address.address1 ? logisticViewData.display_destination_address.address1 + ", " : ""}${logisticViewData.display_destination_address.address2 ? logisticViewData.display_destination_address.address2 + ", " : ""}${logisticViewData.display_destination_address.landmark ? logisticViewData.display_destination_address.landmark + ", " : ""}${logisticViewData.display_destination_address.city ? logisticViewData.display_destination_address.city + ", " : ""}${logisticViewData.display_destination_address.country ? logisticViewData.display_destination_address.country + ", " : ""}${logisticViewData.display_destination_address.state ? logisticViewData.display_destination_address.state + ", " : ""}
                                        ${logisticViewData.display_destination_address.district ? logisticViewData.display_destination_address.district + ", " : ""}${logisticViewData.display_destination_address.pincode ? logisticViewData.display_destination_address.pincode + ", " : ""}`
                                        :
                                        ''
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="tab_content_wrapper mt-4">
                        <span className="content_heading">Tracking Details</span>
                        {
                            (status !== "Cancel" && canEdit !== "N" && (this.props.permissions && this.props.permissions.includes('logistic_edit'))) &&
                            <span className="text-end dropdown">
                            <button type="button" onClick={() => this.handleLogisticEditModal("Tracking")} className="btn btn-light">
                            <TapIcon.FontAwesomeIcon className="text-muted" icon={TapIcon.faPencilAlt} />
                            </button>
                            </span>
                        }
                    </div>
                    <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                        <tbody>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "25%" }}>Date of Dispatch</td>
                                <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData.dispatch_date ? DateService.dateTimeFormat(logisticViewData.dispatch_date, 'DD-MMM-YYYY') : ''}</td>
                                <td className="fs14 fw-normal" style={{ "width": "25%" }}>Expected Delivery Date</td>
                                <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData.delivery_date ? DateService.dateTimeFormat(logisticViewData.delivery_date, 'DD-MMM-YYYY') : ''}</td>
                            </tr>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "25%" }}>Delivery Date at Godown</td>
                                <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData.delivery_date_at_godown ? DateService.dateTimeFormat(logisticViewData.delivery_date_at_godown, 'DD-MMM-YYYY') : ''}</td>
                                <td className="fs14 fw-normal" style={{ "width": "25%" }}>Delivery Date at Site</td>
                                <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData.delivery_date_at_site ? DateService.dateTimeFormat(logisticViewData.delivery_date_at_site, 'DD-MMM-YYYY') : ''}</td>
                            </tr>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "25%" }}>Transport Days</td>
                                <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData.transport_days ? logisticViewData.transport_days : ''}</td>
                                <td className="fs14 fw-normal" style={{ "width": "25%" }}>Receiver Name</td>
                                <td className="fs14 fw500" colSpan={3} >{logisticViewData.receiver_name}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="tab_content_wrapper mt-4">
                        <span className="content_heading">Consigment Details</span>
                        {
                            (status !== "Cancel" && canEdit !== "N" && (this.props.permissions && this.props.permissions.includes('logistic_edit'))) &&
                            <span className="text-end dropdown">
                            <button type="button" onClick={() => this.handleLogisticEditModal("Consigment")} className="btn btn-light">
                            <TapIcon.FontAwesomeIcon className="text-muted" icon={TapIcon.faPencilAlt} />
                            </button>
                            </span>
                        }
                    </div>
                    <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                        <tbody>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "25%" }}>Type of Load</td>
                                <td className="fs14 fw500" style={{ "width": "25%" }}>
                                    {logisticViewData.load_type ? logisticViewData.load_type : ''}
                                </td>
                                <td className="fs14 fw-normal" style={{ "width": "25%" }}>Consigment Number</td>
                                <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData.consigment_number ? logisticViewData.consigment_number : ''}</td>
                            </tr>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "25%" }}>Booking Place</td>
                                <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData.booking_place ? logisticViewData.booking_place : ''}</td>
                                <td className="fs14 fw-normal" style={{ "width": "25%" }}>Courier/Transporter Name</td>
                                <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData.transporter_name ? logisticViewData.transporter_name : ''}</td>
                            </tr>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "25%" }}>Vehicle Number</td>
                                <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData.vehicle_number ? logisticViewData.vehicle_number : ''}</td>
                                <td className="fs14 fw-normal" style={{ "width": "25%" }}>Weight</td>
                                <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData.weight ? logisticViewData.weight : ''}</td>
                            </tr>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "25%" }}>No. of Boxes</td>
                                <td className="fs14 fw500" colSpan="3">{logisticViewData.number_of_boxes ? logisticViewData.number_of_boxes : ''}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="tab_content_wrapper mt-4">
                        <span className="content_heading">Billing Details</span>
                        {
                            (status !== "Cancel" && canEdit !== "N" && (this.props.permissions && this.props.permissions.includes('logistic_edit'))) &&
                            <span className="text-end dropdown">
                            <button type="button" onClick={() => this.handleLogisticEditModal("Billing")} className="btn btn-light">
                            <TapIcon.FontAwesomeIcon className="text-muted" icon={TapIcon.faPencilAlt} />
                            </button>
                            </span>
                        }
                    </div>
                    <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                        <tbody>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "25%" }}>Invoice Number</td>
                                <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData.gst_invoice_number ? logisticViewData.gst_invoice_number : ''}</td>
                                <td className="fs14 fw-normal" style={{ "width": "25%" }}>Invoice Date</td>
                                <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData.invoice_date ? DateService.dateTimeFormat(logisticViewData.invoice_date, 'DD-MMM-YYYY') : ''}</td>
                            </tr>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "25%" }}>Invoice Value</td>
                                <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData.invoice_value ? logisticViewData.invoice_value : ''}</td>
                                <td className="fs14 fw-normal" style={{ "width": "25%" }}>Eway Bill Number</td>
                                <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData.eway_bill_number ? logisticViewData.eway_bill_number : ''}</td>
                            </tr>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "25%" }}>Payment Mode</td>
                                <td className="fs14 fw500" style={{ "width": "25%" }} >{logisticViewData.payment_mode ? logisticViewData.payment_mode : ''}</td>
                                <td className="fs14 fw-normal" style={{ "width": "25%" }}>Payment Date</td>
                                <td className="fs14 fw500" style={{ "width": "25%" }} >{logisticViewData.payment_date ? DateService.dateTimeFormat(logisticViewData.payment_date, 'DD-MMM-YYYY') : ''}</td>
                            </tr>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "25%" }}>Machinery/Material Description</td>
                                <td className="fs14 fw500" colspan="3">{logisticViewData.machinery_description ? logisticViewData.machinery_description : ''}</td>
                            </tr>
                        </tbody>
                    </table>

                    {
                        this.props.logisticViewData.logistic_type === "International" &&
                        <Ax>
                            <div className="tab_content_wrapper mt-4">
                                <span className="content_heading">C&F Details</span>
                                {
                                    (status !== "Cancel" && canEdit !== "N" && (this.props.permissions && this.props.permissions.includes('logistic_edit'))) &&
                                    <span className="text-end dropdown">
                                    <button type="button" onClick={() => this.handleLogisticEditModal("C&F")} className="btn btn-light">
                                    <TapIcon.FontAwesomeIcon className="text-muted" icon={TapIcon.faPencilAlt} />
                                    </button>
                                    </span>
                                }
                            </div>
                            <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                                <tbody>
                                    <tr>
                                        <td className="fs14 fw-normal" style={{ "width": "25%" }}>Material Handler Name</td>
                                        <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData.handler_name ? logisticViewData.handler_name : ''}</td>
                                        <td className="fs14 fw-normal" style={{ "width": "25%" }}>C&F Agent</td>
                                        <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData.cnfAgent_name ? logisticViewData.cnfAgent_name : ''}</td>
                                    </tr>
                                    <tr>
                                        <td className="fs14 fw-normal" style={{ "width": "25%" }}>BOE Number</td>
                                        <td className="fs14 fw500" colspan="3">{logisticViewData.boe_number ? logisticViewData.boe_number : ''}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Ax>
                    }
                    <div className="tab_content_wrapper mt-4">
                        <span className="content_heading">Expense Details</span>
                        {
                            (status !== "Cancel" && canEdit !== "N" && (this.props.permissions && this.props.permissions.includes('logistic_edit'))) &&
                            <span className="text-end dropdown">
                            <button type="button" onClick={() => this.handleLogisticEditModal("Expense")} className="btn btn-light">
                            <TapIcon.FontAwesomeIcon className="text-muted" icon={TapIcon.faPencilAlt} />
                            </button>
                            </span>
                        }
                    </div>
                    <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                        <tbody>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "25%" }}>Logistic Cost</td>
                                <td className="fs14 fw500" colSpan={this.props.logisticViewData.logistic_type === "Domestic" ? 3 : ""}>{logisticViewData.logistic_cost ? logisticViewData.logistic_cost : ''}</td>
                                {
                                    this.props.logisticViewData.logistic_type === "International" &&
                                    <Ax>
                                        <td className="fs14 fw-normal" style={{ "width": "25%" }}>CHA Cost</td>
                                        <td className="fs14 fw500" >{logisticViewData.cha_cost ? logisticViewData.cha_cost : ''}</td>
                                    </Ax>
                                }
                            </tr>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "25%" }}>Handling Cost</td>
                                <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData.handling_cost ? logisticViewData.handling_cost : ''}</td>
                                <td className="fs14 fw-normal" style={{ "width": "25%" }}>Total Cost</td>
                                <td className="fs14 fw500" >{logisticViewData.total_cost ? logisticViewData.total_cost : ''}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div>
                    <div className="tab_content_wrapper mt-4">
                        <span className="content_heading">Linking with Purchase & Inventory Transfer</span>
                        {
                            (status !== "Cancel" && canEdit !== "N" && (this.props.permissions && this.props.permissions.includes('logistic_edit'))) &&
                            <span className="text-end dropdown">
                            <button type="button" onClick={() => this.handleLogisticEditModal("Purchase & Inventory")} className="btn btn-light">
                            <TapIcon.FontAwesomeIcon className="text-muted" icon={TapIcon.faPencilAlt} />
                            </button>
                            </span>
                        }
                    </div>
                    <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                        <tbody>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "25%" }}>Purchase Order</td>
                                <td className="fs14 fw500" style={{ "width": "75%" }}>
                                {
                                    (logisticViewData?.purchase_order_transaction_ids && logisticViewData.purchase_order_transaction_ids.length > 0) &&
                                    logisticViewData.purchase_order_transaction_ids.map((val, i) => {
                                        return <Link key={i} onClick={() => this.poDetailModalInit(val)}>{val} {(i !== logisticViewData.purchase_order_transaction_ids.length -1) ? ', ' : ''}</Link>;
                                    })
                                }
                                </td>
                            </tr>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "25%" }}>Purchase</td>
                                <td className="fs14 fw500" style={{ "width": "75%" }}>
                                {
                                    (logisticViewData?.purchase_transaction_ids && logisticViewData.purchase_transaction_ids.length > 0) &&
                                    logisticViewData.purchase_transaction_ids.map((val, i) => {
                                        return <Link key={i} onClick={() => this.purchaseModal(val)}>{val} {(i !== logisticViewData.purchase_transaction_ids.length -1) ? ', ' : ''}</Link>;
                                    })
                                }
                                </td>
                            </tr>
                            <tr>
                                <td className="fs14 fw-normal" style={{ "width": "25%" }}>Inventory Transfer</td>
                                <td className="fs14 fw500" style={{ "width": "75%" }}>
                                {
                                    (logisticViewData?.inv_transfer_transaction_ids && logisticViewData.inv_transfer_transaction_ids.length > 0) &&
                                    logisticViewData.inv_transfer_transaction_ids.map((val, i) => {
                                        return <Link key={i} onClick={() => this.invTransferModal(val)}>{val} {(i !== logisticViewData.inv_transfer_transaction_ids.length -1) ? ', ' : ''}</Link>;
                                    })
                                }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="tab_content_wrapper mt-4">
                        <span className="content_heading">Logistic Document</span>
                        {
                            (status !== "Cancel" && canEdit !== "N" && (this.props.permissions && this.props.permissions.includes('logistic_edit') && this.props.permissions.includes('logistic_doc_add'))) &&
                            <span className="text-end dropdown">
                            <button type="button" onClick={() => this.handleDocumentModal()} className="btn btn-light">
                            <TapIcon.FontAwesomeIcon className="text-muted" icon={TapIcon.faPlus} />
                            </button>
                            </span>
                        }
                    </div>
                    <table className="table table-bordered bg-white my-2">
                        <thead className="table-secondary">
                            <tr>
                                <td style={{ "width": "5%" }}>S.No</td>
                                <td style={{ "width": "35%" }}>Name</td>
                                <td style={{ "width": "30%" }}>Number</td>
                                <td style={{ "width": "10%" }}>View</td>
                                <td style={{ "width": "10%" }}>Download</td>
                                <td style={{ "width": "10%" }}>Delete</td>
                            </tr>
                        </thead>
                        <tbody>
                            {logisticViewData.documents && logisticViewData.documents.length > 0 ? logisticViewData.documents.map((d,i) => {
                                return <tr className='text-center'>
                                    <td>{i + 1}</td>
                                    <td>{d.name}</td>
                                    <td>{d.number}</td>
                                    <td scope="col" className="text-center" style={{ "width": "10%" }}>
                                        <Link role="button"
                                            target='_blank'
                                            to={{pathname : d.document}}
                                            className={[d.view_permission ? '' : 'disabled-link'].join(' ')}
                                            title={d.view_permission ? '' : 'You do not have permission to perform this action'}
                                            style={{ "pointerEvents": "all" }}
                                        > <TapIcon.FontAwesomeIcon icon={TapIcon.faEye}  /></Link>
                                    </td>
                                    <td scope="col" className="text-center" style={{ "width": "10%" }}>
                                        <Link role="button"
                                            target='_blank'
                                            to={{pathname : d.download_link}}
                                            className={[d.downlaod_permission ? '' : 'disabled-link'].join(' ')}
                                            title={d.downlaod_permission ? '' : 'You do not have permission to perform this action'}
                                            style={{ "pointerEvents": "all" }}
                                        > <TapIcon.FontAwesomeIcon icon={TapIcon.faDownload}  /></Link>
                                    </td>
                                    <td scope="col" className="text-center" style={{ "width": "10%" }}>
                                        <a role="button"
                                            onClick={() => {
                                                if (this.props.permissions.includes('logistic_doc_delete')) {
                                                   return this.deleteLogistocDocument(d.doc_id)
                                               }
                                            }}
                                            className={[this.props.permissions.includes('logistic_doc_delete') ? '' : 'disabled-link'].join(' ')}
                                            title={!this.props.permissions.includes('logistic_doc_delete') ? 'You do not have permission to perform this action' : ''}
                                            style={{ "pointerEvents": "all" }}
                                        > <TapIcon.FontAwesomeIcon icon={TapIcon.faTrashAlt}  /></a>
                                    </td>
                                </tr>
                            })
                            : <tr><td className='text-center' colSpan="6">No Document Found</td></tr>
                        }
                        </tbody>
                    </table>
                    <div className="tab_content_wrapper mt-4">
                        <span className="content_heading">Comments & History</span>
                    </div>
                    <form onSubmit={this.submitUpdateCommentForm}>
                        <div className="my-3 row">
                            <label className="col-sm-3 col-form-label">Change status to</label>
                            <div className="col-sm-5">
                                <TapSelect
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(selectedOption, 'logisticStatusData', 'status');
                                    }}
                                    value={this.state.allAllowedStatus.find(s => s.value == this.state.logisticStatusData.status)}
                                    options={this.state.allAllowedStatus}
                                    isDisabled={(status === "Cancel" || canEdit === "N" || !(this.props.permissions && this.props.permissions.includes('logistic_edit'))) ? true : false}
                                    isSearchable={false}
                                    placeholder="Please Select Status"
                                />
                            </div>
                        </div>
                        <div className="my-2">
                            <label className="form-label">Comments</label>
                            <textarea
                                name="notes"
                                value={this.state.logisticStatusData.notes}
                                onChange={(e) => this.formInputHandler(e, 'logisticStatusData')}
                                className="form-control"
                                placeholder="Please Add a comment"
                                required
                                style={{ height: "100px" }}
                            />
                        </div>
                        <div className="col-12 text-end">
                            <button type="submit" disabled={this.state.addCommentFormSubmitting ? true : false} className="btn btn-primary mx-2">
                                Update {this.state.addCommentFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}
                            </button>
                        </div>
                    </form>
                    <hr/>
                    <div className="my-1">
                        {
                            logisticViewData.comments && logisticViewData.comments.length > 0 
                            ? 
                            logisticViewData.comments.map((c, k) => {
                                return (
                                    <Ax key={k}>
                                        <div className="row">
                                            {
                                                c.msg 
                                                ? 
                                                <div className="col-sm-8" dangerouslySetInnerHTML={{ __html: c.msg }} /> 
                                                : 
                                                <div className='col-sm-8'>-</div>
                                            }
                                            <div className="col-sm-4 text-end">
                                                <TapIcon.imageIcon icon={TapIcon.CalenderIcon} className="img-fluid pr-5 fs12" alt="item-action" />
                                                <span> {DateService.dateTimeFormat(c.created_at)}</span>
                                            </div>
                                            <div className="col-sm-8 my-1">{c.notes}</div>
                                        </div>
                                        <hr />
                                    </Ax>
                                );
                            })
                            : null
                        }
                    </div>
                </div>
            </div>       
            </Ax>
        )
    }

    submitUpdateCommentForm=(e) =>{
        e.preventDefault();

        this.setState({addCommentFormSubmitting : true});

        HttpAPICall.withAthorization('PUT', this.logisticUpdateStatusUrl, this.props.access_token, this.state.logisticStatusData, null, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.props.getLogisticViewDetails(this.props.logisticViewData.transaction_id);
        }).then(()  => this.setState({addCommentFormSubmitting : false}));

    }

    invTransferModal    =   (invId) => {
        this.setState({
            inventoryTransactionId :   invId
       }, () => {
            this.viewInventoryTransactionModal.show();
       }); 
    }

    purchaseModal = (purId) => {
        this.setState({
            purchaseTransactionId : purId
        }, () => {
            this.viewPurchaseTransactionModal.show();
        })
    }

    poDetailModalInit          =       (req_item_id)           =>      {
        this.PODetailModalRef.current.modalPOInit(req_item_id); 
    }


    purchaseDetailsModalJsx                     =   ()  =>  {
        return (
            <div className="modal fade" id="viewPurchaseTransactionModal" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" >Transaction Id : {this.state.purchaseTransactionId ? this.state.purchaseTransactionId : "-"}</h5>
                            <button type = "button" className="btn btn-secondary" data-bs-dismiss="modal">Close
                            </button>
                        </div>
                        <div className="modal-body">
                          <PurchaseDetail purchaseId={this.state.purchaseTransactionId}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    inventoryTransactionDetailsModalJsx                     =   ()  =>  {
        return (
            <div className="modal fade" id="viewInventoryTransactionModal" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Transaction Id : {this.state.inventoryTransactionId ? this.state.inventoryTransactionId : "-"}</h5>
                            <button type = "button" className="btn btn-secondary" data-bs-dismiss="modal">Close
                            </button>
                        </div>
                        <div className="modal-body">
                          <InventoryTransferDetail inventoryId={this.state.inventoryTransactionId}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    
    render() {
        return (
            <Ax>
                {this.LogisticDetailJsx()}
                <LogisticEditModal ref = {this.LogisticEditModalRef} LogisticDetail = {this.props.logisticViewData} getLogisticViewDetails={(transaction_id)=>this.props.getLogisticViewDetails(transaction_id)}/>
                <OriginEditModal ref = {this.originEditModalRef} logisticDetail = {this.props.logisticViewData} getLogisticViewDetails={(transaction_id)=>this.props.getLogisticViewDetails(transaction_id)}/>
                <DestinationEditModal ref = {this.destinationEditModalRef} logisticDetail = {this.props.logisticViewData} getLogisticViewDetails={(transaction_id)=>this.props.getLogisticViewDetails(transaction_id)}/>
                <DocumentModal ref = {this.documentModalRef} logisticDetail = {this.props.logisticViewData} getLogisticViewDetails={(transaction_id)=>this.props.getLogisticViewDetails(transaction_id)}/>
                {this.inventoryTransactionDetailsModalJsx()}
                {this.purchaseDetailsModalJsx()}
                <PurchaseOrderDetailModal parentProps={this.props} ref={this.PODetailModalRef}/>
            </Ax>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(LogisticDetail);