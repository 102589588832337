import React from 'react';
import moment from 'moment';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import axios from 'axios';
import Loader from "../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import ReactPaginate from "react-paginate";
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import TapApiUrls from '../../../services/TapApiUrls';
import HttpAPICall from '../../../services/HttpAPICall';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import { Helmet } from 'react-helmet';
import AppBaseComponent from '../../../components/AppBaseComponent';
import { Modal } from 'bootstrap';
import { Link } from 'react-router-dom';
import swal from "sweetalert";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";
import UpdateAttendenceModal from './UpdateAttendenceModal';
import Status from '../../../components/ui/Status';


class DailyCounterlog extends AppBaseComponent {

    constructor(props) {
        super(props);
        
        this.addAttendenceFormInit          =       {
            status          :           "present",
            check_in        :           "",
            check_out       :           "",
            check_in_date   :           new Date(),
            check_out_date   :           new Date(),
        }

        this.state                          =   {
            form_data_loading               :   false,
            formDataLoaded                  :   false,
            all_counterlog_category         :   [],
            formSearchedElems               :   [],
            listing_loading                 :   false,
            listing_tbl_page                :   1,
            employee_listing              :   [],
            listingMeta                     :   null,
            rosterMeta                      :   null,
            totalListingCount               :   0, 
            date                            :   new Date(), 
            isLoading                       :   false,
            addAttendenceForm               :   {...this.addAttendenceFormInit}
        };
        this.updateAttendenceModalRef           =   React.createRef(); 
    }

    componentDidMount() {
      this.initilaizeAppDataToFilterForm(this.props);
    }

    componentWillReceiveProps(nextProps){
        this.initilaizeAppDataToFilterForm(nextProps);
    }

    initilaizeAppDataToFilterForm           =   (props)  =>  {
        if(!this.state.formDataLoaded){
            this.setState({form_data_loading : true})
            HttpAPICall.withAthorization('GET',  TapApiUrls.IAM + '/counterlog/asset_wise_shift_reading_form_data' , this.props.access_token, {}, {}, (resp) => {
                        
                this.setState({
                   formDataLoaded          :   true,
            },() => this.loadListingTblData(1));
            }).then(() => this.setState({form_data_loading: false}));           
        }
    
    }


    //************************GET PO LISTING**************** */
    loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {...this.state.submittedCounterlogFilterForm,page:page,per_page : 50,date : this.state.date ? moment(this.state.date).format('YYYY-MM-DD') : ''};
        HttpAPICall.withAthorization('GET',  TapApiUrls.HRM_URL + '/attendance/list' , this.props.access_token, params, {}, (resp) => {
          let respData            =       resp.data;          
           
            if(respData.rosterMeta && respData.rosterMeta.any_shift_started && respData.rosterMeta.any_shift_started == 'N'){
                this.goToPreviousDateHandler()
            }else{
                this.setState({
                    employee_listing          :       respData.data,
                    listingMeta                :       respData.meta,
                    rosterMeta                 :       respData.rosterMeta,
                    totalListingCount          :       respData.meta.total,
                });
            }

        }).then(() => this.setState({listing_loading: false}));
    }

    refreshattendence                     =   ()  =>  this.loadListingTblData(1)

    goToPreviousDateHandler         =       ()      =>      {
        swal({
            title: "Shift not Started",
            text: "Shift for the current date still not started. Navigating to the previous day.",
            icon: "warning",
             dangerMode: true,
             showCancelButton: false,
             showConfirmButton: true,
            buttons: "Ok",
        }).then(willNavigate => {
            if (willNavigate) { 
               this.dateChangeHandler('prev')
            }
        });
    }

    dateChangeHandler       =   (type)   =>  {
        if(type == 'prev'){
         let date = this.state.date;
         this.setState({date : moment(date).subtract(1, 'days').format('YYYY-MM-DD')},() => this.loadListingTblData(1))
         
        }else{
            let date = this.state.date;
            this.setState({date : moment(date).add(1, 'days').format('YYYY-MM-DD')},() => this.loadListingTblData(1))           
        }
    }

    updateEmployeeAttendenceModalInit       =   (employee)      =>  {
        let date                            =   moment(this.state.date, 'YYYY-MM-DD').format('YYYY-MM-DD');
        this.updateAttendenceModalRef.current.updateAttendenceInit(date, employee);
    }

    checkAllItem() {
        
        let employeeCollections      =   document.getElementsByClassName('employee_id_checkbox')
        if(employeeCollections && employeeCollections.length > 0) {
            for (let i = 0; i < employeeCollections.length; i++) {
                employeeCollections[i].checked = document.getElementById('all_check_employee').checked;
            }
        }
    }

    updateMultipleAttendenceInit       =       ()      =>      {
        // this.setState({cancelledItemList : []} , () => {
        var showErrorMessage                =   false;
        let employee_ids        =       []
        let employeeCollectionsforCheckbox      =   document.getElementsByClassName('employee_id_checkbox');
        if(employeeCollectionsforCheckbox && employeeCollectionsforCheckbox.length > 0) {
            for (let i = 0; i < employeeCollectionsforCheckbox.length; i++) {
                if(employeeCollectionsforCheckbox[i].checked) {
                    let emp_id = employeeCollectionsforCheckbox[i].value;
                    let data = this.state.employee_listing.find(i => i.enc_id == emp_id);
                    employee_ids.push({...data})
                }
            }
        }
        if(employee_ids && employee_ids.length == 0 && !showErrorMessage){
            toast.error('Please select an Employee', { position: toast.POSITION.TOP_RIGHT });
        }else{
          
            let date                            =   moment(this.state.date, 'YYYY-MM-DD').format('YYYY-MM-DD');
            this.updateAttendenceModalRef.current.updateAttendenceInit(date, employee_ids);
        }
   
    }

  
    afterSubmitAttendenceHandler = (date, empData) => {
        let itemList = [...this.state.employee_listing];
    
        // Iterate through each employee in empData
        empData.forEach(emp => {
            let empIndex = itemList.findIndex(i => i.enc_id === emp.enc_id);
    
            // Update loading status for the current employee
            itemList[empIndex] = { ...itemList[empIndex], isLoading: true };
        });
    
        this.setState({ employee_listing: itemList });
    
        let empIds = empData.map(emp => emp.enc_id);
    
        let params = {
            page: 1,
            per_page: 50,
            search_employee_ids: empIds,
            date: moment(this.state.date, 'YYYY-MM-DD').format('YYYY-MM-DD')
        };
    
        HttpAPICall.withAthorization('GET', TapApiUrls.HRM_URL + '/attendance/list', this.props.access_token, params, {}, (resp) => {
            if (resp.data && resp.data.data) {
                resp.data.data.forEach(updatedEmp => {
                    let empIndex = itemList.findIndex(i => i.enc_id === updatedEmp.enc_id);
                    itemList[empIndex] = updatedEmp;
                });
                this.setState({ employee_listing: itemList });
            }
        });
    }
    
   
    dailyAttendenceListJsx            =       ()      =>    {
        return (<Ax>
            <div className="page_title row m0">
                <div className="col">
                    <h3>Daily Employee Attendence</h3>
                </div>
                <div className="col-5 mt15">
                    <div className="input-group justify-content-center">
                        <button className="btn btn-secondary px-3" type="button" disabled={this.state.listing_loading} onClick={() => this.dateChangeHandler('prev')}>
                            <tapIcon.FontAwesomeIcon icon={tapIcon.faArrowLeft} className="me-1" />  Prev
                        </button>
                        <div className="add_calender_section">
                            <DatePicker
                                selected={ this.state.date ? moment(this.state.date).toDate() : false }
                                name="date"
                               
                                onChange={(value, event) => {
                                    this.formDateStateHandler(value, 'date','YYYY-MM-DD',() => this.loadListingTblData(1))
                                }}
                               
                                dateFormat="dd-MMM-yyyy"
                                className="form-control form-control-sm"
                                showMonthDropdown
                                showYearDropdown
                                autoComplete="off"
                                scrollMonthYearDropdown
                                required={true}
                                maxDate={new Date()}
                                disabled={this.state.listing_loading}
                                placeholderText={`Please Enter Date `}
                            />
                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon} className="add_calender-icon" style={{ top: "6px" }} />
                        </div>
                        <button className="btn btn-secondary  px-3" type="button" disabled={this.state.listing_loading || (this.state.rosterMeta && !this.state.rosterMeta.next_date)} onClick={() => this.dateChangeHandler('next')}>
                            Next <tapIcon.FontAwesomeIcon icon={tapIcon.faArrowRight} className="ms-1" />
                        </button>
                    </div>
                </div>
                <div className="col text-end mt15">
                    <button type="button" className="btn btn-secondary" onClick={this.refreshattendence} disabled={this.state.listing_loading}>
                        <tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className={[this.state.listing_loading ? "fa-spin" : ''].join(' ')} color="white" />
                    </button>
                    <button type="button" className="btn btn-secondary" disabled={this.state.listing_loading} onClick={() => this.updateMultipleAttendenceInit()}>
                       Update Attendence
                    </button>
                    {/* <button type="button" className="btn btn-secondary" disabled={this.state.listing_loading} onClick={() => this.counterlogSearchModalInit()}>
                        <tapIcon.imageIcon icon={tapIcon.SearchIcon} />
                    </button> */}
                    {/* <button type="button" className="btn btn-secondary" disabled={this.state.file_downloading || this.state.listing_loading} onClick={this.attendenceDownloadHandler} >
                        <tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} />
                        {this.state.file_downloading ? <tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" /> : null}
                    </button> */}
                </div>
            </div>
            <div className="container-fluid pl5">
                <div className="page_containt row" >
                    <div className={['pageTbl', 'col-12'].join(' ')} >
                        {this.attendenceTableJsx()}
                    </div>
                </div>
            </div>
        </Ax>);
    }

    attendenceTableJsx                   =   ()  =>  {
        return (
            <Ax>
                <table className="table table-bordered bg-white table-fixed  table-hover  mb-2" id="assetDailyattendenceTable">
                    <thead className='table-secondary text-center'>
                        <th style={{ width: "5%" }}>
                             <input type='checkbox' onChange={this.checkAllItem} name="chk[]"  id="all_check_employee" />
                        </th>
                        <th style={{ width: "18%" }}>Employee</th>
                        <th style={{ width: "15%" }}>Shift</th>
                        <th style={{ width: "13%" }}>Attendence Status</th>
                        <th style={{ width: "15%" }}>Check In</th>
                        <th style={{ width: "15%" }}>Check Out</th>
                        <th style={{ width: "15%" }}>Details</th>
                        <th style={{ width: "5%" }}>Action</th>
                       
                    </thead>
                    <tbody>
                        {!this.state.listing_loading
                            ?
                            this.state.employee_listing && this.state.employee_listing.length > 0 ? this.state.employee_listing.map((s, i) => {
                                return (<tr className='text-center '>
                                    <td className="va_middle">
                                        <input
                                            type='checkbox'
                                            className="employee_id_checkbox"
                                            value={s.enc_id}
                                        /></td>
                                    <td className="va_middle text-start"><b><Link to={{pathname : '/employee_list' ,state : {id :s.enc_id}}}>{s.name}</Link></b>
                                        <br />{s.employee_code}<br />
                                        Site : {s.site ? `${s.site.site_name}` : ''}
                                    </td>

                                    <td className='text-center'>
                                        {s.roster_working_data && s.roster_working_data.shifts && s.roster_working_data.shifts.length > 0
                                        ? s.roster_working_data.shifts.map((sf, key) => {return(<Ax>{sf.shift_name} <br /> <span className="text-sm">{sf.shift_start} - {sf.shift_end}</span></Ax>)})
                                        : "-"}
                                        </td>
                                    <td>{
                                            s.attendance 
                                        ? <div className="mt-1 link-primary cursor_pointer">
                                            {s.attendance.status ? <Status color={s.attendance.status.color_code}>{s.attendance.status.name}</Status> : ""}
                                        </div>

                                        : 
                                        <a role="button" className='link-primary' onClick={() => this.updateEmployeeAttendenceModalInit(s)}>No Record</a>}
                                    </td>
                                    {s.attendance && <Ax>
                                        <td>{s.attendance.check_in ? s.attendance.check_in.substring(0, 10) === s.attendance.date.substring(0, 10) ? (
                                            // If equal, display only the time
                                            new Date(s.attendance.display_check_in).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                                        ) : (
                                            // If not equal, display the full check-in time
                                            s.attendance.display_check_in
                                        ) : ""} </td>
                                       <td>{s.attendance.check_out ? s.attendance.check_out.substring(0, 10) === s.attendance.date.substring(0, 10) ? (
                                            // If equal, display only the time
                                            new Date(s.attendance.display_check_out).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                                        ) : (
                                            // If not equal, display the full check-in time
                                            s.attendance.display_check_out
                                        ) : ""} </td>
                                        <td>{s.attendance.note ? s.attendance.note : ""} </td>
                                        <td className="text-center">

                                            <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                            <ul className="dropdown-menu">
                                                {/* <li>  <a className='dropdown-item' onClick={() => this.viewDetail(user.id)}>View</a>   </li> */}
                                                <li>  <a className='dropdown-item' onClick={() => this.updateEmployeeAttendenceModalInit(s)}>Edit</a>   </li>
                                            </ul></td>
                                    </Ax>}
                                   

                                </tr>)
                         


                            }

                            )
                                : <tr><td className='text-center' colSpan={11}>No Record</td></tr>
                            : <tr><td className='text-center' colSpan={11}><Loader /></td></tr>
                        }
                    </tbody>

                </table>
                <DisplayListPagination
                    meta={this.state.listingMeta}
                    onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
                />
            </Ax>
        );
    }


    render                              =   ()  =>  {
        return (<ApplicationLayout>
            <Helmet><title>Daily Attendence</title></Helmet>
            
            {this.dailyAttendenceListJsx()}
            <UpdateAttendenceModal ref={this.updateAttendenceModalRef}  afterSubmitAttendence={(date, empData) => {this.afterSubmitAttendenceHandler(date, empData) }}/>
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app.acl_info.permissions,
    };
};

export default connect(mapStateToProps)(DailyCounterlog);