import React from 'react';
import TapSelect from '../../../components/ui/TapSelect';
import tapIcon from '../../../services/TapIcon';
import Ax from "../../../components/hoc/Ax";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Modal } from 'bootstrap';
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import DatePicker from "react-datepicker";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import InputAssetSearch from "../../includes/ui/InputAssetSearch";
import HttpAPICall from "../../../services/HttpAPICall";
import moment from "moment";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { Helmet } from 'react-helmet';
import InputEmployeeSearch from '../../includes/ui/InputEmployeeSearch';
import InputWorkstationSearch from '../../includes/ui/InputWorkstationSearch';
import InputSubAssemblySearch from '../../includes/ui/InputSubAssemblySearch';
import AdditionalAttributeForm from '../../includes/ui/AdditionalAttributeForm';
import InputTicketSearch from '../../includes/ui/InputTicketSearch';
import { IAM_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class TicketTransactionAdd extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initAddTicketForm                 =    {
            ticket_types                       :    '',
            header                             :    '',
            description                        :    '',
            asset_id                           :    null,
            site                               :    null,
            users                              :    [],
            changes_status                     :    null,
            estimate_days                      :    null,
            due_date                           :    null,
            tags                               :    [],
            employees                          :    [],
            employee_id                        :    null,
            workstation                        :    null,
            sub_assembly                       :    null,
            ticket_for                         :    '', 
            parent_ticket_activity_id          :    '',
            ticket_type_id                     :    '',
        }
        this.state                              =   {
            saveFormSubmitting                  :   false,
            addTicketForm                       :   {...this.initAddTicketForm},
            ticketFormData                      :   [],
            allTicketTypes                      :   [],
            iam_user_sites                      :   [],
            formDataLoading                     :   false,
            searchedAsset                       :   null,
            allUsers                            :   [],
            taggedUsers                         :   [],
            allChangeStatus                     :   [],
            ticketTags                          :   [],
            ticket_allow_tag                    :   'N',
            ticket_allow_employees              :   'N',
            searchedEmployee                    :   '',
            searchedWorkstation                 :   '',
            searchedSubAssembly                 :   '',
            ticketForOption                     :   [],
            ticketForSite                       :   null,
            searchedEmployees                   :   [],
            ticketTypeFormData                  :   [],
        };
        this.ticketUrl      =   IAM_API_BASE_URL_2 + '/ticket';
    }

    componentDidMount() {
        this.getTicketFormData();
    }

    getTicketFormData   =   ()  =>  {

        this.setState({formDataLoading: true});
        HttpAPICall.withAthorization('GET',  this.ticketUrl + '/form_data', this.props.access_token, {}, {}, (resp) => {
            let defaultTicketType       =   resp.data.ticket_types.find(a => a.default == 1);

            this.setState({
                iam_user_sites          :   this.props.iam_user_sites ? this.props.iam_user_sites.map((s) => { return {value: s.id, label:`${s.site_name} (${s.site_code})`,code: s.site_code}}) : [],
                ticketFormData          :   resp.data,
                ticketTypeFormData      :   resp.data.ticket_types ? resp.data.ticket_types : [],
                allTicketTypes          :   resp.data.ticket_types ? resp.data.ticket_types.map((s) => { return {value: s.name, label: s.name, id: s.id}}) : [],
                addTicketForm           :   {
                    ticket_types            :   defaultTicketType ? defaultTicketType.name : ''},
                    allUsers                :   resp.data.users ? resp.data.users.map((s)=>{return {value:s.id, label:s.full_name, linked_sites:s.linked_sites}}) : [],
                ticketTags              :   resp.data && resp.data.tags && resp.data.tags.length > 0 ?  resp.data.tags.map((tag) => { return {value: tag, label: tag}; }) : [],    
                ticket_allow_tag        :   resp.data && resp.data.setting && resp.data.setting.ticket_allow_tag,
                ticket_allow_employees  :   resp.data && resp.data.setting && resp.data.setting.ticket_allow_employees, 
                ticketForOption         :   resp.data && resp.data.ticket_for && resp.data.ticket_for.length > 0 ? resp.data.ticket_for.map((opt) => { return {value: opt.key, label: opt.name}}) : [],
                addTicketForm           :   {...this.state.addTicketForm, ticket_for : 'asset', site : (this.props.projectDetails && this.props.projectDetails.site) ? this.props.projectDetails.site.id : null},
                ticketForSite           :   (this.props.projectDetails && this.props.projectDetails.site) ? this.props.projectDetails.site.id : null,
            });
            if(resp.data.change_asset_status == "Y") {
                this.setState({allChangeStatus  :   resp.data.asset_status_list 
                                                        ? resp.data.asset_status_list.map((s) => { return {value: s.key, label: s.name}}) : [] })
            }
        }).then(() => this.setState({formDataLoading: false}));
    }
    
    getTaggedUsers  =   (selectedSite)  =>  {
        if(selectedSite){
            if(this.state.ticketFormData && this.state.ticketFormData.auto_tagged_users){
                let taggedUsers          =   this.state.ticketFormData.auto_tagged_users.find(s => s.site == selectedSite.value);
                if(taggedUsers){
                    this.setState({taggedUsers : taggedUsers.tagged_users})
                }
            }
        }
    }

    setUsers    =   (selected)  =>  {
        const {ticketFormData}              =   this.state;
        if(selected) {
            let taggedUsers  =  ticketFormData && ticketFormData.auto_tagged_users 
                                    ? ticketFormData.auto_tagged_users.find(s => s.site == selected.asset.site_id) : null;
            this.setState({allUsers : this.state.allUsers.filter((user) => user.linked_sites.includes(selected.asset.site_id))})
            if(taggedUsers){
                this.setState({taggedUsers : taggedUsers.tagged_users})
            } else {
                this.setState({taggedUsers : []})
            }
        } else {
            this.setState({
                    allUsers        :   this.state.ticketFormData.users?.map((s) => { return {value: s.id, label: s.full_name,linked_sites:s.linked_sites}}),
                    taggedUsers     :   [],
                    addTicketForm   :   {...this.state.addTicketForm,users:[],site:''}
            })
        }
    }

    closeEvent  =   ()  =>  {
        this.props?.createFromProject 
        ?
        this.props.openCreateTicketScreen()
        :
        this.props.history.push('/ticket')
    }

    addTicketFormSubmitEvent    =   (e) =>  {
        e.preventDefault()
        let additional_attributes = {};
        if(this.state.ticketFormData && this.state.ticketFormData.additional_attributes && this.state.ticketFormData.additional_attributes.length > 0 ){
            this.state.ticketFormData.additional_attributes.forEach((i, k) => {
                let name = 'additional_attributes_' + i.key;
                additional_attributes[i.key] = this.state.addTicketForm[name] ? this.state.addTicketForm[name] : '';
            });
        }
        let frmData     =      {
            // ticket_type        :    this.state.addTicketForm.ticket_types,
            header             :    this.state.addTicketForm.header,
            description        :    this.state.addTicketForm.description,
            estimate_days      :    this.state.addTicketForm.estimate_days,
            // due_date             :    this.state.addTicketForm.due_date,
            asset_status       :    this.state.addTicketForm.changes_status,
            tagged_users       :    this.state.addTicketForm.users,
            asset_id           :    this.state.searchedAsset ? this.state.searchedAsset.value : null,
            site_id            :    this.state.addTicketForm.site,
            tags               :    this.state.addTicketForm.tags,
            employee_id        :    this.state.addTicketForm.employee_id,
            workstation_id     :    this.state.addTicketForm.workstation,
            sub_assembly_id    :    this.state.addTicketForm.sub_assembly,
            additional_attributes,
            ticket_for         :    this.state.addTicketForm.ticket_for,
            employees          :    this.state.addTicketForm.employees,
            parent_ticket_activity_id   :   this.state.addTicketForm.parent_ticket_activity_id,
            project_id         :    this.props?.projectDetails ? this.props.projectDetails.id : '',
            ticket_type_id     :    this.state.addTicketForm.ticket_type_id
        }
        this.setState({saveFormSubmitting:true})
        HttpAPICall.withAthorization('POST', this.ticketUrl + '/create', this.props.access_token, {} , {...frmData} , (response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.props?.createFromProject && this.props.ticketList();
            this.closeEvent()
        }).then(() => this.setState({saveFormSubmitting: false}));
    }

    changeOptionHandle = (option = null) => {
        let optionField = { ticket_for : option , asset_id : '', sub_assembly : '', workstation : '', employee_id : '', site : (this.props.projectDetails && this.props.projectDetails.site) ? this.props.projectDetails.site.id : ''};
        let otherField   = { taggedUsers : [], searchedAsset : null, searchedEmployee : '', searchedWorkstation : '', searchedSubAssembly : '', ticketForSite : (this.props.projectDetails && this.props.projectDetails.site) ? this.props.projectDetails.site.id : null };

        if(option === 'sub_assembly') {
            this.setState({ addTicketForm : {...this.state.addTicketForm, ...optionField}, ...otherField});
        }else if(option === 'asset') {
            this.setState({ addTicketForm : {...this.state.addTicketForm, ...optionField}, ...otherField});
        }else if(option === 'workstation') {
            this.setState({ addTicketForm : {...this.state.addTicketForm, ...optionField}, ...otherField});
        }else if(option === 'employee') {
            this.setState({ addTicketForm : {...this.state.addTicketForm, ...optionField}, ...otherField});
        }else if(option === 'site') {
            this.setState({ addTicketForm : {...this.state.addTicketForm, ...optionField}, ...otherField});
        }
    }

    saveTicketFormJsx   =   ()  =>  {
    return (
    <form className="bg-white my-1 p-3" onSubmit={this.addTicketFormSubmitEvent} id="addTicketForm">
        {this.props?.createFromProject && this.props.projectDetailJsx(this.props.projectDetail)}                  
        {this.state.formDataLoading ?
            <Loader/>  :
            <Ax>
                <div className="tab_content_wrapper mt-2">
                    <span className="content_heading">Ticket Details</span></div>
                <div className="row align-items-center my-3">
                    <div className="col-3">
                        <label htmlFor="ticket_type" className="form-label require">Ticket Type</label>
                    </div>
                    <div className="col-4">
                        <TapSelect
                            options={this.state.allTicketTypes}
                            changeEvent={(opt) => this.formInputStateHandler(opt ? opt.id: '', 'ticket_type_id', 'addTicketForm')}
                            value={this.state.allTicketTypes.find(a => a.id == this.state.addTicketForm.ticket_type_id)}
                            isSearchable={true}
                            isClearable={true}
                            placeholder="Please Select Ticket Type"
                        />
                    </div>
                </div>
                { this.props?.createFromProject && <div className="row align-items-center my-3">
                    <div className="col-3">
                        <label className={`form-label ${this.props.projectDetails.count_tickets > 0 ? 'require' : ''}`}>Parent Ticket</label>
                    </div>
                    <div className="col-4">
                        <InputTicketSearch
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'addTicketForm', 'parent_ticket_activity_id');
                            this.setState({ searchedTicket: selectedOption ? selectedOption : '' });
                        }}
                        search_project_id={this.props.projectDetails.id}
                        required={this.props.projectDetails.count_tickets > 0 ? true : false}
                        disabled={this.props.projectDetails.count_tickets > 0 ? false : true}
                        value={this.state.searchedTicket}
                        search_exclude_ticket_status={[10]}
                    />
                    </div>
                </div> }
                <div className="row align-items-center my-3">
                    <div className="col-3">
                        <label htmlFor="site_id" className="form-label require">Header</label>
                    </div>
                    <div className="col-4">
                        <input
                            name="header"
                            type="text"
                            className="form-control"
                            required={true}
                            autoComplete="off"
                            onChange={(e) => this.formInputHandler(e,"addTicketForm")}
                            placeholder="Please enter Ticket Header"
                        />
                    </div>
                </div>
                <div className="row align-items-center my-3">
                    <div className="col-3">
                        <label htmlFor="site_id" className="form-label ">Description</label>
                    </div>
                    <div className="col-4">
                    <textarea
                        name="description"
                        className="form-control"
                        autoComplete="off"
                        onChange={(e) => this.formInputHandler(e,"addTicketForm")}
                        style={{height: "80px"}}
                        placeholder="Please Add Description"
                    />
                    </div>
                </div>
                <div className="tab_content_wrapper my-4">
                    <span className="content_heading">Tagging Details</span>
                </div>
                <div className="row align-items-center my-3">
                    <div className="col-sm-3">
                        <label className="form-label require">Ticket For</label>
                    </div>
                    <div className="col-4">
                        <TapSelect
                            options={this.state.ticketForOption}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'addTicketForm', 'ticket_for');
                                this.changeOptionHandle(selectedOption ? selectedOption.value : null);
                            }}
                            value={this.state.ticketForOption.find(a => a.value === this.state.addTicketForm.ticket_for)}
                            placeholder="Please Select Ticket For"
                        />
                    </div>
                </div>
                {
                this.state.addTicketForm.ticket_for === "sub_assembly" &&  
                <>
                <div className="row align-items-center my-3">
                    <div className="col-3">
                        <label htmlFor="site_id" className="form-label require">Sub Assembly</label>
                    </div>
                    <div className="col-4">
                        <InputSubAssemblySearch
                            placeholder="Please Select Sub Assembly"
                            changeEvent={(selectedOption) => {
                                this.setState({ 
                                    searchedSubAssembly: selectedOption ? selectedOption : '',
                                }, () => {
                                    this.setState({
                                        addTicketForm : {
                                        ...this.state.addTicketForm, 
                                    },
                                    ticketForSite :  selectedOption?.subAssembly?.asset_profile?.site_id
                                    })
                                });
                                this.tapSelectChange(selectedOption, 'addTicketForm', 'sub_assembly')
                            }}
                            required = {true}
                            menuPlacement="top"
                            isMulti={false}
                            value={this.state.searchedSubAssembly && this.state.searchedSubAssembly}
                            search_site_id={this.state.addTicketForm.site}
                        />
                    </div>
                </div>
                <div className="row align-items-center my-3">
                    <div className="col-3">
                        <label htmlFor="site_id" className='form-label'>Asset</label>
                    </div>
                    <div className="col-4">
                        <input 
                            type='text' 
                            className="form-control"
                            value={this.state.searchedSubAssembly?.subAssembly?.asset_profile?.name}  
                            disabled={true}
                            placeholder='Sub Assembly Asset'
                        />
                    </div>
                </div>
                </>
                }
                {
                this.state.addTicketForm.ticket_for === "asset" &&
                <div className="row align-items-center my-3">
                    <div className="col-3">
                        <label htmlFor="site_id" className={['form-label require',this.state.ticketFormData.asset_validation == "Y" || this.state.addTicketForm.ticket_for === "asset" ? 'require' : ''].join(' ')}>Asset</label>
                    </div>
                    <div className="col-4">
                        <InputAssetSearch
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'addTicketForm', 'asset_id');
                                this.setState({
                                    searchedAsset : selectedOption ? selectedOption : null,
                                    addTicketForm : {...this.state.addTicketForm}
                                }, () => {
                                    this.setState({
                                        ticketForSite :  selectedOption ? selectedOption.asset.site_id : ''
                                    })
                                });
                                this.setUsers(selectedOption);
                            }}
                            placeholder = "Search Asset"
                            required = {this.state.ticketFormData.asset_validation == "Y" || this.state.addTicketForm.ticket_for === "asset" ? true : false}
                            value={this.state.searchedAsset ? {...this.state.searchedAsset, label : this.state.searchedAsset.display_label} : null}
                            search_asset_id = {this.state.searchedAsset}
                            disabled = {this.state.addTicketForm.ticket_for === "sub_assembly" && true}
                            search_site_id = {this.state.addTicketForm.site}
                        />
                    </div>
                </div>
                }
                {  
                this.state.addTicketForm.ticket_for === "workstation" &&  
                <div className="row align-items-center my-3">
                    <div className="col-sm-3">
                        <label htmlFor="site_id" className="form-label require">Workstation</label>
                    </div>
                    <div className="col-4">
                        <InputWorkstationSearch
                            placeholder="Please Select Workstation"
                            changeEvent={(selectedOption) => {
                                this.setState({ 
                                    searchedWorkstation: selectedOption ? selectedOption : '' 
                                }, () => {
                                    this.setState({
                                        addTicketForm : {
                                        ...this.state.addTicketForm, 
                                        },
                                        ticketForSite :  selectedOption?.workstation?.site_id, 
                                    })
                                });
                                this.tapSelectChange(selectedOption, 'addTicketForm', 'workstation')
                            }}
                            required = {true}
                            menuPlacement="top"
                            isMulti={false}
                            value={this.state.searchedWorkstation && this.state.searchedWorkstation}
                            search_site_id = {this.state.addTicketForm.site}
                        />
                    </div>
                </div>
                }
                { 
                this.state.addTicketForm.ticket_for === "employee" && 
                <div className="row align-items-center my-3">
                    <div className="col-sm-3">
                        <label htmlFor="site_id" className="form-label require">Employee</label>
                    </div>
                    <div className="col-4">
                    <InputEmployeeSearch
                        placeholder="Please Select Employee"
                        changeEvent={(selectedOption) => {
                            this.setState({ 
                                searchedEmployee: selectedOption ? selectedOption : '' 
                            }, () => {
                                    this.setState({
                                        addTicketForm : {
                                        ...this.state.addTicketForm, 
                                        },
                                        ticketForSite :  selectedOption?.employee?.site_id, 
                                    })
                                });
                            this.tapSelectChange(selectedOption, 'addTicketForm', 'employee_id')
                        }}
                        
                        menuPlacement="top"
                        required = {true}
                        value={this.state.searchedEmployee && this.state.searchedEmployee}
                        search_site_ids={this.state.addTicketForm.site ? [this.state.addTicketForm.site] : []}
                    />
                    </div>
                </div>
                }
                {
                this.state.addTicketForm.ticket_for === "site" ?
                <div className="row align-items-center my-3">
                    <div className="col-3">
                        <label htmlFor="site_id" className="form-label require">Site</label>
                    </div>
                    <div className="col-4">
                        <TapSelect
                            options={this.state.iam_user_sites}
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'addTicketForm', 'site');
                                this.getTaggedUsers(selectedOption)
                                this.setState({allUsers : this.state.allUsers.filter((user) => user.linked_sites.includes(selectedOption.value))})
                            }}
                            required = {true}
                            value={this.state.iam_user_sites.find(a => a.value == this.state.addTicketForm.site)}
                            isSearchable={true}
                            placeholder="Please Select Site"
                        />
                    </div>
                </div>
                    : null}
                {
                    ['asset', 'sub_assembly', 'workstation', 'employee'].includes(this.state.addTicketForm.ticket_for) &&
                    <div className="row align-items-center my-3">
                        <div className="col-3">
                            <label htmlFor="site_id" className='form-label'>Site</label>
                        </div>
                        <div className="col-4">
                            <input 
                                type='text' 
                                className="form-control"
                                value={this.state.ticketForSite ? this.state.iam_user_sites.find(a => a.value == this.state.ticketForSite)?.label : ''}
                                disabled={true}
                                placeholder='Site Name'
                            />
                        </div>
                    </div>
                }
                <div className="row align-items-center my-3">
                    <div className="col-3">
                        <label htmlFor="site_id" className="form-label">User</label>
                    </div>
                    <div className="col-4">
                        <TapSelect
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            placeholder="Please Select Users"
                            options={this.state.allUsers}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addTicketForm', 'users')}
                            value={this.state.allUsers.filter(u => this.state.addTicketForm?.users?.includes(u.value))}
                        />
                    </div>
                    <div className="col-5">
                        {this.state.taggedUsers.length > 0 ?
                            <div className="form-text">Following Users will be automatically tagged with the ticket as per Configuration.
                            <ul>
                                {this.state.taggedUsers.map((u,i) => <li key={i}>{u.full_name}</li>)}
                            </ul>
                        </div> :
                            null}
                    </div>
                </div>
                <div className="tab_content_wrapper ">
                    <span className="content_heading">Other Details</span></div>
                {this.state.searchedAsset && this.state.ticketFormData.change_asset_status == "Y" ?
                    <div className="row align-items-center my-3">
                        <div className="col-3">
                            <label htmlFor="site_id" className="form-label">Change Asset Status</label>
                        </div>
                        <div className="col-4">
                            <TapSelect
                                options={this.state.allChangeStatus}
                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addTicketForm', 'changes_status')}
                                value={this.state.allChangeStatus.find(a => a.value == this.state.addTicketForm.changes_status)}
                                isSearchable={true}
                                isClearable={true}
                                placeholder="Please Select Status"
                            />
                        </div>
                        <div className="col-md-5">
                            <div className="form-text">On creation of this Ticket, Asset Status will be changed to the selected option</div>
                        </div>
                    </div> :
                    null}
                <div className="row align-items-center my-3">
                    <div className="col-3">
                        <label className="form-label require">Estimate Days</label>
                    </div>
                    <div className="col-4">
                        <input 
                            type="number"
                            className="form-control"
                            autoComplete="off"
                            name='estimate_days'
                            required
                            value={this.state.addTicketForm.estimate_days}
                            onChange={(e) => this.formInputHandler(e, 'addTicketForm')}
                            placeholder="Enter Estimate Days"
                        />
                    </div>
                </div>
                {/* <div className="row align-items-center my-3">
                    <div className="col-3">
                        <label htmlFor="site_id" className="form-label">Due Date</label>
                    </div>
                    <div className="col-4 add_calender_section">
                        <DatePicker
                            selected={ this.state.addTicketForm.due_date ? moment(this.state.addTicketForm.due_date).toDate() : false }
                            name="due_date"
                            autoComplete="off"
                            onChange={(value, event) => this.formDateHandler('due_date', value, 'addTicketForm')}
                            
                            dateFormat="dd-MMM-yyyy"
                            className={"form-control"}
                            showMonthDropdown
                            showYearDropdown
                            scrollMonthYearDropdown
                            placeholderText={`Please Enter Due Date`}
                        />
                        <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt = "requisition_date" className = "add_calender-icon" />
                    </div>
                </div> */}
                {
                this.state.ticket_allow_tag === "Y" &&  
                <div className="row align-items-center my-3">
                    <div className="col-sm-3">
                        <label htmlFor="site_id" className="form-label">Tags</label>
                    </div>
                    <div className="col-4">
                        <TapSelect
                            changeEvent={(selectedOption) => {
                                this.tapSelectChange(selectedOption, 'addTicketForm', 'tags');
                            }}
                            options={this.state.ticketTags}
                            isSearchable={true}
                            isClearable={true}
                            isMulti= {true}
                            value={this.state.ticketTags.filter(s => this.state.addTicketForm.tags && this.state.addTicketForm.tags.includes(s.value))}
                            placeholder="Select Tags"
                        />
                    </div>
                </div>
                }
                {
                this.state.ticket_allow_employees === "Y" &&  
                <div className="row align-items-center my-3">
                    <div className="col-sm-3">
                        <label htmlFor="site_id" className="form-label">Employees</label>
                    </div>
                    <div className="col-4">
                        <InputEmployeeSearch
                            placeholder="Please Select Employees"
                            changeEvent={(selectedOption) => {
                                this.setState({ 
                                    searchedEmployees: selectedOption && selectedOption.length > 0 ? selectedOption : [] 
                                });
                                this.tapSelectChange(selectedOption, 'addTicketForm', 'employees')
                            }}
                            
                            menuPlacement="top"
                            isMulti={true}
                            value={this.state.searchedEmployees && this.state.searchedEmployees.length > 0 
                            ? this.state.searchedEmployees.map(a => { a.label = a.label; return a; })
                            : 
                            []}
                            search_site_ids={[this.state.addTicketForm.site]}
                        />
                    </div>
                </div>
                }
                {
                    this.state.ticketFormData && this.state.ticketFormData.additional_attributes && this.state.ticketFormData.additional_attributes.length > 0
                        ? (
                            <AdditionalAttributeForm
                                additionalAttributes={this.state.ticketFormData.additional_attributes}
                                formState={this.state.addTicketForm}
                                onAttributeChange={(value, attributeName) => {
                                    this.setState(() => ({
                                        addTicketForm: {
                                            ...this.state.addTicketForm,
                                            [attributeName]: value
                                        }
                                    }))
                                }
                                }
                            />
                        )
                        : null
                }
                <div className="text-end fixed-bottom py-2 bg-white" style={{left: "auto", right: "7px"}}>
                    <button id="addTicketCancelButton" type="button" disabled={this.state.saveFormSubmitting ? true : false} onClick={this.closeEvent} className="btn btn-secondary mx-3">Cancel</button>
                    <button id="addTicketSaveButton" type="submit" disabled={this.state.saveFormSubmitting ? true : false} className="btn btn-primary mx-2">
                        Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                    </button>
                </div>
            </Ax>}

    </form>
    );
    }

    header = () => {
        return (
        <div className="page_title row m0">
            <div className="col-12">
                <h3>Create Ticket</h3>
                <div className="text-end mt15">
                    <button onClick={this.closeEvent} type="button" className="btn btn-secondary">
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Requisition" />
                    </button>
                </div>
            </div>
        </div>
        )
    }

    createTicktJsx = ()  => {
        return(
        <Ax>
            <Helmet><title>Create Ticket</title></Helmet>
            {!this.props?.createFromProject && this.header()}
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                        {this.props?.createFromProject && this.header()}
                        {this.saveTicketFormJsx()}
                    </div>
                </div>
            </div>
        </Ax>
        )
    }

    render  =   ()  =>  {
        return this.props?.createFromProject ? this.createTicktJsx() : <ApplicationLayout> {this.createTicktJsx()} </ApplicationLayout>
        
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
    };
};

export default connect(mapStateToProps)(TicketTransactionAdd);