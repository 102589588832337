import React from 'react';
import {Link} from 'react-router-dom';
import AppBaseComponent from '../../../../../components/AppBaseComponent';
import {connect} from "react-redux";
import { toast } from 'react-toastify';
import HttpAPICall from "../../../../../services/HttpAPICall";
import Ax from "../../../../../components/hoc/Ax";
import { Helmet } from 'react-helmet';
import TapIcon from '../../../../../services/TapIcon';
import TapApiUrls from '../../../../../services/TapApiUrls';
import Loader from '../../../../../components/ui/Loader/Loader';
import TapSelect from '../../../../../components/ui/TapSelect';
import { Modal } from 'bootstrap';

class Setting extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initApprovedConsumptionForm      =     {
            fuel_consumption_factor         :      '',
            fuel_economy_factor             :      '',
        }

        this.initUpdateSettingInit      =       {
            asset_matrix_code           :       '',
            fuel_consumption            :       '',
            fuel_economy                :       ''
        }

        this.state                  =        {
            assetMatrixDetail          :         null,
            matrix_id                  :        null,
            settingData                :        null,
            changeInvoiceRule          :          {
                invoice_rule_id        :          ''
            },
            form_loading               :         false,
            allInvoicesList            :         [],
            apply_fuel_consumption     :         'Y',
            apply_fuel_economy         :         'N',
            selectedApprovedAction     :           '',
            updateSettingForm          :        {...this.initUpdateSettingInit},
            approvedConumptionForm     :        {...this.initApprovedConsumptionForm},
        }
    }

    id              =   this.props.matrix_enc_id;

    componentDidMount() {
        
        this.setState({matrix_id : this.props.matrix_enc_id,assetMatrixDetail : this.props.assetMatrixDetail},() => this.getSettingsDetails(this.state.matrix_id));
        this.linkInvoiceModal              =   new Modal(document.getElementById('linkInvoiceModal'), {keyboard: false, backdrop :false});
        this.approvedFuelModal             =   new Modal(document.getElementById('approvedFuelModal'), {keyboard: false, backdrop :false});
        this.updateCodeModal               =   new Modal(document.getElementById('updateCodeModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps) {
      
        if(nextProps.matrix_enc_id !== this.state.matrix_id ) {
            this.setState({matrix_id:nextProps.matrix_enc_id},() => this.getSettingsDetails(this.state.matrix_id))
        }
        if(nextProps.assetMatrixDetail !== this.state.assetMatrixDetail ) {
            this.setState({assetMatrixDetail:nextProps.assetMatrixDetail})
        }
      
    }

    getSettingsDetails                 =   (id)  =>         {
        HttpAPICall.withAthorization('GET',  TapApiUrls.IAM + '/asset_matrix_setting/' + id, this.props.access_token, {}, {}, (resp) => {
           
           this.setState({
             settingData        :       resp.data && resp.data.data.length == 0 ? null : resp.data.data,
           })
        })
    }

    linkInvoiceModalInit            =   ()      =>      {
        this.linkInvoiceModal.show()
        this.getAssetFormData()
        this.setState({form_loading : true})    
    }

    updateCodeModalInit            =   ()      =>      {
        this.updateCodeModal.show(); 
        if(document.getElementById("updateSettingForm")) {
            let form = document.getElementById("updateSettingForm")
            form.reset();
        }
        if(this.state.settingData){
            this.setState({
                updateSettingForm      :    {
                    ...this.initUpdateSettingInit,
                    id                          :         this.state.matrix_id,
                    asset_matrix_code           :       this.state.settingData.asset_matrix_code,
                    fuel_consumption            :       this.state.settingData.fuel_consumption,
                    fuel_economy                :       this.state.settingData.fuel_economy
                }
            })
        }
    }

    approvedFuelModalInit            =       (action)      =>      {
        this.approvedFuelModal.show();
        this.setState({
            apply_fuel_consumption      :  this.state.settingData && this.state.settingData.fuel_consumption_factor && this.state.settingData.fuel_consumption_factor > 0 || !this.state.settingData.fuel_economy_factor ? 'Y' : 'N',
            apply_fuel_economy      :   this.state.settingData && this.state.settingData.fuel_economy_factor && this.state.settingData.fuel_economy_factor > 0  && !this.state?.settingData?.fuel_consumption_factor ? 'Y' : 'N', 
            approvedConumptionForm  :   {
                id                      :   this.state.matrix_id,
                fuel_consumption_factor :   this.state.settingData && this.state.settingData.fuel_consumption_factor ? this.state.settingData.fuel_consumption_factor : '',
                fuel_economy_factor     :   this.state.settingData && this.state.settingData.fuel_economy_factor ? this.state.settingData.fuel_economy_factor : '',
            }
            
        })
    }

    getAssetFormData        =       ()      =>      {
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/invoice_rule_attach/formdata', this.props.access_token, {asset_matrix_id:this.state.matrix_id}, null, (response) => {
            let formData = response.data;
           
            this.setState({
                allInvoicesList             :       formData && formData.length > 0 ? formData.map(s => {return({value : s.id,label : `${s.name} (${s.rule_number})`})}) : []
            })       
         }).then(() => this.setState({ form_loading : false }));
    }

    submitLinkInvoicForm        =   (e)      =>  {
        e.preventDefault();
        let formData = {
            ...this.state.changeInvoiceRule,
            asset_matrix_id : this.state.matrix_id
        }
        this.setState({ saveFormSubmitting: true });
        HttpAPICall.withAthorization('PUT', TapApiUrls.IAM + '/invoice_rule_attach/asset_matrix', this.props.access_token, {}, { ...formData }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.linkInvoiceModal.hide()
            this.getSettingsDetails(this.state.matrix_id)
        }).then(() => this.setState({ saveFormSubmitting: false }));
    }

    submitUpdateSettingsForm    =   (e)     =>      {
        e.preventDefault();
        this.setState({ saveFormSubmitting: true });
        HttpAPICall.withAthorization('PUT', TapApiUrls.IAM + '/asset_matrix_setting', this.props.access_token, {}, { ...this.state.updateSettingForm }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.getSettingsDetails(this.state.matrix_id);
            this.updateCodeModal.hide(); 
        }).then(() => this.setState({ saveFormSubmitting: false }))
    }

    submitAppprovedFuelForm         =       (e)     =>      {
        e.preventDefault();
        this.setState({ saveFormSubmitting: true });
        let formData = {
            id : this.state.matrix_id,
            fuel_consumption_factor : this.state.apply_fuel_consumption == 'Y' ? this.state.approvedConumptionForm.fuel_consumption_factor : null,
            fuel_economy_factor     :   this.state.apply_fuel_economy == 'Y' ? this.state.approvedConumptionForm.fuel_economy_factor : null
        }
        HttpAPICall.withAthorization('PUT', TapApiUrls.IAM + '/asset_matrix_setting', this.props.access_token, {}, { ...formData }, (response) => {
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.getSettingsDetails(this.state.matrix_id);
            this.approvedFuelModal.hide(); 
        }).then(() => this.setState({ saveFormSubmitting: false }))
    }

     linkInvoiceRuleModalJsx               =        ()             =>        {
      
        return (
            <div className="modal fade" id="linkInvoiceModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchInvoiceModalLabel">Link Invoice Rule</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <form onSubmit={this.submitLinkInvoicForm}>
                            <div className="modal-body">
                                {this.state.form_loading ? <Loader />
                                    : <Ax>
                                        <div className="row align-items-center mb-3">

                                            <label className="col-sm-3 col-form-label col-form-label-sm ">New Invoice Rule</label>
                                            <div className="col-sm-9">
                                                <TapSelect
                                                    options={this.state.allInvoicesList}
                                                    changeEvent={(selectedOption) => {
                                                        this.tapSelectChange(selectedOption, 'changeInvoiceRule', 'invoice_rule_id');
                                                    }}

                                                    value={this.state.allInvoicesList.find(s => this.state.changeInvoiceRule.invoice_rule_id == s.value)}
                                                    placeholder="Select Invoices"
                                                    isSearchable={true}
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>

                                        </div>
                                    </Ax>}
                            </div>
                            <div className="modal-footer">
                                <button type="button" disabled={this.state.saveFormSubmitting} className="btn btn-secondary" data-bs-dismiss="modal" >Close </button>
                                <button type="submit" disabled={this.state.saveFormSubmitting} className="btn btn-primary">Save {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''} </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    approvedFuelModalJsx               =        ()             =>        {
      
        return (
            <div className="modal fade" id="approvedFuelModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="approvedFuelModalLabel">Apply Advance Fuel Formula</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <form onSubmit={this.submitAppprovedFuelForm}>
                            <div className="modal-body">
                                <div className="row align-items-center mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm ">Apply Formula</label>
                                    <div className="col-sm-9">
                                        <div className="form-check form-check-inline">
                                            <input
                                                name="apply_fuel_consumption"
                                                type="radio"
                                                value={this.state.apply_fuel_consumption}
                                                onChange={(e) => {
                                                    if (e.target && e.target.checked && e.target.checked == true) {
                                                        this.setState({
                                                            apply_fuel_consumption  : 'Y',
                                                            apply_fuel_economy      : 'N',
                                                           
                                                        })
                                                    } else {
                                                        this.setState({
                                                            apply_fuel_consumption: 'N'
                                                        })
                                                    }

                                                }}
                                                checked={this.state.apply_fuel_consumption == 'Y'}
                                                className="form-check-input"
                                                id="apply_fuel_consumption"
                                            />
                                            <label className="form-check-label form-label-sm text-sm" htmlFor="apply_fuel_consumption">Apply Advance Fuel Consumption </label>

                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                name="apply_fuel_economy"
                                                type="radio"
                                                value={this.state.apply_fuel_economy}
                                                onChange={(e) => {
                                                    if (e.target && e.target.checked && e.target.checked == true) {
                                                        this.setState({
                                                            apply_fuel_consumption: 'N',
                                                            apply_fuel_economy: 'Y',
                                                           
                                                        })
                                                    } else {
                                                        this.setState({
                                                            apply_fuel_economy: 'N'
                                                        })
                                                    }
                                                }}
                                                checked={this.state.apply_fuel_economy == 'Y'}
                                                className="form-check-input"
                                                id="apply_fuel_economy"
                                            />
                                            <label className="form-check-label form-label-sm text-sm" htmlFor="apply_fuel_economy">Apply Advance Fuel Economy </label>

                                        </div>
                                    </div>


                                </div>
                                {this.state.apply_fuel_consumption == 'Y' ?
                                    <div className="row align-items-center my-2">

                                        <label className="col-sm-3 col-form-label col-form-label-sm ">Advance Fuel Consumption = </label>
                                        <div className="col-sm-7 align-items-center ">
                                            <div className="d-flex fw-bold justify-content-center">Fuel Consumed - (KM Running *
                                              
                                                    <input
                                                        type="text"
                                                        name="fuel_consumption_factor"
                                                        className="form-control form-control-sm ms-2 mb-1"
                                                        placeholder=" Fuel Per KM"
                                                        autoComplete="off"
                                                        style={{ width: "37%" }}
                                                        value={this.state.approvedConumptionForm.fuel_consumption_factor}
                                                        onChange={(e) => this.formInputHandler(e, "approvedConumptionForm",null,true)}
                                                    />)</div>
                                            <div style={{ borderBottom: "solid 2px #ccc" }}></div>
                                            <div className="text-center fw-bold">Total Hours</div>


                                        </div>

                                    </div>
                                    : <div className="row align-items-center my-2">

                                        <label className="col-sm-3 col-form-label col-form-label-sm ">Advance Fuel Economy =</label>
                                        <div className="col-sm-7 align-items-center ">
                                            <div className="text-center  fw-bold">Total KM</div>
                                            <div style={{ borderBottom: "solid 2px #ccc" }}></div>
                                            <div className=" d-flex fw-bold justify-content-center">Fuel Consumed - (Hours Running  *
                                               <input
                                                        type="text"
                                                        name="fuel_economy_factor"
                                                        className="form-control form-control-sm ms-2 mb-1"
                                                        placeholder=" Fuel Per Hr"
                                                        autoComplete="off"
                                                        style={{ width: "37%" }}
                                                        value={this.state.approvedConumptionForm.fuel_economy_factor}
                                                        onChange={(e) => this.formInputHandler(e, "approvedConumptionForm",null,true)}
                                                    />)</div>


                                        </div>


                                    </div>}


                            </div>
                            <div className="modal-footer">
                                <button type="button" disabled={this.state.saveFormSubmitting} className="btn btn-secondary" data-bs-dismiss="modal" >Close </button>
                                <button type="submit" disabled={this.state.saveFormSubmitting} className="btn btn-primary">Save {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''} </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    updateCodeModalJsx               =        ()             =>        {
      
        return (
            <div className="modal fade" id="updateCodeModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="approvedFuelModalLabel">Update</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <form onSubmit={this.submitUpdateSettingsForm} id="updateSettingForm">
                            <div className="modal-body">
                                <div className="row align-items-center mb-3">
                                    <label className="col-sm-3 col-form-label col-form-label-sm ">Asset Matrix Code</label>
                                    <div className="col-sm-9">

                                        <input
                                            type="text"
                                            name="asset_matrix_code"
                                            onChange={(e) => this.formInputHandler(e, 'updateSettingForm')}
                                            className="form-control form-control-sm "
                                            placeholder="Asset Matrix Code"
                                            autoComplete="off"
                                            value={this.state.updateSettingForm.asset_matrix_code}
                                        />
                                        <div className='text-sm form-text'>
                                            This action will update Asset Matrix Code on all assets of this asset matrix.
                                        </div>

                                    </div>


                                </div>
                                {this.state.assetMatrixDetail && this.state.assetMatrixDetail.assetType && this.state.assetMatrixDetail.assetType.hour_meter == "no" ? null
                                    : <div className="row align-items-center my-2">
                                        <label className="col-sm-3 col-form-label col-form-label-sm ">Approved Fuel Consumption</label>
                                        <div className="col-sm-9">

                                            <input
                                                type="number"
                                                name="fuel_consumption"
                                                onChange={(e) => this.formInputHandler(e, 'updateSettingForm', null, true)}
                                                className="form-control form-control-sm "
                                                placeholder="Approved Fuel Consumption"
                                                autoComplete="off"
                                                value={this.state.updateSettingForm.fuel_consumption}
                                            />

                                        </div>


                                    </div>}
                                {this.state.assetMatrixDetail && this.state.assetMatrixDetail.assetType && this.state.assetMatrixDetail.assetType.odo_meter == "no" ? null :
                                    <div className="row align-items-center my-2">
                                        <label className="col-sm-3 col-form-label col-form-label-sm ">Approved Fuel Economy</label>
                                        <div className="col-sm-9">

                                            <input
                                                type="number"
                                                name="fuel_economy"
                                                onChange={(e) => this.formInputHandler(e, 'updateSettingForm', null, true)}
                                                className="form-control form-control-sm "
                                                placeholder="Approved Fuel Economy"
                                                autoComplete="off"
                                                value={this.state.updateSettingForm.fuel_economy}
                                            />
                                            <div className='text-sm form-text'>
                                                This action will update Approved Limit on all assets of this asset matrix.
                                            </div>

                                        </div>


                                    </div>}

                            </div>
                            <div className="modal-footer">
                                <button type="button" disabled={this.state.saveFormSubmitting} className="btn btn-secondary" data-bs-dismiss="modal" >Close </button>
                                <button type="submit" disabled={this.state.saveFormSubmitting} className="btn btn-primary">Save {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''} </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <Ax>
                <Helmet><title>Asset Matrix Setting</title></Helmet>

                <div className="row ml1">
                    <div className="col-12 pl0 bg-white ">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <Link to={`/asset_matrix/details/${this.state.matrix_id}`} role={"button"}>
                                    <button className="btn nav-link text-capitalize " role="button" >
                                        Details
                                    </button>
                                </Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link to={`/asset_matrix/documents/${this.state.matrix_id}`} role={"button"}>
                                    <button className="btn nav-link text-capitalize" role="button" >
                                        Documents
                                    </button>
                                </Link>
                            </li> */}
                            <li className="nav-item">
                                <Link to={`/asset_matrix/scs_setting/${this.state.matrix_id}`} role={"button"}>
                                    <button className="btn nav-link text-capitalize" role="button" >
                                        SCS Setting
                                    </button>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={`/asset_matrix/setting/${this.state.matrix_id}`} role={"button"}>
                                    <button className="btn nav-link text-capitalize show active" role="button" >
                                        Setting
                                    </button>
                                </Link>
                            </li>


                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane  show active" id="details" role="tabpanel" aria-labelledby="details-tab">

                                <div className="page_containt">
                                    <div className="pageTbl p-2 pb-3" style={{ height: "100%" }}>
                                        <div className="tab_content_wrapper" >
                                            <span className="content_heading">Asset Matrix Code</span>
                                            <button className='btn btn-primary' onClick={() => this.updateCodeModalInit()}>Update</button>
                                        </div>
                                        <table className="table table-hover table-borderless my-2">
                                            <tbody>
                                                <tr >
                                                    <td style={{ width: "33%" }}>Code</td>
                                                    <th>{this.state.settingData && this.state.settingData.asset_matrix_code ? this.state.settingData.asset_matrix_code : "-"}</th>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="tab_content_wrapper" >
                                            <span className="content_heading">
                                                <div className="tooltip-element my-1">Approved Fuel Limit<TapIcon.FontAwesomeIcon icon={TapIcon.faInfoCircle} className="tooltip-element my-1 mx-2" />
                                                    <span className="tooltiptext text-sm">By updating, Fuel limit of all the assets attached with this asset matrix will be replaced.</span>
                                                </div>
                                            </span>
                                        </div>
                                        <table className="table table-hover table-borderless my-2">
                                            <tbody>
                                                <tr >
                                                    <td style={{ width: "33%" }} >  Approved Fuel Consumption   </td>
                                                    <th>{this.state.assetMatrixDetail && this.state.assetMatrixDetail.assetType && this.state.assetMatrixDetail.assetType.hour_meter == "no"
                                                        ? "NA" : this.state.settingData && this.state.settingData.fuel_consumption ? this.state.settingData.fuel_consumption : "-"}</th>
                                                </tr>
                                                <tr >
                                                    <td style={{ width: "33%" }}>Approved Fuel Economy </td>
                                                    <th>{this.state.assetMatrixDetail && this.state.assetMatrixDetail.assetType && this.state.assetMatrixDetail.assetType.odo_meter == "no"
                                                        ? "NA" : this.state.settingData && this.state.settingData.fuel_economy ? this.state.settingData.fuel_economy : "-"}</th>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {this.state.assetMatrixDetail && this.state.assetMatrixDetail.assetType && this.state.assetMatrixDetail.assetType.hour_meter == "no" ||
                                            this.state.assetMatrixDetail && this.state.assetMatrixDetail.assetType && this.state.assetMatrixDetail.assetType.odo_meter == "no"
                                            ? null
                                            : <Ax>
                                                <div className="tab_content_wrapper" >
                                                    <span className="content_heading">
                                                        <div className="tooltip-element my-1">Advance Fuel Formula  </div>
                                                    </span>
                                                    <button className='btn btn-primary' onClick={() => this.approvedFuelModalInit()}>Apply</button>
                                                </div>
                                                {this.state.settingData && !this.state.settingData.fuel_consumption_factor  && !this.state.settingData.fuel_economy_factor 
                                                    ? <div className='text-center fw-bold my-4'>NA</div>
                                                    : null}
                                                {this.state.settingData && this.state.settingData.fuel_consumption_factor && this.state.settingData.fuel_consumption_factor > 0
                                                    ? <table className="table table-hover table-borderless align-middle my-2">
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ width: "30%" }}>
                                                                    Advance Fuel Consumption
                                                                </td>

                                                                <td style={{ width: "10%" }}>
                                                                    =
                                                                </td>
                                                                <th className='text-center'>

                                                                    <div className="pb5">Fuel Consumed - (KM Running *
                                                                        <a> {this.state.settingData && this.state.settingData.fuel_consumption_factor && this.state.settingData.fuel_consumption_factor > 0
                                                                            ? this.state.settingData.fuel_consumption_factor : null}</a>)</div>
                                                                    <div style={{ borderBottom: "solid 1px #ccc" }}></div>
                                                                    <div className="pt5">Total Hours</div>


                                                                </th>

                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                    : null}
                                                {this.state.settingData && this.state.settingData.fuel_economy_factor && this.state.settingData.fuel_economy_factor > 0 && this.state.settingData.fuel_consumption_factor == 0
                                                    ? <table className="table table-hover table-borderless align-middle my-2">
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ width: "30%" }}>

                                                                    Advance Fuel Economy
                                                                </td>

                                                                <td style={{ width: "10%" }}>
                                                                    =
                                                                </td>
                                                                <th className='text-center'>

                                                                    <div className="pb5">Total KM </div>
                                                                    <div style={{ borderBottom: "solid 1px #ccc" }}></div>
                                                                    <div className="pt5">Fuel Consumed - (Hours Running * <a >{this.state.settingData && this.state.settingData.fuel_economy_factor && this.state.settingData.fuel_economy_factor > 0 ? this.state.settingData.fuel_economy_factor : null}</a>)</div>


                                                                </th>

                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                    : null}



                                            </Ax>}


                                        <div className="tab_content_wrapper" >
                                            <span className="content_heading">Asset Priority</span>
                                        </div>
                                        <table className="table table-hover table-borderless my-2">
                                            <tbody>
                                                {this.state.settingData && this.state.settingData.priority && this.state.settingData.priority.length > 0
                                                    ? <Ax>
                                                        {this.state.settingData.priority.map((p, k) => {
                                                            return (<tr key={k}>
                                                                <td style={{ width: "33%" }}>{p.name}</td>
                                                                <th>{p.sites && p.sites.length > 0 ? p.sites.map(s => s.site_name).join(', ') : '-'}</th>
                                                            </tr>)
                                                        })}
                                                    </Ax>
                                                    : <tr>
                                                        <td className="text-center" colSpan={2}>No Record</td>
                                                    </tr>}
                                            </tbody>
                                        </table>
                                        <div className="tab_content_wrapper" >
                                            <span className="content_heading">Linked Invoice Rule</span>
                                            <button className='btn btn-primary' onClick={() => this.linkInvoiceModalInit()}>Link <TapIcon.FontAwesomeIcon icon={TapIcon.faLink} className="mx-2" /></button>
                                        </div>
                                        <table className="table table-hover table-borderless my-2 mb-3">
                                            <tbody>
                                                <tr >
                                                    <td style={{ width: "33%" }}>Invoice Rule Name</td>
                                                    <th>{this.state.settingData && this.state.settingData.invoice_rule ? <span>{this.state.settingData.invoice_rule.name} ({this.state.settingData.invoice_rule.rule_number})</span> : "-"}</th>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {this.approvedFuelModalJsx()}
                {this.linkInvoiceRuleModalJsx()}
                {this.updateCodeModalJsx()}
            </Ax>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
    }
}

export default connect(mapStateToProps)(Setting);
