import React from 'react';
import "../MasterData.css";
import {connect} from "react-redux";
import Ax from "../../../components/hoc/Ax";
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import AssetsConfiguration from "./assetsConfiguration";
import TransactionAgeing from "./TransactionAgeing";
import {Link, Redirect} from "react-router-dom";
import TicketConfiguration from "./ticket/TicketConfiguration";
import PurchaseConfiguration from "./PurchaseConfiguration";
import CounterlogConfiguration from "./counterlog/CounterlogConfiguration";
import ScrapConfiguration from "./ScrapConfiguration";
import Productivity from './productivity/Productivity';
import { Helmet } from 'react-helmet';
import RentalConfiguration from './rental/RentalConfiguration';
import TaskWorkflow from './taskWorkflow/TaskWorkflow';
import MaterialConfiguration from './material/MaterialConfiguration';
import ActivityConfiguration from './activity/ActivityConfiguration';
import AssetTypeGrouping from './AssetTypeGrouping';
import Attachment from './Attachment';
import FuelVendorConfiguration from './fuelVendor/FuelVendorConfiguration';
import FuelReceiverConfiguration from './fuelReceiver/FuelReceiverConfiguration';
import Downtime from './Downtime';
import SubAssemblyCard from './subAssembly/SubAssemblyCard';
import EngagementTimeSetting from './EngagementTimeSetting';
import FuelFeedInSetting from './fuelfeedin/FuelFeedInSetting';

class Configuration extends React.Component {

    constructor(props) {
        super(props);
        this.state               =            {
            configScreen         :           "activity",
            add                  :            false
        }
        this.handleClose            =        this.handleClose.bind(this)
    }

    handleAdd        =      ()     =>   {
        this.setState({add:true})
    }

    handleClose(){
        this.setState({add : false})
    }

    pageTitleBarJsx                    =    (configTitle)   =>          {
        let title =  ""
        if(configTitle === "asset"){
           title = "Asset"
        }else if(configTitle === "ticket"){
            title = "Ticket"
        }else if(configTitle === "purchase"){
            title = "Purchase"
        }else if(configTitle === "counterlog"){
            title = "Counterlog"
        }else if(configTitle === "scrap"){
            title = "Scrap"
        }else if(configTitle === "productivity"){
            title = "Productivity"
        }else if(configTitle === "rental"){
            title = "Commercial"
        }else if(configTitle === "counterlog"){
            title = "Counterlog"
        }else if(configTitle === "task_workflow"){
            title = "Task Workflow"
        }else if(configTitle === "material"){
            title = "Material"
        }else if(configTitle === "activity"){
            title = "Activity Name"
        }else if(configTitle === "asset_type_grouping"){
            title = "Asset Type Grouping"
        }else if(configTitle === "attachment"){
            title = "Attachment"
        }else if(configTitle === "fuel_vendor_configuration"){
            title = "Fuel Vendor"
        }else if(configTitle === "fuel_receiver_configuration"){
            title = "Fuel Receiver"
        }else if(configTitle === "downtime"){
            title = "Downtime Configuration"
        }else if(configTitle === "sub-assembly"){
            title = "Sub Assembly"
        }else if(configTitle === "engagement_time"){
            title = "Engagement Time"
        }else if(configTitle === "fuel_feedin"){
            title = "Fuel FeedIn"
        } else{
            title = "Transaction Delay"
        }
        return(<Ax><div className="page_title row m0">
                <div className="col-3">
                    <h3>Configuration</h3>
                </div>
                <div className="col-9 tap_border_left">
                    <h3>{title}</h3>
                    {
                        configTitle === "site"
                            ? <div className="text-end mt15">
                                <button
                                    className="btn btn-primary" onClick={() => this.handleAdd()}>
                                    Add
                                </button>
                            </div> : null
                    }
                </div>
        </div>
        </Ax>)
    }

    loadConfigContentJsx                   =   (configName)  =>           {
        switch (configName) {
            case 'asset':
                return <AssetsConfiguration/>;
            case 'counterlog':
                return <CounterlogConfiguration/>;
            case 'transaction_delay':
                return <TransactionAgeing/>;
            case 'purchase':
                return <PurchaseConfiguration/>;
            case 'scrap':
                return <ScrapConfiguration/>;
            case 'ticket':
                return <TicketConfiguration/>;
            case 'productivity':
                return <Productivity/>;
            case 'rental':
                return <RentalConfiguration/>;
            case 'task_workflow':
                return <TaskWorkflow/>;
            case 'material':
                return <MaterialConfiguration/>;
            case 'activity':
                return <ActivityConfiguration/>;
            case 'asset_type_grouping':
                return <AssetTypeGrouping/>;
            case 'attachment':
                return <Attachment/>;
            case 'fuel_vendor_configuration':
                return <FuelVendorConfiguration/>;
            case 'fuel_receiver_configuration':
                return <FuelReceiverConfiguration/>;
            case 'downtime':
                return <Downtime/>;
            case 'sub-assembly':
                return <SubAssemblyCard />;
            case 'engagement_time':
                return <EngagementTimeSetting />;
                case 'fuel_feedin':
                return <FuelFeedInSetting />;
            default:
                return <AssetsConfiguration/>;
        }
    }

    //function to get configure table
    configureListTable                     =   (configRow)   =>  {
        return (<div style={{ height: "81vh" }} className="bg-white">
            <table className="table table-hover table-bordered bg-white" >
                <tbody className="table-body">
                <tr className={configRow == "activity" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/configuration/activity`} role={"button"} className="link">Activity Name</Link></td>
                    </tr>
                    <tr className={configRow == "asset" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/configuration/asset`} role={"button"} className="link">Asset</Link></td>
                    </tr>
                    <tr className={configRow == "asset_type_grouping" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/configuration/asset_type_grouping`} role={"button"} className="link">Asset Type Grouping</Link></td>
                    </tr>
                    <tr className={configRow == "attachment" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/configuration/attachment`} role={"button"} className="link">Attachment</Link></td>
                    </tr>
                    {this.props.group_permission && this.props.group_permission.group_modules.includes("iam") && <Ax>
                        <tr className={configRow == "counterlog" ? "selected_config" : "white"}>
                            <td scope="row">
                                <Link to={`/configuration/counterlog`} role={"button"} className="link">Counterlog</Link></td>
                        </tr>
                        <tr className={configRow == "downtime" ? "selected_config" : "white"}>
                            <td scope="row">
                                <Link to={`/configuration/downtime`} role={"button"} className="link">Downtime</Link>
                            </td>
                        </tr>
                    </Ax> }

                    {this.props.group_permission && this.props.group_permission.group_sub_modules.find(s => s.sub_module == "iam-rental") ? <tr className={configRow == "rental" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/configuration/rental`} role={"button"} className="link">Commercial</Link></td>
                    </tr> : null}
                    <tr className={configRow == "engagement_time" ? "selected_config" : "white"}>
                            <td scope="row">
                                <Link to={`/configuration/engagement_time`} role={"button"} className="link">Engagement Time</Link>
                            </td>
                        </tr>
                        <tr className={configRow == "fuel_feedin" ? "selected_config" : "white"}>
                            <td scope="row">
                                <Link to={`/configuration/fuel_feedin`} role={"button"} className="link">Fuel FeedIn</Link>
                            </td>
                        </tr>
                    <tr className={configRow == "fuel_vendor_configuration" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/configuration/fuel_vendor_configuration`} role={"button"} className="link">Fuel Vendor</Link></td>
                    </tr>
                    <tr className={configRow == "fuel_receiver_configuration" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/configuration/fuel_receiver_configuration`} role={"button"} className="link">Fuel Receiver</Link></td>
                    </tr>
                    <tr className={configRow == "material" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/configuration/material`} role={"button"} className="link">Material</Link></td>
                    </tr>
                    <tr className={configRow == "productivity" ? "selected_config" : "white"}>
                        <td scope="row">
                            <Link to={`/configuration/productivity`} role={"button"} className="link">Productivity</Link></td>
                    </tr>

                   {this.props.permissions.includes('iam-sub-assembly-configuration-manage') && <tr className={configRow == "sub-assembly" ? "selected_config" : "white"}>
                        <td className="master_menu_items">
                            <Link to={`/configuration/sub-assembly`} role={"button"} className="link">Sub Assembly</Link>
                        </td>
                    </tr>}

                    {this.props.group_permission && this.props.group_permission.group_sub_modules && this.props.group_permission.group_sub_modules.find(s => s.sub_module == "iam-task-workflow") && this.props.group_permission.permissions && this.props.group_permission.permissions.length > 0 && this.props.group_permission.permissions.includes('iam-task-workflow-configuration') ?
                        <tr className={configRow == "task_workflow" ? "selected_config" : "white"}>
                            <td className="master_menu_items">
                                <Link to={`/configuration/task_workflow`} role={"button"} className="link">Task Workflow</Link></td>
                        </tr>
                        : null}
                    <tr className={configRow == "ticket" ? "selected_config" : "white"}>
                        <td className="master_menu_items">
                            {this.props.permissions.includes('iam-configuration-ticket-setting')
                                ? <Link to={`/configuration/ticket`} role={"button"} className="link" >Ticket</Link>
                                : <a className="link-secondary disabled" style={{ cursor: "pointer" }} title="You do not have permission to perform this action">Ticket</a>
                            }
                        </td>
                    </tr>
                    <tr className={configRow == "transaction_delay" ? "selected_config" : "white"}>
                        <td className="master_menu_items">
                            <Link to={`/configuration/transaction_delay`} role={"button"} className="link">Transaction Delay</Link></td>
                    </tr>
                </tbody>
            </table>
        </div>)
    }


    render() {
       
        return <ApplicationLayout>
              <Helmet><title>Assets Configuration</title></Helmet>
            {this.pageTitleBarJsx(this.props.match.params.config)}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl col-3'].join(' ')} >{this.configureListTable(this.props.match.params.config)}</div>
                    <div className="col-9 pl0 pr4">{this.loadConfigContentJsx(this.props.match.params.config)}</div>
            </div>
            </div>
        </ApplicationLayout>
    }
}

const mapStateToProps = state => {
    return {
        permissions             :   state.app.acl_info.permissions,
        group_permission        :   state.app.acl_info
    };
};

export default connect(mapStateToProps)(Configuration);