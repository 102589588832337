import React from 'react';
import { toast } from 'react-toastify';
import {connect} from "react-redux";
import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import HttpAPICall from "../../../services/HttpAPICall";
import {DisplayListPagination} from "../../../components/TapUi/index";
import Loader from "../../../components/ui/Loader/Loader";
import { Helmet } from 'react-helmet';
import TapApiUrls, { ORG_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class ReportRequest extends AppBaseComponent {

    constructor() {
        super();

        this.state                                   =   {
            listing_loading      :   false,
            listingData          :   [],
            listingMeta          :   null,

        };
        this.reportUrl     =   ORG_API_BASE_URL_2 + "/download_request"; 
    }

    componentDidMount() {
        this.loadListingTblData(1) 
    }
    
    
    loadListingTblData                      =   (page = 1)  =>  {
        this.setState({
            listing_loading         :   true,
            listingData             :   [],
            listing_tbl_page        :   page,
            downloadReportBtn       :   false,
            reportDownloading       :   false
        });
        
        let params                          =   {page:page};
        HttpAPICall.withAthorization('GET',this.reportUrl, this.props.access_token,params,{},(response) => {
            let respData                =   response.data;
            this.setState({
                listingData                 :   respData.data,
                downloadReportBtn           :   true,
                listingMeta                 :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                }
            });
        }, (err) => {
            
        }).then(() => {
            this.setState({listing_loading: false});
        });
    }

    closeReport                             =       ()      =>  {
        this.props.history.goBack()
    }

    pageTitleBarJsx                         =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-12">
                <h3><tapIcon.FontAwesomeIcon icon={tapIcon.faCalculator} /> Report Request</h3>
                <div className="text-end mt15">
                <button disabled={this.state.listing_loading} type="button" onClick={() => this.loadListingTblData(1, true)} className="btn btn-secondary">{this.state.listing_loading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} />)}</button>
                     <button type="button" className="btn btn-secondary mx-1" onClick={this.closeReport}>
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close requisition" />
                    </button> 
                </div>
            </div>
        </div>);
    }

  
    taskReportDisplayJsx               =    ()  =>    {
        return (<div className="bg-white">
           {this.taskReportTableJsx()}
        </div>)
    }

    taskReportTableJsx                          =   ()  =>  {

        return (<Ax>
            <table className="table table-bordered table-fixed align-middle bg-white">
                <thead className="align-middle table-secondary">
                    <tr className='text-center'>
                    <th style={{ width: "5%" }}>S.No </th>
                        <th style={{ width: "15%" }}>Request Id </th>
                        <th style={{ width: "20%" }}>Date of Creation</th>
                        <th style={{ width: "25%" }} className='text-start'>Report Name</th>
                        <th style={{ width: "15%" }}>Status</th>
                        <th>Download</th>
                        
                    </tr>
                </thead>
                <tbody>
                    {this.state.listing_loading ? <tr>
                        <td className='text-center' colSpan={6}><Loader /></td>
                    </tr> : this.state.listingData && this.state.listingData.length > 0
                        ? this.state.listingData.map((requestDr,index) => {
                            return (
                                <tr key={index}className='text-center'>
                                    <td>{this.state.listingMeta ? this.state.listingMeta.from + index : index }</td>
                                     <td>{requestDr.id ? requestDr.id : "-" }</td> 
                                    <td>{requestDr.created_at_display ? requestDr.created_at_display : "-" }</td>
                                    <td className='text-start'>{requestDr.download_title ? requestDr.download_title : "-" }</td>
                                    <td>{requestDr.is_expired ? "Expired" : requestDr.is_available ? "Active" : "Pending"}</td>
                                    <td>{requestDr.is_expired ? "Link Expired" : requestDr.is_available ? <a href={requestDr?.download_path}>Download Report</a> : "-" }</td>
                                </tr>
                            )
                        })
                        : <tr><td className='text-center' colSpan={6}>No Record</td></tr>}
                </tbody>
            </table>
            <DisplayListPagination meta={this.state.listingMeta} onPageChange={(e) => this.loadListingTblData(e.selected + 1)} />
        </Ax>)
    }

    render                                  =   ()  =>  {
        return (<ApplicationLayout>
         <Helmet><title>Report Request - Report</title></Helmet>
            {this.pageTitleBarJsx()}
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div id="pageTbl" className="pageTbl col-12">
                        {this.taskReportTableJsx()}
                    </div>
                </div>
            </div>
        </ApplicationLayout>);
    }
}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
    };
};

export default connect(mapStateToProps)(ReportRequest);