import React from 'react';
import { Modal } from 'bootstrap';
import { connect } from 'react-redux';
import Loader from "../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import moment from 'moment';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import TapSelect from '../../../components/ui/TapSelect';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import AppBaseComponent from '../../../components/AppBaseComponent';
import download from '../../../services/DownloadFile';
import swal from "sweetalert";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import InputItemSearch from '../../includes/ui/InputItemSearch';
import POQtyDetail from '../includes/POQtyDetail';
import RaisePurchaseOrder from '../purchaseOrder/RaisePurchaseOrder';
import RequisitionCancel from './RequisitionCancel';
import ItemViewInList from '../includes/ItemViewInList';
import ItemDetailsModal from '../../items/itemDetails/ItemDetailsModal';
import ConsumptionHistoryModal from '../consumption/ConsumptionHistoryModal';
import POHistoryModal from '../purchaseOrder/POHistoryModal';
import ItemTransactionLedger from '../includes/ItemTransactionLedger';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import CurrencyFormatter from '../../../services/CurrencyFormatter';

class RequisitionList extends AppBaseComponent {

    constructor(props) {
        super(props);
 
        this.initRequisitionSearchForm      =   {
            search_transaction_id               :   '',
            search_title                        :   '',
            search_warehouse_ids                :   [],
            date_range                          :   null,
            date_range_start                    :   null,
            date_range_end                      :   null,
            search_item_ids                     :   [],
            search_item_tags                    :   [],
            search_status_ids                   :   '',
            search_priority                     :   '',
            tagged_user_filter                  :   '',
            search_asset_ids                    :   [],
            search_approved_aging               :   '',
            search_voucher_aging                :   '',
            tagged_user_ids                     :   [],
        }

        this.requisitionSearchFormLabel       =       {
            search_transaction_id           :   'Transaction ID : ',
            search_title                    :   'Title : ',
            search_warehouse_ids            :   'Warehouses : ',
            date_range                      :   'Date Range : ',
            search_item_ids                 :   'Items : ',
            search_item_tags                :   'Item Tags : ',
            search_status_ids               :   'Search Status : ',
            search_priority                 :   'Search Priority : ',
            tagged_user_filter              :   'Requisition : ',
            search_asset_ids                :   'Assets : ',
            search_approved_aging           :   'Approval Ageing : ',
            search_voucher_aging            :   'Voucher Ageing : ',
            tagged_user_ids                 :   'Tagged Users : '
        }

        this.updateCommentFormInit            =       {
            status                            :       '',
            notes                             :       ' ',
        }
        
        this.state                             =     {
            requisitionAddScreenView            :      false,
            requisitionUpdateScreenView         :      false,
            minimizeTable                       :       false,
            requisitionFormDataLoaded           :       false,
            requisitionAddFormData              :       [],
            listing_loading                     :       false,
            listing_tbl_page                    :       1,
            requisition_listing                 :       [],
            listingMeta                         :       null,
            totalListingCount                   :       0,
            requisitionSearchForm               :       {...this.initRequisitionSearchForm},
            submittedRequisitionFilterForm      :       {...this.initRequisitionSearchForm},
            addCommentForm                      :       {...this.updateCommentFormInit},
            formSearchedElems                   :        [],
            requisitionTransId                  :        '',
            requisitionId                       :       '',
            requisitionDetail                   :        null,
            allAllowedStatus                    :        [],
            addCommentFormSubmitting            :        false,
            excelDownloading                    :        false,
            editRequisition                     :       false,
            all_warehouses                      :       [],
            all_periods                         :       [],
            searchedItem                        :       [],
            allStatusOptions                    :        [],
            allPriorityOptions                  :       [],
            allItemTags                         :        [],
            cancelledItemList                   :       [],
           cancel_item_loading                  :        false,
           disableCancelItem                    :       true,
           cancelItemFormSubmitting             :       false,
           reportDownloading                    :   false,
           itemDetails                          :       null,
           searchedAssets                       :        [],
           indentDetail                         :        null,
           cancelledItemForm                    :       {...this.cancelItemFormInit},
           indentTransId                        :       '',
           userFilters                          :               [
            {value  :   'tagged_me',        label : 'My Tagged Requisition'},
            {value  :   'created_by_me',    label : 'Requisition Created By Me'},
                            ],
            interchangeItemData                 :        null,
            interchangeableItemList             :    [],
            interchangeableListingMeta          :   null,
            interchangeableTotalListingCount    :   0,
            interchangeable_loading             :   false,
            interchangeable_page                :   1,
            saveInterchangeableItemForm         :   false,
            interchangeItemForm                 :    {notes:''},
            allUsers                            : [], 
        };
        this.myRef                          =        React.createRef()
            
        this.pOQtyDetailModalRef            =        React.createRef();
        this.RaisePOModalRef                =        React.createRef();
        this.cancelledItemModalRef          =        React.createRef();
        this.itemDetailsRef                 =        React.createRef();
        this.consumptionHistoryRef          =        React.createRef();
        this.poHistoryRef                   =        React.createRef();
        this.transactionLedgerRef           =        React.createRef();

        this.requisitionListDownloadUrl =   INVENTORY_API_BASE_URL_2 + '/report/requisition-list-with-po';
        this.warehouseIndentDetailUrl   =   INVENTORY_API_BASE_URL_2 + '/warehouse_indent/detail';
        this.searchFormDataUrl          =   INVENTORY_API_BASE_URL_2 + '/warehouse_indent/search_form_data';
        this.requisitionUrl             =   INVENTORY_API_BASE_URL_2 + '/requisition';
        this.requisitionItemChangeUrl   =   INVENTORY_API_BASE_URL_2 + '/requisition_item_change';
        this.requisitionReportUrl       =   INVENTORY_API_BASE_URL_2 + '/report/item-wise-requisition';
        this.itemUrl                    =   INVENTORY_API_BASE_URL_2 + '/item';
    }

    componentDidMount() {
      
        let ParamObject                 =       new URLSearchParams(this.props.location.search);
        // this.loadListingTblData(1);
        if(ParamObject.get('search_status_ids') || ParamObject.get('search_voucher_ageing') || ParamObject.get('search_approval_ageing') ){
            this.initializeData(this.props)
        }else{
            this.loadListingTblData(1);
            this.initializeData(this.props)
        }
        this.searchFormDataInit()
        this.requisitionSearchModal                 =   new Modal(document.getElementById('requisitionSearchModal'), {keyboard: false, backdrop :false});
        this.interchangebleItemModal                 =   new Modal(document.getElementById('interchangebleItemModal'), {keyboard: false, backdrop :false});
        
        //****************After Add and update ***************************/
       if(this.props.location && this.props.location.state && this.props.location.state.requisitionId){
        this.viewRequisitionDetail(this.props.location.state.requisitionId)
       }

       this.viewIndentDetailModal      =   new Modal(document.getElementById('viewIndentDetailModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.all_warehouses !== this.props.all_warehouses) {
            this.initializeData(nextProps)
         }     
    }

    initializeData     =      (pr)      =>      {
        if(pr){
            let all_periods             =       [...pr.all_periods , {key : 'custom' , display : 'Custom Date Range'}] ;
            this.setState({
                all_warehouses          :        pr.all_warehouses ? pr.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}) : [] ,
                all_periods             :        all_periods ,
            });
            let ParamObject                 =       new URLSearchParams(pr.location.search);
           if(ParamObject.get('search_voucher_ageing') || ParamObject.get('search_approval_ageing') ){
                 let status_ids           =         ParamObject.get('search_status_ids').split(',').map(Number);
               let updateFilterForm        =   {
                    search_status_ids: status_ids ,
                    search_voucher_aging : ParamObject.get('search_voucher_ageing') ?  ParamObject.get('search_voucher_ageing') : '',
                    search_approved_aging : ParamObject.get('search_approval_ageing') ?  ParamObject.get('search_approval_ageing') : ''
                };
                if (ParamObject.get('search_warehouse_ids')) {
                    let warehouse        =  pr.all_warehouses.find(us => us.id == ParamObject.get('search_warehouse_ids'));
                    if (warehouse) {
                        updateFilterForm['search_warehouse_ids'] = [warehouse.id];
                    }
                }
                if (ParamObject.get('tagged_user')) {
                    updateFilterForm['tagged_user_filter'] = ParamObject.get('tagged_user');
                }

                
                setTimeout(() => {
                    this.setState({
                        requisitionSearchForm  : { ...this.state.requisitionSearchForm, ...updateFilterForm},                      
                    },
                        function () {
                            this.submitRequisitionSearchForm();
                        });
                }, 1000)
            }else if (ParamObject.get('search_status_ids')) {
                let status_ids           =         ParamObject.get('search_status_ids').split(',').map(Number);
                let updateFilterForm     =          { search_status_ids: status_ids }
                if (ParamObject.get('search_warehouse_ids')) {
                    let warehouse        =  pr.all_warehouses.find(us => us.id == ParamObject.get('search_warehouse_ids'));
                    if (warehouse) {
                        updateFilterForm['search_warehouse_ids'] = [warehouse.id];
                    }
                }
                if (ParamObject.get('tagged_user')) {
                    updateFilterForm['tagged_user_filter'] = ParamObject.get('tagged_user');
                }
                setTimeout(() => {
                    this.setState({
                        requisitionSearchForm  : { ...this.state.requisitionSearchForm, ...updateFilterForm},                      
                    },
                        function () {
                            this.submitRequisitionSearchForm();
                        });
                }, 1000)
            }
    
        }
    }

    //************************GET REQUISTION LISTING**************** */
    loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {...this.state.submittedRequisitionFilterForm,page:page};
        HttpAPICall.withAthorization('GET', this.requisitionUrl, this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
            this.setState({
                requisition_listing    :       respData.data,
                listingMeta         :       respData.meta,
                totalListingCount   :       respData.meta.total,
        });
        }).then(() => this.setState({listing_loading: false}));
    }

    downloadRequisitionList         = (e) => {
        let formData                            =   {...this.state.submittedRequisitionFilterForm};
        if(JSON.stringify(this.state.submittedRequisitionFilterForm) !== JSON.stringify(this.initRequisitionSearchForm)){
            this.reportDownloadApiCall(formData);
        } else {
            swal({ 
                title: "Download", icon: "warning", buttons: ["Cancel", "Okay"],
                text: "The download will be for Current Quarter Transaction, in case you want a different Period then apply the criteria before downloading.",
            }).then(willDownload => {
                if (willDownload) { this.reportDownloadApiCall({...formData, date_range: "current_quarter"}); }
            });
        }
    }

    reportDownloadApiCall       =   (formData)  =>  {
        this.setState({reportDownloading : true});
        HttpAPICall.withAthorization('GET', this.requisitionListDownloadUrl, this.props.access_token, {...formData, action : 'download'} , {} , (response) => download.file(response.data.file_path)).then(() => this.setState({reportDownloading: false}));

    }

    //************************REQUISTION SEARCH FORM **************** */

    requisitionSearchModalInit               =       ()           =>        {
        this.requisitionSearchModal.show();
        this.searchFormDataInit()
    }

    viewItemModalInit                    =   (item)  =>  { 
        if(item){
            this.itemDetailsRef.current.modalInit(item)
        }
    }

    searchFormDataInit          =       ()      =>      {
        if(!this.state.requisitionSearchFormDataLoaded) {
            HttpAPICall.withAthorization('GET', this.requisitionUrl + '/list_form_data', this.props.access_token, null, null, (response) => {
             let formData = response.data
                this.setState({
                    requisitionSearchFormDataLoaded    :    true,
                    allStatusOptions                   :    formData && formData.status && formData.status.length > 0 ?  formData.status.map((s) => { return {value: s.id, label: s.name}; }) : [],
                    allPriorityOptions                 :    formData && formData.priorities && formData.priorities.length > 0 ? formData.priorities.map((s) => { return {value: s.key, label: s.name}; }) : [],
                    allItemTags                        :    formData && formData.tags && formData.tags.length > 0 ? formData.tags.map((s) => { return {value: s.id, label: s.name}; }) : [],
                    allUsers                            :   formData && formData.users ? formData.users.map((s) => { return {value: s.id, label: s.full_name,site_ids:s.linked_sites}; }) : [],
                });
            });
        }
    }

    submitRequisitionSearchForm                =       (e)          =>          {
        e && e.preventDefault()
        let serachFormKeys              =   Object.keys(this.state.requisitionSearchForm);
       
        let searchedElems               =   [];
        let searchParams                =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.requisitionSearchFormLabel[key];
            let value                       =   this.state.requisitionSearchForm[key];
            if(value && value.toString().length > 0) {
                    searchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'tagged_user_filter') {
                        show_val            =   this.state.userFilters.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_transaction_id') {
                        show_val            =   this.state.requisitionSearchForm ? this.state.requisitionSearchForm.search_transaction_id : '';
                    }
                    if(key == 'search_title') {
                        show_val            =   this.state.requisitionSearchForm ? this.state.requisitionSearchForm.search_title : '';
                    }
                    if(key == 'search_warehouse_ids') {
                        show_val            =   this.state.all_warehouses.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_item_ids') {
                        show_val            =   this.state.searchedItem ? this.state.searchedItem.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_item_tags') {
                        show_val            =   this.state.allItemTags.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_status_ids') {
                        show_val            =   this.state.allStatusOptions.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_priority') {
                        show_val            =   this.state.allPriorityOptions.filter((s) => value.includes(s.value)).map(s => s.label);
                    }
                    if(key == 'search_approved_aging') {
                        show_val            =   this.state.requisitionSearchForm && this.state.requisitionSearchForm.search_approved_aging ? this.state.requisitionSearchForm.search_approved_aging  : '';
                    }
                    if(key == 'search_voucher_aging') {
                        show_val            =   this.state.requisitionSearchForm && this.state.requisitionSearchForm.search_voucher_aging ? this.state.requisitionSearchForm.search_voucher_aging  : '';
                    }
                
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'tagged_user_ids') {
                        show_val            =   this.state.allUsers.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'date_range') {
                        let  start_range         =      moment(this.state.requisitionSearchForm.date_range_start).format('DD-MMM-YYYY');
                        let end_range            =      moment(this.state.requisitionSearchForm.date_range_end).format('DD-MMM-YYYY')
                        let display_custom      =      `Custom Date Range (${start_range} - ${end_range})`
                        show_val                 =     this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedRequisitionFilterForm   :   {...this.state.requisitionSearchForm},
            formSearchedElems           :   searchedElems
        }, () => {
            this.requisitionSearchModal.hide()
            this.loadListingTblData(1);
        });
    }

    requisitionSearchClear                  =        ()             =>        {
        this.setState({
            formSearchedElems               :   [],
            requisitionSearchForm            :   {...this.initRequisitionSearchForm},
            submittedRequisitionFilterForm     :   {...this.initRequisitionSearchForm},
            searchedAssets                  :       []
        }, () => {
            this.loadListingTblData(1);
            this.requisitionSearchModal.hide();
        });
    }

    requisitionClear                  =        ()             =>        {
        // this.setState({
        //     formSearchedElems               :   [],
        //     requisitionSearchForm            :   {...this.initRequisitionSearchForm},
        //     submittedRequisitionFilterForm     :   {...this.initRequisitionSearchForm}
        // }, () => {
        //     this.requisitionSearchModal.hide();
        // });
    }

    //************************REQUISTION ADD FORM INIT **************** */
    requisitionAddFormDataInit       =       ()      =>      {
        if(!this.state.requisitionFormDataLoaded) {
            HttpAPICall.withAthorization('GET', TapApiUrls.REQUISITON_URL + '/form_data', this.props.access_token, null, null, (response) => {
                this.setState({
                    requisitionFormDataLoaded    :    true,
                    requisitionAddFormData       :    response.data.data,
                });
            });
        }
    }

    updateRequisitionScreenClose      =      ()       =>     {
        this.setState({requisitionUpdateScreenView:false})
    }
      
    viewRequisitionDetail          =      (transaction_id)        =>     {
            this.setState({ minimizeTable: true })
           if(transaction_id !== this.state.requisitionTransId){
              this.getRequisitionDetail(transaction_id)
           }
    }

    getRequisitionDetail        =       (transaction_id)      =>      {
    // if(transaction_id !== this.state.requisitionTransId){
        this.setState({requisition_loading : true})
        HttpAPICall.withAthorization('GET',  this.requisitionUrl + '/' + transaction_id, this.props.access_token, null, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                requisitionDetail    :   respData.data,
                requisitionTransId   :   respData.data.transaction_id,
                allAllowedStatus     :   respData.data && respData.data.user_status && respData.data.user_status.length > 0 ? respData.data.user_status.map((s) => { return {value: s.id, label: s.name}; }) : [] ,
                requisitionId        :   respData.data.id,
                addCommentForm       :  {...this.updateCommentFormInit , status : respData.data.status}   
            });
           
        },(err) => {
            if(err){
                this.setState({minimizeTable : false})
            }
        }).then(() => this.setState({requisition_loading : false}));
    //   }
      
    }

    updateComment           =           ()          =>      {
        this.myRef.current.scrollIntoView()
    }

    closeViewDetails           =      ()      =>    {
        this.setState({minimizeTable  :  false})
        this.props.history.push('/requisition_list')
    }

    afterSubmitUpdateRequisition         =           (e,id)      =>      {
        e.preventDefault()
      this.setState({requisitionUpdateScreenView : false, minimizeTable : true})
      this.loadListingTblData(1)
      this.viewRequisitionDetail(id)
    }

    afterSubmitAddRequisition         =           (e,id)      =>      {
        e.preventDefault()
      this.setState({requisitionAddScreenView:false})
      this.loadListingTblData(1)
    }

    //*************************SUBMIT COMMENT FORM ********************** */
    submitUpdateCommentForm         =       (e)          =>      {
         e.preventDefault()
         this.setState({addCommentFormSubmitting : true})
         let frmData = {
              ...this.state.addCommentForm,
              transaction_id : this.state.requisitionTransId
            }
            HttpAPICall.withAthorization('PUT', this.requisitionUrl + '/comment', this.props.access_token, {}, { ...frmData }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.getRequisitionDetail(this.state.requisitionTransId);
                this.loadListingTblData(1)
                this.setState({addCommentForm : {...this.updateCommentFormInit}})
            }).then(() => this.setState({ addCommentFormSubmitting : false }));
    }

     //***********************DOWNLOAD DETAIL EXCEL **************************** */
    downloadPdfHandler                  =   ()    =>         {
        this.setState({ excelDownloading: true});
        HttpAPICall.withAthorization('GET', this.requisitionUrl + '/' + this.state.requisitionTransId, this.props.access_token,{download : "download"},{},(response) => {
            download.file(response.data.file_path);
        }).then(() => this.setState({excelDownloading: false}));
    }
    
    reportDownloadApiHandler            =   ()  =>  {
        this.setState({ excelDownloading: true});
        HttpAPICall.withAthorization('GET', this.requisitionReportUrl, this.props.access_token, {search_transaction_id : this.state.requisitionTransId, download : 'download'} , {} , (response) => download.file(response.data.file_path)).then(() => this.setState({excelDownloading: false}));
    }
    

    //***************************EDIT REQUISITION ******************** */
    editRequisition         =           ()          =>      {
       
        if(this.state.requisitionDetail){
            if(this.state.requisitionDetail.can_edit == "N"){
                this.props.history.push({ pathname: `/requisition_list/edit`, state: { requisitionEditData: this.state.requisitionDetail } })
            }else{
                this.props.history.push({ pathname: `/requisition_list/add`, state: { requisitionData: this.state.requisitionDetail } }) 
            }
        }

    }

    //***************************CLONE REQUISITION ******************** */
    cloneRequisition         =           ()          =>      {
       
        if(this.state.requisitionDetail){
             this.props.history.push({ pathname: `/requisition_list/add`, state: { requisitionData: this.state.requisitionDetail , clone : 'yes' } }) 
            
        }

    }

    cancelRequisition       =       (transaction_id)          =>      {
        
        let reqItemCheckboxCollections      =   document.getElementsByClassName('req_item_id_checkbox');
        let cancelledItemList               =   this.state.cancelledItemList;
        var showErrorMessage                =   false;
        if(reqItemCheckboxCollections && reqItemCheckboxCollections.length > 0) {
            for (let i = 0; i < reqItemCheckboxCollections.length; i++) {
                if(reqItemCheckboxCollections[i].checked) {
                    let itemId = reqItemCheckboxCollections[i].getAttribute("data-itemid");
                    let data = this.state.requisitionDetail.items.find(i => i.item.id == itemId);
                    let row                         =   {...cancelledItemList[i],...data,new_cancelled_qty:data && data.pending_qty ? data.pending_qty : 0 };
                    cancelledItemList[i]            =   row;
                    let list = cancelledItemList.filter(Boolean)
                    this.setState({cancelledItemList   :   [...list],
                                   interchangeItemForm   :   {...this.state.cancelItemFormInit,
                                   notes : ''}}
                                   ,() => this.cancelledItemModalRef.current.modalInit(list,this.state.requisitionDetail?.warehouse_indent))                   
                }
            }
        }

        if(this.state.cancelledItemList.length == 0 && !showErrorMessage){
            toast.error('Please select an Item for Cancellation', { position: toast.POSITION.TOP_RIGHT });
        }

    }

    //***************************DELETE REQUISITION ******************** */
    deleteRequisition         =           (transaction_id)          =>      {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('DELETE', this.requisitionUrl + '/' + transaction_id, this.props.access_token, {}, {}, (response) => {
                        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                        this.setState({minimizeTable : false})
                        this.loadListingTblData(1)
                     })
                }
            });
    }

    poQtyDetailModalInit                    =   (req_item_id)  =>  {
        //if(this.props.permissions.includes('isc-po-view')){
         this.pOQtyDetailModalRef.current.modalInit(req_item_id);
        //}
    }

    checkAllList() {
        let reqItemCheckboxCollections      =   document.getElementsByClassName('req_item_id_checkbox')
        if(reqItemCheckboxCollections && reqItemCheckboxCollections.length > 0) {
            for (let i = 0; i < reqItemCheckboxCollections.length; i++) {
                reqItemCheckboxCollections[i].checked = document.getElementById('all_check_requisition').checked;
            }
        }
    }

    raisePOHandler                  =   ()  =>  {
        let req_item_ids                    =   [];
        let warehouse_ids                   =   [];
        let status_levels                   =   [];
        let pendingQtyList                  =  [];
        let reqItemCheckboxCollections      =   document.getElementsByClassName('req_item_id_checkbox');
        if(reqItemCheckboxCollections && reqItemCheckboxCollections.length > 0) {
            for (let i = 0; i < reqItemCheckboxCollections.length; i++) {
                if(reqItemCheckboxCollections[i].checked) {
                    req_item_ids.push(reqItemCheckboxCollections[i].value);
                    warehouse_ids.push(reqItemCheckboxCollections[i].getAttribute("data-warehouseId"));
                    status_levels.push(reqItemCheckboxCollections[i].getAttribute("data-statusLevel"));
                    pendingQtyList.push(reqItemCheckboxCollections[i].getAttribute("data-pendingQty"));
                }
            }
        }
        let warehouse_ids_unique            =   warehouse_ids.filter((item, i, ar) => ar.indexOf(item) === i);
        if (req_item_ids.length == 0) {
            toast.error('Please select atleast one Item', { position: toast.POSITION.TOP_RIGHT });
        } else if(warehouse_ids_unique.length > 1) {
            toast.error('Please select item with same warehouse', { position: toast.POSITION.TOP_RIGHT });
        } else if(pendingQtyList.includes('0')) {
            toast.error('Every item must have pendning qty more than 0', { position: toast.POSITION.TOP_RIGHT });
        } else if(!status_levels.every((e) => e >= 3 && e < 5)) {
            toast.error('PO can be raised only for Approved Requisition', { position: toast.POSITION.TOP_RIGHT });
        } else {
            this.RaisePOModalRef.current.choosePoModalInit(req_item_ids, warehouse_ids_unique[0]);
        }
    }

    viewConsumptionModalInit                       =   (data,k)  =>  {
        if (this.state.requisitionDetail) {

            let requisitionData = this.state.requisitionDetail;

            if (data) {
                this.consumptionHistoryRef.current.modalInit(data, null, null)
            }
        }
         
    }

    viewPOHistoryModalInit                       =   (data,k)  =>  {
        if (this.state.requisitionDetail) {

            let requisitionData = this.state.requisitionDetail;

            if (data) {
                this.poHistoryRef.current.modalInit(data, requisitionData.warehouse_id)
            }
        }
         
      }

      viewTransactionLedgerModalInit               =   (data,k)  =>  {
        if (this.state.requisitionDetail) {

            let requisitionData = this.state.requisitionDetail;
            if (data) {
                this.transactionLedgerRef.current.modalInit(data, requisitionData.warehouse_id,requisitionData.warehouse)
            }
        }
         
      }

      //*****************SHOW INDENT DETAIl***************** */

    viewIndentDetailModalInit           =       (id)        =>      {
        if(id){
            this.getIndentDetail(id)
            this.viewIndentDetailModal.show()
        }
        
    }

    getIndentDetail        =       (transaction_id)      =>      {
    
        this.setState({indent_detail_loading : true})
        HttpAPICall.withAthorization('GET', this.warehouseIndentDetailUrl + '/' + transaction_id, this.props.access_token, null, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                indentDetail    :   respData.data,
                indentTransId   :   respData.data.transaction_id,
           });
        
        },(err) => {
            
        }).then(() => this.setState({indent_detail_loading : false}));
   
    }

    viewInterchangeableModalInit        =       (itemdata=null,key)   =>      {
        
        this.interchangebleItemModal.show();
        this.setState({
            interchangeItemData     :   itemdata ,
            interchangeItemForm     :    {notes:''}  
        },() => this.getInterChangeableItemList(itemdata?.item?.id,1))
    }

    getInterChangeableItemList      =       (id,page=1)      =>      {
        this.setState({ interchangeable_loading: true, interchangeable_page: page,interchangeableItemList:[] });
        HttpAPICall.withAthorization('GET', this.itemUrl + '/interchangeable_item/' + id, this.props.access_token, {}, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                interchangeableItemList                :    respData.data,
                interchangeableListingMeta             :   respData.meta,
                interchangeableTotalListingCount       :   respData.meta.total,
               
            })
        }).then(() => this.setState({ interchangeable_loading : false }))
    }
    
    checkAllInterchangeList                          =   ()  =>  {
        let interchangeItemCheckboxCollections      =   document.getElementsByClassName('interchange_id_checkbox');
        if(interchangeItemCheckboxCollections && interchangeItemCheckboxCollections.length > 0) {
            for (let i = 0; i < interchangeItemCheckboxCollections.length; i++) {
                interchangeItemCheckboxCollections[i].checked = document.getElementById('all_check_interchange').checked;
            }
        }
    }

    submitSelectedInterchangeItem        =       (e)         =>      {
        e.preventDefault();
         
        let  interchangeItemCheckboxCollections      =    document.getElementsByClassName('interchange_id_checkbox');
        let allSelectedItems                =    [];
        let data                            =   this.state.interchangeItemData ? this.state.interchangeItemData : null;
        let totalQty = 0
        
        if( interchangeItemCheckboxCollections &&  interchangeItemCheckboxCollections.length > 0) {
            for (let i = 0; i <  interchangeItemCheckboxCollections.length; i++) {
               
                if( interchangeItemCheckboxCollections[i].checked) {
                    // interchangeItemCheckboxCollections[i].disabled = true;
                    let item_id    =       interchangeItemCheckboxCollections[i].value;
                    let qty        =    interchangeItemCheckboxCollections[i].getAttribute("data-qty");
                    allSelectedItems.push({item_id: item_id,qty:qty})
                    totalQty += parseFloat(qty);
                }
            }
        }

   
       //***************************HANDLE SELECTED ITEM FROM PO  **********************/
        if (allSelectedItems.length == 0) {
            toast.error('Please select atleast one Item', { position: toast.POSITION.TOP_RIGHT })
        }else if(allSelectedItems.find(i => parseFloat(i.qty) <= 0)) {
            toast.error('Every Item\'s qty must be more than 0.', { position: toast.POSITION.TOP_RIGHT })
           
        }else if(allSelectedItems.find(i => i.qty == '' || i.qty == null)) {
            toast.error('Every Item\'s  qty should not be blank', { position: toast.POSITION.TOP_RIGHT })
           
        }else if(totalQty > data.pending_qty){
            toast.error('Total Qty cannot be more than pending Qty', { position: toast.POSITION.TOP_RIGHT })
        }else{
            let frmData = {
                req_item_id          :       data.req_item_id,
                transaction_id       :      data.requisition_transaction_id,
                item_id              :      data.item.id,
                replace_items          : allSelectedItems,
                ...this.state.interchangeItemForm
            }
            this.setState({ saveInterchangeableItemForm: true })
            HttpAPICall.withAthorization('PUT', this.requisitionItemChangeUrl, this.props.access_token, {}, { ...frmData }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.interchangebleItemModal.hide();
                this.getRequisitionDetail(data.requisition_transaction_id)
            }).then(() => this.setState({ saveInterchangeableItemForm: false }));
            
        }  
    }
    //***********************REQUISTION JSX****************
    requisitionListjsx            =       ()      =>    {
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Purchase Requisition</h3>
                <div className="text-end mt15">
                    {(!this.state.minimizeTable && this.props.permissions.includes('isc-purchase-requisition-add'))  && <Ax>
                        <Link to="/requisition_list/add">
                            <button type="button" className="btn btn-primary">New Requisition</button>
                        </Link>
                        <button type="button" disabled={this.state.reportDownloading} onClick={this.downloadRequisitionList} className="btn btn-secondary">
                            <tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /> {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                        </button>
                    </Ax>
                    }
                    <button type="button" className="btn btn-secondary" onClick={this.requisitionSearchModalInit} disabled={this.state.listing_loading} >
                        <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                    </button>
                    {this.state.minimizeTable && this.props.permissions.includes('isc-purchase-requisition-add') ? <span className="dropdown">
                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><Link to="/requisition_list/add" className="dropdown-item" role="button" >Add Requisition</Link></li>
                        </ul>
                    </span> : null}
                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Purchase Requisition : {this.state.requisitionTransId}</h3>
                            <div className="text-end mt15">

                                <button type="button" className="btn btn-secondary" disabled={this.state.excelDownloading == true || this.state.requisitionDetail?.status == 5 || this.state.requisitionDetail?.status == 6 || !this.props.permissions.includes('isc-purchase-requisition-status-change')} onClick={() => this.updateComment()}>Update Status </button>
                                
                                <button disabled={this.state.excelDownloading} type="button" id='downloadMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /> {this.state.excelDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="downloadMenuButton">
                                   <li><Link className="dropdown-item" onClick={this.downloadPdfHandler} >PDF Download</Link></li>
                                   <li><Link className="dropdown-item" onClick={this.reportDownloadApiHandler}>Item-Wise Download</Link></li>
                                </ul>
                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                </button>
                                <ul className="dropdown-menu cursor_pointer" aria-labelledby="dropdownMenuButton1">
                                    <li><a className={(this.state.requisitionDetail && this.state.requisitionDetail.can_edit == "N") || this.state.requisition_loading == true || !this.props.permissions.includes('isc-purchase-requisition-edit') ? "dropdown-item disabled" : "dropdown-item"} onClick={() => this.editRequisition(this.state.requisitionId)} disabled={this.state.excelDownloading == true}>Edit</a></li>
                                    <li><a className={this.state.requisitionDetail?.is_closed == "Y" || this.state.requisition_loading == true || !this.props.permissions.includes('isc-purchase-requisition-cancel_item') ? "dropdown-item disabled" : "dropdown-item"} onClick={() => {this.setState({cancelledItemList : []},() => this.cancelRequisition(this.state.requisitionTransId))}} >Cancel Item</a></li>
                                    <li><a className={(this.state.requisition_loading == true ? "dropdown-item disabled" : "dropdown-item")} onClick={() => this.cloneRequisition()} disabled={this.state.excelDownloading == true}>Clone</a></li>
                                    <li><a className={this.state.requisitionDetail?.is_closed == "Y" || this.state.requisition_loading == true || !this.props.permissions.includes('isc-po-add') ? "dropdown-item disabled" : "dropdown-item"} onClick={this.raisePOHandler} >Raise PO</a></li>
                                    <li><a className={this.state.requisitionDetail?.can_delete == "N" || this.state.requisition_loading == true || !this.props.permissions.includes('isc-purchase-requisition-delete')  ? "dropdown-item disabled" : "dropdown-item"} onClick={() => this.deleteRequisition(this.state.requisitionTransId)}  disabled={this.state.requisitionDetail?.can_delete == "N"}>Delete</a></li>

                                </ul>
                                <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails} disabled={this.state.excelDownloading == true}>
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.requisitionListingTableJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4"><div className="bg-white p-2" style={{ height: "100%" }}>
                        {this.requisitionViewDetailJsx()}
                    </div></div> : null}
                </div>
            </div>
        </Ax>);
    }

    requisitionListingTableJsx            =   ()   =>   {
        return (<Ax>
            <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.requisitionSearchClear} />
            <table className="table table-bordered table-fixed  bg-white table-sm">
                <thead className="table-secondary" >
                    {this.state.minimizeTable
                        ? (<tr>
                            <th scope="col" style={{ width: "15%" }}>Requisition</th>
                        </tr>)
                        : (<tr>
                            <th scope="col" style={{ width: "5%" }}>S.No</th>
                            <th scope="col" style={{ width: "15%" }}>Transaction ID</th>
                            <th scope="col" style={{ width: "12%" }}>Date of Transaction</th>
                            <th scope="col" style={{ width: "15%" }}>Warehouse</th>
                            <th scope="col" style={{ width: "25%" }}>Title</th>
                            <th scope="col" style={{ width: "10%" }}>Status</th>
                            <th scope="col" className="text-center" style={{ width: "8%" }}>Total Qty</th>
                            <th scope="col" className="text-end" style={{ width: "10%" }}>Total Amount</th>
                            <th scope="col" className="text-center" style={{ width: "5%" }}>Action</th>
                        </tr>)
                    }
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="10"><Loader /></td></tr>)
                        : (this.state.requisition_listing.length > 0
                            ? (this.state.requisition_listing.map((item, index) => {
                                return (<tr key={index} >
                                    {
                                        this.state.minimizeTable
                                            ? (
                                                <Ax>
                                                    <td>
                                                        <a role="button" onClick={() => this.viewRequisitionDetail(item.transaction_id)} className="link-primary">{item.transaction_id}</a>
                                                        <br /><small>{item.transaction_date_display}</small>
                                                        <small style={{ float: 'right' }}>{item.status_data ? item.status_data.name : ''}</small>
                                                    </td>
                                                </Ax>
                                            )
                                            : (<Ax>
                                                <td>{this.state.listingMeta ? this.state.listingMeta.from + index : index} </td>
                                                <td><a role="button" onClick={() => this.viewRequisitionDetail(item.transaction_id)} className="link-primary">{item.transaction_id}</a></td>
                                                <td>{item.transaction_date_display}</td>
                                                <td>{item.warehouse ? item.warehouse.name : '-'}</td>
                                                <td>{item.title??"-"} </td>
                                                <td>{item.status_data ? item.status_data.name : '-'}</td>
                                                <td className="text-center">{item.total_qty}</td>
                                                <td className="text-end">
                                                <CurrencyFormatter currencyCode={this.props.default_currency} amount={parseFloat(item.total_amount ? item.total_amount : 0).toFixed(2)}/>
                                                    </td>
                                                <td className="text-center">
                                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" role="button" onClick={() => this.viewRequisitionDetail(item.transaction_id)}>View</a></li>
                                                    </ul>
                                                </td>
                                            </Ax>
                                            )}
                                </tr>)
                            }))
                            : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>)
    }

    requisitionViewDetailJsx                =   ()   =>   {
        let requisition = this.state.requisitionDetail;
        
        return (<div className="tab_content_header">

            {!this.state.requisition_loading
                ? (<Ax>
                    {this.state.requisitionDetail
                        ? <div>
                            <table className="table table-bordered bg-white" >
                                <thead className="table-secondary">
                                    <tr>
                                        <th style={{ width: "33%" }}>ID</th>
                                        <th style={{ width: "33%" }}>Date</th>
                                        <th style={{ width: "33%" }}>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td scope="col">{requisition.transaction_id}</td>
                                        <td scope="col">{requisition.transaction_date_display}</td>
                                        <td scope="col" className="align-middle">
                                            {requisition.status_data ? requisition.status_data.name : ''}
                                        </td>  </tr>
                                </tbody>
                            </table>
                            <table className="table table-hover table-bordered  table-responsive bg-white mb-0">
                                <tbody>
                                    <tr>
                                        <td style={{ width: "33%" }} >Warehouse</td>
                                        <td colSpan={2}>{requisition.warehouse ? <div><b>{requisition.warehouse.name} ({requisition.warehouse.code})</b> <br />
                                            {requisition.warehouse ?
                                                <div className='pt-1'>Address : {requisition.warehouse.address1 ? requisition.warehouse.address1 + ", " : ""}
                                                    {requisition.warehouse.address2 ? requisition.warehouse.address2 + ", " : ""}
                                                    {requisition.warehouse.landmark ? requisition.warehouse.landmark + ", " : ""}
                                                    {requisition.warehouse.city ? requisition.warehouse.city + ". " : "-"} <br />
                                                    Destination of Supply : <b>{requisition.warehouse.state_name}</b> <br />
                                                    GSTIN  : {requisition?.warehouse?.gstin ?? "-"} <br />
                                                    Site  : {requisition.site ? requisition.site.site_name : "-"}</div> : "-"}</div> : "-"} <br />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td scope="col">Title</td>
                                        <td scope="col">{requisition.title ?? "-"}</td>
                                    </tr>
                                    <tr>
                                        <td scope="col">Priority</td>
                                        <td scope="col" className='text-capitalize'>{requisition.priority ?? "-"}</td>
                                    </tr>
                                    <tr>
                                        <td scope="col">Due Date</td>
                                        <td scope="col">{requisition.due_date_display ?? "-"}</td>
                                    </tr>
                                    <tr>
                                        <td scope="col">Tag Users</td>
                                        <td scope="col">{requisition.usersList.map(u => <div>{u.full_name}</div>)}</td>
                                    </tr>
                                    <tr>
                                        <td scope="col">Tag Asset</td>
                                        <td scope="col">
                                            {requisition.assetData ? requisition.assetData.name : ''}
                                            {requisition.assetData ? <small className="form-text"> ({requisition.assetData.asset_code})</small> : ''}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td scope="col">Indent</td>
                                        <th scope="col" className='link-primary cursor_pointer'>{requisition.warehouse_indent ? <span onClick={() => this.viewIndentDetailModalInit(requisition.warehouse_indent.transaction_id)}>{requisition.warehouse_indent.transaction_id} ({requisition.warehouse_indent.transaction_date_display})</span> : "-"}</th>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="table  table-sm table-bordered table-responsive bg-white my-3" id="dataTable">
                                <thead className="table-secondary">
                                    <tr className="text-end">
                                        <th scope="col" style={{ width: "5%" }} className="text-center"><input type='checkbox' onChange={this.checkAllList} id="all_check_requisition" /></th>
                                        <th scope="col" style={{ width: "32%" }} className="text-start">Item Details</th>
                                        <th scope="col" style={{ width: "13%" }} className="text-start">Quantity ,Unit & Rate</th>
                                        <th scope="col" style={{ width: "8%" }}>Indent Qty</th>
                                        <th scope="col" style={{ width: "8%" }}>Req. Qty</th>
                                        <th scope="col" style={{ width: "8%" }}>PO Qty</th>
                                        <th scope="col" style={{ width: "8%" }}>Received</th>
                                        <th scope="col" style={{ width: "8%" }}>Cancelled </th>
                                        <th scope="col" style={{ width: "8%" }}>Pending </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        requisition && requisition?.items && requisition?.items?.length > 0 ? requisition?.items?.map((i, k) => {
                                            return (<tr key={k}>
                                                <td className='text-center'>{k + 1} <br /><br/><input type='checkbox'
                                                    className="req_item_id_checkbox"
                                                    name="foo"
                                                    value={i.req_item_id}
                                                    data-warehouseId={requisition.warehouse_id}
                                                    data-statusLevel={requisition.status_data && requisition.status_data.level}
                                                    data-pendingQty={i.pending_qty}
                                                    data-itemid={i.item.id}
                                                /></td>
                                                <td>{i.item ? <div className='row'>
                                                    <div className='col-sm-10'>
                                                    <ItemViewInList item={i.item} remark={i.remark} viewItemModal={() => this.viewItemModalInit(i.item)} />
                                                    </div>
                                                    <div className='col-sm-2 text-end'>
                                                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                        <ul className="dropdown-menu">
                                                            <li><a href="#" className="dropdown-item" onClick={() => this.viewConsumptionModalInit(i.item, k)}>Consumption History</a></li>
                                                           <li><a href="#" className="dropdown-item" onClick={() => this.viewPOHistoryModalInit(i.item, k)}>PO History</a></li>
                                                            <li><a href="#" className="dropdown-item" onClick={() => this.viewTransactionLedgerModalInit(i.item, k)}>Item Transaction Ledger</a></li>
                                                            {this.props.permissions.includes('isc-item-Interchangeable-edit') && <li><a href="#" className="dropdown-item" onClick={() => this.viewInterchangeableModalInit(i, k)}>Interchange Item</a></li>}
                                                        </ul>
                                                    </div>
                                                </div> : null}</td>
                                               
                                                <td> <div className="fs12">{i.qty} {i.item?.measuring_unit?.name ?? ""} @ {i.currency} {i.rate} ({i.currency}.{i.item_amount}) </div></td>
                                                <td className="text-end">{i.indent_data ? i.indent_data.indent_need_qty : "-"}</td>
                                                <td className="text-end">{i.qty}</td>
                                               
                                                <td className="text-end"><a href="#" className={'link-primary cursor_pointer'} onClick={() => this.poQtyDetailModalInit(i.req_item_id)}>{i.current_po_qty}</a></td>
                                                <td className="text-end">{i.received_qty}</td>
                                                <td className="text-end">{i.cancelled_qty}</td>
                                                <td className="text-end">{i.pending_qty}</td>
                                            </tr>);


                                        })
                                            : <tr ><td className='text-center' colSpan={7}>No Record</td></tr>
                                    }
                                </tbody>
                            </table>
                            <div className="row align-items-center ">
                                <div className="col-sm-6">
                                    <label htmlFor="notes" className="form-label">Notes</label>
                                    <div>{requisition.notes ?? "-"}</div>

                                </div>
                                <div className=" col-sm-6">

                                    <table className="table  table-borderless">
                                        <tbody>
                                            <tr className="subtotal_bg_color">
                                                <th>Sub-Total</th>
                                                <td className='float-end'>
                                                <CurrencyFormatter currencyCode={this.props.default_currency} amount={requisition.total_amount ? (requisition.total_amount - requisition.total_tax).toFixed(2) : 0.00}/>
                                                    </td>
                                            </tr>
                                            <tr className="subtotal_bg_color">
                                                <th>Total Tax</th>
                                                <td className='float-end'>
                                                <CurrencyFormatter currencyCode={this.props.default_currency} amount={requisition.total_tax ?? 0}/>
                                                    </td>
                                            </tr>
                                            <tr className="subtotal_bg_color">
                                                <th>Final Total</th>
                                                <td className='float-end'>
                                                <CurrencyFormatter currencyCode={this.props.default_currency} amount={requisition.total_amount ? requisition.total_amount : 0}/>
                                                    </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <hr />
                            {requisition.status == 5 || requisition.status == 6
                                ? null
                                : <div>
                                    <div className="tab_content_wrapper" ref={this.myRef}>
                                        <span className="content_heading">Comments & History</span>
                                    </div>
                                    {this.state.allAllowedStatus
                                        ? <form onSubmit={this.submitUpdateCommentForm}>
                                            <div className="my-3 row">
                                                <label className="col-sm-3 col-form-label">Change status to</label>
                                                <div className="col-sm-5">
                                                    <TapSelect
                                                        changeEvent={(selectedOption) => {
                                                            this.tapSelectChange(selectedOption, 'addCommentForm', 'status');
                                                        }}
                                                        value={this.state.allAllowedStatus.find(s => s.value == this.state.addCommentForm.status)}
                                                        options={this.state.allAllowedStatus}
                                                        isSearchable={false}
                                                        placeholder="Please Select Status"
                                                        isDisabled={!this.props.permissions.includes('isc-purchase-requisition-status-change') ? true  :false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="my-2">
                                                <label className="form-label">Comments</label>
                                                <textarea
                                                    name="notes"
                                                    value={this.state.addCommentForm.notes}
                                                    onChange={(e) => this.formInputHandler(e, 'addCommentForm')}
                                                    className="form-control"
                                                    placeholder="Please Add a comment"
                                                    required
                                                    style={{ height: "100px" }}
                                                />
                                            </div>
                                            <div className="col-12 text-end">
                                                <button type="submit" disabled={this.state.addCommentFormSubmitting ? true : false} className="btn btn-primary mx-2">
                                                    Update {this.state.addCommentFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin ms-2" />) : ''}
                                                </button>
                                            </div>
                                        </form>
                                        : null}
                                </div>}
                            <hr />
                            <div className="my-1">
                                {
                                    requisition.comments.map((c, k) => {
                                        return (<Ax key={k}><div className="row">
                                            {c.msg ? <div className="col-sm-8" dangerouslySetInnerHTML={{ __html: c.msg }} /> : <div className='col-sm-8'>-</div>}
                                            <div className="col-sm-4 text-end">
                                                <tapIcon.imageIcon icon={tapIcon.CalenderIcon} className="img-fluid pr-5 fs12" alt="item-action" />
                                                <span> {c.created_at_display}</span>
                                            </div>
                                            <div className="col-sm-8 my-1">{c.notes}</div>
                                        </div><hr /></Ax>);
                                    })
                                }
                            </div>
                        </div>
                        : null}
                </Ax>)
                : <Loader />}

        </div>)
    }

   
     requisitionSearchModalJsx               =        ()             =>        {
       
         return (
             <div className="modal fade" id="requisitionSearchModal" tabIndex="-1">
                 <div className="modal-dialog modal-lg">
                     <div className="modal-content">
                         <div className="modal-header">
                             <h5 className="modal-title" id="searchAssetModalLabel">Requisition Search</h5>
                             <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.requisitionClear}></button>
                         </div>
                         <form id="requisitionSearchForm" onSubmit={this.submitRequisitionSearchForm}>
                             <div className="modal-body">
                                 {!this.state.requisitionSearchFormDataLoaded ? <Loader />
                                     : <Ax>
                                         <div className="row my-3 align-items-center">
                                             <label className="col-sm-2 col-form-label col-form-label-sm  ">Requisition</label>
                                             <div className="col-sm-10">
                                                 <TapSelect
                                                     isSearchable={true}
                                                     isClearable={true}
                                                     placeholder="Select Requisition Type"
                                                     containerHeight="30px"
                                                     fontSize="93%"
                                                     options={this.state.userFilters}
                                                     value={this.state.userFilters.find(r => r.value === this.state.requisitionSearchForm.tagged_user_filter)}
                                                     changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'requisitionSearchForm', 'tagged_user_filter')}
                                                 />
                                             </div>


                                         </div>
                                         <div className="row my-3 align-items-center">
                                             <label className="col-sm-2 col-form-label col-form-label-sm  ">Transaction ID</label>
                                             <div className="col-sm-4">
                                                 <input
                                                     name="search_transaction_id"
                                                     type="text"
                                                     value={this.state.requisitionSearchForm.search_transaction_id}
                                                     className="form-control"
                                                     autoComplete="off"
                                                     onChange={(e) => this.formInputHandler(e, "requisitionSearchForm")}
                                                     placeholder="Please enter Transaction ID"
                                                     style={{ height: "30px", fontSize: "93%" }}
                                                 />
                                             </div>
                                             <label className="col-sm-1 col-form-label col-form-label-sm  ">Title</label>
                                             <div className="col-sm-5">
                                                 <input
                                                     name="search_title"
                                                     type="text"
                                                     value={this.state.requisitionSearchForm.search_title}
                                                     className="form-control"
                                                     autoComplete="off"
                                                     onChange={(e) => this.formInputHandler(e, "requisitionSearchForm")}
                                                     placeholder="Please enter Title"
                                                     style={{ height: "30px", fontSize: "93%" }}
                                                 />
                                             </div>


                                         </div>
                                         <div className="row my-3 align-items-center">
                                             <label className="col-sm-2 col-form-label col-form-label-sm  ">Status</label>
                                             <div className="col-sm-4">
                                                 <TapSelect
                                                     options={this.state.allStatusOptions}
                                                     changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'requisitionSearchForm', 'search_status_ids')}
                                                     isSearchable={true}
                                                     isClearable={true}
                                                     isMulti={true}

                                                     value={this.state.allStatusOptions.filter(s => this.state.requisitionSearchForm.search_status_ids.includes(s.value))}
                                                     placeholder="Select Status"
                                                     containerHeight="30px"
                                                     fontSize="93%"
                                                 />
                                             </div>
                                             <label className="col-sm-1 col-form-label col-form-label-sm  ">Priority</label>
                                             <div className="col-sm-5">
                                                 <TapSelect
                                                     options={this.state.allPriorityOptions}
                                                     changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'requisitionSearchForm', 'search_priority')}
                                                     value={this.state.allPriorityOptions.find(u => u.value == this.state.requisitionSearchForm.search_priority)}
                                                     isSearchable={true}
                                                     isClearable={true}
                                                     placeholder="Select Priority"
                                                     containerHeight="30px"
                                                     fontSize="93%"
                                                 />
                                             </div>


                                         </div>
                                         <div className="row my-3 align-items-center">
                                             <label className="col-sm-2 col-form-label col-form-label-sm  ">Warehouse</label>
                                             <div className="col-sm-10">
                                                 <TapSelect
                                                     options={this.state.all_warehouses}
                                                     changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'requisitionSearchForm', 'search_warehouse_ids')}
                                                     value={this.state.all_warehouses.filter(u => this.state.requisitionSearchForm?.search_warehouse_ids?.includes(u.value))}
                                                     isSearchable={true}
                                                     isMulti={true}
                                                     isClearable={true}
                                                     placeholder="Select Warehouses"
                                                     containerHeight="30px"
                                                     fontSize="93%"
                                                 />
                                             </div>
                                         </div>
                                         <div className="row my-3 align-items-center">
                                             <label className="col-sm-2 col-form-label col-form-label-sm ">Item</label>
                                             <div className="col-sm-10">
                                                 <InputItemSearch
                                                     placeholder="Search Item"
                                                     isMulti={true}
                                                     changeEvent={(selectedOption) => {
                                                         this.tapSelectChange(selectedOption, 'requisitionSearchForm', 'search_item_ids');
                                                         this.setState({
                                                             searchedItem: selectedOption ? selectedOption : null
                                                         }, () => { });
                                                     }}
                                                     containerHeight="30px"
                                                     fontSize="93%"
                                                     value={this.state.searchedItem.length > 0 ? this.state.searchedItem.map(a => { a.label = a.display_label; return a; }) : []}
                                                 />
                                             </div>

                                         </div>
                                         <div className="row my-3 align-items-center">
                                             <label className="col-sm-2 col-form-label col-form-label-sm">Asset</label>
                                             <div className="col-sm-10">
                                                 <InputAssetSearch
                                                     containerHeight="30px"
                                                     fontSize="93%"
                                                     changeEvent={(selectedOption) => {
                                                         this.tapSelectChange(selectedOption, 'requisitionSearchForm', 'search_asset_ids');
                                                         this.setState({ searchedAssets: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                                     }}
                                                     isMulti={true}
                                                     menuPlacement="top"
                                                     value={this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                                                 />
                                             </div>
                                         </div>
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Item Tags</label>
                                            <div className="col-sm-10">
                                                <TapSelect
                                                    options={this.state.allItemTags}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'requisitionSearchForm', 'search_item_tags')}
                                                    value={this.state.allItemTags.filter(u => this.state.requisitionSearchForm?.search_item_tags?.includes(u.value))}
                                                    isSearchable={true}
                                                    isMulti={true}
                                                    isClearable={true}
                                                    placeholder="Select Item Tags"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>
                                        </div>
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Tagged User</label>
                                            <div className="col-sm-10">
                                                <TapSelect
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    isMulti={true}
                                                    placeholder="Tagged User"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                    options={this.state.allUsers}
                                                    value={this.state.allUsers.filter(s => this.state.requisitionSearchForm.tagged_user_ids.includes(s.value))}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'requisitionSearchForm', 'tagged_user_ids')}
                                                />
                                            </div>
                                        </div>
                                         <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Voucher Ageing</label>
                                            <div className="col-sm-10">
                                                <input
                                                    name="search_voucher_aging"
                                                    type="text"
                                                    value={this.state.requisitionSearchForm.search_voucher_aging}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "requisitionSearchForm")}
                                                    placeholder="Example. 0-5 for a range or 4 for Voucher Ageing"
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>
                                            
                                        </div>
                                        <div className="row my-3 align-items-center">
                                            
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Approval Ageing</label>
                                            <div className="col-sm-10">
                                                <input
                                                    name="search_approved_aging"
                                                    type="text"
                                                    value={this.state.requisitionSearchForm.search_approved_aging}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "requisitionSearchForm")}
                                                    placeholder="Example. 0-5 for a range or 4 for Approval Ageing"
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>


                                        </div>
                                         <div className="row my-3 align-items-center">
                                             <label className="col-sm-2 col-form-label col-form-label-sm ">Transaction Period</label>
                                             <div className="col-sm-10">
                                                 <PeriodSelectionWithCustom
                                                     periods={this.state.all_periods ? this.state.all_periods : []}
                                                     value={this.state.requisitionSearchForm.date_range}
                                                     startDate={this.state.requisitionSearchForm.date_range_start}
                                                     endDate={this.state.requisitionSearchForm.date_range_end}
                                                     onSelectPeriod={(period, startDate, endDate) => {
                                                         this.setState({
                                                             requisitionSearchForm
                                                                 : {
                                                                 ...this.state.requisitionSearchForm,
                                                                 date_range: period,
                                                                 date_range_start: startDate ? startDate : null,
                                                                 date_range_end: endDate ? endDate : null
                                                             }
                                                         });
                                                     }}
                                                     className="form-control-sm"
                                                     containerHeight="30px"
                                                     fontSize="93%"
                                                 />
                                             </div>
                                         </div>
                                        
                                     </Ax>}
                             </div>
                             <div className="modal-footer">
                                 <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={this.requisitionClear}>Close</button>
                                 <button type="submit" className="btn btn-primary">Search</button>
                             </div>
                         </form>
                     </div>
                 </div>
             </div>
         );
    }

    viewIndentDetailModalJsx                             =   ()  =>  {
        let indent = this.state.indentDetail;
        return (
            <div className="modal fade" id="viewIndentDetailModal" tabIndex="-1">
                <div className="modal-dialog modal-xl modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="viewIndentDetailModalLabel">Indent : {this.state.indentTransId ? this.state.indentTransId  : "-"}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body">
                            {!this.state.indent_detail_loading
                                ? (<Ax>
                                    {indent
                                        ?
                                        <div>
                                            <table className="table table-bordered bg-white" >
                                                <thead className="table-secondary">
                                                    <tr>
                                                        <th style={{ width: "33%" }}>ID</th>
                                                        <th style={{ width: "33%" }}>Date</th>
                                                        <th style={{ width: "33%" }}>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td scope="col">{indent.transaction_id ? indent.transaction_id : "-"}</td>
                                                        <td scope="col">{indent.transaction_date_display ? indent.transaction_date_display : "-"}</td>
                                                        <td scope="col" className="align-middle">
                                                            {indent.status_data ? indent.status_data.name : ''}
                                                        </td>  </tr>
                                                </tbody>
                                            </table>
                                            <table className="table table-hover table-bordered  table-responsive bg-white mb-0">
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "33%" }} >Warehouse</td>
                                                        <td colSpan={2}>{indent.warehouse_detail ? <div><b>{indent.warehouse_detail.name} ({indent.warehouse_detail.code})</b> <br />
                                                            {indent.warehouse_detail ?
                                                                <div className='pt-1'>Address : {indent.warehouse_detail.address1 ? indent.warehouse_detail.address1 + ", " : ""}
                                                                    {indent.warehouse_detail.address2 ? indent.warehouse_detail.address2 + ", " : ""}
                                                                    {indent.warehouse_detail.landmark ? indent.warehouse_detail.landmark + ", " : ""}
                                                                    {indent.warehouse_detail.city ? indent.warehouse_detail.city + ". " : "-"} <br />
                                                                    Destination of Supply : <b>{indent.warehouse_detail.state_name}</b> <br />
                                                                    GSTIN  : {indent.warehouse_detail.gstin ? indent.warehouse_detail.gstin : "-"} <br />
                                                                </div> : "-"}</div> : "-"} <br />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td scope="col">Title</td>
                                                        <th scope="col">{indent.title ? indent.title : "-"}</th>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: "33%" }} >Indent For</td>
                                                        <th>{indent.indent_for && indent.indent_for == "asset" ? "Asset" : indent.indent_for && indent.indent_for == "workstation" ? "Workstation" : indent.indent_for && indent.indent_for == "employee" ? "Employee" : "-"} </th>
                                                    </tr>
                                                    {indent.indent_for ?
                                                        <tr>
                                                            <td style={{ width: "33%" }} >{indent.indent_for && indent.indent_for == "asset" ? "Asset" : indent.indent_for && indent.indent_for == "workstation" ? "Workstation" : indent.indent_for && indent.indent_for == "employee" ? "Employee" : "null"}</td>
                                                            <th>
                                                                {indent.indent_for == "asset" && indent.assetProfile ? indent.assetProfile.name : indent.indent_for == "workstation" && indent.workstation ? indent.workstation.workstation_name : indent.indent_for == "employee" && indent.employee ? indent.employee.name : "-"}
                                                            </th>
                                                        </tr> : null}
                                                    {indent.additional_attributes_data && indent.additional_attributes_data.length > 0
                                                        ? indent.additional_attributes_data.map((attr) => {
                                                            return (<tr key={attr.key}>
                                                                <td scope="col" className='text-capitalize'>{attr.name ? attr.name : "-"}</td>
                                                                <th scope="col">{attr.value ? attr.value : "-"}</th>
                                                            </tr>)
                                                        }) : null}
                                                </tbody>
                                            </table>
                                            <table className="table  table-sm table-bordered table-responsive bg-white my-3" id="dataTable">
                                                <thead className="table-secondary">
                                                    <tr className="text-end">
                                                        <th scope="col" style={{ width: "5%" }} className="text-center"><input type='checkbox' onChange={this.checkAllItem} id="all_check_indent" /></th>
                                                        <th scope="col" className="text-center" style={{ width: "31%" }}>Item Details</th>
                                                        <th scope="col" className="text-center" style={{ width: "8%" }}>Indent Qty</th>
                                                        <th scope="col" className="text-end" style={{ width: "8%" }}>Fullfilled</th>
                                                        <th scope="col" className="text-end" style={{ width: "8%" }}>Pur. Req</th>
                                                        <th scope="col" className="text-end" style={{ width: "8%" }}>PO</th>
                                                        <th scope="col" className="text-end" style={{ width: "8%" }}>Purchase</th>
                                                        <th scope="col" className="text-end" style={{ width: "8%" }}>In Transit </th>
                                                        <th scope="col" className="text-end" style={{ width: "8%" }}>Cancelled  </th>
                                                        <th scope="col" className="text-end" style={{ width: "8%" }}>Pending </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        indent && indent?.items && indent?.items?.length > 0 ? indent?.items?.map((i, k) => {
                                                            return (<tr key={k} className="text-end">
                                                                <td className='text-center'>
                                                                    <input
                                                                        type='checkbox'
                                                                        name="foo"
                                                                        value={i?.itemData?.id}
                                                                        data-pendingQty={i.pending_qty}
                                                                        className="indent_item_id_checkbox"
                                                                        data-warehouseId={indent.warehouse_id}
                                                                        data-itemData={i?.itemData}
                                                                        data-transactionId={indent.transaction_id}
                                                                        data-statusLevel={indent.status_data && indent.status_data.level}
                                                                        data-statusIds={indent.status_data && indent.status_data.id}
                                                                    />
                                                                </td>
                                                                <td className="text-start">{i.itemData ? <div className='row'>
                                                                    <div className='col-sm-9'>
                                                                        <ItemViewInList item={i.itemData} remark={i.remark} viewItemModal={() => this.viewItemModalInit(i.itemData)} />
                                                                    </div>

                                                                </div> : null}</td>
                                                                <td>{i.indent_qty ? i.indent_qty : 0}</td>
                                                                <td>{i.fullfilled_qty ? i.fullfilled_qty : 0}</td>
                                                                <td>{i.req_qty ? i.req_qty : 0}</td>
                                                                <td>{i.po_qty ? i.po_qty : 0}</td>
                                                                <td>{i.purchase_qty ? i.purchase_qty : 0}</td>
                                                                <td>{i.transit_qty ? i.transit_qty : 0}</td>
                                                                <td>{i.cancelled_qty ? i.cancelled_qty : 0}</td>
                                                                <td>{i.pending_qty ? i.pending_qty : 0}</td>
                                                            </tr>);


                                                        })
                                                            : <tr ><td className='text-center' colSpan={10}>No Record</td></tr>
                                                    }

                                                </tbody>
                                            </table>
                                            <div className="row align-items-center ">
                                                <div className="col-sm-6">
                                                    <label htmlFor="notes" className="form-label">Notes</label>
                                                    <div>{indent.notes ? indent.notes : "-"}</div>

                                                </div>
                                                <div className=" col-sm-6">

                                                    <table className="table  table-borderless">
                                                        <tbody>

                                                            <tr className="subtotal_bg_color">
                                                                <th>Total Quantity</th>
                                                                <td className='float-end'>{indent.total_qty ? indent.total_qty : 0} </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </div>
                                        : null}
                                </Ax>)
                                : <Loader />}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    viewInterchangeableItemJsx                            =   ()  =>  {
       
        let data = this.state.interchangeItemData ? this.state.interchangeItemData : null;
        let max_qty = data && data.pending_qty ? data.pending_qty : 0;
        // if(data && data.indent_data && data.pending_qty && data.indent_data.pending_qty < data.pending_qty){
        //     max_qty =   data.indent_data.pending_qty;
        // }

        return (
            <div className="modal fade" id="interchangebleItemModal" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="interchangebleItemModal">Interchange Item  </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        {data && <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>
                                    <tr>
                                        <td>Requisition ID </td>
                                        <th>{this.state.requisitionTransId}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "26%" }}>Item Name     </td>
                                        <th>{data.item && data.item.name ? data.item.name : ""} </th>
                                    </tr>
                                    <tr>
                                        <td>Item Code </td>
                                        <th>{data.item && data.item.item_code ? data.item.item_code : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td>Req Qty </td>
                                        <th>{data && data.qty ? data.qty : ''}</th>
                                    </tr>
                                    <tr>
                                        <td>Pending Qty </td>
                                        <th>{data && data.pending_qty ? data.pending_qty : '0'}</th>
                                    </tr>
                                    {data && data.indent_data && <Ax>
                                        <tr>
                                            <td>Indent Qty </td>
                                            <th>{data && data.indent_data ? data.indent_data.indent_need_qty : "-"}</th>
                                        </tr>
                                        <tr>
                                            <td>Indent Pending Qty </td>
                                            <th>{data && data.indent_data ? data.indent_data.pending_qty : "-"}</th>
                                        </tr>
                                    </Ax>}
                                </tbody>
                            </table>
                        </div>
                        }

                        <div className="modal-body">
                            <form className="bg-white" onSubmit={this.submitSelectedInterchangeItem} id="interchangeItemForm" method="post" encType="multipart/form-data">
                                <table className="table table-hover table-bordered table-responsive bg-white my-3 table-sm">
                                    <thead className="table-secondary">
                                        <tr className="text-center">
                                            <th style={{ "width": '7%' }}>
                                            <input type='checkbox' onChange={this.checkAllInterchangeList} id="all_check_interchange" />
                                            </th>
                                            <th className="text-start">Item Details</th>
                                            
                                            <th style={{ width: "13%" }} className="text-end">Qty</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.interchangeable_loading
                                            ? (<tr><td colSpan="6"><Loader /></td></tr>)
                                            : (this.state.interchangeableItemList.length > 0
                                                ? (this.state.interchangeableItemList.map((item, k) => {
                                                    return (<tr key={k} className="text-center">
                                                        <td>
                                                            <input type='checkbox'
                                                                className="interchange_id_checkbox"
                                                                name="interchangebleItem"
                                                                value={item.id}
                                                                data-qty={item?.new_qty}
                                                            />
                                                        </td>
                                                        <td className="text-start"><Ax>
                                                            <b>{item.name} </b> <br />
                                                            <small>Code : <b> {item.item_code} </b></small> <br />
                                                            <small>Mfr : <b> {item.manufacturer?.name ?? '-'} </b>  (MPN : <b> {item.manufacturer_part_no ?? '-'}</b>)</small>
                                                        </Ax></td>
                                                        <td>
                                                            <input
                                                                className='form-control form-control-sm text-end'
                                                                placeholder='Quantity'
                                                                name="new_qty"
                                                                type="number"
                                                                value={item.new_qty}
                                                                onChange={(e) => {
                                                                    this.state.interchangeableItemList[k] = { ...item, new_qty: e.target.value };
                                                                    this.dynamicInputHandlerByKeyNameVal(e.target.value, 'new_qty', k, 'interchangeableItemList')
                                                                }}
                                                                max={max_qty}
                                                                autoComplete="off"
                                                                step=".01" />
                                                        </td>
                                                        

                                                    </tr>);
                                                }))
                                                : (<tr><td colSpan="6" className="text-center">No Records</td></tr>)
                                            )
                                        }
                                    </tbody>
                                </table>
                                <div className="row align-items-center ">
                                    <div className="col-sm-6">
                                        <label htmlFor="notes" className="form-label">Notes</label>
                                        <div> <textarea
                                            name="notes"
                                            value={this.state.interchangeItemForm.notes}
                                            onChange={(e) => this.formInputHandler(e, 'interchangeItemForm')}
                                            className="form-control"
                                            autoComplete='off'
                                            style={{ height: "100px" }}
                                        /></div>

                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" disabled={this.state.saveInterchangeableItemForm} className="btn btn-primary" form="interchangeItemForm">Submit {this.state.saveInterchangeableItemForm ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }



    render                              =   ()  =>  {
       
        return (<ApplicationLayout>
        <Helmet><title>Purchase Requisition Listing</title></Helmet>
            {this.requisitionListjsx()}
            {this.requisitionSearchModalJsx()}
            {this.viewIndentDetailModalJsx()}
            {this.viewInterchangeableItemJsx()}
            <POQtyDetail ref={this.pOQtyDetailModalRef}/>
            <RequisitionCancel ref={this.cancelledItemModalRef} requisitionTransId={this.state.requisitionTransId} getRequisitionDetail={this.getRequisitionDetail}/>
            <RaisePurchaseOrder parentProps={this.props} ref={this.RaisePOModalRef} />
            <ItemDetailsModal ref={this.itemDetailsRef}  /> 
            <ConsumptionHistoryModal ref={this.consumptionHistoryRef}/>
            <POHistoryModal ref={this.poHistoryRef}/>
            <ItemTransactionLedger ref={this.transactionLedgerRef}/>
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        default_currency        :   state.app && state.app.default_currency ? state.app.default_currency : 'INR',
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(RequisitionList);