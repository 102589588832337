import React from 'react';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import {connect} from "react-redux";
import tapIcon from "../../../../services/TapIcon";
import { Modal } from 'bootstrap';
import TapIcon from '../../../../services/TapIcon';
import TapSelect from "../../../../components/ui/TapSelect";
import AdditionalAttribute from '../../AdditionalAttribute';
import Workflow from './Workflow';
import { ORG_API_BASE_URL_2 } from '../../../../services/TapApiUrls';
import HttpAPICall from '../../../../services/HttpAPICall';
import { toast } from 'react-toastify';
import Loader from '../../../../components/ui/Loader/Loader';


class TaskWorkflow extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initGeneralConfigForm          =   {
            prevent_new_task_at_open_task       :   'N',
            new_task_start_on_prev_task_close   :   'N',
            disable_new_task_before_latest_task :   'N',
            delete_only_latest_task             :   'N'
        };

        this.state                          =       {
            saveFormSubmitting                  :   false,
            allAdditonalAttributes              :   [],
            generalConfigloading                :   false,
            general_config_list                 :   null,
            generalConfigForm                   :   {...this.initGeneralConfigForm},
            editFormSubmiting                   :   false,
            currentTaskKey                      :   ''
        };

        this.settingEditUrl =   ORG_API_BASE_URL_2  + '/setting_edit';
        this.settingUrl     =   ORG_API_BASE_URL_2  + '/settings';
    }

    componentDidMount() {
        this.getGeneralSettingListing();
        this.editGeneralConfiguration        =        new Modal(document.getElementById('editGeneralConfigurationModal'), {keyboard: false, backdrop :false});
    }

    getGeneralSettingListing = () => {
        let params = {
            keys : [ 'prevent_new_task_at_open_task','new_task_start_on_prev_task_close','disable_new_task_before_latest_task','delete_only_latest_task','prevent_task_after_3rd_date','previous_day_task'],
        }
        this.setState({ generalConfigloading: true });
        HttpAPICall.withAthorization('GET', this.settingUrl, this.props.access_token, {...params}, {}, (resp) => {
            this.setState({ general_config_list: resp.data.data })
        }).then(() => this.setState({ generalConfigloading: false }));
    }

    editGeneralConfigurationInit = (key = '') => {
        this.editGeneralConfiguration.show();
        if(this.state.general_config_list) {
            let generalConfigForm = [];
            if (this.state.general_config_list) {
                Object.entries(this.state.general_config_list).forEach(([key, value]) => {
                  let name = key;
                  let val = value;
                  generalConfigForm[name] = val;
                });
              }
              
            this.setState({generalConfigForm : generalConfigForm,currentTaskKey : key})
        }
    }

    submitGeneralConfigurationForm        =    (e)          =>     {
        e.preventDefault();
        this.setState({editFormSubmiting : true});
        HttpAPICall.withAthorization('PUT', this.settingEditUrl, this.props.access_token, {} , {...this.state.generalConfigForm} , (response) => {
            toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
            this.editGeneralConfiguration.hide();
            this.getGeneralSettingListing();
        }).then(() => this.setState({editFormSubmiting: false}));
    }

    editGeneralConfigurationModalJsx      =    ()           =>     {
        return (
            <div className="modal fade" id="editGeneralConfigurationModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Edit General Configuration</h5>
                        </div>
                        <form onSubmit={this.submitGeneralConfigurationForm}>
                            <div className="modal-body p-3">
                               {this.state.currentTaskKey  == "prevent_new_task_at_open_task" && <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-9"><label className="form-label">Prevent New Task if a pervious task is open</label></div>
                                    <div className="col-md-1">
                                        <label>
                                            <input
                                                type="radio"
                                                name="prevent_new_task_at_open_task"
                                                value="Y"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.prevent_new_task_at_open_task == "Y"}
                                            /> Yes
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="prevent_new_task_at_open_task"
                                                value="N"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.prevent_new_task_at_open_task == "N"} /> No
                                        </label>
                                    </div>
                                </div>}
                               {this.state.currentTaskKey == "new_task_start_on_prev_task_close" &&  <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-9"><label className="form-label">Task Starting Counterlog Reading must be equal to previous task closing Counterlog Reading</label></div>
                                    <div className="col-md-1">
                                        <label>
                                            <input
                                                type="radio"
                                                name="new_task_start_on_prev_task_close"
                                                value="Y"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.new_task_start_on_prev_task_close == "Y"}
                                            /> Yes
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="new_task_start_on_prev_task_close"
                                                value="N"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.new_task_start_on_prev_task_close == "N"} /> No
                                        </label>
                                    </div>
                                </div>}
                                {this.state.currentTaskKey == "disable_new_task_before_latest_task" &&  <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-9"><label className="form-label">Disable New Task on a date before the latest task date</label></div>
                                    <div className="col-md-1">
                                        <label>
                                            <input
                                                type="radio"
                                                name="disable_new_task_before_latest_task"
                                                value="Y"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.disable_new_task_before_latest_task == "Y"}
                                            /> Yes
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name="disable_new_task_before_latest_task"
                                                value="N"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm.disable_new_task_before_latest_task == "N"} /> No
                                        </label>
                                    </div>
                                </div>}
                                {['prevent_task_after_3rd_date', 'delete_only_latest_task','previous_day_task'].includes(this.state.currentTaskKey) &&  <div className="row align-items-center mb-2 mt-2 px-2">
                                    <div className="col-md-9">
                                    {this.state.currentTaskKey == 'delete_only_latest_task' && <label className="form-label">Delete Only Latest Task</label>}
                                    {this.state.currentTaskKey == 'prevent_task_after_3rd_date' && <label className="form-label">Prevent Task Creation After 3rd of Every Month</label>}
                                    {this.state.currentTaskKey == 'previous_day_task' && <label className="form-label">Previous Day must have Task</label>}
                                    </div>
                                    <div className="col-md-1">
                                        <label>
                                            <input
                                                type="radio"
                                                name={this.state.currentTaskKey}
                                                value="Y"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm[this.state.currentTaskKey] == "Y"}
                                            /> Yes
                                        </label>
                                    </div>
                                    <div className="col-md-2">
                                        <label>
                                            <input
                                                type="radio"
                                                name={this.state.currentTaskKey}
                                                value="N"
                                                onChange={(e) => this.formInputHandler(e, "generalConfigForm")}
                                                checked={this.state.generalConfigForm[this.state.currentTaskKey] == "N"} /> No
                                        </label>
                                    </div>
                                </div>}
                              
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.editFormSubmiting} data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.editFormSubmiting} >Save {this.state.editFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
  }

    generalConfigurationJsx               =    ()           =>     {
        let data = this.state.general_config_list;
        return (<table className="table table-hover table-bordered my-4 ">
            <thead className="table-secondary">
                <tr className="text-center">
                    <th scope="col" style={{ width: "5%" }}>S.No</th>
                    <th scope="col" className="text-start">Configuration</th>
                    <th scope="col" style={{ width: "25%" }} >Value</th>
                    <th scope="col" style={{ width: "10%" }} >Action</th>
                </tr>
            </thead>
            <tbody>
                
                <tr className="text-center">
                    <td>1</td>
                    <td className="text-start">Prevent New Task if a pervious task is open</td>
                    <td>{data && data.prevent_new_task_at_open_task && data.prevent_new_task_at_open_task == 'Y' ? "Yes" : "No"}</td>
                    <td> <span className="dropdown">
                          <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                              <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              <li><a className="dropdown-item" role="button" onClick={() => this.editGeneralConfigurationInit('prevent_new_task_at_open_task')}>Edit</a></li>
                          </ul>
                      </span></td>
                </tr>
                <tr className="text-center">
                    <td>2</td>
                    <td className="text-start">Task Starting Counterlog Reading must be equal to previous task closing Counterlog Reading</td>
                    <td>{data && data.new_task_start_on_prev_task_close && data.new_task_start_on_prev_task_close == 'Y' ? "Yes" : "No"}</td>
                    <td> <span className="dropdown">
                          <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                              <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              <li><a className="dropdown-item" role="button" onClick={() => this.editGeneralConfigurationInit('new_task_start_on_prev_task_close')}>Edit</a></li>
                          </ul>
                      </span></td>
                </tr>
                <tr className="text-center">
                    <td>3</td>
                    <td className="text-start">Disable New Task on a date before the latest task date</td>
                    <td>{data && data.disable_new_task_before_latest_task && data.disable_new_task_before_latest_task == 'Y' ? "Yes" : "No"}</td>
                    <td> <span className="dropdown">
                          <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                              <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              <li><a className="dropdown-item" role="button" onClick={() => this.editGeneralConfigurationInit('disable_new_task_before_latest_task')}>Edit</a></li>
                          </ul>
                      </span></td>
                </tr>
                <tr className="text-center">
                    <td>4</td>
                    <td className="text-start">Delete Only Latest Task</td>
                    <td>{data && data.delete_only_latest_task && data.delete_only_latest_task == 'Y' ? "Yes" : "No"}</td>
                    <td> <span className="dropdown">
                          <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                              <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              <li><a className="dropdown-item" role="button" onClick={() => this.editGeneralConfigurationInit('delete_only_latest_task')}>Edit</a></li>
                          </ul>
                      </span></td>
                </tr>
                <tr className='text-center'>
                    <td>5</td>
                    <td className='text-start'>Prevent Task Creation After 3rd of Every Month</td>
                    <td>{data && data.prevent_task_after_3rd_date && data.prevent_task_after_3rd_date == 'Y' ? "Yes" : "No"}</td>
                    <td> <span className="dropdown">
                          <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                              <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              <li><a className="dropdown-item" role="button" onClick={() => this.editGeneralConfigurationInit('prevent_task_after_3rd_date')}>Edit</a></li>
                          </ul>
                      </span></td>
                </tr>
                <tr className='text-center'>
                    <td>6</td>
                    <td className='text-start'>Previous Day must have Task</td>
                    <td>{data && data.previous_day_task && data.previous_day_task == 'Y' ? "Yes" : "No"}</td>
                    <td> <span className="dropdown">
                          <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                              <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              <li><a className="dropdown-item" role="button" onClick={() => this.editGeneralConfigurationInit('previous_day_task')}>Edit</a></li>
                          </ul>
                      </span></td>
                </tr>
            </tbody>
        </table>)
    }

    render() {
        return (
            <section className="page_containt " style={{ top: "1px" }}>
                <div className=" bg-white">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="workflow-tab" data-bs-toggle="tab" data-bs-target="#workflow"
                                type="button" role="tab" aria-controls="workflow" aria-selected="true">Workflow
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="setting-tab" data-bs-toggle="tab" data-bs-target="#setting"
                                type="button" role="tab" aria-controls="setting" aria-selected="true">Setting
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link " id="additional_attribute-tab" data-bs-toggle="tab" data-bs-target="#additional_attribute"
                                type="button" role="tab" aria-controls="additional_attribute" aria-selected="true">Additional Attribute
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">

                        <div className="tab-pane show active" id="workflow" aria-labelledby="workflow-tab">
                            <div className="tab_content_header">
                                <Workflow attributes_lists={this.state.allAdditonalAttributes} />
                            </div>
                        </div>

                        <div className="tab-pane" id="setting" aria-labelledby="setting-tab">
                            <div className="tab_content_header">
                                <div className="tab_content_wrapper mt-2">
                                    <span className="content_heading">General Configuration</span>
                                </div>
                                {this.state.generalConfigloading ? <Loader /> : this.generalConfigurationJsx() }
                                {this.editGeneralConfigurationModalJsx()}
                            </div>
                        </div>

                        <div className="tab-pane" id="additional_attribute" aria-labelledby="additional_attribute">
                            <div className="tab_content_header">
                                <AdditionalAttribute category="iam_activity_task" getAttributeList={(attributes_lists) => this.setState({allAdditonalAttributes : attributes_lists}) } />
                            </div>
                        </div>
                    </div>
                </div>
                {this.editGeneralConfigurationModalJsx()}
            </section>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token
    };
};

export default connect(mapStateToProps)(TaskWorkflow);