import React from 'react';
import AppBaseComponent from '../../../components/AppBaseComponent';
import {connect} from "react-redux";
import Ax from "../../../components/hoc/Ax";
import AssetCard from "../../includes/assetCard/AssetCard";
import {Link} from "react-router-dom";
import Loader from "../../../components/ui/Loader/Loader";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import {Modal} from "bootstrap";
import {toast} from "react-toastify";
import swal from "sweetalert";
import errorValidation from "../../../services/ErrorValidation";
import { Helmet } from 'react-helmet';
import TapSelect from '../../../components/ui/TapSelect';
import TapApiUrls from '../../../services/TapApiUrls';
import { DisplayListPagination } from '../../../components/TapUi';
import SubAssemblyDetail from '../../groupData/subAssembly/SubAssemblyDetail';
import LinkingModal from '../../groupData/subAssembly/LinkingModal';

class SubAssembly extends AppBaseComponent {

    constructor(props) {
        super(props);
    
        this.state                  =        {
            asset_id                :        null,
            subAssemblyList       :        [],
            listing_loading         :        false,
            listingMeta             :           null,
            totalListingCount       :           0,
            subAssemblyId           :           null
        }
        this.subAssemblyModalRef    =   React.createRef();
        this.linkAssetModalRef      =   React.createRef();
    }

    id = this.props.asset_enc_id

    componentDidMount() {
        this.subAssemblyDetailModal                  =   new Modal(document.getElementById('subAssemblyDetailModal'), {keyboard: false, backdrop :false});
        this.getSubAssemblyList(this.props.asset_enc_id)
     }

    getSubAssemblyList             =         (id,page=1)       => {
        this.setState({listing_loading : true})
        HttpAPICall.withAthorization('GET', process.env.REACT_APP_IAM_API_SERVER + '/asset/linked_sub_assembly_list/' + id,
            this.props.access_token,{page:page})
            .then((response) => {
                let respData                =   response.data;
                this.setState({
                    subAssemblyList        :      respData.data,
                    listingMeta            :       respData.meta,
                    totalListingCount      :       respData.meta.total,
                });
            }).then(() => {
            this.setState({listing_loading: false});
        });
    }

    showSubAssemblyDetail  =    (id)      =>  {
        this.subAssemblyDetailModal.show();
        this.setState({subAssemblyId : id})
        this.subAssemblyModalRef.current.subAssemblyDetail(id)
        this.subAssemblyModalRef.current.getDashboardDetail(id)
        this.subAssemblyModalRef.current.getSiteLinking(id)
        this.subAssemblyModalRef.current.getAssetLinkinglisting(id) 
    }
    
    linkAssetModalInit = (type= '') => {
        this.linkAssetModalRef.current.linkAssetModalInit(null,type,this.props.assetData?.asset_id,this.props.assetData,'N',this.props.assetData?.site_id)   
    }

    delinkAssetModalInit        =   (id,subAssemblyId)      =>      {
        this.setState({loadingDetail : false})
        HttpAPICall.withAthorization('GET',  TapApiUrls.IAM + '/sub_assembly/detail/' + subAssemblyId, this.props.access_token, {}, {}, (resp) => {
            let respData = resp.data.data;
            this.linkAssetModalRef.current.linkAssetModalInit(respData,'delink',id)
        }).then(() => this.setState({loadingDetail: false}));
       
    }

    subAssemblyDetailModalJsx              =   ()  =>  {
        return (
            <div className="modal fade" id="subAssemblyDetailModal" tabIndex="-1">
                <div className="modal-dialog  modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addContactModalLabel">Sub Assembly Detail</h5>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close
                            </button>
                        </div>
                        <div className="modal-body">
                            <SubAssemblyDetail ref={this.subAssemblyModalRef} subAssemblyId={this.state.subAssemblyId} />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary mx-2" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        let  id = this.props.asset_enc_id
        
        return (
            <Ax>
                <Helmet><title>Asset SubAssembly</title></Helmet>
                <div className="page_containt">
                    <div className="pageTbl p-3" style={{ height: "100%" }}>
                        <div className="tab_content_wrapper mt-2">
                            <span className="content_heading">Linked Sub Assembly</span>
                           {this.props.permissions.includes('iam-sub-assembly-asset-manage') && <div className='float-end'><button className='btn btn-primary' onClick={() => this.linkAssetModalInit('link')}>Link</button></div>}
                        </div>
                        <table className="table table-bordered mt-3">
                            <thead className="table-secondary">
                                <tr>
                                    <th style={{ width: "5%" }}>S.No</th>
                                    <th style={{ width: "24%" }}>Name</th>
                                    <th style={{ width: "15%" }}>Category</th>
                                    <th style={{ width: "10%" }} className="text-center">Meter</th>
                                    <th style={{ width: "5%" }} className="text-center">Unit</th>
                                    <th style={{ width: "12%" }} className="text-center">Start</th>
                                    <th style={{ width: "12%" }} className="text-center">End</th>
                                    <th style={{ width: "12%" }} className="text-center">Total Running</th>
                                    {this.props.permissions.includes('iam-sub-assembly-asset-manage') && <th style={{ width: "5%" }} className="text-center">Action</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.listing_loading
                                    ? <tr>
                                        <td className="text-center" colSpan="7"><Loader /></td>
                                    </tr>
                                    :
                                    this.state.subAssemblyList && this.state.subAssemblyList.length > 0 ? this.state.subAssemblyList.map((item, index) => {
                                        return item.current_running_display && item.current_running_display.length > 0 ?
                                            item.current_running_display.map((sf, key) => {
                                                return (
                                                    <tr className='text-center  '>{key == 0 ? <Ax>
                                                        <td className="va_middle text-start" rowspan={item?.current_running_display?.length}>{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                                        <td className="va_middle text-start link-primary cursor_pointer" rowspan={item?.current_running_display?.length} onClick={() => this.showSubAssemblyDetail(item?.enc_id)} >{item.name} <br />{item.code && <div className='form-text text-sm'>Code : {item.code}</div>}</td>
                                                        <td className="va_middle text-start" rowspan={item?.current_running_display?.length}>{item?.category?.name ?? "-"}</td>
                                                    </Ax> : null}
                                                        <td>{sf.meter}</td>
                                                        <td>{sf.unit}</td>
                                                        <td>{sf.start}</td>
                                                        <td>{sf.end}</td>
                                                        <td>{sf.running}</td>
                                                        {key === 0 && this.props.permissions.includes('iam-sub-assembly-asset-manage') ? (
                                                        <td rowSpan={item.current_running_display.length} className="text-center">
                                                            <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                                <ul className="dropdown-menu">
                                                                    <li><Link className="dropdown-item" onClick={() => this.delinkAssetModalInit(this.props.assetData.asset_id,item.enc_id)}>De-link</Link></li>

                                                                </ul>
                                                        </td>
                                                    ) : null}
                                                    </tr>
                                                )
                                            }) : null


                                    }) : <tr>
                                        <td className="text-center" colSpan="5">No Record</td>
                                    </tr>}
                            </tbody>
                        </table>
                        <DisplayListPagination
                            meta={this.state.listingMeta}
                            onPageChange={(e) => this.getSubAssemblyList(this.props.asset_enc_id, e.selected + 1)}
                        />
                    </div>
                </div>
                {this.subAssemblyDetailModalJsx()}
                <LinkingModal ref={this.linkAssetModalRef} afterSubmitForm={() => this.getSubAssemblyList(this.props.asset_enc_id)}/>
            </Ax>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    }
}

export default connect(mapStateToProps)(SubAssembly);
