import React from 'react';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import Loader from "../../../components/ui/Loader/Loader";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import download from '../../../services/DownloadFile';
import swal from "sweetalert";
import { Modal } from 'bootstrap';
import TapSelect from '../../../components/ui/TapSelect';
import AppBaseComponent from '../../../components/AppBaseComponent';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import moment from 'moment';
import InputItemSearch from '../../includes/ui/InputItemSearch';
import { Helmet } from 'react-helmet';
import InputEmployeeSearch from '../../includes/ui/InputEmployeeSearch';
import ItemViewInList from '../includes/ItemViewInList';
import ItemDetailsModal from '../../items/itemDetails/ItemDetailsModal';
import POHistoryModal from '../purchaseOrder/POHistoryModal';
import ReqHistoryItemWiseModal from '../requisition/ReqHistoryItemWiseModal';
import PurchaseItemWiseModal from '../purchase/PurchaseItemWiseModal';
import TransferItemWiseListModal from '../inventoryTransfer/TransferItemWiseListModal';
import ConsumptionHistoryModal from '../consumption/ConsumptionHistoryModal';
import ItemTransactionLedger from '../includes/ItemTransactionLedger';
import TapLink from '../../../services/TapLink';
import TapInputNumber from '../../../components/ui/TapInputNumber';

class WarehouseIndentList extends AppBaseComponent {

    constructor(props) {
        super(props);
       
        this.initIndentSearchForm      =       {
            search_transaction_id            :       '',
            search_title                     :       '',
            search_warehouse_ids             :       [],
            date_range                       :       null,
            date_range_start                 :       null,
            date_range_end                   :       null,
            search_item_ids                  :       [],
            search_item_tags                 :       [],
            search_status_ids               :       [],
            search_indent_for               :         '',
            search_asset_ids                :        [],
            search_workstation_ids          :        [],
            search_employee_ids             :        [],
            search_approved_aging           :        '',
            search_voucher_aging            :        '',
            tagged_user_filter              :       '',
            tagged_user_ids                 :       [],
        }

        this.indentSearchFormLabel       =       {
            search_transaction_id           :   'Transaction ID : ',
            search_title                    :   'Title : ',
            search_warehouse_ids            :   'Warehouses : ',
            date_range                      :   'Date Range : ',
            search_item_ids                 :   'Items : ',
            search_item_tags                :   'Item Tags : ',
            search_status_ids               :   'Search Status : ',
            search_indent_for               :   'Indent For : ',
            search_asset_ids                :   'Assets : ',
            search_workstation_ids          :   'Workstation : ',
            search_employee_ids             :   'Employee : ',
            search_approved_aging           :   'Approval Ageing : ',
            search_voucher_aging            :   'Voucher Ageing : ',
            tagged_user_filter              :   'User Tagging : ',
            tagged_user_ids                 :   'Tagged Users : ',
        }

        this.updateCommentFormInit            =       {
            status                            :       '',
            notes                             :       ' ',
        }

        this.cancelItemFormInit               =       {
            notes                             :       '',
        }

        this.state                      =  {    
            minimizeTable                       :       false,
            listing_loading                     :       false,
            listing_tbl_page                    :       1,
            indent_listing                      :       [],
            listingMeta                         :       null,
            totalListingCount                   :       0,
            indentDetail                        :       null,
            indentTransId                       :       [],
            allAllowedStatus                    :       [],
            indentId                            :       '',
            addCommentForm                      :       {...this.updateCommentFormInit},
            indentSearchForm                    :       {...this.initIndentSearchForm},
            submittedIndentFilterForm           :       {...this.initIndentSearchForm},
            formSearchedElems                   :        [],
            all_periods                         :       [],
            searchedItems                       :       [],
            allStatusOptions                    :        [],
            allItemTags                         :        [],
            searchedAssets                      :        [],
            searchedEmployees                   :        [],
            allWorkstations                     :        [],
            allIndentOptions                    :        [],
            all_warehouses                      :        [],
            indentSearchFormDataLoaded          :        false,
            addCommentFormSubmitting            :        false,
            cancelledItemList                   :       [],
            cancelItemFormSubmitting            :       false,
            pdfDownloading                      :        false,
            cancelledItemForm                   :       {...this.cancelItemFormInit},
            interchangeItemData                 :        null,
            interchangeableItemList             :    [],
            interchangeableListingMeta          :   null,
            interchangeableTotalListingCount    :   0,
            interchangeable_loading             :   false,
            interchangeable_page                :   1,
            saveInterchangeableItemForm         :   false,
            interchangeItemForm                 :    {notes:''},
            userFilters                         :   [
                {value  :   'tagged_me', label : 'My Tagged Warehouse Indent'},
                {value  :   'created_by_me', label : 'Warehouse Indent Created By Me'},
            ],
            allUsers                            : [],         
        };
        this.myRef                      =   React.createRef();
        this.itemDetailsRef             =   React.createRef();
        this.itemWisePORef              =   React.createRef();
        this.itemWiseReqRef             =   React.createRef();
        this.itemWisePurchaseRef        =   React.createRef();
        this.itemWiseTransferRef        =   React.createRef();
        this.consumptionHistoryRef      =   React.createRef();
        this.transactionLedgerRef       =   React.createRef();

        this.searchFormDataUrl      =   INVENTORY_API_BASE_URL_2 + '/warehouse_indent/search_form_data';
        this.warehouseIndentUrl     =   INVENTORY_API_BASE_URL_2 + '/warehouse_indent';
        this.detailUrl              =   INVENTORY_API_BASE_URL_2 + '/warehouse_indent/detail';
        this.commentUrl             =   INVENTORY_API_BASE_URL_2 + '/warehouse_indent/comment';
        this.itemUrl                =   INVENTORY_API_BASE_URL_2 + '/item';        
    }

    componentDidMount() {
        let ParamObject                 =       new URLSearchParams(this.props.location.search);
        // this.loadListingTblData(1);
        if(ParamObject.get('search_status_ids') || ParamObject.get('search_voucher_ageing') || ParamObject.get('search_approval_ageing') ){
            this.initializeData(this.props)
        }else{
            this.loadListingTblData(1);
            this.initializeData(this.props)
        }
       
         if(this.props.location && this.props.location.state && this.props.location.state.indentId){
            this.viewIndentDetail(this.props.location.state.indentId)
         }
         this.indentSearchModal                 =   new Modal(document.getElementById('indentSearchModal'), {keyboard: false, backdrop :false});
         this.cancelIndentModal                     =   new Modal(document.getElementById('cancelIndentModal'), {keyboard: false, backdrop :false});
         this.interchangebleItemModal                 =   new Modal(document.getElementById('interchangebleItemModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.all_warehouses !== this.props.all_warehouses) {
            this.initializeData(nextProps)
         }     
    }

    initializeData     =      (pr)      =>      {
        if(pr){
            if(!this.state.indentSearchFormDataLoaded) {
                HttpAPICall.withAthorization('GET', this.searchFormDataUrl, this.props.access_token, null, null, (response) => {
                 let formData = response.data;
                 let all_periods             =       [...this.props.all_periods , {key : 'custom' , display : 'Custom Date Range'}] ;
                    this.setState({
                        indentSearchFormDataLoaded         :       true,
                        allWorkstations                    :       formData && formData.workstations ?  formData.workstations.map((ws) => {  return {value: ws.id, label:`${ws.workstation_name} `, workstation : ws} }) : [],
                        allIndentOptions                   :       [{value:'asset',label:'Asset'},{value:'workstation',label:'Workstation'},{value:'employee',label:'Employee'}],
                        all_warehouses                     :       pr.all_warehouses ? pr.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}) : [] ,
                        all_periods                        :       all_periods ,
                        allStatusOptions                   :       formData && formData.status && formData.status.length > 0 ?  formData.status.map((s) => { return {value: s.id, label: s.name}; }) : [],
                        allItemTags                        :       formData && formData.tags && formData.tags.length > 0 ? formData.tags.map((s) => { return {value: s.id, label: s.name}; }) : [],
                        allUsers                     :   formData && formData.users ? formData.users.map((s) => { return {value: s.id, label: s.full_name,site_ids:s.linked_sites}; }) : [],
                    });
                });
                let ParamObject                 =       new URLSearchParams(pr.location.search);
                if(ParamObject.get('search_voucher_ageing') || ParamObject.get('search_approval_ageing') ){
                    let status_ids           =         ParamObject.get('search_status_ids').split(',').map(Number);
                    let updateFilterForm        =   {
                        search_voucher_aging : ParamObject.get('search_voucher_ageing') ?  ParamObject.get('search_voucher_ageing') : '',
                        search_approved_aging : ParamObject.get('search_approval_ageing') ?  ParamObject.get('search_approval_ageing') : '',
                        search_status_ids: status_ids
                    };
                    if (ParamObject.get('search_warehouse_ids')) {
                        let warehouse        =  pr.all_warehouses.find(us => us.id == ParamObject.get('search_warehouse_ids'));
                        if (warehouse) {
                            updateFilterForm['search_warehouse_ids'] = [warehouse.id];
                        }
                    }
                    if (ParamObject.get('tagged_user')) {
                        updateFilterForm['tagged_user_filter'] = ParamObject.get('tagged_user');
                    }
    

                   
                    setTimeout(() => {
                        this.setState({
                            indentSearchForm  : { ...this.state.indentSearchForm, ...updateFilterForm},                      
                        },
                            function () {
                                this.submitIndentSearchForm();
                            });
                    }, 1000)
                }else if (ParamObject.get('search_status_ids')){
                    let status_ids           =         ParamObject.get('search_status_ids').split(',').map(Number);
                    let updateFilterForm     =          { search_status_ids: status_ids }
                    if (ParamObject.get('search_warehouse_ids')) {
                        let warehouse        =  pr.all_warehouses.find(us => us.id == ParamObject.get('search_warehouse_ids'));
                        if (warehouse) {
                            updateFilterForm['search_warehouse_ids'] = [warehouse.id];
                        }
                    }
                    if (ParamObject.get('tagged_user')) {
                        updateFilterForm['tagged_user_filter'] = ParamObject.get('tagged_user');
                    }
    
                   
                    setTimeout(() => {
                        this.setState({
                            indentSearchForm  : { ...this.state.indentSearchForm, ...updateFilterForm},                      
                        },
                            function () {
                                this.submitIndentSearchForm();
                            });
                    }, 1000)
                }
            }
        
        }
    }

   
  
     //************************GET Warehouse Indent LISTING**************** */
     loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,...this.state.submittedIndentFilterForm};
        HttpAPICall.withAthorization('GET',  this.warehouseIndentUrl, this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
            this.setState({
                indent_listing      :       respData.data,
                listingMeta         :       respData.meta,
                totalListingCount   :       respData.meta.total,
        });
        }).then(() => this.setState({listing_loading: false}));
    }

    indentSearchModalInit               =       ()             =>        {
        this.indentSearchModal.show();
    }
    
    submitIndentSearchForm                =       (e)          =>          {
        e && e.preventDefault()
        let serachFormKeys              =   Object.keys(this.state.indentSearchForm);
       
        let searchedElems               =   [];
        let searchParams                =   {};
        serachFormKeys.map((key)        =>  {
            
            let label                       =   this.indentSearchFormLabel[key];
            let value                       =   this.state.indentSearchForm[key];
            
            if(value && value.toString().length > 0) {
                searchParams[key]      =   value;
           if(label) {
                let show_val            =   value;
                if(key == 'search_transaction_id') {
                    show_val            =   this.state.indentSearchForm && this.state.indentSearchForm.search_transaction_id ? this.state.indentSearchForm.search_transaction_id : '';
                }
                if(key == 'search_title') {
                    show_val            =   this.state.indentSearchForm && this.state.indentSearchForm.search_title ? this.state.indentSearchForm.search_title  : '';
                }
                if(key == 'search_warehouse_ids') {
                    show_val            =   this.state.all_warehouses.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                }
                if(key == 'search_item_ids') {
                    show_val            =   this.state.searchedItems ? this.state.searchedItems.map(s => s.display_label).join(', ') : '';
                }
                if(key == 'search_item_tags') {
                    show_val            =   this.state.allItemTags.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                }
                if(key == 'search_status_ids') {
                    show_val            =   this.state.allStatusOptions.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                }
                
                if(key == 'search_asset_ids') {
                    show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                }
                if(key == 'search_workstation_ids') {
                    show_val            =   this.state.allWorkstations.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                }
                if(key == 'search_employee_ids') {
                    show_val            =   this.state.searchedEmployees ? this.state.searchedEmployees.map(s => s.label).join(', ') : '';
                }
                if(key == 'search_approved_aging') {
                    show_val            =   this.state.indentSearchForm && this.state.indentSearchForm.search_approved_aging ? this.state.indentSearchForm.search_approved_aging  : '';
                }
                if(key == 'search_voucher_aging') {
                    show_val            =   this.state.indentSearchForm && this.state.indentSearchForm.search_voucher_aging ? this.state.indentSearchForm.search_voucher_aging  : '';
                }
                if(key == 'tagged_user_filter') {
                    show_val            =   this.state.userFilters.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                }
                if(key == 'tagged_user_ids') {
                    show_val            =   this.state.allUsers.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                }
                if(key == 'date_range') {
                    let  start_range         =      moment(this.state.indentSearchForm.date_range_start).format('DD-MMM-YYYY');
                    let end_range            =      moment(this.state.indentSearchForm.date_range_end).format('DD-MMM-YYYY')
                    let display_custom      =      `Custom Date Range (${start_range} - ${end_range})`
                    show_val                 =     this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                }
                searchedElems.push(label + `<b>${show_val}</b>`);
            }
        }
    });
        this.setState({
            submittedIndentFilterForm   :   {...this.state.indentSearchForm},
            formSearchedElems           :   searchedElems
        }, () => {
            this.indentSearchModal.hide()
            this.loadListingTblData(1);
        });
    }

    indentSearchClear                  =        ()             =>        {
        this.setState({
            formSearchedElems             :   [],
            indentSearchForm              :   {...this.initIndentSearchForm},
            submittedIndentFilterForm     :   {...this.initIndentSearchForm},
            searchedAssets                :   [],
            searchedEmployees             :   []
        }, () => {
            this.loadListingTblData(1);
            this.indentSearchModal.hide();
        });
    }

    openAddStoreIndentScreen        =       ()      =>      {
        this.props.history.push('/warehouse_indent_add')
    }

   

    closeViewDetails        =       ()      =>      {
        this.setState({minimizeTable : false})
    }

    viewIndentDetail          =      (transaction_id)        =>     {
        this.setState({ minimizeTable: true })
       if(transaction_id !== this.state.indentTransId){
          this.getIndentDetail(transaction_id)
       }
    }

    viewItemModalInit                    =   (item)  =>  { 
        if(item){
            this.itemDetailsRef.current.modalInit(item)
        }
    }
   
    updateComment           =           ()          =>      {
        this.myRef.current.scrollIntoView()
    }

    submitUpdateCommentForm         =       (e)          =>      {
        e.preventDefault()
        this.setState({addCommentFormSubmitting : true})
        let frmData = {
             ...this.state.addCommentForm,
             transaction_id : this.state.indentTransId
           }
           HttpAPICall.withAthorization('PUT', this.commentUrl, this.props.access_token, {}, { ...frmData }, (response) => {
               toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
               this.getIndentDetail(this.state.indentTransId);
               if(this.state.indentDetail && this.state.indentDetail.status_data && this.state.indentDetail.status_data.id !== this.state.addCommentForm.status){
                 this.loadListingTblData(1)
               }
               this.setState({addCommentForm : {...this.updateCommentFormInit}})
           }).then(() => this.setState({ addCommentFormSubmitting : false }));
   }

    getIndentDetail        =       (transaction_id)      =>      {
    
        this.setState({indent_loading : true})
        HttpAPICall.withAthorization('GET',  this.detailUrl + '/' + transaction_id, this.props.access_token, null, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                indentDetail    :   respData.data,
                indentTransId   :   respData.data.transaction_id,
                allAllowedStatus     :   respData.data && respData.data.user_status && respData.data.user_status.length > 0 ? respData.data.user_status.map((s) => { return {value: s.id, label: s.name}; }) : [] ,
                indentId        :   respData.data.id,
                addCommentForm       :  {...this.updateCommentFormInit , status : respData.data.status_data ? respData.data.status_data.id : '' }   
            });
        
        },(err) => {
            if(err){
                this.setState({minimizeTable : false})
            }
        }).then(() => this.setState({indent_loading : false}));
   
    }

    /***************Open Indent from Voucher**************** */

    checkAllItem() {
        let indentItemForCheckbox      =   document.getElementsByClassName('indent_item_id_checkbox')
        if(indentItemForCheckbox && indentItemForCheckbox.length > 0) {
            for (let i = 0; i < indentItemForCheckbox.length; i++) {
                indentItemForCheckbox[i].checked = document.getElementById('all_check_indent').checked;
            }
        }
    }

    openIndentFulfillmentfromVoucher        =       ()          =>      {
        let indent_item_ids                 =   [];
        let warehouse_ids                   =   [];
        let status_levels                   =   [];
        let pendingQtyList                  =   [];
        let status_ids                      =   [];
        let itemDataList                    =   [];
        let transactionId                      =   [];
        let indentDetail                    =      this.state.indentDetail;
        
        let indentItemForCheckbox      =   document.getElementsByClassName('indent_item_id_checkbox');
        if(indentItemForCheckbox && indentItemForCheckbox.length > 0) {
            for (let i = 0; i < indentItemForCheckbox.length; i++) {
                if(indentItemForCheckbox[i].checked) {
                    
                    indent_item_ids.push(indentItemForCheckbox[i].value);
                    warehouse_ids.push(indentItemForCheckbox[i].getAttribute("data-warehouseId"));
                    status_levels.push(indentItemForCheckbox[i].getAttribute("data-statusLevel"));
                    pendingQtyList.push(indentItemForCheckbox[i].getAttribute("data-pendingQty"));
                    status_ids.push(indentItemForCheckbox[i].getAttribute("data-statusIds"));
                    let selectedItemData= this.state?.indentDetail.items?.find(data => data?.itemData?.id == indentItemForCheckbox[i].value);
                    transactionId.push(indentItemForCheckbox[i].getAttribute("data-transactionId"))
                    itemDataList.push({...selectedItemData?.itemData,remark : selectedItemData && selectedItemData.remark ? selectedItemData.remark : ''});
                }
            }
        }
        let warehouse_ids_unique            =   warehouse_ids.filter((item, i, ar) => ar.indexOf(item) === i);
        if (indent_item_ids.length == 0) {
            toast.error('Please select atleast one Item', { position: toast.POSITION.TOP_RIGHT });
        } else if(warehouse_ids_unique.length > 1) {
            toast.error('Please select item with same warehouse', { position: toast.POSITION.TOP_RIGHT });
        } else if(pendingQtyList.includes('0')) {
            toast.error('Every item must have pending qty more than 0', { position: toast.POSITION.TOP_RIGHT });
        } else if(!status_levels.every((e) => e >= 3 && e < 5)) {
            toast.error('Fullfillment is allowed only for Approved Indents', { position: toast.POSITION.TOP_RIGHT });
        } else {
       
            let warehouseIndentData                    =   {   
                warehouse_id                :   warehouse_ids[0],
                items                       :   indent_item_ids,
                status_ids                  :   status_ids,
                transactionId               :   transactionId[0],
                assetProfile                :   indentDetail && indentDetail.assetProfile ? indentDetail.assetProfile : null,
                title                       :   indentDetail && indentDetail.title ? indentDetail.title : '',
                itemDataList                :   itemDataList.map(v => ({...v, display_label : v.name,label:v.name,value:v.id}))
            };
           
            this.props.history.push({ pathname: `/indent_fulfillment`, state: { warehouseIndentData : warehouseIndentData} });
        }
           
    }

    transferPurchaseInit        =       ()          =>      {

        let indent_item_ids                 =   [];
        let warehouse_ids                   =   [];
        let status_levels                   =   [];
        let purchaseQtyList                  =   [];
        let status_ids                      =   [];
        // let itemDataList                    =   [];
        let transactionId                      =   [];
        // let indentDetail                    =      this.state.indentDetail;
        
        let indentItemForCheckbox      =   document.getElementsByClassName('indent_item_id_checkbox');
        if(indentItemForCheckbox && indentItemForCheckbox.length > 0) {
            for (let i = 0; i < indentItemForCheckbox.length; i++) {
                if(indentItemForCheckbox[i].checked) {
                    
                    indent_item_ids.push(indentItemForCheckbox[i].value);
                    warehouse_ids.push(indentItemForCheckbox[i].getAttribute("data-warehouseId"));
                    status_levels.push(indentItemForCheckbox[i].getAttribute("data-statusLevel"));
                    purchaseQtyList.push(indentItemForCheckbox[i].getAttribute("data-purchaseQty"));
                    status_ids.push(indentItemForCheckbox[i].getAttribute("data-statusIds"));
                    transactionId.push(indentItemForCheckbox[i].getAttribute("data-transactionId"))
                    // let selectedItemData= this.state?.indentDetail.items?.find(data => data?.itemData?.id == indentItemForCheckbox[i].value);
                    // transactionId.push(indentItemForCheckbox[i].getAttribute("data-transactionId"))
                    // itemDataList.push({...selectedItemData?.itemData,remark : selectedItemData && selectedItemData.remark ? selectedItemData.remark : ''});
                }
            }
        }
        let warehouse_ids_unique            =   warehouse_ids.filter((item, i, ar) => ar.indexOf(item) === i);
        if (indent_item_ids.length == 0) {
            toast.error('Please select atleast one Item', { position: toast.POSITION.TOP_RIGHT });
        } else if(purchaseQtyList.includes('0')) {
            toast.error('Every item must have purchase qty more than 0', { position: toast.POSITION.TOP_RIGHT });
        } else if(!status_levels.every((e) => e >= 3 && e < 5)) {
            toast.error('Transfer Purchase is allowed only for Approved Indents', { position: toast.POSITION.TOP_RIGHT });
        } else {
       
            let warehouseIndentData                    =   {   
                warehouse_id                :   warehouse_ids[0],
                transactionId               :   transactionId[0],
            };
           
            this.props.history.push({ pathname: `/inventory_transfer_list/add`, state: {transferPurchaseFromIndent : warehouseIndentData} });
        }
           
    }

    /***************Open Indent from Listing**************** */

    checkAllIndentItemList() {
        let indentItemForCheckbox      =   document.getElementsByClassName('indent_list_id_checkbox')
        if(indentItemForCheckbox && indentItemForCheckbox.length > 0) {
            for (let i = 0; i < indentItemForCheckbox.length; i++) {
                indentItemForCheckbox[i].checked = document.getElementById('all_indent_list_check').checked;
            }
        }
    }

    openIndentFulfillment        =       ()          =>      {
        let indent_transacton_ids           =   [];
        let warehouse_ids                   =   [];
       
        let indentItemForCheckbox      =   document.getElementsByClassName('indent_list_id_checkbox');
        if(indentItemForCheckbox && indentItemForCheckbox.length > 0) {
            for (let i = 0; i < indentItemForCheckbox.length; i++) {
                if(indentItemForCheckbox[i].checked) {
                    indent_transacton_ids.push(indentItemForCheckbox[i].value);
                    warehouse_ids.push(indentItemForCheckbox[i].getAttribute("data-warehouseId"));
                  
                }
            }
        }
        let warehouse_ids_unique            =   warehouse_ids.filter((item, i, ar) => ar.indexOf(item) === i);
        if(warehouse_ids_unique.length > 1) {
            toast.error('Please select item with same warehouse', { position: toast.POSITION.TOP_RIGHT });
        }else {
       
            let warehouseIndentData                    =   {   
                warehouse_id                :   warehouse_ids[0],
                transactionId              :   indent_transacton_ids.toString()
            };
           
            if(warehouseIndentData && warehouseIndentData.warehouse_id){
                this.props.history.push({ pathname: `/indent_fulfillment`, state: { warehouseIndentData : warehouseIndentData} });
            }else{
                this.props.history.push({ pathname: `/indent_fulfillment` });
            }
            
        }
           
    }

    cancelWarehouseIndentItem       =       (transaction_id)          =>      {
        let cancelledItemList          =        this.state.cancelledItemList;
        var showErrorMessage                =   false;
        let indentItemForCheckbox      =   document.getElementsByClassName('indent_item_id_checkbox');
        if(indentItemForCheckbox && indentItemForCheckbox.length > 0) {
            for (let i = 0; i < indentItemForCheckbox.length; i++) {
                if(indentItemForCheckbox[i].checked) {
                    let itemId = indentItemForCheckbox[i].value;
                    let data = this.state.indentDetail.items.find(i => i.item_id  == itemId);
                    let row                         =   {...cancelledItemList[i],...data };
                    cancelledItemList[i]            =   row;
                    let list = cancelledItemList.filter(Boolean)
                     this.setState({cancelledItemList   :   [...list],
                                   cancelledItemForm   :   {...this.state.cancelItemFormInit,
                                   notes : ''}}
                                   ,() => this.cancelIndentModal.show())
                   
                }
            }
        }
        if(this.state.cancelledItemList && this.state.cancelledItemList.length == 0 && !showErrorMessage){
            toast.error('Please select an Item for Cancellation', { position: toast.POSITION.TOP_RIGHT });
        }
      
    }

    submitCancelItemForm            =       (e)      =>      {
        e.preventDefault()
        
        let items                   =        [...this.state.cancelledItemList];
          
        let itemsLists              =        items.map(i => {
                                               
                                                 return {item_id : i.item_id, cancelled_qty : Number(i.new_cancelled_qty),transaction_id : i.transaction_id};
                                                });
                                               
        let formData                =          {...this.state.cancelledItemForm, items : [...itemsLists]}
        
          this.setState({ cancelItemFormSubmitting: true })
          HttpAPICall.withAthorization('PUT', this.warehouseIndentUrl + '/item_cancel', this.props.access_token, {}, { ...formData }, (response) => {
              toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
              this.cancelIndentModal.hide()
              this.setState({cancelledItemForm         :       {...this.cancelItemFormInit}})
              this.getIndentDetail(this.state.indentTransId)
          }).then(() => this.setState({ cancelItemFormSubmitting: false }));
      }

      deleteIndent         =           (transaction_id)          =>      {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('DELETE', this.warehouseIndentUrl + '/delete/' + transaction_id, this.props.access_token, {}, {}, (response) => {
                        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                        this.setState({minimizeTable : false})
                        this.loadListingTblData(1)
                     })
                }
            });
    }

    viewItemWisePOInit          =       (itemData=null,warehouse_id='',indent_transacton_id='') => {
        //if (this.props.permissions.includes('isc-po-view')) {
            this.itemWisePORef.current.modalInit(itemData, '', indent_transacton_id)
        //}
    }

    viewItemWiseReqInit          =       (itemData=null,warehouse_id='',indent_transacton_id='') => {
        this.itemWiseReqRef.current.modalInit(itemData,'',indent_transacton_id)
    }

    viewItemWisePurchaseInit          =       (itemData=null,warehouse_id='',indent_transacton_id='') => {
        this.itemWisePurchaseRef.current.modalInit(itemData,'',indent_transacton_id)
    }

    viewItemWiseTransferInit          =       (itemData=null,warehouse_id='',indent_transacton_id='') => {
        this.itemWiseTransferRef.current.modalInit(itemData,'',indent_transacton_id)
    }

    viewConsumptionModalInit                       =   (data,k)  =>  {
        if (data) {
            this.consumptionHistoryRef.current.modalInit(data, null, null)
        }
         
    }

   
      viewTransactionLedgerModalInit               =   (data,k)  =>  {
        if (this.state.indentDetail) {

            let indentData = this.state.indentDetail;
            if (data) {
                this.transactionLedgerRef.current.modalInit(data, indentData.warehouse_id,indentData.warehouse_detail)
            }
        }
         
      }

     //***********************DOWNLOAD PDF **************************** */
     downloadPdfHandler              =    (transaction_id)     =>         {
        this.setState({ pdfDownloading: true});
        HttpAPICall.withAthorization('GET',   this.detailUrl + '/' + transaction_id, this.props.access_token,{download : "download"},{},(response) => {
            download.file(response.data.file_path);
        })
        .then(() => this.setState({pdfDownloading: false}));
    }

    viewInterchangeableModalInit        =       (itemdata=null,key)   =>      {
        
        this.interchangebleItemModal.show();
        this.setState({
            interchangeItemData     :   itemdata ,
            interchangeItemForm     :    {notes:''}   
        },() => this.getInterChangeableItemList(itemdata?.itemData?.id,1))
    }

    getInterChangeableItemList      =       (id,page=1)      =>      {
        this.setState({ interchangeable_loading: true, interchangeable_page: page,interchangeableItemList:[] });
        HttpAPICall.withAthorization('GET', this.itemUrl + '/interchangeable_item/' + id, this.props.access_token, {}, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                interchangeableItemList                :    respData.data,
                interchangeableListingMeta             :   respData.meta,
                interchangeableTotalListingCount       :   respData.meta.total,
               
            })
        }).then(() => this.setState({ interchangeable_loading : false }))
    }
    
    checkAllInterchangeList                          =   ()  =>  {
        let interchangeItemCheckboxCollections      =   document.getElementsByClassName('interchange_id_checkbox');
        if(interchangeItemCheckboxCollections && interchangeItemCheckboxCollections.length > 0) {
            for (let i = 0; i < interchangeItemCheckboxCollections.length; i++) {
                interchangeItemCheckboxCollections[i].checked = document.getElementById('all_check_interchange').checked;
            }
        }
    }

    submitSelectedInterchangeItem        =       (e)         =>      {
        e.preventDefault();
         
        let  interchangeItemCheckboxCollections      =    document.getElementsByClassName('interchange_id_checkbox');
        let allSelectedItems                =    [];
        let data                            =   this.state.interchangeItemData ? this.state.interchangeItemData : null;
        let totalQty = 0
        
        if( interchangeItemCheckboxCollections &&  interchangeItemCheckboxCollections.length > 0) {
            for (let i = 0; i <  interchangeItemCheckboxCollections.length; i++) {
               
                if( interchangeItemCheckboxCollections[i].checked) {
                    // interchangeItemCheckboxCollections[i].disabled = true;
                    let item_id    =       interchangeItemCheckboxCollections[i].value;
                    let qty        =    interchangeItemCheckboxCollections[i].getAttribute("data-qty");
                    allSelectedItems.push({item_id: item_id,qty:qty})
                    totalQty += parseFloat(qty);
                }
            }
        }

   
       //***************************HANDLE SELECTED ITEM FROM PO  **********************/
        if (allSelectedItems.length == 0) {
            toast.error('Please select atleast one Item', { position: toast.POSITION.TOP_RIGHT })
        }else if(allSelectedItems.find(i => parseFloat(i.qty) <= 0)) {
            toast.error('Every Item\'s qty must be more than 0.', { position: toast.POSITION.TOP_RIGHT })
           
        }else if(allSelectedItems.find(i => i.qty == '' || i.qty == null)) {
            toast.error('Every Item\'s  qty should not be blank', { position: toast.POSITION.TOP_RIGHT })
           
        }else if(totalQty > data.pending_qty){
            toast.error('Total Qty cannot be more than pending Qty', { position: toast.POSITION.TOP_RIGHT })
        }else{
            let frmData = {
                transaction_id       :      data.transaction_id,
                item_id              :      data.itemData.id,
                replace_items          : allSelectedItems,
                ...this.state.interchangeItemForm
            }
            this.setState({ saveInterchangeableItemForm: true })
            HttpAPICall.withAthorization('PUT', this.warehouseIndentUrl + '/item_change', this.props.access_token, {}, { ...frmData }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.interchangebleItemModal.hide();
                this.getIndentDetail(data.transaction_id)
            }).then(() => this.setState({ saveInterchangeableItemForm: false }));
            
        }
    }

    storeIndentJsx            =       ()      =>    {
        let deleteVoucher = false;
        if(this.state.indentDetail && this.state.indentDetail.items && this.state.indentDetail.items.length > 0){
            const allItemsMatch = this.state.indentDetail.items.every(item => item.indent_qty == item.pending_qty);
            if(this.state.indentDetail.status_data.level == 3 && !allItemsMatch){
                deleteVoucher = true
            }
        }
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Warehouse Indent</h3>
                <div className="text-end mt15">
                    {(!this.state.minimizeTable) ?
                        <Ax>
                            <button type="button" className="btn btn-primary" disabled={!this.props.permissions.includes('isc-warehouse-indent-add')} onClick={this.openAddStoreIndentScreen}>New Warehouse Indent</button>
                            <button type="button" className="btn btn-secondary" onClick={this.openIndentFulfillment}>Indent Fulfillment</button>
                            <button type="button" className="btn btn-secondary" onClick={this.indentSearchModalInit} disabled={this.state.listing_loading} >
                                <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                            </button>
                        </Ax>
                        : null}


                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Warehouse Indent : {this.state.indentTransId} </h3>
                            <div className="text-end mt15">
                                <button type="button" className="btn btn-secondary" onClick={this.openIndentFulfillmentfromVoucher} disabled={this.state?.indentDetail?.is_closed == 'Y' ? true : false}>Indent Fulfillment</button>
                                <button type="button" className="btn btn-secondary" disabled={!this.props.permissions.includes('isc-warehouse-indent-status_change')} onClick={() => this.updateComment()}>Update Status </button>
                                <button type="button" className="btn btn-secondary" disabled={this.state.pdfDownloading} onClick={() => this.downloadPdfHandler(this.state.indentTransId)}><tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /></button>
                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                </button>
                                <ul className="dropdown-menu cursor_pointer" aria-labelledby="dropdownMenuButton1">
                                    <li>
                                        <TapLink
                                            pathname={'/warehouse_indent_add'}
                                            state={{ indentDetailData: this.state.indentDetail }}
                                            conditions={{
                                                canEdit: this.state.indentDetail?.can_edit === 'Y' &&
                                                    this.props.permissions.includes('isc-warehouse-indent-edit') ? 'Y' : 'N',
                                                canShowTitle: this.state.indentDetail?.can_edit == 'N' ||
                                                    !this.props.permissions.includes('isc-warehouse-indent-edit') ? 'Y' : 'N',
                                            }}
                                        >
                                            Edit
                                        </TapLink>
                                    </li>

                                    <li>
                                        <Link role="button"
                                            onClick={() => {
                                                if (this.state?.indentDetail?.is_closed == 'N' && this.props.permissions.includes('isc-warehouse-indent-cancel_item')) {
                                                    return this.cancelWarehouseIndentItem()
                                                }
                                            }}
                                            className={['dropdown-item cursor_pointer', this.state?.indentDetail?.is_closed == 'N' && this.props.permissions.includes('isc-warehouse-indent-cancel_item') ? '' : 'disabled'].join(' ')}
                                            title={this.state?.indentDetail?.is_closed == 'Y' || !this.props.permissions.includes('isc-warehouse-indent-cancel_item') ? 'You do not have permission to perform this action' : ''}
                                            style={{ "pointerEvents": "all" }}
                                        >Cancel Item</Link>
                                    </li>
                                    <li><a className={this.state?.indentDetail?.can_delete == "N" || deleteVoucher || !this.props.permissions.includes('isc-warehouse-indent-delete') ? "dropdown-item disabled" : "dropdown-item"} onClick={() => this.deleteIndent(this.state.indentTransId)} disabled={this.state.indentDetail?.can_delete == "TT"}>Delete</a></li>
                                    <li><a className={this.state?.indentDetail?.is_closed == 'Y' || !this.props.permissions.includes('isc-inventory_transfer-add') ? "dropdown-item disabled" : "dropdown-item"} onClick={() => this.transferPurchaseInit()} >Transfer Purchase</a></li>

                                </ul>
                                <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                </button>

                            </div>
                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.storeIndentLisitngJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4"><div className="bg-white p-2" style={{ height: "100%" }}>{this.indentViewDetailJsx()}</div></div> : null}
                </div>
            </div>
        </Ax>);
    }

    storeIndentLisitngJsx            =   ()   =>   {
        return(<Ax>
         <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.indentSearchClear} /> 
            <table className="table table-bordered table-fixed  bg-white table-sm">
                <thead className="table-secondary" >
                    {this.state.minimizeTable
                        ? (<tr>
                            <th scope="col" style={{ width: "15%" }}>Warehouse Indent</th>
                        </tr>)
                        : (<tr className='text-center'>
                            <th scope="col" style={{ width: "5%" }}><input type="checkbox"  onChange={this.checkAllIndentItemList} disabled={true} id="all_indent_list_check"/></th>
                            <th scope="col" style={{ width: "10%" }}>Transaction ID</th>
                            <th scope="col" style={{ width: "10%" }}>Date of Transaction</th>
                            <th scope="col" style={{ width: "15%" }}>Warehouse</th>
                            <th scope="col" style={{ width: "18%" }}>Asset/Workstation/Employee</th>
                            <th scope="col" style={{ width: "15%" }}>Title</th>
                            <th scope="col" style={{ width: "8%" }}>Status</th>
                            <th scope="col" className="text-center" style={{ width: "6%" }}>Indent Qty</th>
                            <th scope="col" className="text-end" style={{ width: "6%" }}>Fullfilled Qty</th>
                            <th scope="col" className="text-center" style={{ width: "5%" }}>Action</th>
                        </tr>)
                    }
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="10"><Loader /></td></tr>)
                        : (this.state.indent_listing.length > 0
                            ? (this.state.indent_listing.map((item, index) => {
                                return (<tr key={index} >
                                    {
                                        this.state.minimizeTable
                                            ? (
                                                <Ax>
                                                    <td>
                                                        <a role="button" onClick={() => this.viewIndentDetail(item.transaction_id)} className="link-primary">{item.transaction_id}</a>
                                                        <br /><small>{item.transaction_date_display}</small>
                                                        <small style={{ float: 'right' }}>{item.status_data ? item.status_data.name : ''}</small>
                                                    </td>
                                                </Ax>
                                            )
                                            : (<Ax>
                                                <td className="text-center">
                                                    <input type="checkbox"
                                                    name="indent"
                                                    value={item.transaction_id}
                                                    className="indent_list_id_checkbox"
                                                    data-warehouseId={item.warehouse_id}
                                            
                                                    disabled={item.status_data && item.status_data.level !== 3 && item.status_data.level !== 4 }
                                                     /> 
                                                    </td>
                                                <td className="text-center"><a role="button" onClick={() => this.viewIndentDetail(item.transaction_id)} className="link-primary">{item.transaction_id}</a></td>
                                                <td className="text-center">{item.transaction_date_display}</td>
                                                <td>{item.warehouse ? item.warehouse.name : '-'}</td>
                                                <td>{item.assetProfile ? <div><small className='form-text'>{item.indent_for == 'asset' ? 'Asset : ' : ''}</small> {item.assetProfile.name} </div>
                                                    : item.workstation ? <div><small className='form-text'>{item.indent_for == 'workstation' ?  'Workstation : ' : ''}</small> {item.workstation.workstation_name}  </div> 
                                                    : item.employee ? <div><small className='form-text'>{item.indent_for == 'employee' ?  'Employee : ' : ''}</small> {item.employee.name}  </div> : ''  } </td>
                                                <td>{item.title ?? "-"} </td>
                                                <td className="text-center">{item.status_data ? item.status_data.name : '-'}</td>
                                                <td className="text-end">{item.total_qty}</td>
                                                <td className="text-end">{item.fullfilled_qty ? item.fullfilled_qty : 0}</td>
                                                <td className="text-center">
                                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" role="button" onClick={() => this.viewIndentDetail(item.transaction_id)}>View</a></li>
                                                    </ul>
                                                </td>
                                            </Ax>
                                            )}
                                </tr>)
                            }))
                            : (<tr><td colSpan="10" className="text-center">No Records</td></tr>)
                        )
                    }

                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>)
    }


    indentViewDetailJsx                =   ()   =>   {
        let indent = this.state.indentDetail;
        
        return (<div className="tab_content_header">

            {!this.state.indent_loading
                ? (<Ax>
                    {indent
                        ?
                        <div>
                            <table className="table table-bordered bg-white" >
                                <thead className="table-secondary">
                                    <tr>
                                        <th style={{ width: "33%" }}>ID</th>
                                        <th style={{ width: "33%" }}>Date</th>
                                        <th style={{ width: "33%" }}>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td scope="col">{indent.transaction_id ? indent.transaction_id : "-"}</td>
                                        <td scope="col">{indent.transaction_date_display ? indent.transaction_date_display : "-"}</td>
                                        <td scope="col" className="align-middle">
                                            {indent.status_data ? indent.status_data.name : ''}
                                        </td>  </tr>
                                </tbody>
                            </table>
                            <table className="table table-hover table-bordered  table-responsive bg-white mb-0">
                                <tbody>
                                    <tr>
                                        <td style={{ width: "33%" }} >Warehouse</td>
                                        <td colSpan={2}>{indent.warehouse_detail ? <div><b>{indent.warehouse_detail.name} ({indent.warehouse_detail.code})</b> <br />
                                            {indent.warehouse_detail ?
                                                <div className='pt-1'>Address : {indent.warehouse_detail.address1 ? indent.warehouse_detail.address1 + ", " : ""}
                                                    {indent.warehouse_detail.address2 ? indent.warehouse_detail.address2 + ", " : ""}
                                                    {indent.warehouse_detail.landmark ? indent.warehouse_detail.landmark + ", " : ""}
                                                    {indent.warehouse_detail.city ? indent.warehouse_detail.city + ". " : "-"} <br />
                                                    Destination of Supply : <b>{indent.warehouse_detail.state_name}</b> <br />
                                                    GSTIN  : {indent.warehouse_detail.gstin ? indent.warehouse_detail.gstin : "-"} <br />
                                                </div> : "-"}</div> : "-"} <br />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td scope="col">Title</td>
                                        <th scope="col">{indent.title ? indent.title : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "33%" }} >Indent For</td>
                                        <th>{indent.indent_for && indent.indent_for == "asset" ? "Asset" : indent.indent_for && indent.indent_for == "workstation" ? "Workstation" : indent.indent_for && indent.indent_for == "employee" ? "Employee" : "-"} </th>
                                    </tr>
                                    {indent.indent_for ?
                                        <tr>
                                            <td style={{ width: "33%" }} >{indent.indent_for && indent.indent_for == "asset" ? "Asset" : indent.indent_for && indent.indent_for == "workstation" ? "Workstation" : indent.indent_for && indent.indent_for == "employee" ? "Employee" : "null"}</td>
                                            <th>
                                                {indent.indent_for == "asset" && indent.assetProfile ? indent.assetProfile.name : indent.indent_for == "workstation" && indent.workstation ? indent.workstation.workstation_name : indent.indent_for == "employee" && indent.employee ? indent.employee.name : "-"}
                                            </th>
                                        </tr> : null}
                                    <tr>
                                        <td scope="col">Tag Users</td>
                                        <td scope="col">{indent && indent.usersList && indent.usersList.length > 0 ? indent.usersList.map(u => <div>{u.full_name}</div>) : ""}</td>
                                    </tr>
                                    {indent.additional_attributes_data && indent.additional_attributes_data.length > 0
                                        ? indent.additional_attributes_data.map((attr) => {
                                            return (<tr key={attr.key}>
                                                <td scope="col" className='text-capitalize'>{attr.name ? attr.name : "-"}</td>
                                                <th scope="col">{attr.value ? attr.value : "-"}</th>
                                            </tr>)
                                        }) : null}
                                </tbody>
                            </table>
                            <table className="table  table-sm table-bordered table-responsive bg-white my-3" id="dataTable">
                                <thead className="table-secondary">
                                    <tr className="text-end">
                                        <th scope="col" style={{ width: "5%" }} className="text-center"><input type='checkbox' onChange={this.checkAllItem} id="all_check_indent" /></th>
                                        <th scope="col" className="text-center" style={{ width: "31%" }}>Item Details</th>
                                        <th scope="col" className="text-center" style={{ width: "8%" }}>Indent Qty</th>
                                        <th scope="col" className="text-end" style={{ width: "8%" }}>Fullfilled</th>
                                        <th scope="col" className="text-end" style={{ width: "8%" }}>Pur. Req</th>
                                        <th scope="col" className="text-end" style={{ width: "8%" }}>PO</th>
                                        <th scope="col" className="text-end" style={{ width: "8%" }}>Purchase</th>
                                        <th scope="col" className="text-end" style={{ width: "8%" }}>In Transit </th>
                                        <th scope="col" className="text-end" style={{ width: "8%" }}>Cancelled  </th>
                                        <th scope="col" className="text-end" style={{ width: "8%" }}>Pending </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        indent && indent?.items && indent?.items?.length > 0 ? indent?.items?.map((i, k) => {
                                            return (<tr key={k} className="text-end">
                                                <td className='text-center'>
                                                    {k + 1} <br /><br/>
                                                    <input
                                                        type='checkbox'
                                                        name="foo"
                                                        value={i?.itemData?.id}
                                                        data-pendingQty={i.pending_qty}
                                                        className="indent_item_id_checkbox"
                                                        data-warehouseId={indent.warehouse_id}
                                                        data-purchaseQty={i?.purchase_qty??0}
                                                        data-itemData={i?.itemData}
                                                        data-transactionId={indent.transaction_id}
                                                        data-statusLevel={indent.status_data && indent.status_data.level}
                                                        data-statusIds={indent.status_data && indent.status_data.id}
                                                    />
                                                </td>
                                                <td className="text-start">{i.itemData ? <div className='row'>
                                                    <div className='col-sm-10'>
                                                        <ItemViewInList item={i.itemData} remark={i.remark} viewItemModal={() => this.viewItemModalInit(i.itemData)} />
                                                    </div>
                                                    <div className='col-sm-2 text-end'>
                                                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                        <ul className="dropdown-menu">
                                                            <li><a href="#" className="dropdown-item" onClick={() => this.viewConsumptionModalInit(i.itemData, k)}>Consumption History</a></li>
                                                            <li><a href="#" className="dropdown-item" onClick={() => this.viewTransactionLedgerModalInit(i.itemData, k)}>Item Transaction Ledger</a></li>
                                                            {this.props.permissions.includes('isc-item-Interchangeable-edit') &&  <li><a href="#" className="dropdown-item" onClick={() => this.viewInterchangeableModalInit(i, k)}>Interchange Item</a></li>}
                                                        </ul>
                                                    </div>
                                                </div> : null}</td>
                                                <td>{i.indent_qty ? i.indent_qty : 0}</td>
                                                <td>{i.fullfilled_qty ? i.fullfilled_qty : 0}</td>
                                                <td onClick={() => this.viewItemWiseReqInit(i?.itemData,indent.warehouse_id,indent.transaction_id)} className='link-primary cursor_pointer'>{i.req_qty ? i.req_qty : 0}</td>
                                                <td onClick={() => this.viewItemWisePOInit(i?.itemData,indent.warehouse_id,indent.transaction_id)} className={ 'link-primary cursor_pointer'}>{i.po_qty ? i.po_qty : 0}</td>
                                                <td  onClick={() => this.viewItemWisePurchaseInit(i?.itemData,indent.warehouse_id,indent.transaction_id)} className='link-primary cursor_pointer'>{i.purchase_qty ? i.purchase_qty : 0}</td>
                                                <td  onClick={() => this.viewItemWiseTransferInit(i?.itemData,indent.warehouse_id,indent.transaction_id)} className='link-primary cursor_pointer'>{i.transit_qty ? i.transit_qty : 0}</td>
                                                <td>{i.cancelled_qty ? i.cancelled_qty : 0}</td>
                                                <td>{i.pending_qty ? i.pending_qty : 0}</td>
                                            </tr>);


                                        })
                                            : <tr ><td className='text-center' colSpan={10}>No Record</td></tr>
                                    }

                                </tbody>
                            </table>
                            <div className="row align-items-center ">
                                <div className="col-sm-6">
                                    <label htmlFor="notes" className="form-label">Notes</label>
                                    <div>{indent.notes ? indent.notes : "-"}</div>

                                </div>
                                <div className=" col-sm-6">

                                    <table className="table  table-borderless">
                                        <tbody>

                                            <tr className="subtotal_bg_color">
                                                <th>Total Quantity</th>
                                                <td className='float-end'>{indent.total_qty ? indent.total_qty : 0} </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <hr />
                            
                                    <div>
                                        <div className="tab_content_wrapper" ref={this.myRef}>
                                            <span className="content_heading">Comments & History</span>
                                        </div>
                                        {this.state.allAllowedStatus
                                            ? <form onSubmit={this.submitUpdateCommentForm}>
                                                <div className="my-3 row">
                                                    <label className="col-sm-3 col-form-label">Change status to</label>
                                                    <div className="col-sm-5">
                                                        <TapSelect
                                                            changeEvent={(selectedOption) => {
                                                                this.tapSelectChange(selectedOption, 'addCommentForm', 'status');
                                                            }}
                                                            value={this.state.allAllowedStatus.find(s => s.value == this.state.addCommentForm.status)}
                                                            options={this.state.allAllowedStatus}
                                                            isSearchable={false}
                                                            isDisabled={indent.change_status == 'N' || !this.props.permissions.includes('isc-warehouse-indent-status_change') ? true  :false}
                                                            placeholder="Please Select Status"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="my-2">
                                                    <label className="form-label">Comments</label>
                                                    <textarea
                                                        name="notes"
                                                        value={this.state.addCommentForm.notes}
                                                        onChange={(e) => this.formInputHandler(e, 'addCommentForm')}
                                                        className="form-control"
                                                        placeholder="Please Add a comment"
                                                        required
                                                        style={{ height: "100px" }}
                                                    />
                                                </div>
                                                <div className="col-12 text-end">
                                                    <button type="submit" disabled={this.state.addCommentFormSubmitting ? true : false} className="btn btn-primary mx-2">
                                                        Update {this.state.addCommentFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin ms-2" />) : ''}
                                                    </button>
                                                </div>
                                            </form>
                                            : null}
                                    </div>
                                    <hr />
                                    <div className="my-1">
                                        {
                                            indent.comments.map((c, k) => {
                                                return (<Ax key={k}><div className="row">
                                                    {c.msg ? <div className="col-sm-8" dangerouslySetInnerHTML={{ __html: c.msg.split("|").join("<br />") }} /> : <div className='col-sm-8'>-</div>}
                                                    <div className="col-sm-4 text-end">
                                                        <tapIcon.imageIcon icon={tapIcon.CalenderIcon} className="img-fluid pr-5 fs12" alt="item-action" />
                                                        <span> {c.created_at_display}</span>
                                                    </div>
                                                    <div className="col-sm-8 my-1">{c.notes}</div>
                                                </div><hr /></Ax>);
                                            })
                                        }
                                    </div>
                                
                        </div>
                        : null}
                </Ax>)
                : <Loader />}

        </div>)
    }

    indentSearchModalJsx               =        ()             =>        {
       
        return (
            <div className="modal fade" id="indentSearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Indent Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.indentClear}></button>
                        </div>
                        <form id="indentSearchForm" onSubmit={this.submitIndentSearchForm}>
                            <div className="modal-body">
                                {!this.state.indentSearchFormDataLoaded ? <Loader />
                                    : <Ax>
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Transaction ID</label>
                                            <div className="col-sm-4">
                                                <input
                                                    name="search_transaction_id"
                                                    type="text"
                                                    value={this.state.indentSearchForm.search_transaction_id}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "indentSearchForm")}
                                                    placeholder="Please enter Transaction ID"
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>
                                            <label className="col-sm-1 col-form-label col-form-label-sm  ">Title</label>
                                            <div className="col-sm-5">
                                                <input
                                                    name="search_title"
                                                    type="text"
                                                    value={this.state.indentSearchForm.search_title}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "indentSearchForm")}
                                                    placeholder="Please enter Title"
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>


                                        </div>

                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Status</label>
                                            <div className="col-sm-10">
                                                <TapSelect
                                                    options={this.state.allStatusOptions}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'indentSearchForm', 'search_status_ids')}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    isMulti={true}

                                                    value={this.state.allStatusOptions.filter(s => this.state.indentSearchForm.search_status_ids.includes(s.value))}
                                                    placeholder="Select Status"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>
                                        </div>

                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Warehouse</label>
                                            <div className="col-sm-10">
                                                <TapSelect
                                                    options={this.state.all_warehouses}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'indentSearchForm', 'search_warehouse_ids')}
                                                    value={this.state.all_warehouses.filter(u => this.state.indentSearchForm?.search_warehouse_ids?.includes(u.value))}
                                                    isSearchable={true}
                                                    isMulti={true}
                                                    isClearable={true}
                                                    placeholder="Select Warehouses"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>
                                        </div>
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm ">Item</label>
                                            <div className="col-sm-10">
                                                <InputItemSearch
                                                    placeholder="Search Item"
                                                    isMulti={true}
                                                    changeEvent={(selectedOption) => {
                                                        this.tapSelectChange(selectedOption, 'indentSearchForm', 'search_item_ids');
                                                        this.setState({
                                                            searchedItems: selectedOption ? selectedOption : null
                                                        }, () => { });
                                                    }}
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                    value={this.state.searchedItems.length > 0 ? this.state.searchedItems.map(a => { a.label = a.display_label; return a; }) : []}
                                                />
                                            </div>

                                        </div>
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Item Tags</label>
                                            <div className="col-sm-10">
                                                <TapSelect
                                                    options={this.state.allItemTags}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'indentSearchForm', 'search_item_tags')}
                                                    value={this.state.allItemTags.filter(u => this.state.indentSearchForm?.search_item_tags?.includes(u.value))}
                                                    isSearchable={true}
                                                    isMulti={true}
                                                    isClearable={true}
                                                    placeholder="Select Item Tags"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>
                                        </div>
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm ">Indent For</label>
                                            <div className="col-sm-10">
                                                <TapSelect
                                                    changeEvent={(selectedOption) => {
                                                        this.tapSelectChange(selectedOption, 'indentSearchForm', 'search_indent_for', () => {
                                                            this.setState({
                                                                searchedAssets: [],
                                                                searchedEmployees: [],
                                                                indentSearchForm: {
                                                                    ...this.state.indentSearchForm,
                                                                    search_asset_ids: [],
                                                                    search_employee_ids: [],
                                                                    search_workstation_ids: [],
                                                                }
                                                            })
                                                        });
                                                    }}
                                                    options={this.state.allIndentOptions}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                    value={this.state.allIndentOptions.find(s => s.value == this.state.indentSearchForm.search_indent_for)}
                                                    placeholder="Select Indent For"
                                                />
                                            </div>

                                        </div>
                                        {this.state.indentSearchForm && this.state.indentSearchForm.search_indent_for ?
                                            <div className="row my-3 align-items-center">
                                                <label className="col-sm-2 col-form-label col-form-label-sm">
                                                    {this.state.indentSearchForm.search_indent_for == "asset" ? "Asset" :
                                                        this.state.indentSearchForm.search_indent_for == "workstation" ? "Workstation" :
                                                            this.state.indentSearchForm.search_indent_for == "employee" ? "Employee" : null}</label>
                                                <div className="col-sm-10">
                                                    {this.state.indentSearchForm.search_indent_for == "asset"
                                                        ? <Ax>
                                                            <InputAssetSearch
                                                                changeEvent={(selectedOption) => {
                                                                    this.tapSelectChange(selectedOption, 'indentSearchForm', 'search_asset_ids');
                                                                    this.setState({ searchedAssets: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                                                }}
                                                                placeholder="Search Asset"
                                                                isMulti={true}
                                                                containerHeight="30px"
                                                                fontSize="93%"
                                                                value={this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                                                            />


                                                        </Ax>
                                                        : this.state.indentSearchForm.search_indent_for == "workstation"
                                                            ? <Ax>

                                                                <TapSelect
                                                                    changeEvent={(selectedOption) => {
                                                                        this.tapSelectChange(selectedOption, 'indentSearchForm', 'search_workstation_ids');
                                                                    }}
                                                                    options={this.state.allWorkstations}
                                                                    isSearchable={true}
                                                                    isClearable={true}
                                                                    isMulti={true}
                                                                    containerHeight="30px"
                                                                    fontSize="93%"
                                                                    value={this.state.allWorkstations.filter(m => this.state.indentSearchForm.search_workstation_ids.includes(m.value))}
                                                                    placeholder="Please Select Workstation"
                                                                />

                                                            </Ax>
                                                            : this.state.indentSearchForm.search_indent_for == "employee"
                                                                ? <Ax>

                                                                    <InputEmployeeSearch
                                                                        placeholder="Please Select Employee"
                                                                        changeEvent={(selectedOption) => {
                                                                            this.tapSelectChange(selectedOption, 'indentSearchForm', 'search_employee_ids');
                                                                            this.setState({ searchedEmployees: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                                                        }}
                                                                        isMulti={true}
                                                                        containerHeight="30px"
                                                                        fontSize="93%"
                                                                        value={this.state.searchedEmployees.length > 0 ? this.state.searchedEmployees.map(a => { a.label = a.label; return a; }) : []}
                                                                    />
                                                                </Ax> : null
                                                    }

                                                </div>
                                            </div> : null}
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Tag Category</label>
                                            <div className="col-sm-10">
                                                <TapSelect
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    placeholder="All"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                    options={this.state.userFilters}
                                                    value={this.state.userFilters.find(r => r.value === this.state.indentSearchForm.tagged_user_filter)}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'indentSearchForm', 'tagged_user_filter')}
                                                />
                                            </div>
                                        </div>
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Tagged User</label>
                                            <div className="col-sm-10">
                                                <TapSelect
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    isMulti={true}
                                                    placeholder="Tagged User"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                    options={this.state.allUsers}
                                                    value={this.state.allUsers.filter(s => this.state.indentSearchForm.tagged_user_ids.includes(s.value))}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'indentSearchForm', 'tagged_user_ids')}
                                                />
                                            </div>
                                        </div>
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Voucher Ageing</label>
                                            <div className="col-sm-10">
                                                <input
                                                    name="search_voucher_aging"
                                                    type="text"
                                                    value={this.state.indentSearchForm.search_voucher_aging}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "indentSearchForm")}
                                                    placeholder="Example. 0-5 for a range or 4 for Voucher Ageing"
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>

                                        </div>
                                        <div className="row my-3 align-items-center">

                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Approval Ageing</label>
                                            <div className="col-sm-10">
                                                <input
                                                    name="search_approved_aging"
                                                    type="text"
                                                    value={this.state.indentSearchForm.search_approved_aging}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "indentSearchForm")}
                                                    placeholder="Example. 0-5 for a range or 4 for Approval Ageing"
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>


                                        </div>
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm ">Transaction Period</label>
                                            <div className="col-sm-10">
                                                <PeriodSelectionWithCustom
                                                    periods={this.state.all_periods ? this.state.all_periods : []}
                                                    value={this.state.indentSearchForm.date_range}
                                                    startDate={this.state.indentSearchForm.date_range_start}
                                                    endDate={this.state.indentSearchForm.date_range_end}
                                                    onSelectPeriod={(period, startDate, endDate) => {
                                                        this.setState({
                                                            indentSearchForm
                                                                : {
                                                                ...this.state.indentSearchForm,
                                                                date_range: period,
                                                                date_range_start: startDate ? startDate : null,
                                                                date_range_end: endDate ? endDate : null
                                                            }
                                                        });
                                                    }}
                                                    className="form-control-sm"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>
                                        </div>

                                    </Ax>}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={this.indentClear}>Close</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
   }

   cancelledItemModalJsx               =        ()             =>        {
      
    return (
        <div className="modal fade" id="cancelIndentModal" tabIndex="-1">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="searchAssetModalLabel">Cancel Item for : {this.state.indentTransId}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { this.setState({ cancelledItemList: [] }) }}></button>
                    </div>
                    <form onSubmit={this.submitCancelItemForm}>
                        <div className="modal-body">
                            {this.state.cancel_item_loading ? <Loader />
                                : <Ax>
                                    <table className="table table-bordered table-responsive bg-white ">
                                        <thead className="table-secondary">
                                            <tr className="text-end">
                                                <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                                                <th scope="col" style={{ width: "28%" }} className="text-start">Item Details</th>
                                                <th scope="col" className="text-center" style={{ width: "8%" }}>Indent Qty</th>
                                                <th scope="col" className="text-end" style={{ width: "8%" }}>Fullfilled</th>
                                                <th scope="col" className="text-end" style={{ width: "8%" }}>Pur. Req</th>
                                                <th scope="col" className="text-end" style={{ width: "8%" }}>PO</th>
                                                <th scope="col" className="text-end" style={{ width: "8%" }}>Purchase</th>
                                                <th scope="col" className="text-end" style={{ width: "8%" }}>In Transit </th>
                                                <th scope="col" className="text-end" style={{ width: "8%" }}>Cancelled  </th>
                                                <th scope="col" className="text-end" style={{ width: "8%" }}>Pending </th>
                                                <th scope="col" style={{ width: "9%" }}>New Cancelled </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.cancelledItemList.map((i, k) => {
                                                return <tr className="text-end" key={k}>
                                                    <td className="text-center align-middle">{k + 1}</td>

                                                    <td className='text-start'>{i.itemData ? <div><span className="fw-bold">{i.itemData.name}</span><br />
                                                        <div className="form-text"><b>Code: </b>{i.itemData.item_code}</div>
                                                        <div className="form-text"><b>Mfr: </b>{i.itemData?.manufacturer?.name ?? '-'} (MPN : {i.itemData?.manufacturer_part_no ?? "-"} )</div>
                                                        <div className="form-text"> {
                                                            i.itemData?.tags?.length > 0
                                                                ? (i.itemData?.tags.map((item, k) => <span key={k} className="badge bg-secondary">{item.name}</span>))
                                                                : '-'
                                                        }</div>
                                                        <div className="form-text"><b>Remark: </b>{i.remark ?? "-"}</div>
                                                    </div>
                                                        : null}</td>
                                                    <td>{i.indent_qty ? i.indent_qty : 0}</td>
                                                    <td>{i.fullfilled_qty ? i.fullfilled_qty : 0}</td>
                                                    <td>{i.req_qty ? i.req_qty : 0}</td>
                                                    <td>{i.po_qty ? i.po_qty : 0}</td>
                                                    <td>{i.purchase_qty ? i.purchase_qty : 0}</td>
                                                    <td>{i.transit_qty ? i.transit_qty : 0}</td>
                                                    <td>{i.cancelled_qty ? i.cancelled_qty : 0}</td>
                                                    <td>{i.pending_qty ? i.pending_qty : 0}</td>
                                                    <td className="text-end"> 
                                                        <TapInputNumber
                                                            name="new_cancelled_qty"
                                                            value={this.state.cancelledItemList[k]?.new_cancelled_qty}
                                                            onChange={(newValue) => {
                                                                const updatedRows = [...this.state.cancelledItemList];
                                                                updatedRows[k].new_cancelled_qty = newValue; // Update the quantity in the corresponding row
                                                                this.setState({ cancelledItemList: updatedRows });
                                                            }}
                                                            placeholder="Cancelled Qty"
                                                            required={true}
                                                            max={i.pending_qty}
                                                        />
                                                        
                                                    </td>




                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                    <div className="row align-items-center ">
                                        <div className="col-sm-6">
                                            <label htmlFor="notes" className="form-label">Notes</label>
                                            <div> <textarea
                                                name="notes"
                                                value={this.state.cancelledItemForm.notes}
                                                onChange={(e) => this.formInputHandler(e, 'cancelledItemForm')}
                                                className="form-control"
                                                style={{ height: "100px" }}
                                            /></div>

                                        </div>
                                    </div>
                                </Ax>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.cancelItemFormSubmitting}
                                onClick={() => { this.setState({ cancelledItemList: [] }) }}>Close  {this.state.cancelItemFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            <button type="submit" className="btn btn-primary" disabled={this.state.cancelItemFormSubmitting}>Save  {this.state.cancelItemFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        );
    }

    viewInterchangeableItemJsx                            =   ()  =>  {
       
        let data = this.state.interchangeItemData ? this.state.interchangeItemData : null;
        let max_qty = data && data.pending_qty ? data.pending_qty : 0;

        return (
            <div className="modal fade" id="interchangebleItemModal" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="interchangebleItemModal">Interchange Item  </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        {data && <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>
                                    <tr>
                                        <td>Transaction ID </td>
                                        <th>{this.state.indentId}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "26%" }}>Item Name     </td>
                                        <th>{data.itemData && data.itemData.name ? data.itemData.name : ""} </th>
                                    </tr>
                                    <tr>
                                        <td>Item Code </td>
                                        <th>{data.itemData && data.itemData.item_code ? data.itemData.item_code : "-"}</th>
                                    </tr>
                                  
                                    <tr>
                                        <td>Pending Qty </td>
                                        <th>{data && data.pending_qty ? data.pending_qty : '0'}</th>
                                    </tr>
                                   
                                </tbody>
                            </table>
                        </div>
                        }

                        <div className="modal-body">
                            <form className="bg-white" onSubmit={this.submitSelectedInterchangeItem} id="interchangeItemForm" method="post" encType="multipart/form-data">
                                <table className="table table-hover table-bordered table-responsive bg-white my-3 table-sm">
                                    <thead className="table-secondary">
                                        <tr className="text-center">
                                            <th style={{ "width": '7%' }}>
                                            <input type='checkbox' onChange={this.checkAllInterchangeList} id="all_check_interchange" />
                                            </th>
                                            <th className="text-start">Item Details</th>
                                            
                                            <th style={{ width: "13%" }} className="text-end">Qty</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.interchangeable_loading
                                            ? (<tr><td colSpan="6"><Loader /></td></tr>)
                                            : (this.state.interchangeableItemList && this.state.interchangeableItemList.length > 0
                                                ? (this.state.interchangeableItemList.map((item, k) => {
                                                    return (<tr key={k} className="text-center">
                                                        <td>
                                                            <input type='checkbox'
                                                                className="interchange_id_checkbox"
                                                                name="interchangebleItem"
                                                                value={item.id}
                                                                data-qty={item?.new_qty}
                                                            />
                                                        </td>
                                                        <td className="text-start"><Ax>
                                                            <b>{item.name} </b> <br />
                                                            <small>Code : <b> {item.item_code} </b></small> <br />
                                                            <small>Mfr : <b> {item.manufacturer?.name ?? '-'} </b>  (MPN : <b> {item.manufacturer_part_no ?? '-'}</b>)</small>
                                                        </Ax></td>
                                                        <td>
                                                            <input
                                                                className='form-control form-control-sm text-end'
                                                                placeholder='Quantity'
                                                                name="new_qty"
                                                                type="number"
                                                                value={item.new_qty}
                                                                onChange={(e) => {
                                                                    this.state.interchangeableItemList[k] = { ...item, new_qty: e.target.value };
                                                                    this.dynamicInputHandlerByKeyNameVal(e.target.value, 'new_qty', k, 'interchangeableItemList')
                                                                }}
                                                                max={max_qty}
                                                                autoComplete="off"
                                                                step=".01" />
                                                        </td>
                                                        

                                                    </tr>);
                                                }))
                                                : (<tr><td colSpan="6" className="text-center">No Records</td></tr>)
                                            )
                                        }
                                    </tbody>
                                </table>
                                <div className="row align-items-center ">
                                    <div className="col-sm-6">
                                        <label htmlFor="notes" className="form-label">Notes</label>
                                        <div> <textarea
                                            name="notes"
                                            value={this.state.interchangeItemForm.notes}
                                            onChange={(e) => this.formInputHandler(e, 'interchangeItemForm')}
                                            className="form-control"
                                            autoComplete='off'
                                            style={{ height: "100px" }}
                                        /></div>

                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" disabled={this.state.saveInterchangeableItemForm} className="btn btn-primary" form="interchangeItemForm">Submit {this.state.saveInterchangeableItemForm ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    render                              =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>Warehouse Indent Listing</title></Helmet>
            {this.storeIndentJsx()}
            {this.indentSearchModalJsx()}
            {this.cancelledItemModalJsx()}
            {this.viewInterchangeableItemJsx()}
            <ItemDetailsModal ref={this.itemDetailsRef}  /> 
            <POHistoryModal ref={this.itemWisePORef}/>
            <ReqHistoryItemWiseModal ref={this.itemWiseReqRef}/>
            <PurchaseItemWiseModal ref={this.itemWisePurchaseRef}/>
            <TransferItemWiseListModal ref={this.itemWiseTransferRef}/>
            <ConsumptionHistoryModal ref={this.consumptionHistoryRef}/>
            <ItemTransactionLedger ref={this.transactionLedgerRef}/>
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(WarehouseIndentList);