import React from 'react';
import ApplicationLayout from "../../layouts/ApplicationLayout";
import Ax from "../../components/hoc/Ax";
import reportStyles from "./Report.module.css"
import {Link} from "react-router-dom";
import HttpAPICall from '../../services/HttpAPICall';
import TapApiUrls, { ORG_API_BASE_URL_2 } from '../../services/TapApiUrls';
import { connect } from 'react-redux';
import Loader from '../../components/ui/Loader/Loader';
import {Helmet} from "react-helmet";
import * as actions from "../../store/actions/index"

class ReportList extends React.Component {
    
    constructor(props) {
        super(props);
        this.state              =           {
            listing_loading     :           false,
            report_listing      :           [],
        }

        this.report_url         =   ORG_API_BASE_URL_2 + '/reports';
    }

    componentDidMount()         {
        if(this.props.report_listing && this.props.report_listing.length > 0){
            let report_list = [];
            let respData =  this.props.report_listing.filter((r) => {
                return r.view_access === 'Y'
            });
            respData.forEach(
                r => {
                    if (!report_list[r.section]) {
                        report_list[r.section] = [];
                    }
                    report_list[r.section].push(r);
                }
            )
            this.setState({
                report_listing: report_list,
            });
        }else{
            this.getReportListing()
        }
    }

    //****************************GET REPORT LISTING********************* */
    getReportListing            =           ()          =>      {
        this.setState({ listing_loading: true });
        HttpAPICall.withAthorization('GET', this.report_url, this.props.access_token, {}, {}, (resp) => {
            let respData = resp.data;
            respData =  respData.filter((r) => {
                return r.view_access === 'Y'
            });
            
            let report_list = []
            respData?.forEach(
                r => {
                    if (!report_list[r.section]) {
                        report_list[r.section] = [];
                    }
                    report_list[r.section].push(r);
                }
            )
            this.setState({
                report_listing: report_list,
            });
            if(this.props){
                this.props.reportLisitingInit()
                this.props.reportLisitingLoaded(respData)
            }
        }).then(() => this.setState({ listing_loading: false }));
    }
     
    render() {
        return (
            <ApplicationLayout>
                <Helmet><title>Report Home</title></Helmet>
                <div className='d-flex justify-content-between bg-white'>
                    <ul className="nav nav-tabs bg-white" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="reports-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="reports" aria-selected="true">Reports
                            </button>
                        </li>
                    </ul>
                    <div className='text-end'>
                        <button className="btn btn-secondary mt-1 mx-2" onClick={() => this.props.history.push('/report/download_request')}>Download Request
                        </button>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="page_containt row">
                        <div className="col-12 bg-white pageTbl">
                            {this.state.listing_loading ? <Loader />
                            : Object.entries(this.state.report_listing).map((rs, rsi) => {
                                return <Ax key={rsi}>
                                    <div className="row my-3">
                                        <div className="col-sm-4">
                                            <div className="tab_content_wrapper content_heading">{rs[0]}</div>
                                        </div>
                                    </div>
                                    <div className="row mt-2 mb-4">
                                        {rs[1].map((r, i) => <div className="col-sm-4" key={i}>
                                            <div className={[reportStyles.card_container, 'card mb-3'].join(' ')}>
                                                <div className={[reportStyles.card_body, 'card-body'].join(' ')}>
                                                    <div className="row">
                                                        <Link className={[reportStyles.card_heading, 'lh-base', 'col-sm-12'].join(' ')} 
                                                        to={{ pathname : "/report/" + r.key }}>
                                                            {r.name}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)}                                       
                                    </div>
                                </Ax>
                            })}
                        </div>
                    </div>
                </div>
            </ApplicationLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        report_listing          :   state.app && state.app.report_listing ? state.app.report_listing : []
    };
};

const mapDispatchToProps = dispatch => {
    return {
        reportLisitingInit           :       ()          =>   dispatch(actions.reportListingInitilization()),
        reportLisitingLoaded         :       (data)      =>   dispatch(actions.reportListingDataLoadSuccessfully(data)),
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(ReportList);