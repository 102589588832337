import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../../../components/AppBaseComponent';
import HttpAPICall from '../../../../../services/HttpAPICall';
import TapApiUrls from '../../../../../services/TapApiUrls';
import { Modal } from 'bootstrap';
import Ax from "../../../../../components/hoc/Ax";
import Loader from "../../../../../components/ui/Loader/Loader";
import TapSelect from '../../../../../components/ui/TapSelect';
import moment from 'moment';
import DatePicker from "react-datepicker";
import TapIcon from '../../../../../services/TapIcon';
import { toast } from 'react-toastify';
import swal from "sweetalert";
import uuid from 'react-uuid';
import InputVendorSearch from '../../../../includes/ui/InputVendorSearch';
import InputEmployeeSearch from '../../../../includes/ui/InputEmployeeSearch';

class Expense extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.initExpenseForm    =   {
           expense_head         :       '',
           details              :       '',
           amount               :       '',
           payee                :       'other',
           payee_name           :       '',
           payee_id             :       '',
           wallet_id            :       '',
           reference_number     :       '',
           notes                :       '',
           document_name        :       '',
           base64_document      :       '',
           section              :       '',
           sub_section          :       ''
        }
        
        this.initState              =   {
            formDataLoading             :   false,
            counterlog                  :   null,
            assetData                   :   null,
            shift                       :   null,
            transaction_id              :   '',
            date                        :   '',
            addExpenseForm             :   {...this.initExpenseForm},
            addExpenseFormSubmitting   :   false,
            showMoreDetailsForm         :   'N',
            searchedEmployee            :   null,
            searchedVendor              :   null,
            allExpenseHead              :   [],
            AllWalletList               :   [],
            allSections                 :   [],
            allSubSections              :   [],
            permissions                 :   [],
            expenseData                 :   null,
            wallet_permission           :   true,
            allPayeeTypes               :   [{value:'vendor',label:'Vendors'},{value:'employee',label:'Employees'},{value:'other',label:'Other'}]
        }
        this.state                  =   {
            access_token                :   '',
            ...this.initState
        }
        this.uploadExpenseModalId      =   uuid();
    }
    
    componentDidMount                   =   ()  =>  {
        this.initalizeComponent(this.props);
        this.uploadExpenseModal        =   new Modal(document.getElementById(this.uploadExpenseModalId), {keyboard: false, backdrop: false}); 
    }
    
    componentWillReceiveProps(nextProps) {
        this.initalizeComponent(nextProps);
    }
    
    initalizeComponent                  =   (pr)  =>  {
        this.setState({access_token : pr.access_token,permissions : pr.permissions});
       
    }
    
    
    uploadExpenseModalInit             =   (shiftDetail, counterlog, assetData = null,expenseData = null)  =>  {
        this.uploadExpenseModal.show();

        let allPayeeTypes = [],payee='';
        if( this.state.permissions && this.state.permissions.group_modules && !this.state.permissions.group_modules.includes("hrm") && !this.state.permissions.group_modules.includes("isc")){
            allPayeeTypes = [{value:'other',label:'Other'}];payee='other'
        }else if(this.state.permissions && this.state.permissions.group_modules && !this.state.permissions.group_modules.includes("hrm")){
            allPayeeTypes = [{value:'other',label:'Other'},{value:'vendor',label:'Vendors'}];payee='other'
        }else if(this.state.permissions && this.state.permissions.group_modules && !this.state.permissions.group_modules.includes("isc")){
            allPayeeTypes  =  [{value:'employee',label:'Employees'},{value:'other',label:'Other'}];payee='employee'
        }else{
            allPayeeTypes  =  [{value:'vendor',label:'Vendors'},{value:'employee',label:'Employees'},{value:'other',label:'Other'}];payee='employee'
        }

        this.setState(
            {...this.initState,allPayeeTypes : allPayeeTypes}
            ,() => {
                this.setState({
                    assetData           :   assetData,
                    shift               :   shiftDetail,
                    counterlog          :   counterlog,
                    date                :   counterlog ? counterlog.feed_date : '',
                    transaction_id      :   counterlog ? counterlog.transaction_id : '',
                    addExpenseForm     :   {...this.initExpenseForm}
                });
                let form = document.getElementById("uploadExpenseForm")
                form.reset()
                if(assetData){
                    this.loadFormDataFromApi(expenseData)
                }
            })
       
   } 

    loadFormDataFromApi                 =   (expenseData)  =>  {
        this.setState({formDataLoading: true})
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/expense/form_data', this.state.access_token , {}, {}, (response) => {
        let respData = response.data;
        this.setState({
            allExpenseHead     :  respData.heads && respData.heads.length > 0 ? respData.heads.map(expense_heads => {return({label : expense_heads.expense_head , value : expense_heads.id})}) : [] ,
            AllWalletList      :  respData.wallets && respData.wallets.length > 0 ?   respData.wallets.map(wallet => {return({label : wallet.wallet_name , value : wallet.id})}) : [] ,  
            allSections        :  response.data.sections && response.data.sections.length > 0 ? response.data.sections.map(section => {return({value : section.id,label : section.component,subcomponents : section.subcomponents})}) : [],
            },() => {
                if(expenseData){
                    let subSection = expenseData && expenseData.section  ? this.state.allSections.find(s => expenseData.section == s.value) : null;
                    this.setState({
                        allSubSections: subSection && subSection.subcomponents && subSection.subcomponents.length > 0 ? subSection.subcomponents.map((s, i) => { return ({ value: s.sub_component_id, label: s.subcomponent }) }) : [],
                        addExpenseForm : {
                        ...this.initExpenseForm,
                        expense_head         :       expenseData.expense_head ? expenseData.expense_head : '',
                        details              :       expenseData.details ? expenseData.details : '',
                        amount               :       expenseData.amount ? expenseData.amount : '',
                        payee                :       expenseData.payee ? expenseData.payee : '',
                        payee_name           :       expenseData.payee_name ? expenseData.payee_name : '',
                        payee_id             :       expenseData.payee_id ? expenseData.payee_id : '',
                        wallet_id            :       expenseData.wallet_id ? expenseData.wallet_id : '',
                        reference_number     :       expenseData.reference_number ? expenseData.reference_number : '',
                        notes                :       expenseData.notes ? expenseData.notes : '',
                        section              :       expenseData.section ? expenseData.section : '',
                        sub_section          :       expenseData.sub_section ? expenseData.sub_section : '',
                        transaction_id       :       expenseData.transaction_id ? expenseData.transaction_id : ''
                    },showMoreDetailsForm : 'Y',
                    expenseData     :   expenseData,
                    searchedEmployee : expenseData.employeeData ? {value: expenseData.employeeData.id,label:expenseData.employeeData.display_full_name} : null,
                    searchedVendor   : expenseData.vendorData ?  {value : expenseData.vendorData.id  ,display_label : expenseData.vendorData.name } : null});
                }
            })
        
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => this.setState({formDataLoading: false}))
    }
    
   showMoreDetailHandler        =   ()      =>      {
      this.setState({showMoreDetailsForm : "Y"})
   }

    handleFileChange = async (e) => {
        let file = e.target.files[0];
        if (file) {
            let filename = file.name;
            let base64_document = await this.convertBase64(file);
            this.setState({ addExpenseForm: { ...this.state.addExpenseForm, base64_document: base64_document, document_name: filename } })
        }
    }

    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result.replace("data:", "").replace(/^.+,/, ""));
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }
   
    submitExpenseForm                  =   (e) =>  {
        e.preventDefault() 
        if (this.state.expenseData) {
            this.setState({ addExpenseFormSubmitting: true });
            HttpAPICall.withAthorization('PUT', TapApiUrls.IAM + '/expense/counterlog', this.props.access_token, null, { ...this.state.addExpenseForm, counterlog_transaction_id: this.state.transaction_id }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.uploadExpenseModal.hide();
                if (this.props.afterSaveExpense) {
                    let asset_id = this.state.assetData ? this.state.assetData.asset_id : null;
                    this.props.afterSaveExpense(this.state.date, asset_id);
                }

                this.setState({
                    addExpenseForm: { ...this.initExpenseForm },
                });
            }).then(() => this.setState({ addExpenseFormSubmitting: false }))
        } else {
            this.setState({ addExpenseFormSubmitting: true });
            HttpAPICall.withAthorization('POST', TapApiUrls.IAM + '/expense/counterlog', this.props.access_token, null, { ...this.state.addExpenseForm, counterlog_transaction_id: this.state.transaction_id }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.uploadExpenseModal.hide();
                if (this.props.afterSaveExpense) {
                    let asset_id = this.state.assetData ? this.state.assetData.asset_id : null;
                    this.props.afterSaveExpense(this.state.date, asset_id);
                }

                this.setState({
                    addExpenseForm: { ...this.initExpenseForm },
                });
            }).then(() => this.setState({ addExpenseFormSubmitting: false }))
        }
        
    }

    deleteExpenseInit                      =   (id)  =>  {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
                HttpAPICall.withAthorization('DELETE', TapApiUrls.IAM + '/expense/' + id , this.props.access_token, {},{} ,(response) => {
                    toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                    if(this.props.afterDeleteExpense) {
                        this.props.afterDeleteExpense()
                    }
                }).then(() => { });
            }
        });
    }
    
    uploadExpenseModalJsx              =   ()  =>  {
        // let wallet_permission = this.state.permissions && this.state.permissions.group_sub_modules && this.state.permissions.group_sub_modules.find(s => s.sub_module == "iam-wallet")
     
        return (
            <div className="modal fade" id={this.uploadExpenseModalId} tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="fs-6 m-0">
                                <span>Upload Shift Expense for : </span>{this.state.counterlog ? this.state.counterlog.feed_date_display : null}
                                {this.state.shift ? <span className="ps-2">{this.state.shift.shift_name} ({this.state.shift.shift_start})</span> : null}
                            </h5>
                            <button type="button" className="btn-close" disabled={this.state.addExpenseFormSubmitting} data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "25%" }}> Asset Name (Code)   </td>
                                        <th>{this.state.assetData ? `${this.state.assetData.name} (${this.state.assetData.asset_code})` : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td>Asset Type </td><th>{this.state.assetData ? `${this.state.assetData.asset_type_name} ` : "-"}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-body mx-1">
                            {this.state.formDataLoading
                                ? <Loader />
                                : <form className={['',].join(' ')} onSubmit={this.submitExpenseForm} id="uploadExpenseForm">
                                    <div className="row ">
                                        <label className="col-sm-3 col-form-label col-form-label-sm require my-1">Expense Head</label>
                                        <div className='col-sm-7 my-1'>
                                            <TapSelect
                                                options={this.state.allExpenseHead}
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'addExpenseForm', 'expense_head');
                                                }}
                                                isSearchable={true}
                                                value={this.state.allExpenseHead.find(s => this.state.addExpenseForm.expense_head == s.value)}
                                                isClearable={true}
                                                placeholder="Select Expense Head"
                                                autoCompelete="off"
                                                required={true}
                                                autoFocus={true}
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label className="col-sm-3 col-form-label col-form-label-sm my-1">Expense Details</label>
                                        <div className='col-sm-7 my-1'>
                                            <textarea
                                                name="details"
                                                value={this.state.addExpenseForm.details}
                                                className="form-control"
                                                onChange={(e) => this.formInputHandler(e, "addExpenseForm")}
                                                placeholder="Enter Expense Details"
                                                style={{ height: "60px", fontSize: "93%" }}
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label className="col-sm-3 col-form-label col-form-label-sm require my-1">Expense Amount in ₹</label>
                                        <div className='col-sm-7 my-1'>
                                            <input
                                                name="amount"
                                                value={this.state.addExpenseForm.amount}
                                                className="form-control"
                                                onChange={(e) => this.formInputHandler(e, "addExpenseForm")}
                                                type="number"
                                                autoComplete="off"
                                                placeholder="Please enter Expense Amount"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label className="col-sm-3 col-form-label col-form-label-sm require my-1">Payee</label>
                                        <div className='col-sm-3 my-1 mr0'>
                                            <TapSelect
                                                options={this.state.allPayeeTypes}
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'addExpenseForm', 'payee');
                                                }}
                                                value={this.state.allPayeeTypes.find(s => this.state.addExpenseForm.payee == s.value)}
                                                placeholder="Select Payee"
                                                autoCompelete="off"
                                                required={true}
                                                containerHeight="30px"
                                                fontSize="93%"
                                            />
                                        </div>
                                        <div className='col-sm-4 my-1'>
                                            {this.state.addExpenseForm && this.state.addExpenseForm.payee == "vendor"
                                                ? <InputVendorSearch

                                                    placeholder="Search"
                                                    changeEvent={(selectedOption) => {
                                                        this.tapSelectChange(selectedOption, 'addExpenseForm', 'payee_id');
                                                        this.setState({ searchedVendor: selectedOption ? selectedOption : null });
                                                    }}
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                    value={this.state.searchedVendor ? { ...this.state.searchedVendor, label: this.state.searchedVendor.display_label } : null}
                                                />
                                                : this.state.addExpenseForm && this.state.addExpenseForm.payee == "employee"
                                                    ? <InputEmployeeSearch

                                                        placeholder="Search"
                                                        changeEvent={(selectedOption) => {
                                                            this.tapSelectChange(selectedOption, 'addExpenseForm', 'payee_id');
                                                            this.setState({ searchedEmployee: selectedOption ? selectedOption : null });
                                                        }}
                                                        search_site_ids={this.state.counterlog && this.state.counterlog.site_id ? [this.state.counterlog.site_id] : ''}
                                                        containerHeight="30px"
                                                        fontSize="93%"
                                                        transaction_date={this.state.counterlog && this.state.counterlog.feed_datetime ? this.state.counterlog.feed_datetime : null }
                                                        value={this.state.searchedEmployee ? this.state.searchedEmployee : null}
                                                    />
                                                    : <input
                                                        name="payee_name"
                                                        value={this.state.addExpenseForm.payee_name}
                                                        className="form-control form-control-sm"
                                                        onChange={(e) => this.formInputHandler(e, "addExpenseForm")}
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder="Please enter Payee Name"
                                                    />}
                                        </div>
                                    </div>

                                    {this.props && this.props.permissions && this.props.permissions.group_sub_modules && this.props.permissions.group_sub_modules.find(s => s.sub_module == "iam-wallet") ?
                                        <div className="row">
                                            <label className="col-sm-3 col-form-label col-form-label-sm my-1">Wallet</label>
                                            <div className='col-sm-7 my-1'>
                                                <TapSelect
                                                    options={this.state.AllWalletList}
                                                    changeEvent={(selectedOption) => {
                                                        this.tapSelectChange(selectedOption, 'addExpenseForm', 'wallet_id');
                                                    }}
                                                    value={this.state.AllWalletList.find(s => this.state.addExpenseForm.wallet_id == s.value)}
                                                    placeholder="Select Wallet"
                                                    autoCompelete="off"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>
                                        </div> : null}
                                    {this.state.showMoreDetailsForm == "N" ? <div className="row">
                                        <div className='col-sm-12 my-1 text-end link-primary cursor_pointer' onClick={this.showMoreDetailHandler}>+ Add more details </div>
                                    </div> : null}
                                    {this.state.showMoreDetailsForm == "Y"
                                        ? <div>
                                            <div className="row">
                                                <label className="col-sm-3 col-form-label col-form-label-sm  my-1">Reference Number</label>
                                                <div className='col-sm-7 my-1'>
                                                    <input
                                                        name="reference_number"
                                                        value={this.state.addExpenseForm.reference_number}
                                                        onChange={(e) => this.formInputHandler(e, "addExpenseForm")}
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        autoComplete="off"
                                                        placeholder="Please enter Reference Number"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <label className="col-sm-3 col-form-label col-form-label-sm  my-1">Upload Expense Voucher</label>
                                                <div className='col-sm-7 my-1'>
                                                    <input
                                                        onChange={(e) => { this.handleFileChange(e) }}
                                                        type="file"
                                                        className="form-control form-control-sm"
                                                        autoComplete="off"
                                                        placeholder="Please upload Expense Voucher"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <label className="col-sm-3 col-form-label col-form-label-sm  my-1">Section</label>
                                                <div className='col-sm-7 my-1'>
                                                    <TapSelect
                                                        options={this.state.allSections}
                                                        changeEvent={(selectedOption) => {
                                                            this.tapSelectChange(selectedOption, 'addExpenseForm', 'section');
                                                            let subSection = selectedOption && selectedOption.value ? this.state.allSections.find(s => selectedOption.value == s.value) : null
                                                            this.setState({
                                                                allSubSections: subSection && subSection.subcomponents && subSection.subcomponents.length > 0 ? subSection.subcomponents.map((s, i) => { return ({ value: s.sub_component_id, label: s.subcomponent }) }) : []
                                                            })
                                                        }}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        value={this.state.allSections.find(s => this.state.addExpenseForm.section == s.value)}
                                                        placeholder="Please enter Section Name"
                                                        containerHeight="30px"
                                                        fontSize="93%"

                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <label className="col-sm-3 col-form-label col-form-label-sm  my-1">Sub-Section</label>
                                                <div className='col-sm-7 my-1'>
                                                    <TapSelect
                                                        options={this.state.allSubSections}
                                                        changeEvent={(selectedOption) => {
                                                            this.tapSelectChange(selectedOption, 'addExpenseForm', 'sub_section');
                                                        }}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        value={this.state.allSubSections.find(s => this.state.addExpenseForm.sub_section == s.value)}
                                                        placeholder="Please enter Sub Section Name"
                                                        containerHeight="30px"
                                                        fontSize="93%"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <label className="col-sm-3 col-form-label col-form-label-sm my-1">Notes</label>
                                                <div className='col-sm-7 my-1'>
                                                    <textarea
                                                        name="notes"
                                                        value={this.state.addExpenseForm.notes}
                                                        onChange={(e) => this.formInputHandler(e, "addExpenseForm")}
                                                        className="form-control form-control-sm"
                                                        autoComplete="off"
                                                        placeholder="Enter Notes"
                                                        style={{ height: "60px", fontSize: "93%" }}

                                                    />
                                                </div>
                                            </div>
                                        </div> : null}

                                </form>
                            }
                        </div>
                        <div className="modal-footer text-end">

                            <button type="button" className="btn btn-secondary mx-2" disabled={this.state.addExpenseFormSubmitting} data-bs-dismiss="modal">Cancel</button>
                            <button type="submit" className="btn btn-primary" disabled={this.state.addExpenseFormSubmitting} form="uploadExpenseForm">Update {this.state.addExpenseFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>

                        </div>

                    </div>
                </div>
            </div>
        );
    }

    render                              =   ()  =>  {
        return (<Ax>
           {this.uploadExpenseModalJsx()}
        </Ax>)
    }
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app && state.app.acl_info ? state.app.acl_info : [],
    };
};


export default connect(mapStateToProps, null, null, { forwardRef: true })(Expense);

