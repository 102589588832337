export const APP_BASE_URL           =  process.env.REACT_APP_API_SERVER;
export const ISC_API_BASE_URL       =  process.env.REACT_APP_ISC_API_SERVER;
export const INVENTORY_API_BASE_URL =  process.env.REACT_APP_INVENTORY_API_SERVER;


export const IAM_API_BASE_URL       = process.env.REACT_APP_IAM_API_SERVER;
export const IAM_API_BASE_URL_2     = process.env.REACT_APP_API_SERVER_2 + '/iam';


export const APP_BASE_URL_2         =  process.env.REACT_APP_API_SERVER_2;
export const ORG_API_BASE_URL       = process.env.REACT_APP_API_SERVER + '/org';
export const ORG_API_BASE_URL_2     = process.env.REACT_APP_API_SERVER_2 + '/org';
export const INVENTORY_API_BASE_URL_2= process.env.REACT_APP_API_SERVER_2 + '/inventory';
export const HRM_API_BASE_URL_2       =   process.env.REACT_APP_API_SERVER_2 + '/hrm';




export const ISC_API_BASE_URL_2       =   process.env.REACT_APP_API_SERVER_2 + '/isc';

//url for fund transfer
const TapApiUrls    = ()      =>   {
    return{

        FUND_TRANSFER_FORM_DATA       :          IAM_API_BASE_URL + '/wallet/fund_transfer_list/form_data',
        INVENTORY                     :          INVENTORY_API_BASE_URL,
        INVENTORY_ADJUSTMENT          :          INVENTORY_API_BASE_URL + '/inventory_adjustment',
        ISC                           :          ISC_API_BASE_URL,
        IAM                           :          IAM_API_BASE_URL,
        ITEM                          :          INVENTORY_API_BASE_URL + '/item',
        ITEM_ADD_FORM_DATA            :          INVENTORY_API_BASE_URL + '/item/add_form_data',
        ITEM_ADDITIONAL_ATTR          :          ISC_API_BASE_URL + '/additional_attribute',
        ITEM_GENERAL_CONFIGURATION    :          INVENTORY_API_BASE_URL + '/configuration',
        ITEM_SEARCH_FORM_DATA         :          INVENTORY_API_BASE_URL + '/item/search_form_data',
        ITEM_TAGS_LISTING             :          ISC_API_BASE_URL + '/tags',
        ITEM_TAGS_CHANGE_STATUS       :          ISC_API_BASE_URL + '/tag',
        MANUFACTURER_CONFIGURATION    :          ISC_API_BASE_URL + '/manufactures',
        ITEM_OPENING_STOCK            :          INVENTORY_API_BASE_URL + '/item_opening_stock',
        PURCHASE_URL                  :          INVENTORY_API_BASE_URL + '/purchase',
        PURCHASE_RETURN_URL           :          INVENTORY_API_BASE_URL + '/purchase_return',
        PURCHASE_ORDER_URL            :          INVENTORY_API_BASE_URL + '/purchase_order',
        ORGANIZATION_URL              :          APP_BASE_URL + '/org',
        VENDOR_URL                    :          INVENTORY_API_BASE_URL + '/vendor',
        WAREHOUSE_URL                 :          ISC_API_BASE_URL + '/warehouse',
        CONSUMPTION_URL               :          INVENTORY_API_BASE_URL + '/consumption',
        REQUISITON_URL                 :         INVENTORY_API_BASE_URL + '/requisition',
        INVENTORY_TRANSFER_URL        :          INVENTORY_API_BASE_URL + '/inventory_transfer',
        APP_URL                       :          APP_BASE_URL,
        HRM_URL                       :          APP_BASE_URL + '/hrm',
      }
}

export default TapApiUrls()