import React from "react";
import AppBaseComponent from "../../../components/AppBaseComponent";
import Status from "../../../components/ui/Status";
import DateService from "../../../services/DateService";
import Ax from "../../../components/hoc/Ax";

class ProjectDetails extends AppBaseComponent {
    constructor () {
        super();
    }


    render () {
        const projectDetail = this.props.projectDetail;
        const ticketData = this.props.ticketData;
        return (
            <Ax>
                <div className="tab_content_wrapper mt-2">
                    <span className="content_heading">Project Details</span>
                </div>
                <table className="table mt-2 table-hover table-bordered">
                    <tbody>
                        <tr>
                            <td style={{ width: "30%" }}>Project Name</td>
                            <th style={{ width: "70%" }}>{projectDetail ? projectDetail.name : '-'}</th>
                        </tr>
                        <tr>
                            <td style={{ width: "30%" }}>Project ID</td>
                            <th style={{ width: "70%" }}>{projectDetail ? projectDetail.transaction_id : '-'}</th>
                        </tr>
                        <tr>
                            <td style={{ width: "30%" }}>Total Ticket</td>
                            <th style={{ width: "70%" }}>{projectDetail ? projectDetail.count_tickets : '-'}</th>
                        </tr>
                        <tr>
                            <td style={{ width: "30%" }}>Site</td>
                            <th style={{ width: "70%" }}>{projectDetail && projectDetail.site ? projectDetail.site.site_name : '-'}</th>
                        </tr>
                        <tr>
                            <td style={{ width: "30%" }}>Status</td>
                            <th style={{ width: "70%" }}>{projectDetail && projectDetail.status ? <Status color={projectDetail.status.color_code}>{projectDetail.status.status}</Status> : '-'}</th>
                        </tr>
                        <tr>
                            <td style={{ width: "30%" }}>Notes</td>
                            <th style={{ width: "70%" }}>{projectDetail ? projectDetail.notes : '-'}</th>
                        </tr>
                        <tr>
                            <td style={{ width: "30%" }}>Project Start Date</td>
                            <th style={{ width: "70%" }}>{projectDetail && projectDetail.starting_date ? DateService.dateTimeFormat(projectDetail.starting_date, 'DD-MMM-YYYY') : '-'}</th>
                        </tr>
                        <tr>
                            <td style={{ width: "30%" }}>Original Estimated Completion Date</td>
                            <th style={{ width: "70%" }}>{projectDetail && projectDetail.estimated_completion_date ? DateService.dateTimeFormat(projectDetail.estimated_completion_date, 'DD-MMM-YYYY') : '-'}</th>
                        </tr>
                        <tr>
                            <td style={{ width: "30%" }}>Current Estimate Completion Date</td>
                            <th style={{ width: "70%" }}>{projectDetail && projectDetail.completion_date ? DateService.dateTimeFormat(projectDetail.completion_date, 'DD-MMM-YYYY') : '-'}</th>
                        </tr>
                    </tbody>
                </table>
                <div className="tab_content_wrapper mt-2">
                    <span className="content_heading">Ticket Details</span>
                </div>
                <table className="table mt-2 table-hover table-bordered">
                    <tbody>
                        
                        <tr>
                            <td style={{ width: "30%" }}>Parent Ticket</td>
                            <th style={{ width: "70%" }}>{ticketData && ticketData.parent_ticket ? ticketData.parent_ticket.header : '-'}</th>
                        </tr>
                        <tr>
                            <td style={{ width: "30%" }}>Child Tickets</td>
                            <th style={{ width: "70%" }}>{ticketData && ticketData.child_tickets ? ticketData.child_tickets.map(ct => ct.header).join(', ') : '-'}</th>
                        </tr>
                        <tr>
                            <td style={{ width: "30%" }}>On Critical Path</td>
                            <th style={{ width: "70%" }}>{ticketData && ticketData.is_in_critical_path === "Y" ? "Yes" : "No"}</th>
                        </tr>
                    </tbody>
                </table>
            </Ax>
        )
    }


}

export default ProjectDetails;