import React from 'react';
import { connect } from 'react-redux';
import HttpAPICall from '../../../services/HttpAPICall';
import { APP_BASE_URL_2 } from '../../../services/TapApiUrls';
import Ax from '../../../components/hoc/Ax';
import TapIcon from '../../../services/TapIcon';
import { Modal } from 'bootstrap';
import Loader from '../../../components/ui/Loader/Loader';
import AppBaseComponent from '../../../components/AppBaseComponent';
import uuid from 'react-uuid';
import DateService from '../../../services/DateService';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

class LogisticViewModal extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.state                      =   {
            loading_modal                    :   false,
            transaction_id                  :   '',
            logisticDetail                  :   null,
        };
        
        this.modalId                    =    uuid();   
        this.logisticUrl                =   APP_BASE_URL_2 + '/logistic';
       
    }
    
  
    componentDidMount(){
        this.detailModal                  =   new Modal(document.getElementById(this.modalId), {keyboard: false, backdrop :false});
    }

    showModalInit           =   (transaction_id)  =>  {
        this.setState({
            loading_modal    :   true,
            transaction_id  :   transaction_id
        }, () =>{
            this.detailModal.show();
        });

        let params                  =   {need_documents : "Y", need_commet : "Y"}; 
        HttpAPICall.withAthorization('GET',  `${this.logisticUrl}/detail/${transaction_id}`, this.props.access_token, params, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                logisticDetail          :   respData.data,
            });
        }).then(() => { this.setState({ loading_modal: false}) });
        
    }
   

    //*********************************ITEM ADD JSX*********************** */
    modalJsx                     =   ()  =>  {

        const logisticViewData  =   this.state.logisticDetail;
        let originType          =   'Origin Detail';
        let destinationType     =   'Destination Detail';

        let status              =   logisticViewData && logisticViewData.status ? logisticViewData.status.name : null;
        
        let origin_type_detail  =   '';
        if(logisticViewData && logisticViewData.origin_type_detail) {
            origin_type_detail  = logisticViewData.origin_type_detail ? `${logisticViewData.origin_type_detail.name} (${logisticViewData.origin_type_detail.code})` : ''
        }

        let destination_type_detail  =   '';
        if(logisticViewData && logisticViewData.destination_type_detail) {
            destination_type_detail  = logisticViewData.destination_type_detail ? `${logisticViewData.destination_type_detail.name} (${logisticViewData.destination_type_detail.code})` : ''
        }
        if(logisticViewData && logisticViewData.origin_type) {
            if(logisticViewData.origin_type === 'warehouse') {
                originType  = 'Warehouse';
            }else if(logisticViewData.origin_type === 'vendor') {
                originType  = 'Vendor';
            }else if(logisticViewData.origin_type === 'customer') {
                originType  = 'Customer';
            }else if(logisticViewData.origin_type === 'manual_entry') {
                originType  = 'Origin Detail';
            } 
        }

        if(logisticViewData && logisticViewData.destination_type) {
            if(logisticViewData.destination_type === 'warehouse') {
                destinationType  = 'Warehouse';
            }else if(logisticViewData.destination_type === 'vendor') {
                destinationType  = 'Vendor';
            }else if(logisticViewData.destination_type === 'customer') {
                destinationType  = 'Customer';
            }else if(logisticViewData.destination_type === 'manual_entry') {
                destinationType  = 'Destination Detail';
            } 
        }   

        return (

            <div className="modal fade" id={this.modalId} tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content  ">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addModalLabel">Logistic Detail : {this.state.transaction_id}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <div className="modal-body mb-3">
                            {
                            this.state.loading_modal 
                            ? 
                            <Loader/>
                            : 
                            <Ax>     
                                <table className="table table-bordered bg-white" >
                                    <thead className="table-secondary">
                                        <tr>
                                            <td style={{ width: "25%"}}>ID</td>
                                            <td style={{ width: "25%" }}>Date</td>
                                            <td style={{ width: "25%" }}>Status</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="fs14 fw500" scope="col">{logisticViewData && logisticViewData.transaction_id}</td>
                                            <td className="fs14 fw500" scope="col">{logisticViewData && logisticViewData.transaction_date ? DateService.dateTimeFormat(logisticViewData.transaction_date, 'DD-MMM-YYYY') : ''}</td>
                                            <td className="fs14 fw500" scope="col">
                                                {logisticViewData && logisticViewData.status ? logisticViewData.status?.name : ''}
                                            </td>  
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="tab_content_wrapper mt-4">
                                    <span className="content_heading">Transaction Details</span>
                                </div>
                                <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                                    <tbody>
                                        <tr>
                                            <td className="fs14 fw-normal" style={{ "width": "25%" }}>Logistic Type</td>
                                            <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData && logisticViewData.logistic_type ? logisticViewData.logistic_type : ''}</td>
                                            <td className="fs14 fw-normal" style={{ "width": "25%" }}>Division</td>
                                            <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData && logisticViewData.division_name ? logisticViewData.division_name : ''}</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div className="tab_content_wrapper mt-4">
                                    <span className="content_heading">Origin Details</span>
                                </div>
                                <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                                    <tbody>
                                        <tr>
                                            <td className="fs14 fw-normal" style={{ "width": "25%" }}>{originType}</td>
                                            <td className="fs14 fw500" style={{ "width": "75%" }}>{origin_type_detail}</td>
                                        </tr>
                                        <tr>
                                            <td className="fs14 fw-normal" style={{ "width": "25%" }}>Address</td>
                                            <td className="fs14 fw500" style={{ "width": "75%" }}>
                                                {
                                                    logisticViewData && logisticViewData.display_origin_address 
                                                    ? 
                                                    `${logisticViewData.display_origin_address.address1 ? logisticViewData.display_origin_address.address1 + ", " : ""}${logisticViewData.display_origin_address.address2 ? logisticViewData.display_origin_address.address2 + ", " : ""}${logisticViewData.display_origin_address.landmark ? logisticViewData.display_origin_address.landmark + ", " : ""}${logisticViewData.display_origin_address.city ? logisticViewData.display_origin_address.city + ", " : ""}${logisticViewData.display_origin_address.country ? logisticViewData.display_origin_address.country + ", " : ""}${logisticViewData.display_origin_address.state ? logisticViewData.display_origin_address.state + ", " : ""}${logisticViewData.display_origin_address.district ? logisticViewData.display_origin_address.district + ", " : ""}${logisticViewData.display_origin_address.pincode ? logisticViewData.display_origin_address.pincode + ", " : ""}`
                                                    :
                                                    ''
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="tab_content_wrapper mt-4">
                                    <span className="content_heading">Destination Details</span>
                                </div>
                                <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                                    <tbody>
                                        <tr>
                                            <td className="fs14 fw-normal" style={{ "width": "25%" }}>{destinationType}</td>
                                            <td className="fs14 fw500" style={{ "width": "75%" }}>{destination_type_detail}</td>
                                        </tr>
                                        <tr>
                                            <td className="fs14 fw-normal" style={{ "width": "25%" }}>Address</td>
                                            <td className="fs14 fw500" style={{ "width": "75%" }}>
                                                {
                                                    logisticViewData && logisticViewData.display_destination_address 
                                                    ?
                                                    `${logisticViewData.display_destination_address.address1 ? logisticViewData.display_destination_address.address1 + ", " : ""}${logisticViewData.display_destination_address.address2 ? logisticViewData.display_destination_address.address2 + ", " : ""}${logisticViewData.display_destination_address.landmark ? logisticViewData.display_destination_address.landmark + ", " : ""}${logisticViewData.display_destination_address.city ? logisticViewData.display_destination_address.city + ", " : ""}${logisticViewData.display_destination_address.country ? logisticViewData.display_destination_address.country + ", " : ""}${logisticViewData.display_destination_address.state ? logisticViewData.display_destination_address.state + ", " : ""}
                                                    ${logisticViewData.display_destination_address.district ? logisticViewData.display_destination_address.district + ", " : ""}${logisticViewData.display_destination_address.pincode ? logisticViewData.display_destination_address.pincode + ", " : ""}`
                                                    :
                                                    ''
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            <div className="tab_content_wrapper mt-4">
                                <span className="content_heading">Tracking Details</span>
                            </div>
                            <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                                <tbody>
                                    <tr>
                                        <td className="fs14 fw-normal" style={{ "width": "25%" }}>Date of Dispatch</td>
                                        <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData && logisticViewData.dispatch_date ? DateService.dateTimeFormat(logisticViewData.dispatch_date, 'DD-MMM-YYYY') : ''}</td>
                                        <td className="fs14 fw-normal" style={{ "width": "25%" }}>Expected Delivery Date</td>
                                        <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData && logisticViewData.delivery_date ? DateService.dateTimeFormat(logisticViewData.delivery_date, 'DD-MMM-YYYY') : ''}</td>
                                    </tr>
                                    <tr>
                                        <td className="fs14 fw-normal" style={{ "width": "25%" }}>Delivery Date at Godown</td>
                                        <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData && logisticViewData.delivery_date_at_godown ? DateService.dateTimeFormat(logisticViewData.delivery_date_at_godown, 'DD-MMM-YYYY') : ''}</td>
                                        <td className="fs14 fw-normal" style={{ "width": "25%" }}>Delivery Date at Site</td>
                                        <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData && logisticViewData.delivery_date_at_site ? DateService.dateTimeFormat(logisticViewData.delivery_date_at_site, 'DD-MMM-YYYY') : ''}</td>
                                    </tr>
                                    <tr>
                                        <td className="fs14 fw-normal" style={{ "width": "25%" }}>Transport Days</td>
                                        <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData && logisticViewData.transport_days ? logisticViewData.transport_days : ''}</td>
                                        <td className="fs14 fw-normal" style={{ "width": "25%" }}>Receiver Name</td>
                                        <td className="fs14 fw500" colSpan={3} >{logisticViewData && logisticViewData.receiver_name}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <div className="tab_content_wrapper mt-4">
                                <span className="content_heading">Consigment Details</span>
                            </div>
                            <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                                <tbody>
                                    <tr>
                                        <td className="fs14 fw-normal" style={{ "width": "25%" }}>Type of Load</td>
                                        <td className="fs14 fw500" style={{ "width": "25%" }}>
                                            {logisticViewData && logisticViewData.load_type ? logisticViewData.load_type : ''}
                                        </td>
                                        <td className="fs14 fw-normal" style={{ "width": "25%" }}>Consigment Number</td>
                                        <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData && logisticViewData.consigment_number ? logisticViewData.consigment_number : ''}</td>
                                    </tr>
                                    <tr>
                                        <td className="fs14 fw-normal" style={{ "width": "25%" }}>Booking Place</td>
                                        <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData && logisticViewData.booking_place ? logisticViewData.booking_place : ''}</td>
                                        <td className="fs14 fw-normal" style={{ "width": "25%" }}>Courier/Transporter Name</td>
                                        <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData && logisticViewData.transporter_name ? logisticViewData.transporter_name : ''}</td>
                                    </tr>
                                    <tr>
                                        <td className="fs14 fw-normal" style={{ "width": "25%" }}>Vehicle Number</td>
                                        <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData && logisticViewData.vehicle_number ? logisticViewData.vehicle_number : ''}</td>
                                        <td className="fs14 fw-normal" style={{ "width": "25%" }}>Weight</td>
                                        <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData && logisticViewData.weight ? logisticViewData.weight : ''}</td>
                                    </tr>
                                    <tr>
                                        <td className="fs14 fw-normal" style={{ "width": "25%" }}>No. of Boxes</td>
                                        <td className="fs14 fw500" colSpan="3">{logisticViewData && logisticViewData.number_of_boxes ? logisticViewData.number_of_boxes : ''}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <div className="tab_content_wrapper mt-4">
                                <span className="content_heading">Billing Details</span>
                            </div>
                            <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                                <tbody>
                                    <tr>
                                        <td className="fs14 fw-normal" style={{ "width": "25%" }}>Invoice Number</td>
                                        <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData && logisticViewData.gst_invoice_number ? logisticViewData.gst_invoice_number : ''}</td>
                                        <td className="fs14 fw-normal" style={{ "width": "25%" }}>Invoice Date</td>
                                        <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData && logisticViewData.invoice_date ? DateService.dateTimeFormat(logisticViewData.invoice_date, 'DD-MMM-YYYY') : ''}</td>
                                    </tr>
                                    <tr>
                                        <td className="fs14 fw-normal" style={{ "width": "25%" }}>Invoice Value</td>
                                        <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData && logisticViewData.invoice_value ? logisticViewData.invoice_value : ''}</td>
                                        <td className="fs14 fw-normal" style={{ "width": "25%" }}>Eway Bill Number</td>
                                        <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData && logisticViewData.eway_bill_number ? logisticViewData.eway_bill_number : ''}</td>
                                    </tr>
                                    <tr>
                                        <td className="fs14 fw-normal" style={{ "width": "25%" }}>Payment Mode</td>
                                        <td className="fs14 fw500" style={{ "width": "25%" }} >{logisticViewData && logisticViewData.payment_mode ? logisticViewData.payment_mode : ''}</td>
                                        <td className="fs14 fw-normal" style={{ "width": "25%" }}>Payment Date</td>
                                        <td className="fs14 fw500" style={{ "width": "25%" }} >{logisticViewData && logisticViewData.payment_date ? DateService.dateTimeFormat(logisticViewData.payment_date, 'DD-MMM-YYYY') : ''}</td>
                                    </tr>
                                    <tr>
                                        <td className="fs14 fw-normal" style={{ "width": "25%" }}>Machinery/Material Description</td>
                                        <td className="fs14 fw500" colspan="3">{logisticViewData && logisticViewData.machinery_description ? logisticViewData.machinery_description : ''}</td>
                                    </tr>
                                </tbody>
                            </table>

                                {
                                    logisticViewData && logisticViewData.logistic_type === "International" &&
                                    <Ax>
                                        <div className="tab_content_wrapper mt-4">
                                            <span className="content_heading">C&F Details</span>
                                        </div>
                                        <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                                            <tbody>
                                                <tr>
                                                    <td className="fs14 fw-normal" style={{ "width": "25%" }}>C&F Agent</td>
                                                    <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData && logisticViewData.cnfAgent_name ? logisticViewData.cnfAgent_name : ''}</td>
                                                    <td className="fs14 fw-normal" style={{ "width": "25%" }}>Material Handler Name</td>
                                                    <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData && logisticViewData.handler_name ? logisticViewData.handler_name : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td className="fs14 fw-normal" style={{ "width": "25%" }}>BOE Number</td>
                                                    <td className="fs14 fw500" colspan="3">{logisticViewData && logisticViewData.boe_number ? logisticViewData.boe_number : ''}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Ax>
                                }
                                <div className="tab_content_wrapper mt-4">
                                    <span className="content_heading">Expense Details</span>
                                </div>
                                <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                                    <tbody>
                                        <tr>
                                            <td className="fs14 fw-normal" style={{ "width": "25%" }}>Logistic Cost</td>
                                            <td className="fs14 fw500" colSpan={logisticViewData && logisticViewData.logistic_type === "Domestic" ? 3 : ""}>{logisticViewData && logisticViewData.logistic_cost ? logisticViewData.logistic_cost : ''}</td>
                                            {
                                                logisticViewData && logisticViewData.logistic_type === "International" &&
                                                <Ax>
                                                    <td className="fs14 fw-normal" style={{ "width": "25%" }}>CHA Cost</td>
                                                    <td className="fs14 fw500" >{logisticViewData && logisticViewData.cha_cost ? logisticViewData.cha_cost : ''}</td>
                                                </Ax>
                                            }
                                        </tr>
                                        <tr>
                                            <td className="fs14 fw-normal" style={{ "width": "25%" }}>Handling Cost</td>
                                            <td className="fs14 fw500" style={{ "width": "25%" }}>{logisticViewData && logisticViewData.handling_cost ? logisticViewData.handling_cost : ''}</td>
                                            <td className="fs14 fw-normal" style={{ "width": "25%" }}>Total Cost</td>
                                            <td className="fs14 fw500" >{logisticViewData && logisticViewData.total_cost ? logisticViewData.total_cost : ''}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="tab_content_wrapper mt-4">
                                    <span className="content_heading">Linking with Purchase & Inventory Transfer</span>
                                </div>
                                <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                                    <tbody>
                                        <tr>
                                            <td className="fs14 fw-normal" style={{ "width": "25%" }}>Purchase Order</td>
                                            <td className="fs14 fw500" style={{ "width": "75%" }}>
                                            {
                                                (logisticViewData && logisticViewData?.purchase_order_transaction_ids && logisticViewData.purchase_order_transaction_ids.length > 0) &&
                                                logisticViewData.purchase_order_transaction_ids.map((val) => val).join(', ')
                                            }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fs14 fw-normal" style={{ "width": "25%" }}>Purchase</td>
                                            <td className="fs14 fw500" style={{ "width": "75%" }}>
                                            {
                                                (logisticViewData && logisticViewData?.purchase_transaction_ids && logisticViewData.purchase_transaction_ids.length > 0) &&
                                                logisticViewData.purchase_transaction_ids.map((val) => val).join(', ')
                                            }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="fs14 fw-normal" style={{ "width": "25%" }}>Inventory Transfer</td>
                                            <td className="fs14 fw500" style={{ "width": "75%" }}>
                                            {
                                                (logisticViewData && logisticViewData?.inv_transfer_transaction_ids && logisticViewData.inv_transfer_transaction_ids.length > 0) &&
                                                logisticViewData.inv_transfer_transaction_ids.map(val => val).join(', ')
                                            }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="tab_content_wrapper mt-4">
                                    <span className="content_heading">Logistic Document</span>
                                </div>
                                <table className="table table-bordered bg-white my-2">
                                    <thead className="table-secondary">
                                        <tr>
                                            <td style={{ "width": "5%" }}>S.No</td>
                                            <td style={{ "width": "35%" }}>Name</td>
                                            <td style={{ "width": "30%" }}>Number</td>
                                            <td style={{ "width": "10%" }}>View</td>
                                            <td style={{ "width": "10%" }}>Download</td>
                                            <td style={{ "width": "10%" }}>Delete</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {logisticViewData && logisticViewData.documents && logisticViewData.documents.length > 0 ? 
                                            logisticViewData.documents.map((d,i) => {
                                            return <tr className='text-center'>
                                                <td>{i + 1}</td>
                                                <td>{d.name}</td>
                                                <td>{d.number}</td>
                                                <td scope="col" className="text-center" style={{ "width": "10%" }}>
                                                    <Link role="button"
                                                        target='_blank'
                                                        to={{pathname : d.document}}
                                                        className={[d.view_permission ? '' : 'disabled-link'].join(' ')}
                                                        title={d.view_permission ? '' : 'You do not have permission to perform this action'}
                                                        style={{ "pointerEvents": "all" }}
                                                    > <TapIcon.FontAwesomeIcon icon={TapIcon.faEye}  /></Link>
                                                </td>
                                                <td scope="col" className="text-center" style={{ "width": "10%" }}>
                                                    <Link role="button"
                                                        target='_blank'
                                                        to={{pathname : d.download_link}}
                                                        className={[d.downlaod_permission ? '' : 'disabled-link'].join(' ')}
                                                        title={d.downlaod_permission ? '' : 'You do not have permission to perform this action'}
                                                        style={{ "pointerEvents": "all" }}
                                                    > <TapIcon.FontAwesomeIcon icon={TapIcon.faDownload}  /></Link>
                                                </td>
                                                <td scope="col" className="text-center" style={{ "width": "10%" }}>
                                                    <a role="button"
                                                        onClick={() => {
                                                            if (this.props.permissions.includes('logistic_doc_delete')) {
                                                            return this.deleteLogistocDocument(d.doc_id)
                                                        }
                                                        }}
                                                        className={[this.props.permissions.includes('logistic_doc_delete') ? '' : 'disabled-link'].join(' ')}
                                                        title={!this.props.permissions.includes('logistic_doc_delete') ? 'You do not have permission to perform this action' : ''}
                                                        style={{ "pointerEvents": "all" }}
                                                    > <TapIcon.FontAwesomeIcon icon={TapIcon.faTrashAlt}  /></a>
                                                </td>
                                            </tr>
                                        })
                                        : <tr><td className='text-center' colSpan="6">No Document Found</td></tr>
                                    }
                                    </tbody>
                                </table>
                            </Ax>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    

    render() {
        return (<Ax>{this.modalJsx()}</Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : [],
    };
};

export default connect(mapStateToProps,null, null, { forwardRef: true })(LogisticViewModal);