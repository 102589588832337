import moment from 'moment';

const DateService         =   {

    addDays                 :   function(days = 1, date = null) {
        var result             =   date ? new Date(date) : new Date();
        result.setDate(result.getDate() + days);
        return result;
    },

    subDays                 :   function(days = 1, date = null) {
        var result             =   date ? new Date(date) : new Date();
        result.setDate(result.getDate() - days);
        return result;
    },

    isValidDate             :   function(date) {
        return new Date(date).toString() == 'Invalid Date' ? false : true
    },

    convertSeconds      :       function(seconds, separator = ":") {
        const hours             =   Math.floor(seconds / 3600);
        const remainingSeconds  =   seconds % 3600;
        const minutes           =   Math.floor(remainingSeconds / 60);
        const secs              =   remainingSeconds % 60;

        if (hours > 0) {
            return `${hours}${separator}${minutes < 10 ? '0' : ''}${minutes}`;
        } else {
            return `0${separator}${minutes}${separator}${secs < 10 ? '0' : ''}${secs}`;
        }
    },

    dateTimeFormat: function (date, format = 'DD-MM-YYYY HH:mm A') {
        if (date && this.isValidDate(date)) {
            return moment(date).format(format);
        }
        return null;
    }

    
};

export default DateService;