import React from 'react';
import {connect} from "react-redux";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import ApplicationLayout from '../../layouts/ApplicationLayout';
import ReactPaginate from "react-paginate";
import Ax from "../../components/hoc/Ax";
import tapIcon from "../../services/TapIcon"
import Loader from "../../components/ui/Loader/Loader";
import TapSelect from "../../components/ui/TapSelect";
import {Modal} from "bootstrap";
import swal from "sweetalert";
import { Helmet } from 'react-helmet';
import TapApiUrls from '../../services/TapApiUrls';
import HttpAPICall from '../../services/HttpAPICall';
import { DisplayListPagination } from '../../components/TapUi';
import { Link } from 'react-router-dom';
import AppBaseComponent from '../../components/AppBaseComponent';
import TapIcon from '../../services/TapIcon';
import {AllCheckerCheckbox, Checkbox, CheckboxGroup} from "@createnl/grouped-checkboxes";

class AssetPermission extends AppBaseComponent {
  
    constructor() {
        super();
     
        this.state = {
           roleData         :       null,
           listing_loading  :       false,
           allTransactionLockingList    :   [],
           allPermissionList            :   [],
           fullAccessChecked            :   false,
           selectedPermissions: [],
           subSectionFullAccess: {},
           moduleFullAccess: {},      // Initial state for module full access checkboxes
           overallFullAccess: false,
           permissions       :  [
                {
                    id: 1,
                    name: "Assets",
                    sub_sections: [
                        {
                            id: 1.1,
                            name: "Asset",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "iam-asset-add",
                                    "name": "Asset Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 273,
                                    "permission": "iam-asset-edit",
                                    "name": "Asset Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "iam-asset-delete",
                                    "name": "Asset Delete",
                                    "type": "delete",
                                    "access": true
                                }
                            ]
                        },
                        {
                            id: 2.1,
                            name: "Asset-Site Linking",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "iam-asset-site-linking-change",
                                    "name": "Asset Site Add",
                                    "type": "add",
                                    "access": true
                                },
                               
                                {
                                    id: 2,
                                    "permission": "iam-asset-site-linking-delete",
                                    "name": "Asset Site Delete",
                                    "type": "delete",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "iam-asset-site-linking-view",
                                    "name": "Asset Site View",
                                    "type": "view",
                                    "access": true
                                },
                            ]
                        },
                        {
                            id: 3.1,
                            name: "Asset-Employee Linking",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "iam-asset-employee-linking",
                                    "name": "Asset-Employee Linking",
                                    "type": "edit",
                                    "access": true
                                },
                            ]
                        },
                        {
                            id: 4.1,
                            name: "Asset-Commercial",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "iam-asset-rental-update",
                                    "name": "Asset-Commercial",
                                    "type": "edit",
                                    "access": true
                                },
                            ]
                        },
                        {
                            id: 5.1,
                            name: "Asset-Compliance",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "iam-compliance-add",
                                    "name": "Asset-Compliance Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "iam-compliance-edit",
                                    "name": "Asset-Compliance Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "iam-compliance-delete",
                                    "name": "Asset-Compliance Delete",
                                    "type": "delete",
                                    "access": true
                                },
                            ]
                        },
                        {
                            id: 6.1,
                            name: "Reset Counter Log",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "iam-asset-counterlog-reset-delete",
                                    "name": "Reset Counter Log Delete",
                                    "type": "delete",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "iam-asset-counterlog-reset",
                                    "name": "Reset Counter Log Add",
                                    "type": "add",
                                    "access": true
                                },
                                
                            ]
                        },
                        {
                            id: 7.1,
                            name: "Asset-Roster Linking",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "iam-asset-roster-add",
                                    "name": "Asset Roster Add",
                                    "type": "add",
                                    "access": true
                                },
                                
                            ]
                        },
                        {
                            id: 8.1,
                            name: "Invoice Rule",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "iam-asset-invoice-view",
                                    "name": "Invoice Rule View",
                                    "type": "view",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "iam-asset-invoice-add",
                                    "name": "Invoice Rule Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "iam-asset-invoice-update",
                                    "name": "Invoice Rule Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 4,
                                    "permission": "iam-asset-invoice-linking",
                                    "name": "Invoice linking to Asset",
                                    "type": "other",
                                    "access": true
                                },
                                {
                                    id: 5,
                                    "permission": "iam-asset-matrix-invoice-linking",
                                    "name": "Invoice linking to Asset Matrix",
                                    "type": "other",
                                    "access": true
                                },
                                
                            ]
                        },
                        {
                            id: 9.1,
                            name: "Save Invoice Rule Calculation",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "iam-asset-invoice-calculation-view",
                                    "name": "Invoice Rule Calculation View",
                                    "type": "view",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "iam-asset-invoice-calculation-save",
                                    "name": "Invoice Rule Calculation Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "iam-asset-invoice-calculation-edit",
                                    "name": "Invoice Rule Calculation Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 4,
                                    "permission": "iam-asset-invoice-calculation-delete",
                                    "name": "Invoice Rule Calculation Delete",
                                    "type": "delete",
                                    "access": true
                                },
                                
                            ]
                        },
                        {
                            id: 10.1,
                            name: "Overtime Rule",
                            permissions: [
                               
                                {
                                    id: 1,
                                    "permission": "iam-asset-overtime-rule-add",
                                    "name": "Overtime Rule Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "iam-asset-overtime-rule-edit",
                                    "name": "Overtime Rule Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "iam-asset-overtime-rule-delete",
                                    "name": "Overtime Rule Delete",
                                    "type": "delete",
                                    "access": true
                                },
                                
                            ]
                        },
                        {
                            id: 11.1,
                            name: "Fuel Station",
                            permissions: [
                               
                                {
                                    id: 1,
                                    "permission": "iam-fuel-station-add",
                                    "name": "Fuel Station Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "iam-fuel-station-update",
                                    "name": "Fuel Station  Edit",
                                    "type": "edit",
                                    "access": true
                                },
                               
                            ]
                        },
                        {
                            id: 12.1,
                            name: "Inventory Asset List",
                            permissions: [
                               
                                {
                                    id: 1,
                                    "permission": "iam-inventory-add-asset",
                                    "name": "Inventory Asset Add",
                                    "type": "add",
                                    "access": true
                                },
                               
                            ]
                        },
                        {
                            id: 13.1,
                            name: "Fund Transfer",
                            permissions: [
                               
                                {
                                    id: 1,
                                    "permission": "iam-wallet-fund-add",
                                    "name": "Fund Transfer Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "iam-wallet-fund-edit",
                                    "name": "Fund Transfer Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "iam-wallet-fund-delete",
                                    "name": "Fund Transfer Delete",
                                    "type": "delete",
                                    "access": true
                                },
                               
                            ]
                        },
                        
                    ]
                },
                {
                    id: 2,
                    name: "Site Productivity",
                    sub_sections: [
                        {
                            id: 1.2,
                            name: "Configuration",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "site-productivity-configuration-view",
                                    "name": "Configuration View",
                                    "type": "view",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "site-productivity-configuration-add",
                                    "name": "Configuration Add",
                                    "type": "add",
                                    "access": true
                                },
                               
                                {
                                    id: 3,
                                    "permission": "site-productivity-configuration-edit",
                                    "name": "Configuration Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 4,
                                    "permission": "site-productivity-configuration-change-status",
                                    "name": "Change Status",
                                    "type": "other",
                                    "access": true
                                }
                            ]
                        },
                        {
                            id: 2.2,
                            name: "Site Productivity",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "site-productivity-view",
                                    "name": "Site Productivity View",
                                    "type": "view",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "site-productivity-add",
                                    "name": "Site Productivity Add",
                                    "type": "add",
                                    "access": true
                                },
                               
                                {
                                    id: 3,
                                    "permission": "site-productivity-edit",
                                    "name": "Site Productivity Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 4,
                                    "permission": "site-productivity-delete",
                                    "name": "Site Productivity Delete",
                                    "type": "delete",
                                    "access": true
                                }
                            ]
                        },
                       
                    ]
                },
                {
                    id: 3,
                    name: "Configuration",
                    sub_sections: [
                        {
                            id: 1.3,
                            name: "Configuration ( General)",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "group-configuration",
                                    "name": "Configuration ( General) View",
                                    "type": "view",
                                    "access": true
                                },
                                
                            ]
                        },
                        {
                            id: 2.3,
                            name: "Configuration (Assets)",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "iam-configuration",
                                    "name": "Configuration (Assets) View",
                                    "type": "view",
                                    "access": true
                                },
                                
                            ]
                        },
                        {
                            id: 3.3,
                            name: "Configuration (Inventory)",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "isc-configuration",
                                    "name": "Configuration (Inventory) View",
                                    "type": "view",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "isc-configuration-edit",
                                    "name": "Configuration (Inventory) Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                
                            ]
                        },
                        {
                            id: 12.3,
                            name: "Counterlog Configuration",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "iam-counterlog-configuration-manage-column",
                                    "name": "Manage Column",
                                    "type": "other",
                                    "access": true
                                },
                                
                            ]
                        },
                        {
                            id: 4.3,
                            name: "User",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "grp-user-view",
                                    "name": "User View",
                                    "type": "view",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "grp-user-add",
                                    "name": "User Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "grp-user-edit",
                                    "name": "User Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 4,
                                    "permission": "grp-user-delete",
                                    "name": "User Delete",
                                    "type": "delete",
                                    "access": true
                                },
                                {
                                    id: 5,
                                    "permission": "iam-site-user-linking",
                                    "name": "Site Linking",
                                    "type": "other",
                                    "access": true
                                },
                                {
                                    id: 6,
                                    "permission": "iam-asset-user-linking",
                                    "name": "Asset Linking",
                                    "type": "other",
                                    "access": true
                                },
                                {
                                    id: 7,
                                    "permission": "grp-user-role",
                                    "name": "Role Change",
                                    "type": "other",
                                    "access": true
                                },
                                
                            ]
                        },
                        {
                            id: 5.3,
                            name: "Role",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "iam-user-role-view",
                                    "name": "Role View",
                                    "type": "view",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "iam-user-role-add",
                                    "name": "Role Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "iam-user-role-edit",
                                    "name": "Role Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 4,
                                    "permission": "iam-user-role-delete",
                                    "name": "Role Delete",
                                    "type": "delete",
                                    "access": true
                                },
                                {
                                    id: 5,
                                    "permission": "iam-user-role-change",
                                    "name": "Change Role of User",
                                    "type": "other",
                                    "access": true
                                },
                                {
                                    id: 6,
                                    "permission": "iam-asset-user-visibility",
                                    "name": "User Asset Visibility",
                                    "type": "other",
                                    "access": true
                                },
                                {
                                    id: 7,
                                    "permission": "iam-user-role-visibility-edit",
                                    "name": "Update User Role Visibility",
                                    "type": "other",
                                    "access": true
                                },
                                {
                                    id: 8,
                                    "permission": "iam-user-role-transaction-locking-edit",
                                    "name": " Update Transaction Locking",
                                    "type": "other",
                                    "access": true
                                },
                                
                            ]
                        },
                        {
                            id: 6.3,
                            name: "Role Permission",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "iam-user-role-permission-view",
                                    "name": "Role Permission View",
                                    "type": "view",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "iam-user-role-permission-edit",
                                    "name": "Role Permission Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                
                            ]
                        },
                        {
                            id: 7.3,
                            name: "Group Notification Setting",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "iam-notification-setting-view",
                                    "name": "Group Notification View",
                                    "type": "view",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "iam-notification-setting-update",
                                    "name": "Group Notification Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                
                            ]
                        },
                        {
                            id: 8.3,
                            name: "License Management",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "iam-license-management-view",
                                    "name": "License Management View",
                                    "type": "view",
                                    "access": true
                                },
                               
                            ]
                        },
                        {
                            id: 9.3,
                            name: "Transaction Delay Setting",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "iam-configuration-transaction-setting",
                                    "name": "Transaction Delay Setting Edit",
                                    "type": "edit",
                                    "access": true
                                },
                               
                            ]
                        },
                        {
                            id: 10.3,
                            name: "Ticket Configuration",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "iam-configuration-ticket-setting",
                                    "name": "Ticket Setting Edit",
                                    "type": "edit",
                                    "access": true
                                },
                               
                            ]
                        },
                        {
                            id: 11.3,
                            name: "Taskflow Configuration",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "iam-task-workflow-configuration",
                                    "name": "Taskflow Configuration View",
                                    "type": "view",
                                    "access": true
                                },
                               
                            ]
                        },
                       
                    ]
                },
                {
                    id: 4,
                    name: "Access Shift Counter Log",
                    sub_sections: [
                        {
                            id: 1.4,
                            name: "Counter Log",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "iam-shift-counterlog-add",
                                    "name": "Counterlog Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "iam-shift-counterlog-edit",
                                    "name": "Counterlog Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "iam-shift-counterlog-delete",
                                    "name": "Counterlog Delete",
                                    "type": "delete",
                                    "access": true
                                },
                                {
                                    id: 4,
                                    "permission": "skip_prev_day_cl_prevention",
                                    "name": "Skip Counterlog Prevention for Previous Day Reading",
                                    "type": "other",
                                    "access": true
                                },
                                
                            ]
                        },
                        {
                            id: 2.4,
                            name: "Fuel Feedin",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "iam-fuel-feedin-add",
                                    "name": "Fuel Feedin Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "iam-fuel-feedin-update",
                                    "name": "Fuel Feedin Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "iam-fuel-feedin-delete",
                                    "name": "Fuel Feedin Delete",
                                    "type": "delete",
                                    "access": true
                                },
                                
                            ]
                        },
                        {
                            id: 3.4,
                            name: "Opening Fuel Balance",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "iam-counterlog-fuel-balance-add",
                                    "name": "Opening Fuel Balance Add",
                                    "type": "add",
                                    "access": true
                                },
                                
                                {
                                    id: 2,
                                    "permission": "iam-counterlog-fuel-balance-delete",
                                    "name": "Opening Fuel Balance Delete",
                                    "type": "delete",
                                    "access": true
                                },
                                
                            ]
                        },
                        {
                            id: 4.4,
                            name: "Expense",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "iam-shift-counterlog-expense-add",
                                    "name": "Expense Add",
                                    "type": "add",
                                    "access": true
                                },
                                
                                {
                                    id: 2,
                                    "permission": "iam-asset-expense-update",
                                    "name": "Expense Update",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "iam-asset-expense-delete",
                                    "name": "Expense Delete",
                                    "type": "delete",
                                    "access": true
                                },
                                
                            ]
                        },
                        {
                            id: 5.4,
                            name: "Productivity",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "iam-asset-productivity-add",
                                    "name": "Productivity Add",
                                    "type": "add",
                                    "access": true
                                },
                                
                                {
                                    id: 2,
                                    "permission": "iam-asset-productivity-update",
                                    "name": "Productivity Update",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "iam-asset-productivity-delete",
                                    "name": "Productivity Delete",
                                    "type": "delete",
                                    "access": true
                                },
                                
                            ]
                        },
                        {
                            id: 6.4,
                            name: "Asset Activity",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "iam-asset-activity-add",
                                    "name": "Activity Add",
                                    "type": "add",
                                    "access": true
                                },
                                
                                {
                                    id: 2,
                                    "permission": "iam-asset-activity-update",
                                    "name": "Activity Update",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "iam-asset-activity-delete",
                                    "name": "Activity Delete",
                                    "type": "delete",
                                    "access": true
                                },
                                
                            ]
                        },
                        {
                            id: 7.4,
                            name: "Service Checksheet",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "iam-asset-scs-execute",
                                    "name": "Service Checksheet Add",
                                    "type": "add",
                                    "access": true
                                },
                                
                                {
                                    id: 2,
                                    "permission": "iam-asset-scs-execute-delete",
                                    "name": "Service Checksheet Update",
                                    "type": "delete",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "iam-asset-scs-custom-add",
                                    "name": "Add Custom SCS",
                                    "type": "other",
                                    "access": true
                                },
                                {
                                    id: 4,
                                    "permission": "iam-asset-scs-setting-update",
                                    "name": "SCS Setting",
                                    "type": "other",
                                    "access": true
                                },
                                
                            ]
                        },
                        {
                            id: 8.4,
                            name: "Task",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "iam-task-workflow-execution",
                                    "name": "Task View",
                                    "type": "view",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "iam-asset-task-delete",
                                    "name": "Task Delete",
                                    "type": "delete",
                                    "access": true
                                },
                                
                               
                            ]
                        },
                        {
                            id: 9.4,
                            name: "Downtime",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "downtime-add",
                                    "name": "Downtime Add",
                                    "type": "add",
                                    "access": true
                                },
                                
                                {
                                    id: 2,
                                    "permission": "downtime-delete",
                                    "name": "Downtime Delete",
                                    "type": "delete",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "downtime-edit",
                                    "name": "Downtime Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 4,
                                    "permission": "downtime-view",
                                    "name": "Downtime View",
                                    "type": "view",
                                    "access": true
                                },
                                {
                                    id: 5,
                                    "permission": "downtime-type-manage",
                                    "name": "Downtime Type Manage",
                                    "type": "other",
                                    "access": true
                                },
                                
                            ]
                        },
                    ]
                },
                {
                    id: 5,
                    name: "Group Data",
                    sub_sections: [
                        {
                            id: 1.5,
                            name: "Site",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "iam-site-add",
                                    "name": "Site Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "iam-site-edit",
                                    "name": "Site Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "iam-site-status-change",
                                    "name": "Change Status",
                                    "type": "other",
                                    "access": true
                                },
                                
                            ]
                        },
                        {
                            id: 2.5,
                            name: "Site Address",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "iam-site-address-view",
                                    "name": "Site Address View",
                                    "type": "view",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "iam-site-address-add",
                                    "name": "Site Address Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "iam-site-address-edit",
                                    "name": "Site Address Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 4,
                                    "permission": "iam-site-address-delete",
                                    "name": "Site Address Delete",
                                    "type": "delete",
                                    "access": true
                                },
                                
                                
                            ]
                        },
                        {
                            id: 3.5,
                            name: "Vendor",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "vendor-view",
                                    "name": "Vendor View",
                                    "type": "view",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "vendor-add",
                                    "name": "Vendor Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "vendor-edit",
                                    "name": "Vendor Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 4,
                                    "permission": "vendor-change-status",
                                    "name": "Change Status",
                                    "type": "other",
                                    "access": true
                                },
                                {
                                    id: 5,
                                    "permission": "vendor-manage-address",
                                    "name": "Manage Address",
                                    "type": "other",
                                    "access": true
                                },
                                {
                                    id: 6,
                                    "permission": "vendor-manage-contact",
                                    "name": "Manage Contact",
                                    "type": "other",
                                    "access": true
                                },
                                
                                
                            ]
                        },
                        {
                            id: 4.5,
                            name: "Workstation",
                            permissions: [
                               
                                {
                                    id: 1,
                                    "permission": "iam-workstation-add",
                                    "name": "Workstation Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "iam-workstation-edit",
                                    "name": "Workstation Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                
                            ]
                        },
                        {
                            id: 5.5,
                            name: "Sub Assembly",
                            permissions: [
                               
                                {
                                    id: 1,
                                    "permission": "iam-sub-assembly-add",
                                    "name": "Sub Assembly Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "iam-sub-assembly-edit",
                                    "name": "Sub Assembly Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "iam-sub-assembly-change-status",
                                    "name": "Change Status",
                                    "type": "other",
                                    "access": true
                                },
                                {
                                    id: 4,
                                    "permission": "iam-sub-assembly-asset-manage",
                                    "name": "Asset Linking/Delinking",
                                    "type": "other",
                                    "access": true
                                },

                                
                                {
                                    id: 8,
                                    "permission": "iam-sub-assembly-configuration-manage",
                                    "name": "Configuration Manage",
                                    "type": "other",
                                    "access": true
                                },
                                {
                                    id: 9,
                                    "permission": "iam-sub-assembly-site-list",
                                    "name": "Site List",
                                    "type": "other",
                                    "access": true
                                },
                                {
                                    id: 10,
                                    "permission": "iam-sub-assembly-change-site",
                                    "name": "Change Site",
                                    "type": "other",
                                    "access": true
                                },
                            ]
                        },
                       
                    ]
                },
                {
                    id: 6,
                    name: "Document",
                    sub_sections: [
                        {
                            id: 1.6,
                            name: "Site",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "iam-site-document-view",
                                    "name": "Site Document View",
                                    "type": "view",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "iam-site-document-add",
                                    "name": "Site  Document Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "iam-site-document-download",
                                    "name": "Download",
                                    "type": "other",
                                    "access": true
                                },
                                {
                                    id: 4,
                                    "permission": "iam-site-document-delete",
                                    "name": "Delete",
                                    "type": "delete",
                                    "access": true
                                },
                                
                            ]
                        },
                        {
                            id: 2.6,
                            name: "Shift",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "iam-asset-counterlog-doc-view",
                                    "name": "Shift Document View",
                                    "type": "view",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "iam-asset-counterlog-doc-add",
                                    "name": "Shift Document Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "iam-asset-counterlog-doc-download",
                                    "name": "Download",
                                    "type": "other",
                                    "access": true
                                },
                                {
                                    id: 4,
                                    "permission": "iam-asset-counterlog-doc-delete",
                                    "name": "Delete",
                                    "type": "delete",
                                    "access": true
                                },
                                
                            ]
                        },
                        {
                            id: 3.6,
                            name: "Asset-Document",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "iam-asset-doc-view",
                                    "name": "Asset-Document View",
                                    "type": "view",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "iam-asset-doc-add",
                                    "name": "Asset-Document Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "iam-asset-doc-download",
                                    "name": "Download",
                                    "type": "other",
                                    "access": true
                                },
                                {
                                    id: 4,
                                    "permission": "iam-asset-doc-delete",
                                    "name": "Delete",
                                    "type": "delete",
                                    "access": true
                                },
                                
                            ]
                        },
                        {
                            id: 4.6,
                            name: "Sub Assembly",
                            permissions: [
                            {
                                id: 1,
                                "permission": "sub_assembly_doc_delete",
                                "name": "Document Delete",
                                "type": "delete",
                                "access": true
                            },
                            {
                                id: 2,
                                "permission": "sub_assembly_doc_download",
                                "name": "Document Download",
                                "type": "other",
                                "access": true
                            },
                            {
                                id: 3,
                                "permission": "sub_assembly_doc_view",
                                "name": "Document View",
                                "type": "view",
                                "access": true
                            },
                            {
                                id: 4,
                                "permission": "sub_assembly_doc_add",
                                "name": "Document Add",
                                "type": "add",
                                "access": true
                            },
                        ]
                        },
                        {
                            id: 4.8,
                            name: "Purchase",
                            permissions: [
                            {
                                id: 1,
                                "permission": "isc_purchase_doc_delete",
                                "name": "Document Delete",
                                "type": "delete",
                                "access": true
                            },
                            {
                                id: 2,
                                "permission": "isc_purchase_doc_download",
                                "name": "Document Download",
                                "type": "other",
                                "access": true
                            },
                            {
                                id: 3,
                                "permission": "isc_purchase_doc_view",
                                "name": "Document View",
                                "type": "view",
                                "access": true
                            },
                            {
                                id: 4,
                                "permission": "isc_purchase_doc_add",
                                "name": "Document Add",
                                "type": "add",
                                "access": true
                            },
                        ]
                        }
                    ]
                },
                {
                    id: 7,
                    name: "Inventory Module",
                    sub_sections: [
                        {
                            id: 1.7,
                            name: "Item",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "isc-item-view",
                                    "name": "Item View",
                                    "type": "view",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "isc-item-add",
                                    "name": "Item Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "isc-item-edit",
                                    "name": "Item Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 4,
                                    "permission": "isc-item-opening-balance",
                                    "name": "Item Opening Balance",
                                    "type": "other",
                                    "access": true
                                },
                                {
                                    id: 5,
                                    "permission": "isc-item-Interchangeable-edit",
                                    "name": "Item Interchangeable",
                                    "type": "other",
                                    "access": true
                                },
                                
                            ]
                        },
                        {
                            id: 2.7,
                            name: "Item Tags",
                            permissions: [
                               
                                {
                                    id: 1,
                                    "permission": "isc-item-tag-add",
                                    "name": "Item Tag Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "isc-item-tag-edit",
                                    "name": "Item Tag Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "isc-item-tag-delete",
                                    "name": "Item Tag Delete",
                                    "type": "delete",
                                    "access": true
                                },
                                {
                                    id: 4,
                                    "permission": "isc-item-tag-deactivate",
                                    "name": "Deactivate Tags",
                                    "type": "other",
                                    "access": true
                                },
                               
                            ]
                        },
                        {
                            id: 3.7,
                            name: "Item Manufacturer",
                            permissions: [
                               
                                {
                                    id: 1,
                                    "permission": "isc-item-manufacturer-add",
                                    "name": "Item Manufacturer Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "isc-item-manufacturer-edit",
                                    "name": "Item Manufacturer Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "isc-item-manufacturer-delete",
                                    "name": "Item Manufacturer Delete",
                                    "type": "delete",
                                    "access": true
                                },
                               
                            ]
                        },
                        {
                            id: 4.7,
                            name: "Item UOM",
                            permissions: [
                               
                                {
                                    id: 1,
                                    "permission": "isc-item-uom-add",
                                    "name": "Item UOM Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "isc-item-uom-edit",
                                    "name": "Item UOM Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "isc-item-uom-deactivate",
                                    "name": "Change Status",
                                    "type": "other",
                                    "access": true
                                },
                               
                            ]
                        },
                        {
                            id: 5.7,
                            name: "Warehouse Indent",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "isc-warehouse-indent-view",
                                    "name": "Warehouse Indent View",
                                    "type": "view",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "isc-warehouse-indent-add",
                                    "name": "Warehouse Indent Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "isc-warehouse-indent-edit",
                                    "name": "Warehouse Indent Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 4,
                                    "permission": "isc-warehouse-indent-delete",
                                    "name": "Warehouse Indent Delete",
                                    "type": "delete",
                                    "access": true
                                },
                                {
                                    id: 5,
                                    "permission": "isc-warehouse-indent-status_change",
                                    "name": "Change Status",
                                    "type": "other",
                                    "access": true
                                },
                                 {
                                    id: 6,
                                    "permission": "isc-warehouse-indent-cancel_item",
                                    "name": "Cancel Item",
                                    "type": "other",
                                    "access": true
                                },
                               
                            ]
                        },
                        {
                            id: 6.7,
                            name: "Purchase Requisition",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "isc-purchase-requisition-view",
                                    "name": "Purchase Requisition View",
                                    "type": "view",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "isc-purchase-requisition-add",
                                    "name": "Purchase Requisition Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "isc-purchase-requisition-edit",
                                    "name": "Purchase Requisition Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 4,
                                    "permission": "isc-purchase-requisition-delete",
                                    "name": "Purchase Requisition Delete",
                                    "type": "delete",
                                    "access": true
                                },
                                {
                                    id: 5,
                                    "permission": "isc-purchase-requisition-status-change",
                                    "name": "Change Status",
                                    "type": "other",
                                    "access": true
                                },
                                 {
                                    id: 6,
                                    "permission": "isc-purchase-requisition-cancel_item",
                                    "name": "Cancel Item",
                                    "type": "other",
                                    "access": true
                                },
                                {
                                    id: 7,
                                    "permission": "isc-purchase-requisition-status-manage",
                                    "name": "Workflow Status",
                                    "type": "other",
                                    "access": true
                                },
                               
                            ]
                        },
                        {
                            id: 7.7,
                            name: "Purchase Order",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "isc-po-view",
                                    "name": "Purchase Order View",
                                    "type": "view",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "isc-po-add",
                                    "name": "Purchase Order Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "isc-po-edit",
                                    "name": "Purchase Order Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                
                                {
                                    id: 4,
                                    "permission": "isc-po-status-change",
                                    "name": "Change Status",
                                    "type": "other",
                                    "access": true
                                },
                                 {
                                    id: 5,
                                    "permission": "isc-po-cancel_item",
                                    "name": "Cancel Item",
                                    "type": "other",
                                    "access": true
                                },
                                {
                                    id: 6,
                                    "permission": "isc-po-status-manage",
                                    "name": "Workflow Status",
                                    "type": "other",
                                    "access": true
                                },
                               
                            ]
                        },
                        {
                            id: 8.7,
                            name: "Purchase",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "isc-purchase-view",
                                    "name": "Purchase  View",
                                    "type": "view",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "isc-purchase-add",
                                    "name": "Purchase Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "isc-purchase-edit",
                                    "name": "Purchase Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 4,
                                    "permission": "isc-purchase-delete",
                                    "name": "Purchase Delete",
                                    "type": "delete",
                                    "access": true
                                },
                                
                                {
                                    id: 5,
                                    "permission": "isc-purchase-status-change",
                                    "name": "Change Status",
                                    "type": "other",
                                    "access": true
                                },
                                
                                {
                                    id: 6,
                                    "permission": "isc-purchase-status-manage",
                                    "name": "Workflow Status",
                                    "type": "other",
                                    "access": true
                                },
                               
                            ]
                        },
                        {
                            id: 13.7,
                            name: "Purchase Return",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "isc-purchase-return-view",
                                    "name": "Purchase Return  View",
                                    "type": "view",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "isc-purchase-return-add",
                                    "name": "Purchase Return Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "isc-purchase-return-edit",
                                    "name": "Purchase Return Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 4,
                                    "permission": "isc-purchase-return-delete",
                                    "name": "Purchase Return Delete",
                                    "type": "delete",
                                    "access": true
                                },
                                
                                {
                                    id: 5,
                                    "permission": "isc-purchase-return-status-change",
                                    "name": "Change Status",
                                    "type": "other",
                                    "access": true
                                },
                                
                                {
                                    id: 6,
                                    "permission": "isc-purchase-return-status-manage",
                                    "name": "Workflow Status",
                                    "type": "other",
                                    "access": true
                                },
                               
                            ]
                        },
                        {
                            id: 14.7,
                            name: "Item Demand",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "isc_item_demand_view",
                                    "name": "Item Demand  View",
                                    "type": "view",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "isc_item_demand_add",
                                    "name": "Item Demand Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "isc_item_demand_add",
                                    "name": "Item Demand Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 4,
                                    "permission": "isc_item_demand_delete",
                                    "name": "Item Demand Delete",
                                    "type": "delete",
                                    "access": true
                                },
                                
                                {
                                    id: 5,
                                    "permission": "isc_item_demand_change_status",
                                    "name": "Change Status",
                                    "type": "other",
                                    "access": true
                                },
                                
                                {
                                    id: 6,
                                    "permission": "isc_item_demand_manage_status",
                                    "name": "Workflow Status",
                                    "type": "other",
                                    "access": true
                                },
                                {
                                    id: 7,
                                    "permission": "isc_item_demand_item_cancel",
                                    "name": "Cancel Item",
                                    "type": "other",
                                    "access": true
                                },
                               
                            ]
                        },
                        {
                            id: 9.7,
                            name: "Allocation",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "isc-allocation-view",
                                    "name": "Allocation  View",
                                    "type": "view",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "isc-allocation-add",
                                    "name": "Allocation Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "isc-allocation-edit",
                                    "name": "Allocation Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 4,
                                    "permission": "isc-allocation-delete",
                                    "name": "Allocation Delete",
                                    "type": "delete",
                                    "access": true
                                },
                                
                                {
                                    id: 5,
                                    "permission": "isc-allocation-status-change",
                                    "name": "Change Status",
                                    "type": "other",
                                    "access": true
                                },
                                
                                {
                                    id: 6,
                                    "permission": "isc-allocation-status-manage",
                                    "name": "Workflow Status",
                                    "type": "other",
                                    "access": true
                                },
                                {
                                    id: 7,
                                    "permission": "isc-allocation-item-cancel",
                                    "name": "Cancel Item",
                                    "type": "other",
                                    "access": true
                                },
                               
                            ]
                        },
                        {
                            id: 10.7,
                            name: "Consumption",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "isc-consumption-view",
                                    "name": "Consumption  View",
                                    "type": "view",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "isc-consumption-add",
                                    "name": "Consumption Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "isc-consumption-edit",
                                    "name": "Consumption Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 4,
                                    "permission": "isc-consumption-delete",
                                    "name": "Consumption Delete",
                                    "type": "delete",
                                    "access": true
                                },
                                
                                {
                                    id: 5,
                                    "permission": "isc-consumption-status-change",
                                    "name": "Change Status",
                                    "type": "other",
                                    "access": true
                                },
                                
                                {
                                    id: 6,
                                    "permission": "isc-consumption-status-manage",
                                    "name": "Workflow Status",
                                    "type": "other",
                                    "access": true
                                },
                               
                            ]
                        },
                        {
                            id: 11.7,
                            name: "Inventory Transfer",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "isc-inventory_transfer-view",
                                    "name": "Inventory Transfer  View",
                                    "type": "view",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "isc-inventory_transfer-add",
                                    "name": "Inventory Transfer Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "isc-inventory_transfer-edit",
                                    "name": "Inventory Transfer Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 4,
                                    "permission": "isc-inventory_transfer-delete",
                                    "name": "Inventory Transfer Delete",
                                    "type": "delete",
                                    "access": true
                                },
                                
                                {
                                    id: 5,
                                    "permission": "isc-inventory_transfer-status-change",
                                    "name": "Change Status",
                                    "type": "other",
                                    "access": true
                                },
                                
                                {
                                    id: 6,
                                    "permission": "isc-inventory_transfer-status-manage",
                                    "name": "Workflow Status",
                                    "type": "other",
                                    "access": true
                                },
                               
                            ]
                        },
                        {
                            id: 12.7,
                            name: "Inventory Adjustment",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "isc-inventory-adjustment-view",
                                    "name": "Inventory Transfer  View",
                                    "type": "view",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "isc-inventory-adjustment-add",
                                    "name": "Inventory Transfer Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "isc-inventory-adjustment-edit",
                                    "name": "Inventory Transfer Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 4,
                                    "permission": "isc-inventory-adjustment-delete",
                                    "name": "Inventory Transfer Delete",
                                    "type": "delete",
                                    "access": true
                                },
                                
                                {
                                    id: 5,
                                    "permission": "isc-inventory-adjustment-status-change",
                                    "name": "Change Status",
                                    "type": "other",
                                    "access": true
                                },
                                
                                {
                                    id: 6,
                                    "permission": "isc-inventory-adjustment-status-manage",
                                    "name": "Workflow Status",
                                    "type": "other",
                                    "access": true
                                },
                               
                            ]
                        }
                       
                    ]
                },
                {
                    id: 8,
                    name: "HR Module",
                    sub_sections: [
                        {
                            id: 1.8,
                            name: "Employee",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "hrm-employee-view",
                                    "name": "View",
                                    "type": "view",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "hrm-employee-add",
                                    "name": "Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "hrm-employee-update",
                                    "name": "Edit",
                                    "type": "edit",
                                    "access": true
                                },
                               
                                
                            ]
                        },
                        {
                            id: 2.8,
                            name: "Employee Tags",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "hrm-employee-tag-delete",
                                    "name": "Delete",
                                    "type": "delete",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "hrm-employee-tag-add",
                                    "name": "Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "hrm-employee-tag-edit",
                                    "name": "Edit",
                                    "type": "edit",
                                    "access": true
                                },
                               
                                
                            ]
                        },
                        {
                            id: 3.8,
                            name: "Employee Compliance",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "hrm_compliance_delete",
                                    "name": "Delete",
                                    "type": "delete",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "hrm_compliance_add",
                                    "name": "Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "hrm_compliance_edit",
                                    "name": "Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 4,
                                    "permission": "hrm_compliance_view",
                                    "name": "View",
                                    "type": "view",
                                    "access": true
                                },
                                {
                                    id: 5,
                                    "permission": "hrm_compliance_view_unmasked",
                                    "name": "View Imp Detail",
                                    "type": "other",
                                    "access": true
                                },
                                {
                                    id: 6,
                                    "permission": "hrm_compliance_doc_view",
                                    "name": "Document View",
                                    "type": "other",
                                    "access": true
                                },
                                {
                                    id: 7,
                                    "permission": "hrm_compliance_doc_add",
                                    "name": "Document Add",
                                    "type": "other",
                                    "access": true
                                },
                                {
                                    id: 8,
                                    "permission": "hrm_compliance_doc_delete",
                                    "name": "Document Delete",
                                    "type": "other",
                                    "access": true
                                },
                                {
                                    id: 9,
                                    "permission": "hrm_compliance_doc_download",
                                    "name": "Document Download",
                                    "type": "other",
                                    "access": true
                                }
                               
                                
                            ]
                        },
                        
                    ]
                },
                {
                    id: 9,
                    name: "General Permissions",
                    sub_sections: [
                        {
                            id: 1.9,
                            name: "Fuel Ledger",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "iam-fuel-station-ledger",
                                    "name": "View",
                                    "type": "view",
                                    "access": true
                                },
                                
                            ]
                        },
                        {
                            id: 2.9,
                            name: "Receive Fuel (Fuel Station)",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "iam-fuel-station-fuel-receive",
                                    "name": "Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "iam-fuel-station-fuel-receive-edit",
                                    "name": "Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "iam-fuel-station-fuel-receive-delete",
                                    "name": "Delete",
                                    "type": "delete",
                                    "access": true
                                },
                                
                            ]
                        },
                        {
                            id: 3.9,
                            name: "Issue Fuel (Fuel Station)",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "iam-fuel-station-fuel-issue",
                                    "name": "Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "iam-fuel-station-fuel-issue-edit",
                                    "name": "Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "iam-fuel-station-fuel-issue-delete",
                                    "name": "Delete",
                                    "type": "delete",
                                    "access": true
                                },
                                
                            ]
                        },
                        {
                            id: 4.9,
                            name: "Fuel Transfer Within Fuel Station",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "iam-fuel-station-fuel-transfer",
                                    "name": "Add",
                                    "type": "add",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "iam-fuel-station-fuel-transfer-edit",
                                    "name": "Edit",
                                    "type": "edit",
                                    "access": true
                                },
                                {
                                    id: 3,
                                    "permission": "iam-fuel-station-fuel-transfer-delete",
                                    "name": "Delete",
                                    "type": "delete",
                                    "access": true
                                },
                                
                            ]
                        }
                       
                    ]
                },
                {
                    id: 10,
                    name: "Integration",
                    sub_sections: [
                        {
                            id: 1.11,
                            name: "Master Data",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "hg_master_data_view",
                                    "name": "View",
                                    "type": "view",
                                    "access": true
                                },
                                {
                                    id: 2,
                                    "permission": "hg_master_data_process_asset",
                                    "name": "Process to Add Asset",
                                    "type": "other",
                                    "access": true
                                },
                                
                            ]
                        },                       
                    ]
                },
                {
                    id: 11,
                    name: "Logistic Permissions",
                    sub_sections: [
                        {
                            id: 1.10,
                            name: "Logistic Data",
                            permissions: [
                                {
                                    id: 1,
                                    "permission": "logistic_view",
                                    "name": "View",
                                    "type": "view",
                                    "access": true
                                },                                
                                {
                                    id: 2,
                                    "permission": "logistic_add",
                                    "name": "Add",
                                    "type": "add",
                                    "access": true
                                },                                
                                {
                                    id: 3,
                                    "permission": "logistic_edit",
                                    "name": "Edit",
                                    "type": "edit",
                                    "access": true
                                },    
                                {
                                    id: 4,
                                    "permission": "logistic_doc_add",
                                    "name": "Logistic Document Add",
                                    "type": "other",
                                    "access": true
                                },                            
                                {
                                    id: 5,
                                    "permission": "logistic_doc_view",
                                    "name": "Logistic Document View",
                                    "type": "other",
                                    "access": true
                                },                            
                                {
                                    id: 6,
                                    "permission": "logistic_doc_download",
                                    "name": "Logistic Document Download",
                                    "type": "other",
                                    "access": true
                                },                            
                                {
                                    id: 7,
                                    "permission": "logistic_doc_delete",
                                    "name": "Logistic Document Delete",
                                    "type": "other",
                                    "access": true
                                },                            
                            ]
                        },                       
                        {
                            id: 2.10,
                            name: "Logistic Configuration",
                            permissions: [                              
                            ]
                        },                       
                        {
                            id: 2.10,
                            name: "Logistic Manage Workflow",
                            permissions: [                              
                            ]
                        },                       
                    ]
                },
                
                
                
            ]
        }
   }


   componentDidMount() {
        this.initilaizeData(this.props);
   }

    componentWillReceiveProps(nextProps) {
        if (nextProps.roleData !== this.props.roleData) {
            this.initilaizeData(nextProps);
        }
    }


    initilaizeData          =       (pr)        =>      {
        if(pr && pr.roleData){
            this.getAllPermissionsList(pr.roleData.id);
            this.setState({
                roleData : pr.roleData
            })
        }
    }
    
    getAllPermissionsList = (id) => {
        this.setState({ listing_loading: true ,selectedPermissions :[], subSectionFullAccess: {},
            moduleFullAccess: {},      // Initial state for module full access checkboxes
            overallFullAccess: false});
    
        HttpAPICall.withAthorization('GET', TapApiUrls.APP_URL + '/group/role/permission/' + id, this.props.access_token, {}, {}, (response) => {
            if (response.data && response.data.all_permissions && response.data.all_permissions.length > 0) {
                let allTransactionLockingPermission = response.data.all_permissions.filter(i => i.transaction_locking == "Y")
                let allTransactionList = allTransactionLockingPermission.map(value => Object.values(value).filter(i => i !== "Y").map((acc, curr) => { return acc }))
                let allTransactionLockingList = allTransactionList.flat()
    
                // Get all the permissions
                let allPermissions = response.data && response.data.data && response.data.data.length > 0 ? response.data.data : []
    
                // Update the state of selected permissions
                this.setState({
                    selectedPermissions: allPermissions,
                    allTransactionLockingList: allTransactionLockingList
                },() => {
                    this.updateFullAccessCheckboxes(allPermissions);
                });
    
                // Update the state of full access checkboxes based on the permissions
                
            }
        }).then(() => this.setState({ listing_loading: false }));
    }
    
    updateFullAccessCheckboxes(selectedPermissions) {
        // Loop through modules and subsections to determine full access checkboxes
        let newModuleFullAccess = {};
        let newSubSectionFullAccess = {};
    
        this.state.permissions.forEach(module => {
            module.sub_sections.forEach(ss => {
                const areAllSubSectionPermissionsSelected = ss.permissions.every(perm => selectedPermissions.includes(perm.permission));
                newSubSectionFullAccess[ss.id] = areAllSubSectionPermissionsSelected;
            });
    
            const isAllSubSectionsFullAccessChecked = module.sub_sections.every(ss => newSubSectionFullAccess[ss.id]);
            newModuleFullAccess[module.id] = isAllSubSectionsFullAccessChecked;
        });
    
        const isAllModulesFullAccessChecked = this.state.permissions.every(module => newModuleFullAccess[module.id]);
    
        this.setState({
            moduleFullAccess: newModuleFullAccess,
            subSectionFullAccess: newSubSectionFullAccess,
            overallFullAccess: isAllModulesFullAccessChecked
        });
    }
    
    isPermissionSelected(permission) {
        return this.state.selectedPermissions.includes(permission);
    }

    isFullAccessChecked(subSectionId) {
        return this.state.subSectionFullAccess[subSectionId] || false;
    }


    toggleFullAccess(subSectionId, moduleId) {
        this.setState(prevState => {
            const selectedPermissions = [...prevState.selectedPermissions];
            const module = this.state.permissions.find(p => p.id === moduleId);
    
            if (!module) return;
    
            const subSection = module.sub_sections.find(ss => ss.id === subSectionId);
    
            if (!subSection) return;
    
            // Check if all subSection's permissions are selected
            const isSubSectionFullAccess = subSection.permissions.every(perm => selectedPermissions.includes(perm.permission));
    
            // Update the selectedPermissions based on the full access state
            if (isSubSectionFullAccess) {
                subSection.permissions.forEach(perm => {
                    const index = selectedPermissions.indexOf(perm.permission);
                    selectedPermissions.splice(index, 1);
                });
            } else {
                subSection.permissions.forEach(perm => {
                    if (!selectedPermissions.includes(perm.permission)) {
                        selectedPermissions.push(perm.permission);
                    }
                });
            }
    
            // Update the state of the subSection's full access checkbox
            const newSubSectionFullAccess = { ...prevState.subSectionFullAccess };
            newSubSectionFullAccess[subSectionId] = !isSubSectionFullAccess;
    
            // Update the state of the module's full access checkbox
            const isAllSubSectionsFullAccessChecked = module.sub_sections.every(ss =>
                newSubSectionFullAccess[ss.id]
            );
    
            this.setState({
                selectedPermissions,
                subSectionFullAccess: newSubSectionFullAccess,
                moduleFullAccess: isAllSubSectionsFullAccessChecked
            });
        });
    }


    togglePermission(permission) {
        this.setState(prevState => {
            const selectedPermissions = [...prevState.selectedPermissions];
            
            if (selectedPermissions.includes(permission)) {
                const index = selectedPermissions.indexOf(permission);
                selectedPermissions.splice(index, 1);
            } else {
                selectedPermissions.push(permission);
            }
    
            // Check if all permissions in a subSection are selected
            this.state.permissions.forEach(module => {
                module.sub_sections.forEach(ss => {
                    const areAllSubSectionPermissionsSelected = ss.permissions.every(perm => selectedPermissions.includes(perm.permission));
    
                    // Update the state of the subSection's full access checkbox
                    this.setState(prevState => {
                        const newSubSectionFullAccess = { ...prevState.subSectionFullAccess };
                        newSubSectionFullAccess[ss.id] = areAllSubSectionPermissionsSelected;
    
                        return { subSectionFullAccess: newSubSectionFullAccess };
                    });
                });
    
                // Check if all subSections' full access are selected
                const isAllSubSectionsFullAccessChecked = module.sub_sections.every(ss => this.state.subSectionFullAccess[ss.id]);
    
                // Update the state of the module's full access checkbox
                this.setState(prevState => {
                    const newModuleFullAccess = { ...prevState.moduleFullAccess };
                    newModuleFullAccess[module.id] = isAllSubSectionsFullAccessChecked;
    
                    return { moduleFullAccess: newModuleFullAccess };
                });
            });
    
            // Check if all modules' full access are selected
            const isAllModulesFullAccessChecked = this.state.permissions.every(module =>
                this.state.moduleFullAccess[module.id]
            );
    
            // Update the state of the overall full access checkbox
            this.setState({
                overallFullAccess: isAllModulesFullAccessChecked
            });
    
            return { selectedPermissions };
        });
    }
    
    
    updatePermission(e){
        e.preventDefault();
    
         let  frmData = {
             role_id : this.state.roleData.id,
             permission : this.state.selectedPermissions
        }; 
        this.setState({saveFormSubmitting : true})
        axios({
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.props.access_token},
            data: {...frmData},
            url: process.env.REACT_APP_API_SERVER + '/group/role/permission'
        }).then((response) => {
            toast.success(response.data?.msg, {position: toast.POSITION.TOP_CENTER})
            this.getAllPermissionsList(this.state?.roleData?.id);
            this.setState({saveFormSubmitting : false})
        }).catch((err) => {
            this.setState({saveFormSubmitting : false})
            toast.error(err.response?.data?.message, {position: toast.POSITION.TOP_CENTER})});

    }
   
    render() {
       let permission =  this.state.allPermissionList;
       let hg_permission = this.props.group_permission.group_sub_modules.find(s => s.sub_module == "hg_sap") ? true :  false;
       return (
           <Ax>
               <Helmet><title>Role - Asset Permission</title></Helmet>
               {this.state.permission_loading ? <div className='text-center'><Loader /></div>
                   : <form className="bg-white px-2" onSubmit={this.updatePermission.bind(this)} id="permissionUpdateForm">
                       <div className="tab-pane show active " id="permission" role="tabpanel" aria-labelledby="permission-tab">
                               <div className="tab_content_wrapper my-2"><span className="content_heading">Permission Details</span></div>
                               {this.state.permissions && this.state.permissions.filter(ps => ps.id !== 10 || (ps.id === 10 && hg_permission)).map((ps, key) => {
                                   return (<table className="table table-bordered table-hover bg-white mb-3" key="key">
                                       <thead className="table-secondary">
                                           <tr>
                                               <th scope="col" style={{ width: "30%" }}>{ps.name}</th>
                                               <th scope="col" style={{ width: "10%" }} className="text-center">Full Access</th>
                                               <th scope="col" style={{ width: "10%" }} className="text-center">View</th>
                                               <th scope="col" style={{ width: "10%" }} className="text-center">Add</th>
                                               <th scope="col" style={{ width: "10%" }} className="text-center" >Edit</th>
                                               <th scope="col" style={{ width: "10%" }} className="text-center">Delete</th>
                                               <th scope="col" style={{ width: "20%" }} className="text-center">More Permissions</th>
                                           </tr>
                                       </thead>
                                       <tbody>

                                           {ps.sub_sections && ps.sub_sections.length > 0 && ps.sub_sections.map(module => (
                                               <tr key={module.id} className="permission_row">
                                                   <td>{module.name}</td>
                                                   <td className="text-center">
                                                       <label>
                                                           <input
                                                               type="checkbox"
                                                               checked={this.isFullAccessChecked(module.id)}
                                                               onChange={() => this.toggleFullAccess(module.id, ps.id)}
                                                           />
                                                       </label>
                                                   </td>
                                                   <td className="text-center">
                                                       {module.permissions.map(perm => {
                                                           if (perm.type === "view") {
                                                               return (
                                                                   <label key={perm.id}>
                                                                       <input
                                                                           type="checkbox"
                                                                           checked={this.isPermissionSelected(perm.permission)}
                                                                           onChange={() => this.togglePermission(perm.permission)}
                                                                       />
                                                                        {this.state.allTransactionLockingList.includes(perm.permission) ? <span className="require require_alignment"> </span> : null}
                                                                   </label>
                                                               );
                                                           }
                                                           return null;
                                                       })}
                                                   </td>
                                                   <td className="text-center">
                                                       {module.permissions.map(perm => {
                                                           if (perm.type === "add") {
                                                               return (
                                                                   <label key={perm.id}>
                                                                       <input
                                                                           type="checkbox"
                                                                           checked={this.isPermissionSelected(perm.permission)}
                                                                           onChange={() => this.togglePermission(perm.permission)}
                                                                       />
                                                                       {this.state.allTransactionLockingList.includes(perm.permission) ? <span className="require require_alignment"> </span> : null}
                                                                   </label>
                                                               );
                                                           }
                                                           return null;
                                                       })}
                                                   </td>
                                                   <td className="text-center">
                                                       {module.permissions.map(perm => {
                                                           if (perm.type === "edit") {
                                                               return (
                                                                   <label key={perm.id}>
                                                                       <input
                                                                           type="checkbox"
                                                                           checked={this.isPermissionSelected(perm.permission)}
                                                                           onChange={() => this.togglePermission(perm.permission)}
                                                                       />
                                                                       {this.state.allTransactionLockingList.includes(perm.permission) ? <span className="require require_alignment"> </span> : null}
                                                                   </label>
                                                               );
                                                           }
                                                           return null;
                                                       })}
                                                   </td>
                                                   <td className="text-center">
                                                       {module.permissions.map(perm => {
                                                           if (perm.type === "delete") {
                                                               return (
                                                                   <label key={perm.id}>
                                                                       <input
                                                                           type="checkbox"
                                                                           checked={this.isPermissionSelected(perm.permission)}
                                                                           onChange={() => this.togglePermission(perm.permission)}
                                                                       />
                                                                       {this.state.allTransactionLockingList.includes(perm.permission) ? <span className="require require_alignment"> </span> : null}
                                                                   </label>
                                                               );
                                                           }
                                                           return null;
                                                       })}
                                                   </td>
                                                   <td className="text-center">
                                                       {module.permissions && module.permissions.length > 0 && module.permissions.find(perm => perm.type === "other") && (
                                                           <Ax>
                                                            <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="action-btn">
                                                                   <tapIcon.FontAwesomeIcon icon={tapIcon.faPlusCircle} />
                                                               </button>
                                                               <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                               {module.permissions.map(perm => {
                                                                   if (perm.type === "other") {
                                                                       return (
                                                                        <li className="dropdown-item" style={{ display: "flex" }}>
                                                                           <label key={perm.id}>
                                                                               <input
                                                                                   type="checkbox"
                                                                                   checked={this.isPermissionSelected(perm.permission)}
                                                                                   onChange={() => this.togglePermission(perm.permission)}
                                                                               />
                                                                               {this.state.allTransactionLockingList.includes(perm.permission) ? <span className="require require_alignment"> </span> : null}
                                                                                <span className="mx-2" >{perm.name}</span>
                                                                           </label>
                                                                           </li>
                                                                       );
                                                                   }
                                                                   return null;
                                                               })}

                                                            </ul>
                                                           </Ax>
                                                       )}
                                                   </td>
                                               </tr>
                                           ))}
                                        
                                          

                                       </tbody>
                                   </table>)
                               })}
                               <div className="text-end mb-3">
                                   <span className="small text-danger"> * Transaction Locking applicable in these permissions</span></div>
                               <div className="float-end">
                                   <button type="submit" disabled={this.state.saveFormSubmitting} className="btn btn-primary mb-2">
                                       Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                                   </button>
                               </div>
                       </div>

                   </form>}
           </Ax>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app.acl_info.permissions,
        group_permission        :   state.app.acl_info
    };
};


export default connect(mapStateToProps)(AssetPermission);