import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Ax from '../../components/hoc/Ax';
import loginStyle from './Login.module.css';
import TappetLogo from '../../assets/img/tappetbox.png';
import MobileLogo from '../../assets/img/tappet-mobile-logo.jpg';
import whiteLogo from '../../assets/img/logo-white.png';
import AlertErrorMsg from '../../components/ui/alert/AlertErrorMsg';
import * as actions from '../../store/actions/index';
import tapIcon from "../../services/TapIcon"
import { APP_BASE_URL, ISC_API_BASE_URL, INVENTORY_API_BASE_URL_2, ORG_API_BASE_URL, ORG_API_BASE_URL_2 } from '../../services/TapApiUrls';

class Login extends React.Component {
    constructor() {
        super();

        this.groupVerifyFormInitialState         =   {
            'group_id'                      :   '',
            'mobile'                        :   '',
            'password'                      :   '',
            'otp'                           :   '',
            'password_confirmation'         :   ''
        };

        this.state                      =   {
            goto_top_visible                :   false,
            form                            :   'loginProcessWithPasswordForm',
            groupVerifyForm                 :   this.groupVerifyFormInitialState,
            groupVerifyFormSubmitting       :   false,
            loginFormErrorMsg               :   null,
            loginFormSuccessMsg             :   null,
            groupUserCanChange              :   true,
            groupUserLoginFormSubmitting    :   false,
            loginProcessWith                :   null,
            forgotPasswordProcess           :   false,
            forgotPasswordForm              :   {...this.groupVerifyFormInitialState, step : 'user_verify'},
            forgotPasswordFormSubmitting    :   false,
            countdownTimer                  :    20, 
            isResendEnabled                 :   true
        };

        this.check_group_url                =   ORG_API_BASE_URL_2 + '/check_group';
        this.login_otp_send_url             =   ORG_API_BASE_URL_2 + '/login_otp_send';
        this.login_url                      =   ORG_API_BASE_URL_2 + '/login';
        this.login_with_otp_url             =   ORG_API_BASE_URL_2 + '/login_with_otp';
        this.forgot_password_verify_otp_url =   APP_BASE_URL + '/forgot_password_verify_otp';
        this.forgot_password_change_password_url =   APP_BASE_URL + '/forgot_password_change_password';
        this.forgot_password_send_otp_url   =   APP_BASE_URL + '/forgot_password_send_otp';

    }

    componentDidMount() {
        window.addEventListener('scroll', this.toggleVisibility);
    }

    scrollToTop                         =   ()  =>  window.scrollTo({ top: 0, behavior: "smooth" })
    toggleVisibility                    =   ()  =>  this.setState({ goto_top_visible: window.pageYOffset > 200 })

    formInputHandler                    =   (e, key = 'groupVerifyForm')  =>  {
        if(key && e.target && e.target.name) {
            this.setState({ [key]: { ...this.state[key], [e.target.name] : e.target.value } });
        }
        this.setState({loginFormErrorMsg : null});
    }

    groupVerifyFormSubmit               =   (event, action = 'password')  =>  {
        //errors = CustomValdation(this.state.groupVerifyForm, [{'group_id' : 'required', 'mobile' : 'required|min:10|max:10'}]);
        let errors                  =   [];
        if(this.state.groupVerifyForm.group_id.trim() == '') {
            errors                  =   [...errors, 'Please enter Group Id'];
        }
        if(this.state.groupVerifyForm.mobile.trim() == '') {
            errors                  =   [...errors, 'Please enter Mobile No.'];
        }
        const pattern = /^\d+$/;
        if(!pattern.test(this.state.groupVerifyForm.mobile.trim())) {
            errors                  =   [...errors, 'Please enter valid Mobile No.'];
        }
        if (this.state.groupVerifyForm.mobile.trim().length < 10 ||  this.state.groupVerifyForm.mobile.trim().length > 10) {
            errors                  =   [...errors, 'Please enter valid Mobile No. (Only 10 Digit)'];
        }
        if(errors.length > 0) {
            let errorMsg            =   errors[0];
            this.setState({loginFormErrorMsg : errorMsg});
        } else {
            this.setState({loginFormErrorMsg : null});
            this.setState({groupVerifyFormSubmitting : true});
            axios({
                method: 'GET',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                params:   {...this.state.groupVerifyForm, device_token : 'WebReactInvenory', module : process.env.REACT_APP_MODULE, source : 'app',action:action},
                url: this.check_group_url
            })
            .then((response) => {
                //Only readonly GroupId + Mobile
                this.setState({groupUserCanChange : false});
                if(action == 'forgot_password'){
                   this.forgetPasswordStepsHandler()
                }else{
                    if(action == 'otp') {
                        //Send OTP to Login :-
                        return axios({
                            method: 'POST',
                            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                            params:   {...this.state.groupVerifyForm, device_token : 'WebReactInvenory', module : process.env.REACT_APP_MODULE, source : 'app'},
                            url: this.login_otp_send_url
                        }).then((response) => {
                            this.setState({loginProcessWith : action});
                            toast.success('OTP Send Successfully', {position: toast.TOP_RIGHT});
                        });
                    } else {
                        if(response.data && response.data.screen && (response.data.screen === 'otp_verify')) {
                            action      =   'otp';
                        }
                        this.setState({loginProcessWith : action});
                        toast.success(response.data.message, {position: toast.POSITION.TOP_RIGHT});
                    }
                }
            })
            .catch((err) => {
                if(err && err.response && err.response.data && err.response.data.message) {
                    this.setState({loginFormErrorMsg : err.response.data.message});
                }
            }).then(() => {
                this.setState({groupVerifyFormSubmitting : false});
            });
        }
    }

    groupUserLoginFormSubmit            =   (event)  =>  {
        let errors                          =   [];
        if(this.state.loginProcessWith == 'password') {
            if (this.state.groupVerifyForm.password.trim().length < 4) {
                errors                  =   [...errors, 'Password must be more than 4 characters'];
            }
        }
        if(this.state.loginProcessWith == 'otp' || this.state.loginProcessWith == 'forgot_password_otp') {
            if (this.state.groupVerifyForm.otp.trim().length < 4 ||  this.state.groupVerifyForm.otp.trim().length > 6) {
                errors                  =   [...errors, 'OTP must be between 4 to 6 characters'];
            }
        }
        if(this.state.loginProcessWith == 'forgot_password_confirmation') {
            if (this.state.groupVerifyForm.password_confirmation.trim().length < 4) {
                errors                  =   [...errors, 'Password must be more than 4 characters'];
            }
        }
        if(errors.length > 0) {
            let errorMsg            =   errors[0];
            this.setState({loginFormErrorMsg : errorMsg});
        } else {
           if(this.state.loginProcessWith == 'forgot_password_otp'){
                this.forgetPasswordStepsHandler('2')
           }else if(this.state.loginProcessWith == 'forgot_password_confirmation'){
             this.forgetPasswordStepsHandler('3')
           }else {
                let loginRequest        =   {
                    method  : 'POST',
                    headers : { 'Accept': 'application/json', 'Content-Type': 'application/json' }
                };
                if(this.state.loginProcessWith == 'password') {
                    loginRequest        =   { ...loginRequest,
                        params  :   {...this.state.groupVerifyForm, device_token : 'WebReactInvenory', module : process.env.REACT_APP_MODULE, source : 'web'},
                        url: this.login_url
                    };
                }
                if(this.state.loginProcessWith == 'otp') {
                    loginRequest        =   { ...loginRequest,
                        params:   {...this.state.groupVerifyForm, device_token : 'WebReactInvenory', module : process.env.REACT_APP_MODULE, source : 'web'},
                        url: this.login_with_otp_url
                    };
                }
                if(loginRequest.url) {
                    this.setState({groupUserLoginFormSubmitting : true});
                    //Auth Action Start :-
                    this.props.onAuthStart();
                    axios(loginRequest)
                    .then((response) => {
                        //Login SuccessFully Now Put Access Token In Redux Data Action Success :-
                        this.props.onAuthSucess(response.data);
                        this.props.applicationInitLoading(response.data.access_token);
                    })
                    .catch((err) => {
                        this.props.onAuthFail(err);
                        if(err && err.response && err.response.data && err.response.data.message) {
                            this.setState({loginFormErrorMsg : err.response.data.message});
                        }
                    }).then(() => {
                        this.setState({groupUserLoginFormSubmitting : false});
                    });
                }
           }
        }
    }

    loginFormSubmitHandler              =   (event, action='password') =>  {
        event.preventDefault();
        if(this.state.loginProcessWith == 'otp' || this.state.loginProcessWith == 'password') {
            this.groupUserLoginFormSubmit(event);
        } else {
            this.groupVerifyFormSubmit(event, action);
        }
    }

    forgetPasswordFormSubmitHandler             =   (event) =>  {
        event.preventDefault();
        
        if(this.state.loginProcessWith == 'forgot_password_otp' || this.state.loginProcessWith == 'forgot_password_confirmation') {
            this.groupUserLoginFormSubmit(event);
        } else {
            this.groupVerifyFormSubmit(event, 'forgot_password');
        }
      
    }

    forgetPasswordStepsHandler      =       (step='1')      =>      {
       
       if(step == '1'){
            axios({
                method: 'GET',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                params:   {...this.state.groupVerifyForm, device_token : 'WebReactInvenory', module : process.env.REACT_APP_MODULE, source : 'app'},
                url: this.forgot_password_send_otp_url
            }).then((response) => {
                this.setState({loginProcessWith : "forgot_password_otp"});
                toast.success('OTP Send Successfully', {position: toast.TOP_RIGHT});
            });
       }else if(step == '2'){
            let loginRequest        =   {
                method  : 'GET',
                headers : { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                params  :   {...this.state.groupVerifyForm, device_token : 'WebReactInvenory', module : process.env.REACT_APP_MODULE, source : 'web'},
                url: this.forgot_password_verify_otp_url
            };
           this.setState({ groupUserLoginFormSubmitting: true });
           //Auth Action Start :-
          
           axios(loginRequest)
               .then((response) => {
                   //Login SuccessFully Now Put Access Token In Redux Data Action Success :-
                  this.setState({
                         loginProcessWith     :   'forgot_password_confirmation',
                         groupVerifyForm      :     {...this.state.groupVerifyForm,password : ''}
                  })
               })
               .catch((err) => {
                
                   if (err && err.response && err.response.data && err.response.data.message) {
                       this.setState({ loginFormErrorMsg: err.response.data.message });
                   }
               }).then(() => {
                   this.setState({ groupUserLoginFormSubmitting: false });
               });
       
       }else{
           let loginRequest = {
               method: 'GET',
               headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
               params: { ...this.state.groupVerifyForm, device_token: 'WebReactInvenory', module: process.env.REACT_APP_MODULE, source: 'web' },
               url: this.forgot_password_change_password_url
           };
           this.setState({ groupUserLoginFormSubmitting: true });
           //Auth Action Start :-

           axios(loginRequest)
               .then((response) => {
                   //Login SuccessFully Now Put Access Token In Redux Data Action Success :-
                   this.setState({
                       loginProcessWith         :   'password',
                       forgotPasswordProcess   :   false,
                       groupVerifyForm      :     {...this.state.groupVerifyForm,password : ''}
                   })
                   toast.success('Password Changed Successfully', {position: toast.TOP_RIGHT});
               })
               .catch((err) => {
                    
                   if (err && err.response && err.response.data && err.response.data.message) {
                       this.setState({ loginFormErrorMsg: err.response.data.message });
                   }
               }).then(() => {
                   this.setState({ groupUserLoginFormSubmitting: false });
               });
       }
    }

    startCountdown = () => {
        this.setState({ isResendEnabled: false });
      
        const countdownInterval = setInterval(() => {
          this.setState((prevState) => ({
            countdownTimer: prevState.countdownTimer - 1,
          }));
      
          if (this.state.countdownTimer === 0) {
            clearInterval(countdownInterval);
            this.setState({ countdownTimer: 20, isResendEnabled: true });
          }
        }, 1000);
    };

    topHeader                           =   ()  =>  {
        return (<nav className={loginStyle.nav_top}>
                    <div className={['container'].join(' ')} >
                        <div className="row">
                            <div className="col-md-7">
                                <a className={loginStyle.social_icon} target="_blank" href="https://www.facebook.com/tappetbox/?ref=aymt_homepage_panel">
                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faFacebookF} />
                                </a>
                                <a className={loginStyle.social_icon} target="_blank" href="https://twitter.com/tappetbox">
                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faTwitter} />
                                </a>
                                <a className={loginStyle.social_icon} target="_blank" href="https://www.linkedin.com/company/tappetbox">
                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faLinkedinIn} />
                                </a>
                                <a className={loginStyle.social_icon} target="_blank" href="https://www.youtube.com/channel/UCq0x42wA3zUr_CPgHH8TaYg">
                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faYoutube} />
                                </a>
                            </div>
                            <div className="col-md-5 text-end">Call Us Today! 9650-222-555</div>
                        </div>
                    </div>
                </nav>);
    }

    headerLogo                          =   ()  =>  {
        return (<header className={loginStyle.header_wrapper}>
                    <div className={['container'].join(' ')} >
                        <div className="row">
                            <div className="col-md-12">
                                <img src={TappetLogo} height="60" alt="logo"/>
                            </div>
                        </div>
                    </div>
                </header>);
    }

    footer                              =   ()  =>  {
        return (
            <footer className={['container-fluid', 'mt-3', 'pb-3', 'text-white'].join(' ')}>
                <div className="row">
                    <div className="col-md-4">
                        <img src={whiteLogo} className="mt-4" alt="Tappetbox" />
                        <div className={['mx-2', 'mt-2', 'text-sm'].join(' ')}></div>
                    </div>
                    <div className="col-md-4">
                        <p className={['mt-4' , 'text-xl'].join(' ')}>Contact Us</p>
                        <div className={['mx-2', 'mt-2', 'text-sm', 'text-secondary'].join(' ')}>
                            <p> Plot No.4, Sheetla Complex, <br/> Prem Nagar-II, Gurugram, <br/> Haryana - 122001.<br /></p>
                            <p className="mt-3 mb-2">Phone: +91 9650222555<br/>Email: contact@tappetbox.com </p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <p className={['mt-4' , 'text-xl'].join(' ')}>Get Social</p>
                        <div className={['mx-2', 'mt-2', 'text-xl', 'text-secondary'].join(' ')}>
                            <a className="social_link text-secondary px-2" target="_blank" href="https://www.facebook.com/tappetbox/?ref=aymt_homepage_panel">
                                <tapIcon.FontAwesomeIcon icon={tapIcon.faFacebookF} />
                            </a>
                            <a className="social_link text-secondary px-2" target="_blank" href="https://twitter.com/tappetbox">
                                <tapIcon.FontAwesomeIcon icon={tapIcon.faTwitter} />
                            </a>
                            <a className="social_link text-secondary px-2" target="_blank" href="https://www.linkedin.com/company/tappetbox">
                                <tapIcon.FontAwesomeIcon icon={tapIcon.faLinkedinIn} />
                            </a>
                            <a className="social_link text-secondary px-2" target="_blank" href="https://www.youtube.com/channel/UCq0x42wA3zUr_CPgHH8TaYg">
                                <tapIcon.FontAwesomeIcon icon={tapIcon.faYoutube} />
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
    copywrite                           =   ()  =>  {
        return (
            <Ax>
                <div className="footer_copywrite text-center py-3 text-sm bg-dark text-white">Copyright © {new Date().getFullYear()}, Five Dots Solutions Pvt. Ltd. All Rights Reserved.</div>
                {this.state.goto_top_visible ? <div className="goto_top" onClick={this.scrollToTop}><span><tapIcon.FontAwesomeIcon icon={tapIcon.faAngleUp}/></span></div> : null }
            </Ax>
        );
    }

    loginFormJsx                        =   ()  =>  {
        return (
            <form className="p-3 pt-0 sdsadd" onSubmit={this.loginFormSubmitHandler}>
                <div className="mb-3 mt-3">
                    <label className="form-label">Group ID</label>
                    <div className="input-group">
                        <span className="input-group-text"><tapIcon.FontAwesomeIcon icon={tapIcon.faUsers}/></span>
                        <input
                            name="group_id"
                            type="text"
                            value={this.state.groupVerifyForm.group_id}
                            onChange={(e) => this.formInputHandler(e,'groupVerifyForm')}
                            className="form-control"
                            required={true}
                            autoComplete="off"
                            placeholder="Enter Group/Company ID"
                            readOnly={!this.state.groupUserCanChange}
                            tabIndex={1}
                        />
                    </div>
                </div>
                <div className="mb-3">
                    <label className="form-label">User ID (Mobile No.)</label>
                    <div className="input-group">
                        <span className="input-group-text"><tapIcon.FontAwesomeIcon icon={tapIcon.faUser}/></span>
                        <input
                            name="mobile"
                            type="text"
                            value={this.state.groupVerifyForm.mobile}
                            onChange={(e) => this.formInputHandler(e,'groupVerifyForm')}
                            className="form-control"
                            required={true}
                            autoComplete="off"
                            placeholder="Enter Mobile Number"
                            maxLength="10"
                            readOnly={!this.state.groupUserCanChange}
                            tabIndex={2}
                        />
                    </div>
                </div>
                { this.state.loginProcessWith == 'password'
                    ? (
                        <div className="mb-3">
                            <label className="form-label">Password</label>
                            <div className="input-group">
                                <span className="input-group-text"><tapIcon.FontAwesomeIcon icon={tapIcon.faLock}/></span>
                                <input
                                    name="password"
                                    type="password"
                                    value={this.state.groupVerifyForm.password}
                                    onChange={(e) => this.formInputHandler(e,'groupVerifyForm')}
                                    className="form-control"
                                    required={true}
                                    autoComplete="new-password"
                                    placeholder="Enter Password"
                                    minLength="4"
                                    maxLength="50"
                                    tabIndex={3}
                                />
                            </div>
                        </div>
                    )
                    : null
                }

                { this.state.loginProcessWith == 'otp'
                    ? (
                        <div className="mb-3">
                            <label className="form-label">OTP</label>
                            <div className="input-group">
                                <span className="input-group-text"><tapIcon.FontAwesomeIcon icon={tapIcon.faUnlock}/></span>
                                <input
                                    name="otp"
                                    type="text"
                                    value={this.state.groupVerifyForm.otp}
                                    onChange={(e) => this.formInputHandler(e,'groupVerifyForm')}
                                    className="form-control"
                                    required={true}
                                    autoComplete="off"
                                    placeholder="Enter OTP"
                                    minLength="4"
                                    maxLength="6"
                                    tabIndex={4}
                                />
                            </div>
                        </div>
                    )
                    : null
                }
                <div className="d-grid gap-2 mt-4 mb-2">
                    <button
                        type="submit"
                        className="btn btn-primary btn-lg"
                        tabIndex={5}
                        disabled={this.state.groupUserLoginFormSubmitting || this.state.groupVerifyFormSubmitting ? true : false}
                        >
                            {this.state.loginProcessWith == 'otp' || this.state.loginProcessWith == 'password' ? 'Login' : 'Submit' }
                            {this.state.groupUserLoginFormSubmitting || this.state.groupVerifyFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin ml5"/>) : ''}
                    </button>
                </div>
                <div className="row mb-2">
                    <div className="col-6">
                        <button
                            type="button"
                            disabled={this.state.groupVerifyFormSubmitting || this.state.loginProcessWith == 'otp' ? true : false}
                            onClick={e => this.groupVerifyFormSubmit(e, 'otp')}
                            tabIndex={6}
                            className={['btn', 'btn-link', loginStyle.login_links].join(' ')}>Login Using OTP</button>
                    </div>
                    <div className="col-6 text-end">
                        <button
                            type="button"
                            disabled={this.state.groupVerifyFormSubmitting ? true : false}
                            onClick={() => {this.setState({forgotPasswordProcess : true})}}
                            tabIndex={7}
                            className={['btn', 'btn-link', loginStyle.login_links].join(' ')}>Forgot Password?</button>
                    </div>
                </div>
            </form>
        );
    }

    forgotPasswordFormJsx               =   ()  =>  {
        return (
            <form className="p-3 pt-0" onSubmit={this.forgetPasswordFormSubmitHandler}>
                <div className="mb-3 mt-3">
                    <label className="form-label">Group ID</label>
                    <div className="input-group">
                        <span className="input-group-text"><tapIcon.FontAwesomeIcon icon={tapIcon.faUsers} /></span>
                        <input
                            name="group_id"
                            type="text"
                            value={this.state.groupVerifyForm.group_id}
                            onChange={(e) => this.formInputHandler(e, 'groupVerifyForm')}
                            className="form-control"
                            required={true}
                            autoComplete="off"
                            placeholder="Enter Group/Company ID"
                            readOnly={!this.state.groupUserCanChange}
                            tabIndex={1}
                        />
                    </div>
                </div>
                <div className="mb-3">
                    <label className="form-label">User ID (Mobile No.)</label>
                    <div className="input-group">
                        <span className="input-group-text"><tapIcon.FontAwesomeIcon icon={tapIcon.faUser} /></span>
                        <input
                            name="mobile"
                            type="text"
                            value={this.state.groupVerifyForm.mobile}
                            onChange={(e) => this.formInputHandler(e, 'groupVerifyForm')}
                            className="form-control"
                            required={true}
                            autoComplete="off"
                            placeholder="Enter Mobile Number"
                            maxLength="10"
                            readOnly={!this.state.groupUserCanChange}
                            tabIndex={2}
                        />
                    </div>
                </div>
                {this.state.loginProcessWith == 'forgot_password_confirmation'
                    ? (
                        <Ax>
                            <div className="mb-3">
                            <label className="form-label">New Password</label>
                            <div className="input-group">
                                <span className="input-group-text"><tapIcon.FontAwesomeIcon icon={tapIcon.faLock} /></span>
                                <input
                                    name="password"
                                    type="password"
                                    value={this.state.groupVerifyForm.password}
                                    onChange={(e) => this.formInputHandler(e, 'groupVerifyForm')}
                                    className="form-control"
                                    required={true}
                                    autoComplete="new-password"
                                    placeholder="Enter New Password"
                                    minLength="4"
                                    maxLength="50"
                                    tabIndex={3}
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Re-enter Password</label>
                            <div className="input-group">
                                <span className="input-group-text"><tapIcon.FontAwesomeIcon icon={tapIcon.faLock} /></span>
                                <input
                                    name="password_confirmation"
                                    type="password"
                                    value={this.state.groupVerifyForm.password_confirmation}
                                    onChange={(e) => this.formInputHandler(e, 'groupVerifyForm')}
                                    className="form-control"
                                    required={true}
                                    autoComplete="off"
                                    placeholder="Enter Re-enter Password"
                                    minLength="4"
                                    maxLength="50"
                                    tabIndex={3}
                                />
                            </div>
                        </div>
                        </Ax>
                        
                    )
                    : null
                }

                {this.state.loginProcessWith == 'forgot_password_otp'
                    ? (
                        <Ax>
                            <div className="mb-3">
                                <label className="form-label">OTP</label>
                                <div className="input-group">
                                    <span className="input-group-text"><tapIcon.FontAwesomeIcon icon={tapIcon.faUnlock} /></span>
                                    <input
                                        name="otp"
                                        type="text"
                                        value={this.state.groupVerifyForm.otp}
                                        onChange={(e) => this.formInputHandler(e, 'groupVerifyForm')}
                                        className="form-control"
                                        required={true}
                                        autoComplete="off"
                                        placeholder="Enter OTP"
                                        minLength="4"
                                        maxLength="6"
                                        tabIndex={4}
                                    />
                                </div>
                            </div>
                            <div className=" mb-3">
                                <div>
                                    {this.state.isResendEnabled ? (
                                        <button
                                            type="button"
                                            className={['btn', 'btn-link', loginStyle.login_links].join(' ')}
                                            onClick={() => {
                                                this.startCountdown()
                                                this.forgetPasswordStepsHandler('1')
                                            }}
                                        >
                                            Resend OTP
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            disabled={true}
                                            className={['btn', 'btn-link', loginStyle.login_links].join(' ')}
                                            onClick={() => {
                                                this.startCountdown()
                                                this.forgetPasswordStepsHandler('1')
                                            }}
                                        >
                                            Resend OTP ({this.state.countdownTimer})
                                        </button>

                                    )}
                                </div>
                            </div>
                        </Ax>
                    )
                    : null
                }
                <div className="d-grid gap-2 mt-4 mb-2">
                    <button
                        type="submit"
                        className="btn btn-primary btn-lg"
                        tabIndex={5}
                        disabled={this.state.groupUserLoginFormSubmitting || this.state.groupVerifyFormSubmitting ? true : false}
                    >
                       Submit
                        {this.state.groupUserLoginFormSubmitting || this.state.groupVerifyFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin ml5" />) : ''}
                    </button>
                </div>
                <div className="row mb-2">
                  
                    <div className="offset-6 col-6 text-end">
                        <button
                            type="button"
                            disabled={this.state.groupVerifyFormSubmitting ? true : false}
                            onClick={() => { this.setState({ forgotPasswordProcess: false }) }}
                            tabIndex={7}
                            className={['btn', 'btn-link', loginStyle.login_links].join(' ')}>Back To Login</button>
                    </div>
                </div>
            </form>
        );
    }

    render () {
        return (
            <div className="pageTbl" style={{height:"100vh"}}>
                {this.props.isAuthenticated ? <Redirect to="/my_dashboard" /> :  null}
                <ToastContainer />
                {this.topHeader()}
                {this.headerLogo()}
                <div className="container-fluid bg-white">
                    <div className="row">
                        <div className={['col-md-8', 'px-1'].join(' ')}><img src={MobileLogo} className={loginStyle.page_image} alt="Tappetbox"/></div>
                        <div className={['col-md-4', 'p0' ,loginStyle.login_box].join(' ')}>
                            <h3 className="form-header mb-2">{this.state.forgotPasswordProcess ? 'Reset Password' : 'Login'}</h3>
                            { this.state.loginFormErrorMsg ? (<AlertErrorMsg>{this.state.loginFormErrorMsg}</AlertErrorMsg>) : null }
                            { this.state.loginFormSuccessMsg ? (<AlertErrorMsg>{this.state.loginFormSuccessMsg}</AlertErrorMsg>) : null }
                            { this.state.forgotPasswordProcess ? this.forgotPasswordFormJsx() : this.loginFormJsx() }
                            <div className={[loginStyle.tnc, 'text-xs', 'lh-base', 'p-3'].join(' ')}>By Logging, you agree to our <a target="_blank" href="https://tappetbox.in/software-license">Terms & Conditions </a> and that you have read our <a href="https://tappetbox.in/privacy-policy" target="_blank">Privacy Policy</a></div>
                        </div>
                    </div>
                </div>
                {this.footer()}
                {this.copywrite()}
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        token_type: state.auth.token_type,
        isAuthenticated: state.auth.access_token !== null,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAuthStart             :   () => dispatch(actions.authStart()),
        onAuthSucess            :   (responseData) => dispatch(actions.authSuccess(responseData)),
        applicationInitLoading  :   (access_token)  =>  dispatch(actions.applicationInitLoading(access_token)),
        onAuthFail              :   (err)   =>  dispatch(actions.authFail(err)),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( Login );