import React from 'react';
import {Link} from 'react-router-dom';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import ApplicationLayout from "../../../../layouts/ApplicationLayout";
import {connect} from "react-redux";
import HttpAPICall from "../../../../services/HttpAPICall";
import Ax from "../../../../components/hoc/Ax";
import { Helmet } from 'react-helmet';

class Details extends AppBaseComponent {

    constructor(props) {
        super(props);
        this.state                  =        {
            assetMatrixDetail       :         null,
            matrix_id                :        null
        }
    }

    id              =   this.props.matrix_enc_id;

    componentDidMount() {
        
        this.setState({matrix_id : this.props.matrix_enc_id,assetMatrixDetail : this.props.assetMatrixDetail});
    }

    componentWillReceiveProps(nextProps) {
      
        if(nextProps.matrix_enc_id !== this.state.matrix_id ) {
            this.setState({matrix_id:nextProps.matrix_enc_id})
        }
        if(nextProps.assetMatrixDetail !== this.state.assetMatrixDetail ) {
            this.setState({assetMatrixDetail:nextProps.assetMatrixDetail})
        }
    }

    render() {
      let detail = this.state.assetMatrixDetail  
        return (
            <Ax>
                <Helmet><title>Asset Matrix Details</title></Helmet>

                <div className="row ml1">
                    <div className="col-12 pl0 bg-white ">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <Link to={`/asset_matrix/details/${this.state.matrix_id}`} role={"button"}>
                                    <button className="btn nav-link text-capitalize show active" role="button" >
                                        Details
                                    </button>
                                </Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link to={`/asset_matrix/documents/${this.state.matrix_id}`} role={"button"}>
                                    <button className="btn nav-link text-capitalize" role="button" >
                                        Documents
                                    </button>
                                </Link>
                            </li> */}
                            <li className="nav-item">
                                <Link to={`/asset_matrix/scs_setting/${this.state.matrix_id}`} role={"button"}>
                                    <button className="btn nav-link text-capitalize" role="button" >
                                        SCS Setting
                                    </button>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={`/asset_matrix/setting/${this.state.matrix_id}`} role={"button"}>
                                    <button className="btn nav-link text-capitalize" role="button" >
                                        Setting
                                    </button>
                                </Link>
                            </li>


                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane  show active" id="details" role="tabpanel" aria-labelledby="details-tab">

                                <div className="page_containt">
                                    <div className="pageTbl p-2" style={{ height: "100%" }}>
                                        <table className="table table-hover table-bordered  table-responsive bg-white my-3">
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "33%" }} >Asset Matrix ID</td>
                                                    <th>{detail && detail.asset_matrix_unique_id ? detail.asset_matrix_unique_id : "-"}</th>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "33%" }} >Asset Type</td>
                                                    <th>{detail && detail.assetType ? detail.assetType.asset_type_name : "-"}</th>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "33%" }} >Manufacturer</td>
                                                    <th>{detail && detail.manufacturer? detail.manufacturer.manufacturer : "-"}</th>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "33%" }} >Model</td>
                                                    <th>{detail && detail.model_detail ? detail.model_detail.model : "-"}</th>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "33%" }} >Variant</td>
                                                    <th>{detail &&  detail.variant ? detail.variant : "-"}</th>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "33%" }} >Asset Capacity</td>
                                                    <th>{detail &&  detail.capacity ? detail.capacity : "-"}</th>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "33%" }} >Fuel Type</td>
                                                    <th>{detail &&  detail.fuelType ? detail.fuelType.fuel_type : "-"}</th>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "33%" }} >Electrical System Specification</td>
                                                    <th>{detail && detail.electrical_system ? detail.electrical_system : "-"}</th>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="tab_content_wrapper" >
                                            <span className="content_heading">Section-SubSection List </span>
                                        </div>
                                        <table className="table table-hover table-bordered my-3">
                                            <thead className='table-secondary'>
                                                <tr >
                                                    <th style={{ width: "10%" }} className='text-center'>S.No</th>
                                                    <th style={{ width: "45%" }}>Section</th>
                                                    <th style={{ width: "45%" }}>Sub-Section</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {detail && detail.sections && detail.sections.length > 0
                                            ? detail.sections.map((d,i) => {
                                                return(<tr>
                                                    <td className='text-center'>{i + 1}</td>
                                                    <td>{d.component ? d.component : "-"}</td>
                                                    <td>{d.subcomponents && d.subcomponents.length > 0
                                                    ? <ol>{d.subcomponents.map(sc => {return(<li className='my-1'>{sc.subcomponent}</li>)})}</ol>
                                                    : "-"}</td>
                                                </tr>)
                                            })
                                            : <tr><td className="text-center" colSpan={3}>No Record</td></tr>}
                                              
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </Ax>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
    }
}

export default connect(mapStateToProps)(Details);
