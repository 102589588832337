import React from "react";
import { Modal } from "bootstrap";
import { connect } from "react-redux";
import axios from "axios";
import Loader from "../../../components/ui/Loader/Loader";
import Ax from "../../../components/hoc/Ax";
import {
  DisplayListPagination,
  DisplaySearchCriteria,
  PeriodSelectionWithCustom,
} from "../../../components/TapUi";
import ApplicationLayout from "../../../layouts/ApplicationLayout";
import AppBaseComponent from "../../../components/AppBaseComponent";
import tapIcon from "../../../services/TapIcon";
import { Helmet } from "react-helmet";
import HttpAPICall from "../../../services/HttpAPICall";
import { toast } from "react-toastify";
import TapSelect from "../../../components/ui/TapSelect";
import moment from "moment";
import download from "../../../services/DownloadFile";
import swal from "sweetalert";
import Status from "../../../components/ui/Status";
import { Link } from "react-router-dom";
import EmployeeDocument from "./EmployeeDocument";
import DatePicker from "react-datepicker";
import TapIcon from "../../../services/TapIcon";
import CreatableSelect from "react-select/creatable";
import EmployeeSiteTransition from "./EmployeeSiteTransition";
import DateService from "../../../services/DateService";
import EmployeeDetailModal from "./EmployeeDetailModal";
import EmployeeWorkingDetailList from "./EmployeeWorkingDetailList";
import EmployeeFamilyDetailList from "./EmployeeFamilyDetailList";
import EmpContactDetailModal from "./EmpContactDetailModal";
import EmployeeAddress from "./EmployeeAddress";
import BulkEducationUpload from "./BulkEducationUpload";
import BulkFamilyUpload from "./BulkFamilyUpload";
import BulkWorkingUpload from "./BulkWorkingUpload";
import EmpCustomeDownloadModal from "./EmpCustomeDownloadModal";
import { HRM_API_BASE_URL_2 } from "../../../services/TapApiUrls";

class EmployeeList extends AppBaseComponent {
  constructor(props) {
    super(props);

    

    this.employeeSearchInitState = {
      search_name: "",
      search_like_employee_code: "",
      search_mobile: "",
      // 'email_search'                  :   '',
      search_site_ids: [],
      status_search: "",
      tags_search: [],
      asset_group_ids: [],
      search_asset_type_ids: [],
    };

    this.employeeSearchFormLabel = {
      search_name: "Employee Name : ",
      search_like_employee_code: "Employee ID : ",
      search_mobile: "Employee Number : ",
      // 'email_search'                 :   'Employee Email : ',
      search_site_ids: "Sites : ",
      status_search: "Status : ",
      tags_search: "Tags : ",
      asset_group_ids: "Asset Type Groups : ",
      search_asset_type_ids: "Asset Type : ",
    };

    this.changeSiteFormInit = {
      employee_name: "",
      current_site: "",
      current_transfer_date: "",
      site_since_date: "",
      site_id: "",
    };

    this.changeStatusFormInit = {
      employee_name: "",
      current_status: "",
      current_status_since_date: "",
      status: "",
      status_since_date: "",
      id: "",
    };

    this.changePasswordFormInit = {
      employee_name: "",
      password: "",
      password_confirmation: "",
      employee_id: "",
      mobile: "",
      notes: "",
      employee_code: "",
    };

    this.editEmployeeFormInit = {
      fname: "",
      lname: "",
      mobile: "",
      email: "",
      employee_code: "",
      tags: [],
      asset_type_ids: [],
      id: "",
    };

    this.changeSiteTranstionFormInit = {
      site_since_date: "",
    };

    this.state = {
      minimizeTable: false,
      listing_loading: false,
      listing_tbl_page: 1,
      employee_listing: [],
      listingMeta: null,
      totalListingCount: 0,
      employeeData: null,
      viewDataLoading: false,
      access_token: "",
      file_downloading: false,
      employeeBulkUploadFormSubmitting: false,
      employeeSearchForm: { ...this.employeeSearchInitState },
      submittedEmployeeSearchForm: { ...this.employeeSearchInitState },
      formSearchedElems: [],
      iam_user_sites: [],
      allEmployeeTags: [],
      allStatus: [],
      statusModalLoading: false,
      changeSiteForm: { ...this.changeSiteFormInit },
      changeStatusForm: { ...this.changeStatusFormInit },
      saveFormSubmitting: false,
      actionPerformed: false,
      editEmployeeForm: { ...this.editEmployeeFormInit },
      editSiteTranstionForm: { ...this.changeSiteTranstionFormInit },
      iam_asset_types: [],
      siteTransitionList: [],
      sitelistingMeta: null,
      siteTotalListingCount: 0,
      site_current_page: 1,
      siteListingLoaded: false,
      site_listing_loading: false,
      edit_type: "",
      allAssetTypesGroupsList: [],
      changePasswordForm: { ...this.changePasswordFormInit },
      employeeWorkingDetails: false,
      employeeEducationDetails: false,
      employeeFamilyDetails: false,
      clickSearchModal: false,
    };

    this.editDetailModalRef = React.createRef();
    this.editContactModalRef = React.createRef();
    this.bulkEducationModalRef = React.createRef();
    this.bulkFamilyModalRef = React.createRef();
    this.bulkWorkingModalRef = React.createRef();
    this.excelDownloadModalRef = React.createRef();


    this.emp_url                    =   HRM_API_BASE_URL_2;
    this.emp_list_url               =   HRM_API_BASE_URL_2 + '/employee/list';
    this.emp_deatail_url            =   HRM_API_BASE_URL_2 + '/employee/detail/:replace_id';
    this.emp_sample_download_url    =   HRM_API_BASE_URL_2 + '/employee/download_sample';  
    this.emp_add_by_excel_url       =   HRM_API_BASE_URL_2 + '/employee/add_by_excel'; 
    this.emp_add_form_data_url      =   HRM_API_BASE_URL_2 + '/employee/add_form_data'; 
    this.emp_change_site_url        =   HRM_API_BASE_URL_2 + '/employee/change_site'; 
    this.emp_change_status_url      =   HRM_API_BASE_URL_2 + '/employee/change_status';
    this.emp_change_password_url    =   HRM_API_BASE_URL_2 + '/employee/change_password';
    this.emp_site_transaction_url   =   HRM_API_BASE_URL_2 + '/employee/site_transaction';
    this.emp_site_transaction_by_id_url =   HRM_API_BASE_URL_2 + '/employee/site_transactions/:replace_id';

  }

  componentDidMount() {
    this.initilaizeFilterFormData(this.props);

    let paramObject     =   new URLSearchParams(this.props.location.search);
    if (this.props?.match?.params?.id || this.props?.location?.state?.id || paramObject?.get('transaction_id')) {
        const empId     =   this.props?.match?.params?.id || this.props?.location?.state?.id || paramObject?.get('transaction_id');
        this.viewEmployeeDetail(empId, false);
    }
    this.loadListingTblData(1);

    this.employeeSearchModal = new Modal(
      document.getElementById("employeeSearchModal"),
      { keyboard: false, backdrop: false }
    );
    this.employeeBulkUploadModal = new Modal(
      document.getElementById("employeeBulkUploadModal"),
      { keyboard: false, backdrop: false }
    );
    this.changeSiteModal = new Modal(
      document.getElementById("changeSiteModal"),
      { keyboard: false, backdrop: false }
    );
    this.changeStatusModal = new Modal(
      document.getElementById("changeStatusModal"),
      { keyboard: false, backdrop: false }
    );
    this.editSiteTransititonModal = new Modal(
      document.getElementById("editSiteTransititonModal"),
      { keyboard: false, backdrop: false }
    );
    this.editEmployeePasswordModal = new Modal(
      document.getElementById("editEmployeePasswordModal"),
      { keyboard: false, backdrop: false }
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.iam_user_sites !== this.props.iam_user_sites) {
      this.initilaizeFilterFormData(nextProps);
    }
  }

  initilaizeFilterFormData = (pr) => {
    this.setState({
      access_token: pr.access_token,
      iam_user_sites: pr.iam_user_sites
        ? pr.iam_user_sites.map((s) => {
            return { value: s.id, label: `${s.site_name} (${s.site_code})` };
          })
        : [],
      allAssetTypesGroupsList:
        pr.asset_groups && pr.asset_groups.length > 0
          ? pr.asset_groups.map((oc) => {
              return {
                value: oc.id,
                label: oc.name,
                linked_asset_types: oc.asset_type_ids,
              };
            })
          : [],
    });
  };

  loadListingTblData = (page = 1) => {
    this.setState({
      listing_loading: true,
      listing_tbl_page: page,
      employeeWorkingDetails: false,
    });
    let params = { page: page, ...this.state.submittedEmployeeSearchForm };
    HttpAPICall.withAthorization("GET", this.emp_list_url,
      this.state.access_token
        ? this.state.access_token
        : this.props.access_token,
      params,
      {},
      (resp) => {
        let respData = resp.data;
        this.setState({
          employee_listing: respData.data,
          listingMeta: respData.meta,
          totalListingCount: respData.meta.total,
        });
      }
    ).then(() => this.setState({ listing_loading: false }));
  };

  viewEmployeeDetail = (id, actionPerformed = false) => {
    this.setState({
      minimizeTable: true,
      viewDataLoading: true,
      actionPerformed: actionPerformed,
      employeeWorkingDetails: false,
    });
    HttpAPICall.withAthorization("GET", this.emp_deatail_url.replace(':replace_id', id),
      this.state.access_token ? this.state.access_token : this.props.access_token, {}, {},
      (resp) => {
        let respData = resp.data;
        this.setState({
          employeeData: respData.data,
          siteListingLoaded: false,
        });
      }
    ).then(() => this.setState({ viewDataLoading: false }));
  };

  closeView = () => {
    this.setState({ minimizeTable: false, employeeWorkingDetails: false });
    if (this.state.actionPerformed) {
      this.loadListingTblData(this.state.listing_tbl_page);
    }
    this.props.history.push("/employee_list");
  };

  /**
   * Bulk Upload :-
   */
  employeeBulkUploadModalInit = () => {
    //Initialize Search Form :-
    this.employeeBulkUploadModal.show();
  };

  downloadSampleFile = () => {
    HttpAPICall.withAthorization("GET",this.emp_sample_download_url,this.props.access_token,{},{},(response) => {
        download.file(response.data.file_path);
        toast.success("File Downloaded Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.employeeBulkUploadModal.hide();
      }
    );
  };

  employeeBulkUploadFormSubmit = (event) => {
    event.preventDefault();
    this.setState({ employeeBulkUploadFormSubmitting: true });
    const data = new FormData(
      document.getElementById("employeeBulkUploadForm")
    );
    let headers = {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + this.props.access_token,
    };
    axios.post(this.emp_add_by_excel_url, data, {
        headers: headers,
      }).then((response) => {
        toast.success(response.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.employeeBulkUploadModal.hide();
        //Load Add Item Form Data :-

        download.file(response.data.generated_file.file_path);
        setTimeout(() => {
          this.loadListingTblData(1);
        }, 1000);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message ?? "Unable to Add employee.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .then(() => {
        this.setState({ employeeBulkUploadFormSubmitting: false });
      });
  };

  employeeSearchModalInit = () => {
    this.setState({
        clickSearchModal: true,
      },() => {
        this.employeeSearchModal.show();
        HttpAPICall.withAthorization("GET", this.emp_add_form_data_url, this.props.access_token, {}, {}, (resp) => {
            let respData = resp.data;
            this.setState({
              iam_asset_types:  respData.asset_types && respData.asset_types.length > 0 ? respData.asset_types.map((at) => { return { value: at.id, label: at.asset_type_name }; }) : [],
              allEmployeeTags: respData.tags && respData.tags.length > 0 ? respData.tags.map((s) => {return { value: s, label: s }; }) : [],
              allStatus: respData.status && respData.status.length > 0 ? respData.status.map((s) => {
                return { value: s.key, label: s.name }; }) : [],
            });
        }).then(() => this.setState({clickSearchModal : false}));
    });
  };

  employeeAddInit = () => {
    this.props.history.push("/employee_add");
  };

  employeeSearchFormSubmit = (event = null) => {
    event && event.preventDefault();
    //Get All Keys :-
    let serachFormKeys = Object.keys(this.state.employeeSearchForm);
    let searchedElems = [];
    let employeeSearchParams = {};
    serachFormKeys.map((key) => {
      let label = this.employeeSearchFormLabel[key];
      let value = this.state.employeeSearchForm[key];
      if (value && value.toString().length > 0) {
        employeeSearchParams[key] = value;
        if (label) {
          let show_val = value;
          if (key == "search_name") {
            show_val = this.state.employeeSearchForm.search_name;
          }
          if (key == "search_like_employee_code") {
            show_val = this.state.employeeSearchForm.search_like_employee_code;
          }
          if (key == "search_mobile") {
            show_val = this.state.employeeSearchForm.search_mobile;
          }
          if (key == "email_search") {
            show_val = this.state.employeeSearchForm.email_search;
          }
          if (key == "search_site_ids") {
            show_val = this.state.iam_user_sites
              .filter((s) => value.includes(s.value))
              .map((s) => s.label)
              .join(", ");
          }
          if (key == "tags_search") {
            show_val = this.state.allEmployeeTags
              .filter((s) => value.includes(s.value))
              .map((s) => s.label)
              .join(", ");
          }
          if (key == "status_search") {
            show_val = this.state.allStatus
              .filter((s) => value == s.value)
              .map((s) => s.label)
              .join(", ");
          }
          if (key == "asset_group_ids") {
            show_val = this.state.allAssetTypesGroupsList
              .filter((s) => value.includes(s.value))
              .map((s) => s.label)
              .join(", ");
          }
          if (key == "search_asset_type_ids") {
            show_val = this.state.iam_asset_types
              .filter((s) => value.includes(s.value))
              .map((s) => s.label)
              .join(", ");
          }

          searchedElems.push(label + `<b>${show_val}</b>`);
        }
      }
    });
    //Generate Html :-
    this.setState(
      {
        submittedEmployeeSearchForm: { ...this.state.employeeSearchForm },
        formSearchedElems: searchedElems,
      },
      () => {
        this.employeeSearchModal.hide();
        this.loadListingTblData(1);
      }
    );
  };

  employeeSearchClear = () => {
    this.setState(
      {
        employeeSearchForm: { ...this.employeeSearchInitState },
        submittedEmployeeSearchForm: { ...this.employeeSearchInitState },
        formSearchedElems: [],
      },
      () => {
        this.employeeSearchModal.hide();
        this.loadListingTblData(1);
      }
    );
  };

  employeeDownloadHandler = () => {
    this.setState({ file_downloading: true });
    let params = { ...this.state.submittedEmployeeSearchForm };
    HttpAPICall.withAthorization(
      "GET",
      this.emp_list_url,
      this.props.access_token,
      { download: "download", ...params },
      {},
      (response) => {
        download.file(response.data.file_path);
      }
    ).then(() => this.setState({ file_downloading: false }));
  };

  changeSiteModalInit = () => {
    this.changeSiteModal.show();
    if (this.state.employeeData) {
      let data = this.state.employeeData;
      this.setState({
        changeSiteForm: {
          id: data.enc_id,
          employee_name: data.display_full_name ? data.display_full_name : "",
          current_site: data.site ? data.site.site_name : "",
          current_transfer_date: data.site_since_date_display
            ? data.site_since_date_display
            : "",
          site_since_date: "",
          site_id: "",
        },
      });
    }
  };

  submitChangeSiteForm = (e) => {
    e.preventDefault();

    let frmData = { ...this.state.changeSiteForm };
    let employeeData = this.state.employeeData;
    this.setState({ saveFormSubmitting: true });
    HttpAPICall.withAthorization("PUT", this.emp_change_site_url, this.props.access_token,
      {}, { ...frmData }, (response) => {
        toast.success(response.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.viewEmployeeDetail(this.state.employeeData?.enc_id, true);
        this.changeSiteModal.hide();
        this.setState({
          changeSiteForm: { ...this.changeSiteFormInit },
          actionPerformed: true,
          siteListingLoaded: false,
        });
      }
    ).then(() => this.setState({ saveFormSubmitting: false }));
  };

  changeStatusHandler = () => {
    this.changeStatusModal.show();

    this.setState({ statusModalLoading: true });

    HttpAPICall.withAthorization("GET", this.emp_add_form_data_url, this.props.access_token, { type: "status" }, {}, (resp) => {
        let {status} = resp.data;
        this.setState({
          allStatus : status && status.length > 0
            ? status.map((s) => { return { value: s.key, label: s.name };})
            : [],
        });
      }
    ).then(() => this.setState({ statusModalLoading: false }));

    
    if (this.state.employeeData) {
      let data = this.state.employeeData;
      this.setState({
        changeStatusForm: {
          id: data.enc_id,
          employee_name: data.display_full_name ? data.display_full_name : "",
          current_status: data.status_text ? data.status_text : "",
          current_status_since_date: data.status_since_date_display
            ? data.status_since_date_display
            : "",
          status_since_date: "",
          status_id: "",
        },
      });
    }
  };

  submitChangeStatusForm = (e) => {
    e.preventDefault();

    let frmData = { ...this.state.changeStatusForm, id : this.state.employeeData.enc_id };

    this.setState({ saveFormSubmitting: true });
    HttpAPICall.withAthorization("PUT", this.emp_change_status_url,
      this.props.access_token, {}, { ...frmData },
      (response) => {
        toast.success(response.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.viewEmployeeDetail(this.state.employeeData?.enc_id, true);
        this.changeStatusModal.hide();
        this.setState({ changeStatusForm: { ...this.changeStatusFormInit } });
      }
    ).then(() => this.setState({ saveFormSubmitting: false }));
  };

  changePasswordHandler = () => {
    this.editEmployeePasswordModal.show();
    if (this.state.employeeData) {
      let data = this.state.employeeData;
      this.setState({
        changePasswordForm: {
          employee_id: data.enc_id,
          employee_name: data.display_full_name ? data.display_full_name : "",
          password: "",
          password_confirmation: "",
          employee_code: data.employee_code ? data.employee_code : "",
          mobile: data.mobile ? data.mobile : "",
          notes: "",
        },
      });
    }
  };

  submitchangePasswordForm = (e) => {
    e.preventDefault();

    let frmData = { ...this.state.changePasswordForm };

    this.setState({ saveFormSubmitting: true });
    HttpAPICall.withAthorization("POST", this.emp_change_password_url, this.props.access_token, {}, { ...frmData }, (response) => {
        toast.success(response.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.viewEmployeeDetail(this.state.employeeData?.enc_id, true);
        this.editEmployeePasswordModal.hide();
        this.setState({
          changePasswordForm: { ...this.changePasswordFormInit },
        });
      }
    ).then(() => this.setState({ saveFormSubmitting: false }));
  };

  handleTagCreate = (inputText) => {
    const newValue = { value: inputText, label: inputText };
    this.setState({
      allEmployeeTags: [...this.state.allEmployeeTags, newValue],
    });
    let obj = {};
    obj["value"] = newValue.value;
    obj["label"] = newValue.label;

    this.state.editEmployeeForm.tags.push(obj);
  };

  getSiteTransitionListing = (data) => {
    if (!this.state.siteListingLoaded) {
      this.setState({ site_listing_loading: true });
      HttpAPICall.withAthorization("GET", this.emp_site_transaction_by_id_url.replace(':replace_id', data.enc_id), this.props.access_token, { page: 1 }, {}, (resp) => {
          let respData = resp.data;
          this.setState({
            siteTransitionList: respData,
            siteListingLoaded: true,
          });
        }
      ).then(() => this.setState({ site_listing_loading: false }));
    }
  };

  deleteSiteHistory = (data) => {
    swal({
      title: "Delete",
      text: "This action cannot be reversed, Are you sure you want to proceed?",
      icon: "warning",
      dangerMode: true,
      buttons: ["No", "Yes"],
    }).then((willDelete) => {
      if (willDelete) {
        let formdata = {
          site_transaction_id: data.site_transaction_id,
          employee_id: data.employee_id,
          action: "delete",
        };
        HttpAPICall.withAthorization("PUT", this.emp_site_transaction_url, this.props.access_token, {}, { ...formdata }, (response) => {
            toast.success(response.data.msg, {
              position: toast.POSITION.TOP_RIGHT,
            });
            this.setState({ siteListingLoaded: false }, () => {
              this.getSiteTransitionListing(this.state.employeeData);
              this.viewEmployeeDetail(this.state.employeeData?.enc_id, true);
            });
          }
        );
      }
    });
  };

  editSiteTransitionInit = (data, edit_type = "") => {
    this.editSiteTransititonModal.show();

    this.setState({
      editSiteTranstionForm: {
        site_since_date: data && data.from ? data.from : "",
        site_transaction_id: data.site_transaction_id,
        employee_id: data.employee_id,
        action: edit_type,
        till: data.till,
        fist_transaction_date: data.fist_transaction_date
          ? data.fist_transaction_date
          : "",
      },
      edit_type: edit_type,
    });
  };

  submitChangeSiteTranstionForm = (e) => {
    e.preventDefault();

    this.setState({ saveFormSubmitting: true });
    HttpAPICall.withAthorization("PUT", this.emp_site_transaction_url, this.props.access_token, {}, { ...this.state.editSiteTranstionForm }, (response) => {
        toast.success(response.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.viewEmployeeDetail(this.state.employeeData?.enc_id, true);
        this.getSiteTransitionListing(this.state.employeeData);
        this.editSiteTransititonModal.hide();
        this.setState({
          editSiteTranstionForm: { ...this.changeSiteTranstionFormInit },
        });
      }
    ).then(() => this.setState({ saveFormSubmitting: false }));
  };

  handleBasicEditDetail = () => {
    this.editDetailModalRef.current.modalHandle();
  };
  handleContactDetail = () => {
    this.editContactModalRef.current.modalHandle();
  };
  handleEducationBulk = () => {
    this.bulkEducationModalRef.current.modalHandle();
  };
  handleFamilyBulk = () => {
    this.bulkFamilyModalRef.current.modalHandle();
  };
  handleWorkingBulk = () => {
    this.bulkWorkingModalRef.current.modalHandle();
  };

  //***********************employee JSX****************
  employeeListjsx = () => {
    let employeeData = this.state.employeeData;
    return (
      <Ax>
        <div className="page_title row m0">
          <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
            <h3>Employee </h3>
            {!this.state.minimizeTable ? (
              <div className="text-end mt15">
                <button
                  type="button"
                  disabled={
                    this.state.listing_loading || this.state.file_downloading
                  }
                  onClick={this.employeeAddInit}
                  className="btn btn-primary"
                >
                  Add Employee
                </button>
                <button
                  type="button"
                  disabled={
                    this.state.listing_loading || this.state.file_downloading
                  }
                  onClick={this.employeeSearchModalInit}
                  className="btn btn-secondary"
                >
                  <tapIcon.imageIcon
                    icon={tapIcon.SearchIcon}
                    className="img-fluid"
                    alt="item-action"
                  />
                </button>
                <span className="dropdown">
                  <button
                    type="button"
                    id="actionMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    className="btn btn-secondary"
                    disabled={this.state.excelDownloading == true}
                  >
                    <tapIcon.imageIcon
                      icon={tapIcon.ActionIcon}
                      className="img-fluid"
                      alt="item-action"
                    />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <Link
                        className="dropdown-item"
                        onClick={this.employeeBulkUploadModalInit}
                        role="button"
                      >
                        Bulk Upload
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        onClick={this.handleEducationBulk}
                        role="button"
                      >
                        Bulk Education Upload
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        onClick={this.handleFamilyBulk}
                        role="button"
                      >
                        Bulk Family Upload
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        onClick={this.handleWorkingBulk}
                        role="button"
                      >
                        Bulk Working Upload
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        onClick={this.handleExcelDownload}
                        role="button"
                      >
                        Download
                      </Link>
                    </li>
                  </ul>
                </span>
              </div>
            ) : null}
          </div>
          {this.state.minimizeTable ? (
            <div className="col-9 tap_border_left">
              <h3 className="text-capitalize">
                Employee :{" "}
                {this.state.employeeData ? this.state.employeeData.name : "-"}{" "}
              </h3>
              <div className="text-end mt15">
                <span className="dropdown">
                  {/* {employeeData && <Link to={{ pathname: `/employee/attendence/${employeeData.enc_id}`, state: { employeeDetail: employeeData } }}>   <button type="button" className="btn btn-primary">
                                       Attendence</button></Link>} */}
                  <button
                    type="button"
                    id="actionMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    className="btn btn-secondary"
                  >
                    <tapIcon.imageIcon
                      icon={tapIcon.ActionIcon}
                      className="img-fluid"
                      alt="item-action"
                    />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li onClick={this.handleBasicEditDetail}>
                      <Link
                        role="button"
                        className={["dropdown-item"].join(" ")}
                        style={{ pointerEvents: "all" }}
                      >
                        Edit Basic Details
                      </Link>
                    </li>
                    <li onClick={this.handleContactDetail}>
                      <Link
                        role="button"
                        className={["dropdown-item"].join(" ")}
                        style={{ pointerEvents: "all" }}
                      >
                        Edit Address Details
                      </Link>
                    </li>
                    <li onClick={this.changeSiteModalInit}>
                      {" "}
                      <Link
                        role="button"
                        className={["dropdown-item"].join(" ")}
                        style={{ pointerEvents: "all" }}
                      >
                        Change Linked Site
                      </Link>
                    </li>
                    <li
                      onClick={() =>
                        this.changeStatusHandler(employeeData?.status)
                      }
                    >
                      {" "}
                      <Link role="button" className={["dropdown-item"].join(" ")} style={{ pointerEvents: "all" }} >Change Status</Link>
                    </li>
                    <li
                      onClick={() =>
                        this.changePasswordHandler(employeeData?.status)
                      }
                    >
                      {" "}
                      <Link
                        role="button"
                        className={["dropdown-item"].join(" ")}
                        style={{ pointerEvents: "all" }}
                      >
                        Change Password
                      </Link>
                    </li>
                  </ul>
                </span>
                <button
                  onClick={this.closeView}
                  type="button"
                  className="btn btn-secondary"
                >
                  <tapIcon.imageIcon
                    icon={tapIcon.CloseIcon}
                    className="img-fluid"
                    alt="item-close"
                  />
                </button>
              </div>
            </div>
          ) : null}
        </div>
        <div className="container-fluid pl5">
          <div className="page_containt row">
            <div
              className={[
                "pageTbl",
                this.state.minimizeTable ? "col-3" : "col-12",
              ].join(" ")}
            >
              {this.employeeListingTableJsx()}
            </div>

            {this.state.minimizeTable ? (
              <div className="col-9 pr4 pl4">
                <div className="bg-white p-2" style={{ height: "100%" }}>
                  {this.state.viewDataLoading ? (
                    <Loader />
                  ) : (
                    this.employeeViewJsx()
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </Ax>
    );
  };

  employeeListingTableJsx = () => {
    return (
      <Ax>
        <DisplaySearchCriteria
          searchedElems={this.state.formSearchedElems}
          onClearFilter={this.employeeSearchClear}
        />
        <table className="table table-bordered table-fixed  bg-white table-sm">
          <thead className="table-secondary">
            {this.state.minimizeTable ? (
              <tr>
                <th scope="col" style={{ width: "15%" }}>
                  Employee
                </th>
              </tr>
            ) : (
              <tr>
                <th scope="col" style={{ width: "5%" }} className="text-center">
                  S.No
                </th>
                <th scope="col" style={{ width: "17%" }}>
                  Name
                </th>
                <th scope="col" style={{ width: "12%" }}>
                  {" "}
                  Employee ID
                </th>
                <th scope="col" style={{ width: "11%" }}>
                  Mobile Number
                </th>
                <th scope="col" style={{ width: "15%" }}>
                  Email ID
                </th>
                <th scope="col" style={{ width: "15%" }}>
                  Site
                </th>
                <th scope="col" style={{ width: "15%" }}>
                  Tags
                </th>
                <th
                  scope="col"
                  style={{ width: "10%" }}
                  className="text-center"
                >
                  Status
                </th>
              </tr>
            )}
          </thead>
          <tbody>
            {this.state.listing_loading ? (
              <tr>
                <td colSpan="8">
                  <Loader />
                </td>
              </tr>
            ) : this.state.employee_listing.length > 0 ? (
              this.state.employee_listing.map((employee, index) => {
                return (
                  <tr key={index}>
                    {employee.is_Loading && employee.is_Loading == true ? (
                      <td colSpan={10} className="text-center">
                        <Loader />
                      </td>
                    ) : this.state.minimizeTable ? (
                      <Ax>
                        <td
                          style={{ width: "25%" }}
                        >
                          <div className="text-capitalize link-primary cursor_pointer">
                            <Link
                              to={{
                                pathname: "/employee_list/" + employee.enc_id,
                              }}
                              onClick={() =>
                                this.viewEmployeeDetail(employee.enc_id, false)
                              }
                            >
                              {employee.name ? employee.name : "-"}
                            </Link>
                          </div>
                          <div className="mt-1">
                            <small className="text-capitalize ">
                              {employee.employee_id
                                ? employee.employee_id
                                : "-"}{" "}
                            </small>
                            <small className="float-end">
                              <Status color={employee.status_color_code}>
                                {employee.status_text}
                              </Status>
                            </small>
                          </div>
                        </td>
                      </Ax>
                    ) : (
                      <Ax>
                        <td className="text-center">
                          {this.state.listingMeta
                            ? this.state.listingMeta.from + index
                            : index}
                        </td>
                        <td
                          className="text-capitalize link-primary cursor_pointer"
                        >
                          <Link
                            to={{
                              pathname: "/employee_list/" + employee.enc_id,
                            }}
                            onClick={() =>
                              this.viewEmployeeDetail(employee.enc_id, false)
                            }
                          >
                            {employee.name ? employee.name : "-"}
                          </Link>
                        </td>
                        <td>
                          {employee.employee_id ? employee.employee_id : "-"}
                        </td>
                        <td>{employee.mobile ? employee.mobile : "-"}</td>
                        <td>{employee.email ? employee.email : "-"}</td>
                        <td>{employee.site ? employee.site.site_name : "-"}</td>
                        <td className="text-capitalize">
                          {employee.tags && employee.tags.length > 0
                            ? employee.tags
                                .map((item, k) => {
                                  return item;
                                })
                                .join(", ")
                            : "-"}
                        </td>
                        <td className="text-center">
                          <Status color={employee.status_color_code}>
                            {employee.status_text}
                          </Status>
                        </td>
                      </Ax>
                    )}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="10" className="text-center">
                  No Records
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <DisplayListPagination
          meta={this.state.listingMeta}
          onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
        />
      </Ax>
    );
  };

  employeeWorkingDetails = () => {
    this.setState({ employeeWorkingDetails: true });
  };

  employeeEducationDetails = () => {
    this.setState({ employeeEducationDetails: true });
  };

  employeeFamilyDetails = () => {
    this.setState({ employeeFamilyDetails: true });
  };

  employeeViewJsx = () => {
    let { employeeData, siteTransitionList } = this.state;
    let site_transaction_count = siteTransitionList
      ? siteTransitionList.length
      : 0;
    return (
      <div className="bg-white">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="details-tab"
              data-bs-toggle="tab"
              data-bs-target="#details"
              type="button"
              role="tab"
              aria-controls="details"
              aria-selected="true"
            >
              Details
            </button>
          </li>
          {this.props.permissions.includes("hrm_compliance_view") && (
            <li className="nav-item" role="presentation">
              <button
                className="nav-link "
                id="linking-tab"
                data-bs-toggle="tab"
                data-bs-target="#linking"
                type="button"
                role="tab"
                aria-controls="linking"
                aria-selected="true"
              >
                Compliance
              </button>
            </li>
          )}
          <li className="nav-item" role="presentation">
            <button
              className="nav-link "
              id="emp-working-tab"
              data-bs-toggle="tab"
              data-bs-target="#empWorking"
              type="button"
              role="tab"
              aria-controls="empWorkings"
              aria-selected="true"
              onClick={() => this.employeeWorkingDetails()}
            >
              Working & Education
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link "
              id="transition-tab"
              data-bs-toggle="tab"
              data-bs-target="#transition"
              type="button"
              role="tab"
              aria-controls="transition"
              aria-selected="true"
              onClick={() => this.getSiteTransitionListing(employeeData)}
            >
              Site Transition
            </button>
          </li>
        </ul>
        <section className="pageTbl">
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane  show active"
              id="details"
              role="tabpanel"
              aria-labelledby="details-tab"
            >
              {employeeData && (
                <div className="tab_content_header">
                  <div className="tab_content_wrapper">
                    <span className="content_heading">Detail</span>
                  </div>
                  <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                    <tbody>
                      <tr>
                        <td className="fs14 fw-normal" style={{ width: "15%" }}>
                          Employee Name
                        </td>
                        <td className="fs14 fw500" style={{ width: "35%" }}>
                          {employeeData.name ? employeeData.name : "-"}
                        </td>
                        <td className="fs14 fw-normal" style={{ width: "15%" }}>
                          Email ID
                        </td>
                        <td className="fs14 fw500" style={{ width: "35%" }}>
                          {employeeData.email ? employeeData.email : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td className="fs14 fw-normal" style={{ width: "15%" }}>
                          Mobile Number
                        </td>
                        <td className="fs14 fw500" style={{ width: "35%" }}>
                          {employeeData.mobile ? employeeData.mobile : "-"}
                        </td>
                        <td className="fs14 fw-normal" style={{ width: "15%" }}>
                          Employee Code
                        </td>
                        <td className="fs14 fw500" style={{ width: "35%" }}>
                          {employeeData.employee_code
                            ? employeeData.employee_code
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td className="fs14 fw-normal" style={{ width: "15%" }}>
                          Gender
                        </td>
                        <td className="fs14 fw500" style={{ width: "35%" }}>
                          {employeeData.gender ? employeeData.gender : "-"}
                        </td>
                        <td className="fs14 fw-normal" style={{ width: "15%" }}>
                          Marital Status
                        </td>
                        <td className="fs14 fw500" style={{ width: "35%" }}>
                          {employeeData.marital_status
                            ? employeeData.marital_status
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td className="fs14 fw-normal" style={{ width: "15%" }}>
                          Blood Group
                        </td>
                        <td className="fs14 fw500" style={{ width: "35%" }}>
                          {employeeData.blood_group
                            ? employeeData.blood_group
                            : "-"}
                        </td>
                        <td className="fs14 fw-normal" style={{ width: "15%" }}>
                          Guardian/Father Name
                        </td>
                        <td className="fs14 fw500" style={{ width: "35%" }}>
                          {employeeData.guardian_name
                            ? employeeData.guardian_name
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td className="fs14 fw-normal" style={{ width: "15%" }}>
                          Religion
                        </td>
                        <td className="fs14 fw500" style={{ width: "35%" }}>
                          {employeeData.religion ? employeeData.religion : "-"}
                        </td>
                        <td className="fs14 fw-normal" style={{ width: "15%" }}>
                          Nationality
                        </td>
                        <td className="fs14 fw500" style={{ width: "35%" }}>
                          {employeeData.nationality
                            ? employeeData.nationality
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td className="fs14 fw-normal" style={{ width: "15%" }}>
                          Country of Origin
                        </td>
                        <td className="fs14 fw500" style={{ width: "35%" }}>
                          {employeeData.country_of_origin
                            ? employeeData.country_of_origin
                            : "-"}
                        </td>
                        <td className="fs14 fw-normal" style={{ width: "15%" }}>
                          Date of Birth
                        </td>
                        <td className="fs14 fw500" style={{ width: "35%" }}>
                          {employeeData.dob
                            ? DateService.dateTimeFormat(
                                employeeData.dob,
                                "DD-MMM-YYYY"
                              )
                            : "-"}
                        </td>
                      </tr>
                      {/* <tr>
                                                <td className="fs14 fw-normal" style={{ "width": "25%" }}>Tags</td>
                                                <td className="fs14 fw500" style={{ "width": "25%" }}>{
                                                    employeeData.tags && employeeData.tags.length > 0
                                                        ? (employeeData.tags.map((item, k) => { return (item) })).join(', ')
                                                        : '-'
                                                }</td>
                                                <td className="fs14 fw-normal" style={{ "width": "25%" }}>Asset Types</td>
                                                <td className="fs14 fw500" style={{ "width": "25%" }}>{
                                                    employeeData.asset_types && employeeData.asset_types.length > 0
                                                        ? (employeeData.asset_types.map((item, k) => { return (item?.asset_type_name) })).join(', ')
                                                        : '-'
                                                }</td>
                                            </tr> */}
                    </tbody>
                  </table>
                  <div className="tab_content_wrapper">
                    <span className="content_heading">Status</span>
                  </div>
                  <table className="table table-hover table-bordered table-responsive bg-white my-2 ">
                    <tbody>
                      <tr>
                        <td className="fs14 fw-normal" style={{ width: "15%" }}>
                          Status
                        </td>
                        <td className="fs14 fw500">
                          <Status color={employeeData.status_color_code}>
                            {employeeData.status_text}
                          </Status>
                        </td>
                      </tr>
                      <tr>
                        <td className="fs14 fw-normal" style={{ width: "15%" }}>
                          Status Date
                        </td>
                        <td className="fs14 fw500">
                          {employeeData.status_since_date_display
                            ? employeeData.status_since_date_display
                            : "-"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="tab_content_wrapper">
                    <span className="content_heading">Site Transition</span>
                  </div>
                  <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                    <tbody>
                      <tr>
                        <td className="fs14 fw-normal" style={{ width: "15%" }}>
                          Site
                        </td>
                        <td className="fs14 fw500">
                          {employeeData.site
                            ? employeeData.site.site_name
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td className="fs14 fw-normal" style={{ width: "15%" }}>
                          Date of Transfer
                        </td>
                        <td className="fs14 fw500">
                          {employeeData.site_since_date_display
                            ? employeeData.site_since_date_display
                            : "-"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <EmployeeAddress employeeData={employeeData} />
                  <EmployeeFamilyDetailList
                    emp_id={
                      employeeData && employeeData.enc_id
                        ? employeeData.enc_id
                        : ""
                    }
                  />
                </div>
              )}
            </div>
            {this.props.permissions.includes("hrm_compliance_view") && (
              <div
                className="tab-pane"
                id="linking"
                role="tabpanel"
                aria-labelledby="linking-tab"
              >
                <EmployeeDocument
                  employee_id={
                    employeeData && employeeData.enc_id
                      ? employeeData.enc_id
                      : ""
                  }
                />
              </div>
            )}
            <div
              className="tab-pane"
              id="empWorking"
              role="tabpanel"
              aria-labelledby="emp-working-tab"
            >
              {this.state.employeeWorkingDetails && (
                <div className="tab_content_header">
                  <EmployeeWorkingDetailList
                    emp_id={
                      employeeData && employeeData.enc_id
                        ? employeeData.enc_id
                        : ""
                    }
                  />
                </div>
              )}
            </div>
            {/* <div className="tab-pane" id="empEducation" role="tabpanel" aria-labelledby="emp-education-tab">
                            {
                                this.state.employeeEducationDetails &&
                                <EmployeeEducationDetailList emp_id = {employeeData && employeeData.enc_id ? employeeData.enc_id : ""}/>
                            }
                        </div> */}
            {/* <div className="tab-pane" id="empFamily" role="tabpanel" aria-labelledby="emp-family-tab">
                            {
                                this.state.employeeFamilyDetails &&
                                <EmployeeFamilyDetailList emp_id = {employeeData && employeeData.enc_id ? employeeData.enc_id : ""}/>
                            }
                        </div> */}
            <div
              className="tab-pane"
              id="transition"
              role="tabpanel"
              aria-labelledby="transition-tab"
            >
              <table
                className="table table-bordered bg-white align-items-center  table-sm table-hover my-3"
                id="settingsTable"
              >
                <thead className="table-secondary">
                  <tr className="text-center">
                    <th scope="col" style={{ width: "5%" }}>
                      S.No
                    </th>
                    <th scope="col" style={{ width: "25%" }}>
                      From
                    </th>
                    <th scope="col" style={{ width: "25%" }}>
                      To
                    </th>
                    <th scope="col" style={{ width: "25%" }}>
                      Site{" "}
                    </th>
                    <th scope="col" style={{ width: "15%" }}>
                      No of Days
                    </th>
                    <th scope="col" style={{ width: "5%" }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.site_listing_loading ? (
                    <tr>
                      <td className="text-center" colSpan="6">
                        <Loader />
                      </td>
                    </tr>
                  ) : site_transaction_count > 0 ? (
                    siteTransitionList.map((site_transition, index) => {
                      var date1 =
                        site_transition && site_transition.from
                          ? moment(site_transition.from)
                          : moment(new Date());
                      var date2 =
                        site_transition && site_transition.till
                          ? moment(site_transition.till)
                          : moment(new Date());
                      var days = date2.diff(date1, "days");

                      return (
                        <tr className="text-center">
                          <td>{index + 1}</td>
                          <td>
                            {site_transition.from_display
                              ? site_transition.from_display
                              : "-"}
                          </td>
                          <td>
                            {site_transition.till_display
                              ? site_transition.till_display
                              : "Till Date"}
                          </td>
                          <td>
                            {site_transition.site_name &&
                            site_transition.site_name
                              ? site_transition.site_name
                              : "-"}
                          </td>
                          <td>{days ? days : 0} Days</td>
                          <td className="text-center">
                            {(!site_transition.till ||
                              site_transaction_count == index + 1) && (
                              <Ax>
                                <button
                                  type="button"
                                  id="actionMenuButton"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  className="btn"
                                >
                                  <tapIcon.FontAwesomeIcon
                                    icon={tapIcon.faEllipsisV}
                                  />
                                </button>
                                <ul
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton1"
                                >
                                  {!site_transition.till && (
                                    <li>
                                      <a
                                        role="button"
                                        onClick={() => {
                                          if (
                                            site_transition.can_delete == "Y"
                                          ) {
                                            return this.deleteSiteHistory(
                                              site_transition
                                            );
                                          }
                                        }}
                                        className={[
                                          "dropdown-item",
                                          site_transition.can_delete == "Y"
                                            ? ""
                                            : "disabled",
                                        ].join(" ")}
                                        title={
                                          site_transition.can_delete == "N"
                                            ? "Because Employee exists in Some Transaction during the transaction time, we are unable to delete Site Transaction."
                                            : ""
                                        }
                                        style={{ pointerEvents: "all" }}
                                      >
                                        Delete
                                      </a>
                                    </li>
                                  )}

                                  {site_transaction_count == index + 1 && (
                                    <li>
                                      <a
                                        role="button"
                                        onClick={() => {
                                          if (
                                            site_transition.can_date_change ==
                                            "Y"
                                          ) {
                                            return this.editSiteTransitionInit(
                                              site_transition,
                                              "date"
                                            );
                                          }
                                        }}
                                        className={[
                                          "dropdown-item",
                                          site_transition.can_date_change == "Y"
                                            ? ""
                                            : "disabled",
                                        ].join(" ")}
                                        title={
                                          site_transition.can_date_change == "N"
                                            ? "You do not have permission to perform this action"
                                            : ""
                                        }
                                        style={{ pointerEvents: "all" }}
                                      >
                                        Edit Transfer Date
                                      </a>
                                    </li>
                                  )}
                                </ul>
                              </Ax>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td className="text-center" colSpan="6">
                        No Record
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
    );
  };

  employeeBulkUploadModalJsx = () => {
    return (
      <div className="modal fade" id="employeeBulkUploadModal" tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="itemBulkUploadModalLabel">
                Bulk Upload Employee
              </h5>
              <button
                type="button"
                disabled={this.state.employeeBulkUploadFormSubmitting}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form
              onSubmit={this.employeeBulkUploadFormSubmit}
              id="employeeBulkUploadForm"
              method="post"
              encType="multipart/form-data"
            >
              <div className="modal-body">
                <div className="row align-items-center mb-3 mt-3">
                  <div className="col-12">
                    <input
                      name="document"
                      type="file"
                      className="form-control"
                      required={true}
                    />
                  </div>
                  <div className="col-12 mt-2 form-text">
                    # Excel Sheet with Extention xls,xlm,xlsx,ods Allowed
                  </div>
                  <div className="col-12 mt-2 form-text">
                    # Miximum 100 MB Size of Excel Sheet Allowed
                  </div>
                </div>
              </div>
              <div className="modal-footer d-flex justify-content-between">
                <div>
                  <Link
                    type="button"
                    className="float-left"
                    href="#"
                    onClick={this.downloadSampleFile}
                  >
                    Download Sample File
                  </Link>
                </div>
                <div>
                  <button
                    type="button"
                    disabled={this.state.employeeBulkUploadFormSubmitting}
                    className="btn btn-secondary mx-2"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    disabled={this.state.employeeBulkUploadFormSubmitting}
                    className="btn btn-primary"
                  >
                    Submit{" "}
                    {this.state.employeeBulkUploadFormSubmitting ? (
                      <tapIcon.FontAwesomeIcon
                        icon={tapIcon.faSyncAlt}
                        className="fa-spin"
                      />
                    ) : (
                      ""
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  employeeSearchModalJsx = () => {
    return (
      <div className="modal fade" id="employeeSearchModal" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="employeeSearchModalLabel">Employee Search</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {
                this.state.clickSearchModal 
                ?   <Loader/>
                :   <form onSubmit={this.employeeSearchFormSubmit} id="employeeSearchForm" >
                    <div className="modal-body">
                    <div className="row mb-3">
                    <label className="col-sm-3 col-form-label col-form-label-sm">Name</label>
                    <div className="col-sm-9">
                        <input
                        type="text"
                        name="search_name"
                        className="form-control form-control-sm"
                        placeholder="Enter Employee Name"
                        autoComplete="off"
                        value={this.state.employeeSearchForm.search_name}
                        onChange={(e) =>
                            this.formInputHandler(e, "employeeSearchForm")
                        }
                        />
                    </div>
                    </div>
                    <div className="row mb-3">
                    <label className="col-sm-3 col-form-label col-form-label-sm">
                        Employee ID
                    </label>
                    <div className="col-sm-9">
                        <input
                        type="text"
                        name="search_like_employee_code"
                        className="form-control form-control-sm"
                        placeholder="Enter Employee ID"
                        autoComplete="off"
                        value={
                            this.state.employeeSearchForm.search_like_employee_code
                        }
                        onChange={(e) =>
                            this.formInputHandler(e, "employeeSearchForm")
                        }
                        />
                    </div>
                    </div>
                    <div className="row mb-3">
                    <label className="col-sm-3 col-form-label col-form-label-sm">
                        Mobile Number
                    </label>
                    <div className="col-sm-9">
                        <input
                        type="number"
                        name="search_mobile"
                        className="form-control form-control-sm"
                        placeholder="Enter Employee Number"
                        autoComplete="off"
                        value={this.state.employeeSearchForm.search_mobile}
                        onChange={(e) =>
                            this.formInputHandler(e, "employeeSearchForm")
                        }
                        />
                    </div>
                    </div>

                    <div className="row mb-3">
                    <label className="col-sm-3 col-form-label col-form-label-sm">Site</label>
                    <div className="col-sm-9">
                        <TapSelect
                        options={this.state.iam_user_sites}
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(
                            selectedOption,
                            "employeeSearchForm",
                            "search_site_ids"
                            );
                        }}
                        isSearchable={true}
                        isClearable={true}
                        menuPlacement="bottom"
                        value={this.state.iam_user_sites.filter((s) =>
                            this.state.employeeSearchForm.search_site_ids.includes(
                            s.value
                            )
                        )}
                        placeholder="Select Sites"
                        containerHeight="30px"
                        fontSize="93%"
                        isMulti={true}
                        />
                    </div>
                    </div>
                    <div className="row mb-3">
                    <label className="col-sm-3 col-form-label col-form-label-sm">Tags</label>
                    <div className="col-sm-9">
                        <TapSelect
                        options={this.state.allEmployeeTags}
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption,"employeeSearchForm","tags_search");
                        }}
                        isSearchable={true}
                        isClearable={true}
                        isMulti={true}
                        menuPlacement="bottom"
                        value={this.state.allEmployeeTags.filter((s) => this.state.employeeSearchForm.tags_search.includes(s.value))}
                        placeholder="Select Tags"
                        containerHeight="30px"
                        fontSize="93%"
                        />
                    </div>
                    </div>
                    <div className="row mb-3">
                    <label className="col-sm-3 col-form-label col-form-label-sm">
                        Asset Type Group
                    </label>
                    <div className="col-sm-9">
                        <TapSelect
                        options={this.state.allAssetTypesGroupsList}
                        changeEvent={(selectedOption) => {
                            this.setState({
                            employeeSearchForm: {
                                ...this.state.employeeSearchForm,
                                asset_group_ids:
                                selectedOption && selectedOption.length > 0
                                    ? selectedOption.map((o) => o.value)
                                    : [],
                                search_asset_type_ids:
                                selectedOption && selectedOption.length > 0
                                    ? selectedOption
                                        .map((s) => s.linked_asset_types)
                                        .flat()
                                    : [],
                            },
                            });
                        }}
                        isSearchable={true}
                        isClearable={true}
                        isMulti={true}
                        value={this.state.allAssetTypesGroupsList.filter((s) =>
                            this.state.employeeSearchForm.asset_group_ids.includes(
                            s.value
                            )
                        )}
                        placeholder="Select Asset Type Group"
                        containerHeight="30px"
                        fontSize="93%"
                        />
                    </div>
                    </div>
                    <div className="row mb-3">
                    <label className="col-sm-3 col-form-label col-form-label-sm">
                        Asset Type
                    </label>
                    <div className="col-sm-9">
                        <TapSelect
                        options={this.state.iam_asset_types}
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(
                            selectedOption,
                            "employeeSearchForm",
                            "search_asset_type_ids"
                            );
                        }}
                        isSearchable={true}
                        isClearable={true}
                        menuPlacement="bottom"
                        value={this.state.iam_asset_types.filter((s) =>
                            this.state.employeeSearchForm.search_asset_type_ids.includes(
                            s.value
                            )
                        )}
                        placeholder="Select Asset Types"
                        containerHeight="30px"
                        fontSize="93%"
                        isMulti={true}
                        />
                    </div>
                    </div>
                    <div className="row mb-3">
                    <label className="col-sm-3 col-form-label col-form-label-sm">
                        Status
                    </label>
                    <div className="col-sm-9">
                        <TapSelect
                        options={this.state.allStatus}
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(
                            selectedOption,
                            "employeeSearchForm",
                            "status_search"
                            );
                        }}
                        isSearchable={true}
                        isClearable={true}
                        menuPlacement="bottom"
                        value={this.state.allStatus.find(
                            (s) =>
                            this.state.employeeSearchForm.status_search == s.value
                        )}
                        placeholder="Select Status"
                        containerHeight="30px"
                        fontSize="93%"
                        />
                    </div>
                    </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                        Close
                        </button>
                        <button type="submit" className="btn btn-primary">Search</button>
                    </div>
                </form>
            }
          </div>
        </div>
      </div>
    );
  };

  employeeSiteChangeModalJsx = () => {
    return (
      <div className="modal fade" id="changeSiteModal" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="changeSiteModalLabel">
                Change Site
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form onSubmit={this.submitChangeSiteForm}>
              <div className="modal-body">
                <div className="row mb-3">
                  <label className="col-sm-3 col-form-label col-form-label-sm">
                    Employee
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      name="employee_name"
                      className="form-control form-control-sm"
                      placeholder=" Employee"
                      autoComplete="off"
                      disabled={true}
                      value={this.state.changeSiteForm.employee_name}
                      onChange={(e) =>
                        this.formInputHandler(e, "changeSiteForm")
                      }
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-sm-3 col-form-label col-form-label-sm">
                    Current Site
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      name="current_site"
                      className="form-control form-control-sm"
                      placeholder="Current site"
                      autoComplete="off"
                      disabled={true}
                      value={this.state.changeSiteForm.current_site}
                      onChange={(e) =>
                        this.formInputHandler(e, "changeSiteForm")
                      }
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-sm-3 col-form-label col-form-label-sm">
                    Current Transfer Date
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      name="current_transfer_date"
                      className="form-control form-control-sm"
                      placeholder="Current site"
                      autoComplete="off"
                      disabled={true}
                      value={this.state.changeSiteForm.current_transfer_date}
                      onChange={(e) =>
                        this.formInputHandler(e, "changeSiteForm")
                      }
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <label className="col-sm-3 col-form-label col-form-label-sm require">
                    {" "}
                    Site
                  </label>
                  <div className="col-sm-9">
                    <TapSelect
                      options={this.state.iam_user_sites}
                      changeEvent={(selectedOption) => {
                        this.tapSelectChange(
                          selectedOption,
                          "changeSiteForm",
                          "site_id"
                        );
                      }}
                      isSearchable={true}
                      isClearable={true}
                      menuPlacement="bottom"
                      value={this.state.iam_user_sites.find(
                        (s) => this.state.changeSiteForm.site_id == s.value
                      )}
                      placeholder="Select Site"
                      containerHeight="30px"
                      fontSize="93%"
                      required={true}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-sm-3 col-form-label col-form-label-sm require">
                    Transfer Date
                  </label>
                  <div className="col-sm-9">
                    <DatePicker
                      selected={
                        this.state.changeSiteForm.site_since_date
                          ? moment(
                              this.state.changeSiteForm.site_since_date
                            ).toDate()
                          : false
                      }
                      name="site_since_date"
                      onChange={(value, event) => {
                        this.formDateHandler(
                          "site_since_date",
                          value,
                          "changeSiteForm",
                          "YYYY-MM-DD HH:mm:ss"
                        );
                      }}
                      dateFormat="dd-MMM-yyyy hh:mm a"
                      className={"form-control form-control-sm"}
                      showMonthDropdown
                      showYearDropdown
                      showTimeSelect
                      timeIntervals={5}
                      timeCaption="Time"
                      autoComplete="off"
                      // popperPlacement="right-start"
                      scrollMonthYearDropdown
                      required={true}
                      maxDate={new Date()}
                      minDate={
                        this.state.employeeData
                          ? moment(
                              this.state.employeeData.site_since_date
                            ).toDate()
                          : new Date()
                      }
                      placeholderText={`Please Enter Date of Transfer`}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  disabled={this.state.saveFormSubmitting ? true : false}
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  disabled={this.state.saveFormSubmitting ? true : false}
                  className="btn btn-primary mx-2"
                >
                  Save{" "}
                  {this.state.saveFormSubmitting ? (
                    <TapIcon.FontAwesomeIcon
                      icon={TapIcon.faSyncAlt}
                      className="fa-spin"
                    />
                  ) : (
                    ""
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  employeeStatusChangeModalJsx = () => {
    return (
      <div className="modal fade" id="changeStatusModal" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="changeStatusModalLabel">Change Status</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal"aria-label="Close"></button>
            </div>
            <form onSubmit={this.submitChangeStatusForm}>
                <div className="modal-body">
                    {
                        this.state.statusModalLoading
                        ? <Loader/>
                        :
                        <>
                            <div className="row mb-3">
                            <label className="col-sm-3 col-form-label col-form-label-sm">Employee</label>
                            <div className="col-sm-9">
                                <input
                                type="text"
                                name="employee_name"
                                className="form-control form-control-sm"
                                placeholder=" Employee"
                                autoComplete="off"
                                disabled={true}
                                value={this.state.changeStatusForm.employee_name}
                                onChange={(e) =>
                                    this.formInputHandler(e, "changeStatusForm")
                                }
                                />
                            </div>
                            </div>
                            <div className="row mb-3">
                            <label className="col-sm-3 col-form-label col-form-label-sm">Current Status</label>
                            <div className="col-sm-9">
                                <input
                                type="text"
                                name="current_site"
                                className="form-control form-control-sm"
                                placeholder="Current Status"
                                autoComplete="off"
                                disabled={true}
                                value={this.state.changeStatusForm.current_status}
                                />
                            </div>
                            </div>
                            <div className="row mb-3">
                            <label className="col-sm-3 col-form-label col-form-label-sm">
                                Status Since Date
                            </label>
                            <div className="col-sm-9">
                                <input
                                type="text"
                                name="current_status_since_date"
                                className="form-control form-control-sm"
                                placeholder="Status Since Date"
                                autoComplete="off"
                                disabled={true}
                                value={
                                    this.state.changeStatusForm.current_status_since_date
                                }
                                />
                            </div>
                            </div>
                            <div className="row mb-3">
                            <label className="col-sm-3 col-form-label col-form-label-sm require">
                                {" "}
                                Status
                            </label>
                            <div className="col-sm-9">
                                <TapSelect
                                options={this.state.allStatus.length > 0 && this.state.employeeData ? this.state.allStatus.filter((s) => this.state.employeeData.status !== s.value) : this.state.allStatus}
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption,"changeStatusForm","status");
                                }}
                                isSearchable={true}
                                isClearable={true}
                                menuPlacement="bottom"
                                value={this.state.allStatus.find(
                                    (s) => this.state.changeStatusForm.status == s.value
                                )}
                                placeholder="Select Status"
                                containerHeight="30px"
                                fontSize="93%"
                                required={true}
                                />
                            </div>
                            </div>
                            {this.state.employeeData &&
                            this.state.employeeData.status == 2 && (
                                <div className="row mb-3">
                                <label className="col-sm-3 col-form-label col-form-label-sm require">
                                    {" "}
                                    Site
                                </label>
                                <div className="col-sm-9">
                                    <TapSelect
                                    options={this.state.iam_user_sites}
                                    changeEvent={(selectedOption) => {
                                        this.tapSelectChange(
                                        selectedOption,
                                        "changeStatusForm",
                                        "site_id"
                                        );
                                    }}
                                    isSearchable={true}
                                    isClearable={true}
                                    menuPlacement="bottom"
                                    value={this.state.iam_user_sites.find(
                                        (s) =>
                                        this.state.changeStatusForm.site_id == s.value
                                    )}
                                    placeholder="Select Site"
                                    containerHeight="30px"
                                    fontSize="93%"
                                    // required={true}
                                    />
                                </div>
                                </div>
                            )}
                            <div className="row mb-3">
                            <label className="col-sm-3 col-form-label col-form-label-sm require">
                                Status Date
                            </label>
                            <div className="col-sm-9">
                                <DatePicker
                                selected={
                                    this.state.changeStatusForm.status_since_date
                                    ? moment(
                                        this.state.changeStatusForm.status_since_date
                                        ).toDate()
                                    : false
                                }
                                name="status_since_date"
                                onChange={(value, event) => {
                                    this.formDateHandler(
                                    "status_since_date",
                                    value,
                                    "changeStatusForm",
                                    "YYYY-MM-DD HH:mm:ss"
                                    );
                                }}
                                dateFormat="dd-MMM-yyyy hh:mm a"
                                className={"form-control form-control-sm"}
                                showMonthDropdown
                                showYearDropdown
                                showTimeSelect
                                timeIntervals={5}
                                timeCaption="Time"
                                autoComplete="off"
                                // popperPlacement="right-start"
                                scrollMonthYearDropdown
                                required={true}
                                maxDate={new Date()}
                                minDate={
                                    this.state.employeeData
                                    ? moment(
                                        this.state.employeeData.status_since_date
                                        ).toDate()
                                    : new Date()
                                }
                                placeholderText={`Please Enter Status Date`}
                                />
                            </div>
                            </div>
                        </>
                    }
                </div>
                <div className="modal-footer">
                    <button
                    type="button"
                    disabled={this.state.saveFormSubmitting ? true : false}
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    >
                    Close
                    </button>
                    <button
                    type="submit"
                    disabled={this.state.saveFormSubmitting ? true : false}
                    className="btn btn-primary mx-2"
                    >
                    Save{" "}
                    {this.state.saveFormSubmitting ? (
                        <TapIcon.FontAwesomeIcon
                        icon={TapIcon.faSyncAlt}
                        className="fa-spin"
                        />
                    ) : (
                        ""
                    )}
                    </button>
                </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  employeeChangePasswordModalJsx = () => {
    return (
      <div className="modal fade" id="editEmployeePasswordModal" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editEmployeePasswordModalLabel">
                Change Password
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form onSubmit={this.submitchangePasswordForm}>
              <div className="modal-body">
                <div className="row mb-3">
                  <label className="col-sm-3 col-form-label col-form-label-sm">
                    Employee
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      name="employee_name"
                      className="form-control form-control-sm"
                      placeholder=" Employee"
                      autoComplete="off"
                      disabled={true}
                      value={this.state.changePasswordForm.employee_name}
                      onChange={(e) =>
                        this.formInputHandler(e, "changePasswordForm")
                      }
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-sm-3 col-form-label col-form-label-sm">
                    Employee Code
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      name="employee_code"
                      className="form-control form-control-sm"
                      placeholder=" Employee Code"
                      autoComplete="off"
                      disabled={true}
                      value={this.state.changePasswordForm.employee_code}
                      onChange={(e) =>
                        this.formInputHandler(e, "changePasswordForm")
                      }
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-sm-3 col-form-label col-form-label-sm">
                    Mobile Number
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      name="mobile"
                      className="form-control form-control-sm"
                      placeholder=" Employee Number"
                      autoComplete="off"
                      disabled={true}
                      value={this.state.changePasswordForm.mobile}
                      onChange={(e) =>
                        this.formInputHandler(e, "changePasswordForm")
                      }
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-sm-3 col-form-label col-form-label-sm">
                    New Password
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="password"
                      name="password"
                      className="form-control form-control-sm"
                      placeholder=" New Password"
                      autoComplete="off"
                      value={this.state.changePasswordForm.password}
                      onChange={(e) =>
                        this.formInputHandler(e, "changePasswordForm")
                      }
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-sm-3 col-form-label col-form-label-sm">
                    Confirm Password
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="password"
                      name="password_confirmation"
                      className="form-control form-control-sm"
                      placeholder="Confirm Password"
                      autoComplete="off"
                      value={
                        this.state.changePasswordForm.password_confirmation
                      }
                      onChange={(e) =>
                        this.formInputHandler(e, "changePasswordForm")
                      }
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-sm-3 col-form-label col-form-label-sm">
                    Notes
                  </label>
                  <div className="col-sm-9">
                    <textarea
                      className="form-control"
                      name="notes"
                      value={this.state.changePasswordForm.notes}
                      onChange={(e) =>
                        this.formInputHandler(e, "changePasswordForm")
                      }
                      style={{ height: "60px", fontSize: "93%" }}
                      placeholder="Enter Notes"
                    />
                  </div>
                </div>
              </div>

              <div className=" col-12 form-text">
                <sup className=" fs11">#</sup>
                Employee can login using mobile number or employee code
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  disabled={this.state.saveFormSubmitting ? true : false}
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  disabled={this.state.saveFormSubmitting ? true : false}
                  className="btn btn-primary mx-2"
                >
                  Save{" "}
                  {this.state.saveFormSubmitting ? (
                    <TapIcon.FontAwesomeIcon
                      icon={TapIcon.faSyncAlt}
                      className="fa-spin"
                    />
                  ) : (
                    ""
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  handleExcelDownload = () => {
    this.excelDownloadModalRef.current.handleExcelDownload();
  };

  employeeSiteTranstisionModalJsx = () => {
    let max_date = new Date();
    if (
      this.state.editSiteTranstionForm &&
      this.state.editSiteTranstionForm.fist_transaction_date
    ) {
      max_date = DateService.subDays(
        1,
        this.state.editSiteTranstionForm.fist_transaction_date
      );
    } else if (
      this.state.editSiteTranstionForm &&
      this.state.editSiteTranstionForm.till
    ) {
      max_date = DateService.subDays(1, this.state.editSiteTranstionForm.till);
    }

    return (
      <div className="modal fade" id="editSiteTransititonModal" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="changeSiteModalLabel">
                Change Site Transtion
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form onSubmit={this.submitChangeSiteTranstionForm}>
              <div className="modal-body">
                {this.state.edit_type == "site" ? (
                  <div className="row mb-3">
                    <label className="col-sm-3 col-form-label col-form-label-sm require">
                      {" "}
                      Site
                    </label>
                    <div className="col-sm-9">
                      <TapSelect
                        options={this.state.iam_user_sites}
                        changeEvent={(selectedOption) => {
                          this.tapSelectChange(
                            selectedOption,
                            "editSiteTranstionForm",
                            "site_id"
                          );
                        }}
                        isSearchable={true}
                        isClearable={true}
                        menuPlacement="bottom"
                        value={this.state.iam_user_sites.find(
                          (s) =>
                            this.state.editSiteTranstionForm.site_id == s.value
                        )}
                        placeholder="Select Site"
                        containerHeight="30px"
                        fontSize="93%"
                        required={true}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="row mb-3">
                    <label className="col-sm-3 col-form-label col-form-label-sm require">
                      Transfer Date
                    </label>
                    <div className="col-sm-9">
                      <DatePicker
                        selected={
                          this.state.editSiteTranstionForm.site_since_date
                            ? moment(
                                this.state.editSiteTranstionForm.site_since_date
                              ).toDate()
                            : false
                        }
                        name="site_since_date"
                        onChange={(value, event) => {
                          this.formDateHandler(
                            "site_since_date",
                            value,
                            "editSiteTranstionForm",
                            "YYYY-MM-DD HH:mm:ss"
                          );
                        }}
                        dateFormat="dd-MMM-yyyy hh:mm a"
                        className={"form-control form-control-sm"}
                        showMonthDropdown
                        showYearDropdown
                        showTimeSelect
                        timeIntervals={5}
                        timeCaption="Time"
                        autoComplete="off"
                        // popperPlacement="right-start"
                        scrollMonthYearDropdown
                        required={true}
                        maxDate={max_date}
                        placeholderText={`Please Enter Date of Transfer`}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  disabled={this.state.saveFormSubmitting ? true : false}
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  disabled={this.state.saveFormSubmitting ? true : false}
                  className="btn btn-primary mx-2"
                >
                  Save{" "}
                  {this.state.saveFormSubmitting ? (
                    <TapIcon.FontAwesomeIcon
                      icon={TapIcon.faSyncAlt}
                      className="fa-spin"
                    />
                  ) : (
                    ""
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  render = () => {
    return (
      <ApplicationLayout>
        <Helmet>
          <title>Employee Listing</title>
        </Helmet>
        {this.employeeListjsx()}
        {this.employeeBulkUploadModalJsx()}
        {this.employeeSearchModalJsx()}
        {this.employeeSiteChangeModalJsx()}
        {this.employeeStatusChangeModalJsx()}
        {this.employeeChangePasswordModalJsx()}
        {this.employeeSiteTranstisionModalJsx()}
        <EmpCustomeDownloadModal
          ref={this.excelDownloadModalRef}
          submittedEmployeeSearchForm={this.state.submittedEmployeeSearchForm}
        />
        <EmployeeDetailModal
          ref={this.editDetailModalRef}
          employeeData={this.state.employeeData}
          viewEmployeeDetail={(id) => this.viewEmployeeDetail(id, true)}
        />
        <EmpContactDetailModal
          ref={this.editContactModalRef}
          employeeData={this.state.employeeData}
          viewEmployeeDetail={(id) => this.viewEmployeeDetail(id, true)}
        />
        <BulkEducationUpload
          ref={this.bulkEducationModalRef}
          loadListingTblData={this.loadListingTblData}
        />
        <BulkFamilyUpload
          ref={this.bulkFamilyModalRef}
          loadListingTblData={this.loadListingTblData}
        />
        <BulkWorkingUpload
          ref={this.bulkWorkingModalRef}
          loadListingTblData={this.loadListingTblData}
        />
      </ApplicationLayout>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    access_token: state.auth.access_token,
    iam_user_sites:
      state.app && state.app.user_sites ? state.app.user_sites : [],
    iam_asset_types:
      state.app && state.app.asset_type ? state.app.asset_type : [],
    asset_groups:
      state.app && state.app.asset_groups ? state.app.asset_groups : [],
    permissions:
      state.app.acl_info &&
      state.app.acl_info.permissions &&
      state.app.acl_info.permissions.length > 0
        ? state.app.acl_info.permissions
        : [],
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  EmployeeList
);
