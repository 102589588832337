import React from 'react';
import { Link } from "react-router-dom";
import Ax from '../components/hoc/Ax';
import {connect} from 'react-redux';
import * as actions from '../store/actions/index';
import ApplicationHeader from '../pages/includes/ApplicationHeader';
import ApplicationSidebar from '../pages/includes/ApplicationSidebar';
import './ApplicationLayout.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ApplicationLayout extends React.Component {
    
    constructor() {
        super();
        this.state      =   {
            isMinimizeSidebar   :   false,
            opensubmenu_id      :   null,
        };
    }
    
    componentDidMount() {
        
    }
    
    render                          =   ()  =>  {
        return (
            <Ax>
                <ToastContainer icon={true} />
                <div id="wrapper">
                    <ApplicationHeader />
                    <ApplicationSidebar />
                    <div className="main-container">{this.props.children}</div>
                </div>
            </Ax>
        )
    }
}

export default ApplicationLayout;
