import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../../components/AppBaseComponent';
import HttpAPICall from '../../../../services/HttpAPICall';
import TapApiUrls from '../../../../services/TapApiUrls';
import { Modal } from 'bootstrap';
import Ax from "../../../../components/hoc/Ax";
import Loader from "../../../../components/ui/Loader/Loader";
import TapSelect from '../../../../components/ui/TapSelect';
import moment from 'moment';
import DatePicker from "react-datepicker";
import TapIcon from '../../../../services/TapIcon';
import { toast } from 'react-toastify';
import swal from "sweetalert";
import uuid from 'react-uuid';
import AssetActivityView from '../../../transactions/assetActivity/AssetActivityView';

class Activity extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.addActivityFormInit    =   {
            activity_id             :      '',
            transaction_id          :      '',
            activity_type           :      '',  
            activity                :      '',
            activity_new            :      '',
            activity_count          :      '',
            start_hrm               :      '',
            start_km                :      '',
            start_opc               :      '',
            time_start              :      '',
            close_hrm               :      '',
            close_km                :      '',
            close_opc               :      '',
            time_close              :      '',
            running_hrm             :      '',
            running_km              :      '',
            running_opc             :      '',
            production              :      '',
            material_id             :      '',
            material_new            :      '',
            attachment_id           :      '',
            attachment_new          :      '',
            workstation_id          :      '',
            workstation_new         :       '',
            workstation_to_id       :       '',
            workstation_to_new      :       '',
            operator_name           :       '',
            operator_name_new       :       '',
            remarks                 :       '',
            customer                :       '',
        }
        
        this.initState              =       {
            formDataLoading             :   '',
            addActivityForm             :   {...this.addActivityFormInit},
            addActivityFormSubmitting   :   false,
            allActivityType             :   [],
            allActivityList             :   [],
            allAttachmentList           :   [],
            allOperatorList             :   [],
            allMaterialList             :   [],
            allWorkstationList          :   [],
            allProductionUnits          :   [],
            addNewActivity              :   'N',
            addNewUnit                  :   'N',
            addNewMaterial              :   'N',
            addNewWorkstation           :   'N',
            addNewWorkstationTo         :   'N',
            addNewOperators             :   'N',
            addNewAttachment            :   'N',
            lastActivityData            :   null,
            activityShiftDetail         :   null,
            copy_reading_activity       :   'Y',
            activityData                :   null,
            viewDataLoading             :   false,
            activityViewData            :   null,
            allAdditonalAttribute       :   [],
            allProductionFormulas       :   [],
            linkedProductivityFormula   :   null,
            linkedErrorMessage          :   '',
            runtime_material_creation   :   'N',
            runtime_activity_creation   :   'N',
            runtime_productivity_unit_creation  :   'N'
        }
        this.state                  =   {
            access_token                :   '',
            transaction_id              :   '',
            counterlog                  :   null,
            assetData                   :   null,
            shift                       :   null,
            date                        :   '',
           ...this.initState
        }
        this.uploadActivityModalId      =   uuid();
        this.viewActivityModalId        =   uuid();
    }
    
    componentDidMount                   =   ()  =>  {
        this.initalizeComponent(this.props);
        this.uploadActivityModal        =   new Modal(document.getElementById(this.uploadActivityModalId), {keyboard: false, backdrop: false}); 
        this.viewActivityModal        =   new Modal(document.getElementById(this.viewActivityModalId), {keyboard: false, backdrop: false}); 
    }
    
    componentWillReceiveProps(nextProps) {
        this.initalizeComponent(nextProps);
    }
    
    initalizeComponent                  =   (pr)  =>  {
        this.setState({access_token : pr.access_token});
    }
    
    loadFormDataFromApi                 =   (id,activityData = null)  =>  {
        this.setState({formDataLoading: true, activityData : activityData})
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/counterlog/activity/form_data/' + id, this.state.access_token , {}, {}, (response) => {
          
           let respData = response.data;
           this.setState({
               allActivityType             :       respData && respData.activity_type && respData.activity_type.map(s => {return({label : s.name , value:s.key})}),
               allActivityList             :       respData && respData.activities && respData.activities.length > 0 ? respData.activities.map(a => { return ({ value: a.id, label: a.activity_name, production_units: a.production_units }) }) : [],
               allAttachmentList           :       respData && respData.attachments && respData.attachments.length > 0 ? respData.attachments.map(a => { return ({ value: a.id, label: a.name }) }) : [],
               allOperatorList             :       respData && respData.employees && respData.employees.length > 0 ? respData.employees.map(a => { return ({ value: a.display_full_name, label: a.display_full_name }) }) : [],
               allMaterialList             :       respData && respData.materials && respData.materials.length > 0 ? respData.materials.map(a => { return ({ value: a.id, label: a.material_name }) }) : [],
               allWorkstationList          :       respData && respData.workstations && respData.workstations.length > 0 ? respData.workstations.map(a => { return ({ value: a.id, label: a.workstation_name }) }) : [],
               lastActivityData            :       respData && respData.last_activity  && JSON.stringify(respData.last_activity) !== JSON.stringify({}) ? respData.last_activity : null,
               activityShiftDetail         :       respData && respData.shift ? respData.shift : null,
               copy_reading_activity       :       respData && respData.last_activity && JSON.stringify(respData.last_activity) !== JSON.stringify({}) ? 'Y' : 'N',
               allAdditonalAttribute       :       respData && respData.additional_attributes && respData.additional_attributes.length > 0 ? respData.additional_attributes : [],
               allProductionFormulas       :       respData && respData.apply_prodution_formula == 'Y' && respData.production_formulas && respData.production_formulas.length > 0 ? respData.production_formulas : [],
            runtime_material_creation      :       respData && respData.runtime_material_creation && respData.runtime_material_creation == 'Y' ? 'Y' : 'N',
            runtime_activity_creation      :       respData && respData.runtime_activity_creation && respData.runtime_activity_creation == 'Y' ? 'Y' : 'N',
            runtime_productivity_unit_creation   :       respData && respData.runtime_productivity_unit_creation && respData.runtime_productivity_unit_creation == 'Y'  ? 'Y' : 'N',
        },() => {
                
                if(this.state.lastActivityData && activityData == null) {
                  this.copyPreviousHandler()    
                }

                if(activityData){
    
                    let previousData                     =      activityData;
                    let updateFormState = [];
                    if (previousData.productivity && previousData.productivity.additional_attributes) {
                        Object.keys(previousData.productivity.additional_attributes).map((k) => {
                            let name =  'additional_attributes_' + k;
                            updateFormState[name] = previousData.productivity.additional_attributes[k]
                        });
                    }

                 
                  
                    let activityListContainingProduction =      this.state.allActivityList && this.state.allActivityList.length > 0 && this.state.allActivityList.find(p => { return (p.value == activityData.activity) });
                    let allProductionUnitList            =       activityListContainingProduction && activityListContainingProduction.production_units && activityListContainingProduction.production_units.length > 0   ?  activityListContainingProduction.production_units.map((d) => {  return ({ value: d.measuring_unit, label: d.measuring_unit }) })  : [];
                    
                    this.setState({allProductionUnits : allProductionUnitList},
                        () => {
                            
                            this.setState({addActivityForm : {
                                activity_id             :      previousData.activity ? previousData.activity : '',
                                activity_type           :      previousData.activity_type ? previousData.activity_type : '',  
                                unit                    :      previousData.productivity && previousData.productivity.production_unit ? previousData.productivity.production_unit : '',
                                activity_count          :      previousData.activity_count ? previousData.activity_count : '',
                                material_id             :      previousData.material ? previousData.material : '',
                                material_new            :      previousData.material_new ? previousData.material_new : '',
                                attachment_id           :      previousData.attachment ? previousData.attachment : '',
                                workstation_id          :      previousData.workstation_to ? previousData.workstation_to : '',
                                workstation_to_id       :      previousData.workstation_from && previousData.activity_type == "trip" ? previousData.workstation_from : '',
                                operator_name           :      previousData.operator_name ? previousData.operator_name : '',
                                remarks                 :      previousData.remarks ? previousData.remarks : '',
                                customer                :      previousData.customer ? previousData.customer : '',
                                start_hrm               :      previousData.start_hrm ? previousData.start_hrm : '',
                                start_km                :      previousData.start_km ? previousData.start_km : '',
                                start_opc               :      previousData.start_opc ? previousData.start_opc : '',
                                time_start              :      previousData.time_start ? moment(previousData.time_start, "hh:mm a").toDate()  : '',
                                close_hrm               :      previousData.close_hrm ? previousData.close_hrm : '',
                                close_km                :      previousData.close_km ? previousData.close_km : '',
                                close_opc               :      previousData.close_opc ? previousData.close_opc : '',
                                time_close              :      previousData.time_close ? moment(previousData.time_close, "hh:mm a").toDate()  : '',
                                running_hrm             :      previousData.running_hrm ? previousData.running_hrm : '',
                                running_km              :      previousData.running_km ? previousData.running_km : '',
                                running_opc             :      previousData.running_opc ? previousData.running_opc : '',
                                asset_activity_id       :      previousData.asset_activity_id ? previousData.asset_activity_id : '',
                                production              :      previousData.productivity && previousData.productivity.production ? previousData.productivity.production : '',
                                ...updateFormState
                            },activityData : activityData,copy_reading_activity : 'N',lastActivityData : null}
                            ,()=> this.getLinkedProductivityFormula());
                        })
                        
                }
                
                
            })
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => this.setState({formDataLoading: false}))
    }
    
    uploadActivityModalInit             =   (shiftDetail, counterlog, assetData = null,activityData = null)  =>  {
        this.uploadActivityModal.show();

        this.setState({
            assetData           :   assetData,
            shift               :   shiftDetail,
            counterlog          :   counterlog,
            date                :   counterlog ? counterlog.feed_date : '',
            transaction_id      :   counterlog ? counterlog.transaction_id : '',
            ...this.initState,
            copy_reading_activity   :   activityData ? 'N' : 'Y',
        });
        if(counterlog && counterlog.transaction_id){
            this.loadFormDataFromApi(counterlog.transaction_id,activityData)
        }
        if(document.getElementById("uploadActivityForm")) {
            document.getElementById("uploadActivityForm").reset();
        }
            
    } 

    getLinkedProductivityFormula        =       ()      =>      {
        let linkedFormula = null;
        if(this.state.addActivityForm && this.state.addActivityForm.activity_id){
             linkedFormula = this.state.allProductionFormulas && this.state.allProductionFormulas.length > 0 ? this.state.allProductionFormulas.find(formula => {return(formula.activity_id == this.state.addActivityForm.activity_id)}) : null;

             if(linkedFormula){
                this.setState({
                    linkedProductivityFormula   : linkedFormula,
                    addActivityForm             :       {
                        ...this.state.addActivityForm,
                        unit                     :       linkedFormula.unit_name,
                    },
                    linkedErrorMessage          :    ''
                },() => this.calulateProductivityFormula())
            }else{
                this.setState({
                    linkedProductivityFormula    :   null,
                    linkedErrorMessage           :    ''
                })
            }
        }
    }

    calulateProductivityFormula     =       (e)      =>      {
        if(this.state.allAdditonalAttribute && this.state.allAdditonalAttribute.length > 0 ){
           let  list = this.state.allAdditonalAttribute.filter(key => key.type == "numeric"  ? true : false)
            list.forEach((i, k) => {
                let name = 'additional_attributes_' + i.key;
                window[i.formula_key] = this.state.addActivityForm[name] ? Number(this.state.addActivityForm[name]) : '';
                if (this.state.linkedProductivityFormula && this.state.linkedProductivityFormula.formula && this.state?.linkedProductivityFormula?.formula?.includes(i.formula_key) && document.getElementById(i.formula_key)) {
                    document.getElementById(i.formula_key).required = "required"
                }
            })
            let running_hrm = this.state.addActivityForm.running_hrm ? Number(this.state.addActivityForm.running_hrm) : '';
            let running_km  =   this.state.addActivityForm.running_km ? Number(this.state.addActivityForm.running_km) : '';
            let running_opc =   this.state.addActivityForm.running_opc ? Number(this.state.addActivityForm.running_opc) : '';

            if (this.state.linkedProductivityFormula && this.state.linkedProductivityFormula.formula && this.state?.linkedProductivityFormula?.formula?.includes('running_hrm') && document.getElementById('running_hrm')) {
                document.getElementById('running_hrm').required = "required"
            }
            if (this.state.linkedProductivityFormula && this.state.linkedProductivityFormula.formula && this.state?.linkedProductivityFormula?.formula?.includes('running_km') && document.getElementById('running_km')) {
                document.getElementById('running_km').required = "required"
            }
            if (this.state.linkedProductivityFormula && this.state.linkedProductivityFormula.formula && this.state?.linkedProductivityFormula?.formula?.includes('running_opc') && document.getElementById('running_opc')) {
                document.getElementById('running_opc').required = "required"
            }

            try {   
               let net_productivity =  eval(this.state.linkedProductivityFormula.formula);
               this.setState({
                    addActivityForm     :       {
                        ...this.state.addActivityForm,
                        production                :       net_productivity ? parseFloat(net_productivity).toFixed(2) : ''
                    }
               })
            }catch(e){
               
            }
        } 
    
        
    }

   copyPreviousHandler                 =   ()  =>  {
       if (this.state.copy_reading_activity == "Y" && this.state.lastActivityData) {
        let previousData = this.state.lastActivityData;
        let allProductionUnits               =       [];
        let activityListContainingProduction =      this.state.allActivityList && this.state.allActivityList.length > 0 && this.state.allActivityList.find(p => { return (p.value == this.state.lastActivityData.activity) });
        let allProductionUnitList           =       activityListContainingProduction && activityListContainingProduction.production_units && activityListContainingProduction.production_units.length > 0   ?  activityListContainingProduction.production_units.map((d) => {  return ({ value: d.measuring_unit, label: d.measuring_unit }) })  : [];
        
        this.setState({allProductionUnits : allProductionUnitList},
                () => {
                    this.setState({addActivityForm : {
                        ...this.addActivityFormInit,
                        activity_id             :      previousData.activity ? previousData.activity : '',
                        activity_type           :      previousData.activity_type ? previousData.activity_type : '',  
                        activity_new            :      previousData.activity_new ? previousData.activity_new : '',
                        material_id             :      previousData.material ? previousData.material : '',
                        material_new            :      previousData.material_new ? previousData.material_new : '',
                        attachment_id           :      previousData.attachment ? previousData.attachment : '',
                        workstation_id          :      previousData.workstation_to ? previousData.workstation_to : '',
                        workstation_to_id       :      previousData.workstation_from ? previousData.workstation_from : '',
                        operator_name           :      previousData.operator_name ? previousData.operator_name : '',
                        remarks                 :      previousData.remarks ? previousData.remarks : '',
                        customer                :      previousData.customer ? previousData.customer : '',
                        unit                    :      previousData.productivity && previousData.productivity.production_unit ? previousData.productivity.production_unit : ''
                   
                    }},()=> this.getLinkedProductivityFormula());
                })
            

       } else {
          this.setState({
             addActivityForm             :   {...this.addActivityFormInit},
             linkedProductivityFormula   :   null,
             linkedErrorMessage          :   ''
            // copy_reading_activity   :    this.state.copy_reading_activity ==  'Y' ? 'N' : 'Y' ,
            // activityData            :   null
        })
       }
    }
  
    submitActivityForm                  =   (e) =>  {
        e.preventDefault()
        let addActivityForm = {
            ...this.state.addActivityForm , 
            workstation_to_id   : this.state.addActivityForm.activity_type == 'session' ? '' : this.state.addActivityForm.workstation_to_id,
            workstation_to_new  : this.state.addActivityForm.activity_type == 'session' ? '' : this.state.addActivityForm.workstation_to_new,
            time_start : this.state.addActivityForm.time_start ? moment(this.state.addActivityForm.time_start).format("HH:mm") : null,
            time_close : this.state.addActivityForm.time_close ? moment(this.state.addActivityForm.time_close).format("HH:mm") : null,
        }
        

        let additional_attributes = {};
       if(this.state.allAdditonalAttribute && this.state.allAdditonalAttribute.length > 0 ){
           this.state.allAdditonalAttribute.forEach((i, k) => {
               let name = 'additional_attributes_' + i.key;
               additional_attributes[i.key] = this.state.addActivityForm[name] ? this.state.addActivityForm[name] : '';
           })
       } 
            
        if (this.state.activityData) {
            this.setState({ addActivityFormSubmitting: true });
            HttpAPICall.withAthorization('PUT', TapApiUrls.IAM + '/counterlog/activity', this.props.access_token, null, { ...addActivityForm, transaction_id: this.state.transaction_id,additional_attributes : additional_attributes }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.uploadActivityModal.hide();
                if (this.props.afterSaveActivity) {
                    let asset_id = this.state.assetData ? this.state.assetData.asset_id : null;
                    this.props.afterSaveActivity(this.state.date, asset_id);
                }
                this.setState({...this.initState})
            }).then(() => this.setState({ addActivityFormSubmitting: false }))
        } else {
            this.setState({ addActivityFormSubmitting: true });
            HttpAPICall.withAthorization('POST', TapApiUrls.IAM + '/counterlog/activity', this.props.access_token, null, { ...addActivityForm, transaction_id: this.state.transaction_id,additional_attributes : additional_attributes }, (response) => {
                toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                this.uploadActivityModal.hide();
                if (this.props.afterSaveActivity) {
                    let asset_id = this.state.assetData ? this.state.assetData.asset_id : null;
                    this.props.afterSaveActivity(this.state.date, asset_id);
                }

                this.setState({...this.initState})
            }).then(() => this.setState({ addActivityFormSubmitting: false }))
        }
        
    }
    
    deleteActivityInit                      =   (id)  =>  {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
                HttpAPICall.withAthorization('DELETE', TapApiUrls.IAM + '/counterlog/activity/' + id , this.props.access_token, {},{} ,(response) => {
                    toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                    if(this.props.afterDeleteActivity) {
                        this.props.afterDeleteActivity();
                    }
                }).then(() => { });
            }
        });
    }

    viewActivityModalInit             =   (shiftDetail, counterlog, assetData = null,data)  =>  {
        this.viewActivityModal.show()
        this.setState({activityViewData : null , viewDataLoading : true}
            , () => {
                this.setState({
                    assetData               :   assetData,
                    shift                   :   shiftDetail,
                    counterlog              :   counterlog,
                    date                    :   counterlog ? counterlog.feed_date : '',
                    transaction_id          :   counterlog ? counterlog.transaction_id : '',
                    activityViewData        :   data ? data : null,
                    viewDataLoading         :   false
                });
         })
    }

    uploadActivityModalJsx              =   ()  =>  {
        let max_time = moment(new Date(), "hh:mm a ").toDate();
        if(this.state.activityShiftDetail){
            if(this.state.activityShiftDetail && this.state.activityShiftDetail.shift_start == this.state.activityShiftDetail.shift_end){
                max_time  = moment(this.state.activityShiftDetail.shift_end, "hh:mm a").add(1, 'days').subtract(60,'seconds').toDate();
            }else{
                max_time  = moment(this.state.activityShiftDetail.shift_end, "hh:mm a").toDate()
            }
        }
    
        return (
            <div className="modal fade" id={this.uploadActivityModalId} tabIndex="-1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="fs-6 m-0">
                                <span>Upload Shift Activity for : </span>{this.state.counterlog ? this.state.counterlog.feed_date_display : null}
                                {this.state.shift ? <span className="ps-2">{this.state.shift.shift_name} ({this.state.shift.shift_start})</span> : null}
                            </h5>
                            <button type="button" className="btn-close" disabled={this.state.addActivityFormSubmitting} data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "26%" }}> Asset Name (Code)   </td>
                                        <th>{this.state.assetData ? `${this.state.assetData.name} (${this.state.assetData.asset_code})` : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td>Asset Type </td><th>{this.state.assetData ? `${this.state.assetData.asset_type_name} ` : "-"}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-body mx-1">
                            {this.state.formDataLoading
                                ? <Loader />
                                : <form className={['p-2',].join(' ')} onSubmit={this.submitActivityForm} id="uploadActivityForm">
                                    <div className="tab_content_wrapper fw-bold mb-2">Activity Basic Details</div>
                                    <div className="row ">
                                        <div className='col-sm-3'>
                                            <div className='fw-bold require col-form-label-sm'>Activity Type</div>
                                            <div className='my-2'>
                                                <TapSelect
                                                    options={this.state.allActivityType}
                                                    changeEvent={(selectedOption) => {
                                                        this.tapSelectChange(selectedOption, 'addActivityForm', 'activity_type');
                                                    }}
                                                    isSearchable={true}
                                                    value={this.state.allActivityType.find(s => this.state.addActivityForm.activity_type == s.value)}
                                                    isClearable={true}
                                                    placeholder="Select Activity"
                                                    autoCompelete="off"
                                                    required={true}
                                                    autoFocus={true}
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>
                                        </div>
                                        <div className='col-sm-3'>
                                            <div className='fw-bold require col-form-label-sm'>Activity Name
                                               {this.state.runtime_activity_creation == 'Y' &&  <div className='float-end'>
                                                    <input
                                                        name="addNewActivity"
                                                        type="checkbox"
                                                        value={this.state.newActivity}
                                                        onChange={(e) => {
                                                            let allProductionUnits = [];
                                                            let activityListContainingProduction = this.state.allActivityList && this.state.allActivityList.length > 0 && this.state.allActivityList.filter(p => { return (p.production_units && p.production_units.length > 0 ? true : false) });
                                                            let allProductionUnitList = activityListContainingProduction && activityListContainingProduction.length > 0 && allProductionUnits.concat(activityListContainingProduction.flatMap((d) => { return d.production_units.map(p => { return ({ value: p.measuring_unit, label: p.measuring_unit }) }) }))
                                                            const key = 'value';
                                                            const UniqueProductionUnitByKey = allProductionUnitList && allProductionUnitList.length > 0 && [...new Map(allProductionUnitList.map(item => [item[key], item])).values()];

                                                            this.setState({
                                                                addNewActivity: this.state.addNewActivity == 'N' ? 'Y' : 'N',
                                                                allProductionUnits: this.state.addNewActivity == 'N' && UniqueProductionUnitByKey && UniqueProductionUnitByKey.length > 0 ? UniqueProductionUnitByKey : [],
                                                                addActivityForm: this.state.addNewActivity == 'N' ? { ...this.state.addActivityForm, activity_id: '' } : { ...this.state.addActivityForm }
                                                            })
                                                        }}
                                                        checked={this.state.addNewActivity == 'Y'}
                                                        className="form-check-input"
                                                        id="addNewActivity"

                                                    />
                                                    <label className="form-check-label mx-2 text-sm" htmlFor="addNewActivity">Add New </label>
                                                </div>}
                                            </div>
                                            <div className='my-2'>
                                                {this.state.addNewActivity == "Y"
                                                    ? <input
                                                        name="activity_new"
                                                        type="text"
                                                        value={this.state.addActivityForm.activity_new}
                                                        className="form-control form-control-sm"
                                                        autoComplete="off"
                                                        onChange={(e) => this.formInputHandler(e, "addActivityForm")}
                                                        placeholder="Please enter Activity Name"
                                                        required={true}
                                                    />
                                                    : <TapSelect
                                                        options={this.state.allActivityList}
                                                        changeEvent={(selectedOption) => {
                                                            this.tapSelectChange(selectedOption, 'addActivityForm', 'activity_id');
                                                            this.setState({
                                                                allProductionUnits: selectedOption && selectedOption.production_units && selectedOption.production_units.length > 0 ? selectedOption.production_units.map(p => { return ({ value: p.measuring_unit, label: p.measuring_unit }) }) : []
                                                            }, () => this.getLinkedProductivityFormula())
                                                        }}
                                                        isSearchable={true}
                                                        value={this.state.allActivityList.find(s => this.state.addActivityForm.activity_id == s.value)}
                                                        isClearable={true}
                                                        placeholder="Select Activity Name"
                                                        autoCompelete="off"
                                                        required={true}
                                                        containerHeight="30px"
                                                        fontSize="93%"
                                                    />}
                                            </div>
                                        </div>
                                        <div className='col-sm-3'>
                                            <div className='fw-bold require col-form-label-sm'>No. of Session or Trip</div>
                                            <div className='my-2'>
                                                <input
                                                    name="activity_count"
                                                    type="number"
                                                    value={this.state.addActivityForm.activity_count}
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "addActivityForm")}
                                                    placeholder="Please enter No. of Session/Trip"
                                                    required={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab_content_wrapper fw-bold mb-2">Activity Measurement</div>
                                    <div className="row ">
                                        <div className='col-sm-3'>
                                            <div className='fw-bold col-form-label-sm'>Measuring Unit</div>
                                        </div>
                                        <div className='col-sm-3'>
                                            <div className='fw-bold  col-form-label-sm'>Starting Reading</div>
                                        </div>
                                        <div className='col-sm-3'>
                                            <div className='fw-bold col-form-label-sm'>Stopping Reading</div>
                                        </div>
                                        <div className='col-sm-3'>
                                            <div className='fw-bold col-form-label-sm'>Total Running</div>
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className='col-sm-3 col-form-label col-form-label-sm my-1'>
                                            Hour Meter
                                        </div>
                                        <div className='col-sm-3 my-1'>
                                            <input
                                                name="start_hrm"
                                                type="number"
                                                value={this.state.addActivityForm.start_hrm}
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                onChange={(e) => { this.formInputHandler(e, "addActivityForm",(e) => this.calulateProductivityFormula(e)); }}
                                                onKeyUp={(e) => {
                                                    if (this.state.addActivityForm.start_hrm && this.state.addActivityForm.start_hrm > 0 && this.state.addActivityForm.close_hrm && this.state.addActivityForm.close_hrm > 0) {
                                                        let running_hrm = parseFloat(this.state.addActivityForm.close_hrm).toFixed(2) - parseFloat(this.state.addActivityForm.start_hrm).toFixed(2);
                                                        this.setState({ addActivityForm: { ...this.state.addActivityForm, running_hrm: running_hrm } },() => this.calulateProductivityFormula(e))
                                                    }
                                                }}

                                                placeholder="Starting HRM"

                                            />
                                        </div>
                                        <div className='col-sm-3 my-1'>
                                            <input
                                                name="close_hrm"
                                                type="number"
                                                value={this.state.addActivityForm.close_hrm}
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "addActivityForm",(e) => this.calulateProductivityFormula(e))}
                                                onKeyUp={(e) => {
                                                    if (this.state.addActivityForm.start_hrm && this.state.addActivityForm.start_hrm > 0 && this.state.addActivityForm.close_hrm && this.state.addActivityForm.close_hrm > 0) {
                                                        let running_hrm = parseFloat(this.state.addActivityForm.close_hrm).toFixed(2) - parseFloat(this.state.addActivityForm.start_hrm).toFixed(2);
                                                        this.setState({ addActivityForm: { ...this.state.addActivityForm, running_hrm: running_hrm } },() => this.calulateProductivityFormula(e))
                                                    }
                                                }}
                                                placeholder="Stoping HRM"

                                            />
                                        </div>
                                        <div className='col-sm-3 my-1'>
                                            <input
                                                name="running_hrm"
                                                type="number"
                                                id="running_hrm"
                                                value={this.state.addActivityForm.running_hrm}
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "addActivityForm",(e) => this.calulateProductivityFormula(e))}
                                                onKeyUp={(e) => {
                                                    if (this.state.addActivityForm.running_hrm && this.state.addActivityForm.running_hrm > 0 && this.state.addActivityForm.start_hrm && this.state.addActivityForm.start_hrm > 0) {
                                                        let start_hrm = Number(this.state.addActivityForm.start_hrm); let running_hrm = Number(this.state.addActivityForm.running_hrm)
                                                        let close_hrm = start_hrm + running_hrm;
                                                        this.setState({ addActivityForm: { ...this.state.addActivityForm, close_hrm: parseFloat(close_hrm).toFixed(2) } },() => this.calulateProductivityFormula(e))
                                                    }
                                                }}
                                                placeholder="Total Running HRM"

                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className='col-sm-3 col-form-label col-form-label-sm my-1'>
                                            Odo Meter
                                        </div>
                                        <div className='col-sm-3 my-1'>
                                            <input
                                                name="start_km"
                                                type="number"
                                                value={this.state.addActivityForm.start_km}
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                onChange={(e) => { this.formInputHandler(e, "addActivityForm",(e) => this.calulateProductivityFormula(e)); }}
                                                onKeyUp={(e) => {
                                                    if (this.state.addActivityForm.start_km && this.state.addActivityForm.start_km > 0 && this.state.addActivityForm.close_km && this.state.addActivityForm.close_km > 0) {
                                                        let running_km = parseFloat(this.state.addActivityForm.close_km).toFixed(2) - parseFloat(this.state.addActivityForm.start_km).toFixed(2);
                                                        this.setState({ addActivityForm: { ...this.state.addActivityForm, running_km: running_km } },() => this.calulateProductivityFormula(e))
                                                    }
                                                }}

                                                placeholder="Starting KM"

                                            />
                                        </div>
                                        <div className='col-sm-3 my-1'>
                                            <input
                                                name="close_km"
                                                type="number"
                                                value={this.state.addActivityForm.close_km}
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "addActivityForm",() => this.calulateProductivityFormula(e))}
                                                onKeyUp={(e) => {
                                                    if (this.state.addActivityForm.start_km && this.state.addActivityForm.start_km > 0 && this.state.addActivityForm.close_km && this.state.addActivityForm.close_km > 0) {
                                                        let running_km = parseFloat(this.state.addActivityForm.close_km).toFixed(2) - parseFloat(this.state.addActivityForm.start_km).toFixed(2);
                                                        this.setState({ addActivityForm: { ...this.state.addActivityForm, running_km: running_km } },() => this.calulateProductivityFormula(e))
                                                    }
                                                }}
                                                placeholder="Stoping KM"

                                            />
                                        </div>
                                        <div className='col-sm-3 my-1'>
                                            <input
                                                name="running_km"
                                                type="number"
                                                id="running_km"
                                                value={this.state.addActivityForm.running_km}
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "addActivityForm",(e) => this.calulateProductivityFormula(e))}
                                                onKeyUp={(e) => {
                                                    if (this.state.addActivityForm.running_km && this.state.addActivityForm.running_km > 0 && this.state.addActivityForm.start_km && this.state.addActivityForm.start_km > 0) {
                                                        let start_km = Number(this.state.addActivityForm.start_km); let running_km = Number(this.state.addActivityForm.running_km)
                                                        let close_km = start_km + running_km;
                                                        this.setState({ addActivityForm: { ...this.state.addActivityForm, close_km: parseFloat(close_km).toFixed(2) } },() => this.calulateProductivityFormula(e))
                                                    }
                                                }}
                                                placeholder="Total Running KM"

                                            />
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className='col-sm-3 col-form-label col-form-label-sm my-1'>
                                            Operation Cycle
                                        </div>
                                        <div className='col-sm-3 my-1'>
                                            <input
                                                name="start_opc"
                                                type="number"
                                                value={this.state.addActivityForm.start_opc}
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                onChange={(e) => { this.formInputHandler(e, "addActivityForm",(e) => this.calulateProductivityFormula(e)); }}
                                                onKeyUp={(e) => {
                                                    if (this.state.addActivityForm.start_opc && this.state.addActivityForm.start_opc > 0 && this.state.addActivityForm.close_opc && this.state.addActivityForm.close_opc > 0) {
                                                        let running_opc = parseFloat(this.state.addActivityForm.close_opc).toFixed(2) - parseFloat(this.state.addActivityForm.start_opc).toFixed(2);
                                                        this.setState({ addActivityForm: { ...this.state.addActivityForm, running_opc: running_opc } },() => this.calulateProductivityFormula(e))
                                                    }
                                                }}

                                                placeholder="Starting OPC"

                                            />
                                        </div>
                                        <div className='col-sm-3 my-1'>
                                            <input
                                                name="close_opc"
                                                type="number"
                                                value={this.state.addActivityForm.close_opc}
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "addActivityForm",() => this.calulateProductivityFormula(e))}
                                                onKeyUp={(e) => {
                                                    if (this.state.addActivityForm.start_opc && this.state.addActivityForm.start_opc > 0 && this.state.addActivityForm.close_opc && this.state.addActivityForm.close_opc > 0) {
                                                        let running_opc = parseFloat(this.state.addActivityForm.close_opc).toFixed(2) - parseFloat(this.state.addActivityForm.start_opc).toFixed(2);
                                                        this.setState({ addActivityForm: { ...this.state.addActivityForm, running_opc: running_opc } },() => this.calulateProductivityFormula(e))
                                                    }
                                                }}
                                                placeholder="Stoping OPC"

                                            />
                                        </div>
                                        <div className='col-sm-3 my-1'>
                                            <input
                                                name="running_opc"
                                                type="number"
                                                id="running_opc"
                                                value={this.state.addActivityForm.running_opc}
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                                onChange={(e) => this.formInputHandler(e, "addActivityForm",(e) => this.calulateProductivityFormula(e))}
                                                onKeyUp={(e) => {
                                                    if (this.state.addActivityForm.running_opc && this.state.addActivityForm.running_opc > 0 && this.state.addActivityForm.start_opc && this.state.addActivityForm.start_opc > 0) {
                                                        let start_opc = Number(this.state.addActivityForm.start_opc); let running_opc = Number(this.state.addActivityForm.running_opc)
                                                        let close_opc = start_opc + running_opc;
                                                        this.setState({ addActivityForm: { ...this.state.addActivityForm, close_opc: parseFloat(close_opc).toFixed(2) } },() => this.calulateProductivityFormula(e))
                                                    }
                                                }}
                                                placeholder="Total Running OPC"

                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className='col-sm-3 col-form-label col-form-label-sm my-1'>
                                            Time
                                        </div>
                                        <div className='col-sm-3 my-1'>
                                            <DatePicker
                                                onChange={(value, event) => {
                                                    this.setState({ addActivityForm: { ...this.state.addActivityForm, time_start: value } });
                                                }}
                                                selected={this.state.addActivityForm.time_start ? this.state.addActivityForm.time_start : false}
                                                className="form-control form-control-sm"
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeFormat="hh:mm a"
                                                timeIntervals={5}
                                                timeCaption="Hours"
                                                dateFormat="hh:mm a"
                                                autoComplete={"off"}
                                                placeholderText={`Select Limit`}
                                                minTime={moment(this.state.activityShiftDetail ? this.state.activityShiftDetail.shift_start : new Date(), "hh:mm a ").toDate()}
                                                maxTime={max_time}
                                            />
                                        </div>
                                        <div className='col-sm-3 my-1'>
                                            <DatePicker
                                                onChange={(value, event) => {
                                                    this.setState({ addActivityForm: { ...this.state.addActivityForm, time_close: value } });
                                                }}
                                                selected={this.state.addActivityForm.time_close ? this.state.addActivityForm.time_close : false}
                                                className="form-control form-control-sm"
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeFormat="hh:mm a"
                                                timeIntervals={5}
                                                timeCaption="Hours"
                                                dateFormat="hh:mm a"
                                                autoComplete={"off"}
                                                placeholderText={`Select Limit`}
                                                minTime={moment(this.state.activityShiftDetail ? this.state.activityShiftDetail.shift_start : new Date(), "hh:mm a ").toDate()}
                                                maxTime={moment("11:55 P.M", "hh:mm a").toDate()}

                                            />
                                        </div>

                                    </div>
                                    <div className="tab_content_wrapper fw-bold mb-2">Productivity</div>
                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <div className='fw-bold col-form-label-sm'>Net Productivity</div>
                                            <div className='my-2'>
                                                <input
                                                    name="production"
                                                    type="text"
                                                    value={this.state.addActivityForm.production}
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "addActivityForm")}
                                                    placeholder="Please enter Net Productivity"
                                                    disabled={this.state.linkedProductivityFormula ? true : false}
                                                />
                                                <div className='form-text text-sm my-1'>{this.state.linkedProductivityFormula && this.state.linkedProductivityFormula.formula ? <span>Net Productivity = {this.state.linkedProductivityFormula.formula}</span> : ""}</div>
                                            </div>
                                        </div>
                                        <div className='col-sm-3'>
                                            <div className='fw-bold col-form-label-sm'>Productivity Units
                                                {this.state.runtime_productivity_unit_creation == 'Y' && <div className='float-end'>
                                                    <input
                                                        name="addNewUnit"
                                                        type="checkbox"
                                                        value={this.state.addNewUnit}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                addNewUnit: this.state.addNewUnit == 'N' ? 'Y' : 'N',
                                                                addActivityForm: this.state.addNewUnit == 'N' ? { ...this.state.addActivityForm, unit: '' } : { ...this.state.addActivityForm }
                                                            })
                                                        }}
                                                        checked={this.state.addNewUnit == 'Y'}
                                                        className="form-check-input"
                                                        id="addNewUnitActivity"
                                                        disabled={this.state.linkedProductivityFormula ? true : false}
                                                    />
                                                    <label className="form-check-label text-sm mx-2" htmlFor="addNewUnitActivity">Add New </label>
                                                </div>}
                                            </div>
                                            <div className='my-2'>
                                                {this.state.addNewUnit == "Y"
                                                    ? <input
                                                        name="unit_new"
                                                        type="text"
                                                        value={this.state.addActivityForm.unit_new}
                                                        className="form-control form-control-sm"
                                                        autoComplete="off"
                                                        onChange={(e) => this.formInputHandler(e, "addActivityForm")}
                                                        placeholder="Please enter Productivity Unit"

                                                    />
                                                    :
                                                    <TapSelect
                                                        options={this.state.allProductionUnits}
                                                        changeEvent={(selectedOption) => {
                                                            this.tapSelectChange(selectedOption, 'addActivityForm', 'unit');
                                                        }}
                                                        isSearchable={true}
                                                        value={this.state.allProductionUnits.find(s => this.state.addActivityForm.unit == s.value)}
                                                        isClearable={true}
                                                        placeholder="Select Productivity"
                                                        autoCompelete="off"
                                                        isDisabled={this.state.linkedProductivityFormula ? true : false}
                                                        containerHeight="30px"
                                                        fontSize="93%"
                                                    />
                                                }
                                                <div className='text-danger text-sm'>{this.state.linkedErrorMessage ? this.state.linkedErrorMessage : ""}</div>
                                            </div>
                                        </div>
                                        <div className='col-sm-3'>
                                            <div className='fw-bold col-form-label-sm'>Material Name
                                               {this.state.runtime_material_creation == 'Y' && <div className='float-end'>
                                                    <input
                                                        name="addNewMaterial"
                                                        type="checkbox"
                                                        value={this.state.addNewMaterial}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                addNewMaterial: this.state.addNewMaterial == 'N' ? 'Y' : 'N',
                                                                addActivityForm: this.state.addNewMaterial == 'N' ? { ...this.state.addActivityForm, material_id: '' } : { ...this.state.addActivityForm }
                                                            })
                                                        }}
                                                        checked={this.state.addNewMaterial == 'Y'}
                                                        className="form-check-input"
                                                        id="addNewMaterialActivity"

                                                    />
                                                    <label className="form-check-label text-sm mx-2" htmlFor="addNewMaterialActivity">Add New </label>
                                                </div>}
                                            </div>
                                            <div className='my-2'>
                                                {this.state.addNewMaterial == "Y"
                                                    ? <input
                                                        name="material_new"
                                                        type="text"
                                                        value={this.state.addActivityForm.material_new}
                                                        className="form-control form-control-sm"
                                                        autoComplete="off"
                                                        onChange={(e) => this.formInputHandler(e, "addActivityForm")}
                                                        placeholder="Please enter Material"

                                                    />
                                                    : <TapSelect
                                                        options={this.state.allMaterialList}
                                                        changeEvent={(selectedOption) => {
                                                            this.tapSelectChange(selectedOption, 'addActivityForm', 'material_id');
                                                        }}
                                                        isSearchable={true}
                                                        value={this.state.allMaterialList.find(s => this.state.addActivityForm.material_id == s.value)}
                                                        isClearable={true}
                                                        placeholder="Select Material"
                                                        autoCompelete="off"
                                                        containerHeight="30px"
                                                        fontSize="93%"
                                                    />}
                                            </div>
                                        </div>
                                        <div className='col-sm-3'>
                                            <div className='fw-bold col-form-label-sm'>Attachment
                                                <div className='float-end'>
                                                    <input
                                                        name="addNewAttachment"
                                                        type="checkbox"
                                                        value={this.state.addNewAttachment}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                addNewAttachment: this.state.addNewAttachment == 'N' ? 'Y' : 'N',
                                                                addActivityForm: this.state.addNewAttachment == 'N' ? { ...this.state.addActivityForm, attachment_id: '' } : { ...this.state.addActivityForm }
                                                            })
                                                        }}
                                                        checked={this.state.addNewAttachment == 'Y'}
                                                        className="form-check-input"
                                                        id="addNewAttachmentActivity"

                                                    />
                                                    <label className="form-check-label text-sm mx-2" htmlFor="addNewAttachmentActivity">Add New </label>
                                                </div>
                                            </div>
                                            <div className='my-2'>
                                                {this.state.addNewAttachment == "Y"
                                                    ? <input
                                                        name="attachment_new"
                                                        type="text"
                                                        value={this.state.addActivityForm.attachment_new}
                                                        className="form-control form-control-sm"
                                                        autoComplete="off"
                                                        onChange={(e) => this.formInputHandler(e, "addActivityForm")}
                                                        placeholder="Please enter Attachment"

                                                    />
                                                    : <TapSelect
                                                        options={this.state.allAttachmentList}
                                                        changeEvent={(selectedOption) => {
                                                            this.tapSelectChange(selectedOption, 'addActivityForm', 'attachment_id');
                                                        }}
                                                        isSearchable={true}
                                                        value={this.state.allAttachmentList.find(s => this.state.addActivityForm.attachment_id == s.value)}
                                                        isClearable={true}
                                                        placeholder="Select Attachment"
                                                        autoCompelete="off"
                                                        containerHeight="30px"
                                                        fontSize="93%"
                                                    />}
                                            </div>
                                        </div>

                                    </div>
                                    <div className='row my-2'>
                                    {
                                        this.state.allAdditonalAttribute && this.state.allAdditonalAttribute.length > 0
                                            ? (
                                                <Ax>

                                                    {
                                                        this.state.allAdditonalAttribute.map((i, k) => {
                                                            let name = 'additional_attributes_' + i.key;
                                                            let input = null;
                                                            if (i.type == 'text') {
                                                                input = <input
                                                                    name={name}
                                                                    type="text"
                                                                    value={this.state.addActivityForm[name] ? this.state.addActivityForm[name] : ''}
                                                                    onChange={(e) => this.formInputHandler(e, 'addActivityForm')}
                                                                    className="form-control form-control-sm"
                                                                    autoComplete="off"
                                                                    placeholder={`Please enter ${i.name}`}
                                                                />
                                                            } else if (i.type == 'numeric') {
                                                                input = <input
                                                                    name={name}
                                                                    type="number"
                                                                    id={i.formula_key}
                                                                    value={this.state.addActivityForm[name] ? this.state.addActivityForm[name] : ''}
                                                                    onChange={(e) => this.formInputHandler(e, 'addActivityForm', (e) => this.calulateProductivityFormula(e))}
                                                                    className="form-control form-control-sm"
                                                                    autoComplete="off"
                                                                    placeholder={`Please enter ${i.name}`}
                                                                />
                                                            } else if (i.type == 'date') {
                                                                input = <DatePicker
                                                                    selected={
                                                                        this.state.addActivityForm[name]
                                                                            ? moment(this.state.addActivityForm[name]).toDate()
                                                                            : false
                                                                    }
                                                                    name={name}
                                                                    onChange={(value, event) => this.formDateHandler(name, value, 'addActivityForm')}
                                                                    dateFormat="dd-MMM-yyyy"
                                                                    className={"form-control form-control-sm"}
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    autoComplete='off'
                                                                    scrollMonthYearDropdown
                                                                    placeholderText={`Please Enter ${i.name}`}

                                                                />
                                                            } else if (i.type == 'dropdown') {
                                                                let options = i.options.map((t) => { return { value: t, label: t } });
                                                                input = <TapSelect
                                                                    menuPlacement="top"
                                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addActivityForm', name)}
                                                                    options={options}
                                                                    isSearchable={true}
                                                                    isClearable={true}
                                                                    value={options.find(o => o.value == this.state.addActivityForm[name])}
                                                                    placeholder={`Please Select ${i.name}`}
                                                                    containerHeight="30px"
                                                                    fontSize="93%"
                                                                />
                                                            }

                                                            return (<div className='col-sm-3'>
                                                                    <div className='fw-bold col-form-label-sm'>{i.name}</div>
                                                                    <div className="">{input}</div>
                                                                </div>);
                                                        })
                                                    }
                                                </Ax>
                                            )
                                            : null
                                    }
                                    </div>
                                    

                                    <div className="tab_content_wrapper fw-bold mb-2">Logistics / Movement</div>
                                    <div className='row'>

                                        <div className='col-sm-3'>
                                            <div className='fw-bold col-form-label-sm'>From Location (Workstation)
                                            {this.props.permissions.includes('iam-workstation-add') && <div className='float-end'>
                                                    <input
                                                        name="addNewWorkstation"
                                                        type="checkbox"
                                                        value={this.state.addNewWorkstation}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                addNewWorkstation: this.state.addNewWorkstation == 'N' ? 'Y' : 'N',
                                                                addActivityForm: this.state.addNewWorkstation == 'N' ? { ...this.state.addActivityForm, workstation_id: '' } : { ...this.state.addActivityForm }
                                                            })
                                                        }}
                                                        checked={this.state.addNewWorkstation == 'Y'}
                                                        className="form-check-input"
                                                        id="addNewWorkstationActivity"

                                                    />
                                                    <label className="form-check-label text-sm mx-2" htmlFor="addNewWorkstationActivity">Add New </label>
                                                </div>}
                                            </div>
                                            <div className='my-2'>
                                                {this.state.addNewWorkstation == "Y" 
                                                    ? <input
                                                        name="workstation_new"
                                                        type="text"
                                                        value={this.state.addActivityForm.workstation_new}
                                                        className="form-control form-control-sm"
                                                        autoComplete="off"
                                                        onChange={(e) => this.formInputHandler(e, "addActivityForm")}
                                                        placeholder="Please enter Workstation"

                                                    />
                                                    :
                                                    <TapSelect
                                                        options={this.state.allWorkstationList}
                                                        changeEvent={(selectedOption) => {
                                                            this.tapSelectChange(selectedOption, 'addActivityForm', 'workstation_id');
                                                        }}
                                                        isSearchable={true}
                                                        value={this.state.allWorkstationList.find(s => this.state.addActivityForm.workstation_id == s.value)}
                                                        isClearable={true}
                                                        placeholder="Select Workstation"
                                                        autoCompelete="off"
                                                        containerHeight="30px"
                                                        fontSize="93%"
                                                    />
                                                }
                                            </div>
                                        </div>
                                        {this.state.addActivityForm && this.state.addActivityForm.activity_type == "trip"
                                            ? <div className='col-sm-3'>
                                                <div className='fw-bold col-form-label-sm'>To Location (Workstation)
                                                    {this.props.permissions.includes('iam-workstation-add') && <div className='float-end'>
                                                        <input
                                                            name="addNewWorkstationTo"
                                                            type="checkbox"
                                                            value={this.state.addNewWorkstationTo}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    addNewWorkstationTo: this.state.addNewWorkstationTo == 'N' ? 'Y' : 'N',
                                                                    addActivityForm: this.state.addNewWorkstationTo == 'N' ? { ...this.state.addActivityForm, workstation_to_id: '' } : { ...this.state.addActivityForm }
                                                                })
                                                            }}
                                                            checked={this.state.addNewWorkstationTo == 'Y'}
                                                            className="form-check-input"
                                                            id="addNewWorkstationTo"

                                                        />
                                                        <label className="form-check-label text-sm mx-2" htmlFor="addNewWorkstationTo">Add New </label>
                                                    </div>}
                                                </div>
                                                <div className='my-2'>
                                                    {this.state.addNewWorkstationTo == "Y"
                                                        ? <input
                                                            name="workstation_to_new"
                                                            type="text"
                                                            value={this.state.addActivityForm.workstation_to_new}
                                                            className="form-control form-control-sm"
                                                            autoComplete="off"
                                                            onChange={(e) => this.formInputHandler(e, "addActivityForm")}
                                                            placeholder="Please enter Workstation To"

                                                        />
                                                        :
                                                        <TapSelect
                                                            options={this.state.allWorkstationList}
                                                            changeEvent={(selectedOption) => {
                                                                this.tapSelectChange(selectedOption, 'addActivityForm', 'workstation_to_id');
                                                            }}
                                                            isSearchable={true}
                                                            value={this.state.allWorkstationList.find(s => this.state.addActivityForm.workstation_to_id == s.value)}
                                                            isClearable={true}
                                                            placeholder="Select Workstation To"
                                                            autoCompelete="off"
                                                            containerHeight="30px"
                                                            fontSize="93%"
                                                        />
                                                    }
                                                </div>
                                            </div>
                                            : null}
                                        <div className='col-sm-3'>
                                            <div className='fw-bold require col-form-label-sm'>Customer Name</div>
                                            <div className='my-2'>
                                                <input
                                                    name="customer"
                                                    type="text"
                                                    value={this.state.addActivityForm.customer}
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "addActivityForm")}
                                                    placeholder="Please enter Customer Name"

                                                />
                                            </div>
                                        </div>
                                        <div className='col-sm-3'>
                                            <div className='fw-bold col-form-label-sm'>Operator </div>
                                            <div className='my-2'>
                                                {this.state.addNewOperators == "Y"
                                                    ? <input
                                                        name="operator_name_new"
                                                        type="text"
                                                        value={this.state.addActivityForm.operator_name_new}
                                                        className="form-control form-control-sm"
                                                        autoComplete="off"
                                                        onChange={(e) => this.formInputHandler(e, "addActivityForm")}
                                                        placeholder="Please enter Operator Name"

                                                    />
                                                    :
                                                    <TapSelect
                                                        options={this.state.allOperatorList}
                                                        changeEvent={(selectedOption) => {
                                                            this.tapSelectChange(selectedOption, 'addActivityForm', 'operator_name');
                                                        }}
                                                        isSearchable={true}
                                                        value={this.state.allOperatorList.find(s => this.state.addActivityForm.operator_name == s.value)}
                                                        isClearable={true}
                                                        placeholder="Select Operator"
                                                        autoCompelete="off"
                                                        containerHeight="30px"
                                                        fontSize="93%"
                                                    />
                                                }
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-sm-3 col-form-label col-form-label-sm  fw-bold'>
                                                Remark
                                            </div>
                                            <div>
                                                <textarea
                                                    name="remarks"
                                                    value={this.state.addActivityForm.remarks}
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "addActivityForm")}
                                                    placeholder="Please enter Remarks"
                                                    style={{ height: "80px", fontSize: "93%" }}

                                                />
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            }
                        </div>
                        <div className="modal-footer d-flex justify-content-between row">
                            <div className="col text-start">
                                <input
                                    name="copy_reading_activity"
                                    type="checkbox"
                                    value={this.state.copy_reading_activity}
                                    onChange={(e) => {
                                        this.setState({ copy_reading_activity: this.state.copy_reading_activity == 'N' ? 'Y' : 'N' }, () => this.copyPreviousHandler())
                                    }}
                                    checked={this.state.copy_reading_activity == 'Y'}
                                    className="form-check-input"
                                    id="copy_reading_activity"
                                    disabled={this.state.lastActivityData ? false : true || this.state.activityData ? true : false}
                                />
                                <label className="form-check-label mx-2" htmlFor="copy_reading_activity">Copy Reading from Previous Activity </label>
                            </div>
                            <div className="col text-end">
                                <button type="button" className="btn btn-secondary mx-2" disabled={this.state.addActivityFormSubmitting} data-bs-dismiss="modal">Cancel</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.addActivityFormSubmitting} form="uploadActivityForm">Update {this.state.addActivityFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    viewActivityModalJsx              =   ()  =>  {
        let activity                    =   this.state.activityViewData;
        return (
            <div className="modal fade" id={this.viewActivityModalId} tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="fs-6 m-0">
                                <span>View Activity for : </span>{this.state.counterlog ? this.state.counterlog.feed_date_display : null}
                                {this.state.shift ? <span className="ps-2">{this.state.shift.shift_name} ({this.state.shift.shift_start})</span> : null}
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "26%" }}> Asset Name (Code)   </td>
                                        <th>{this.state.assetData ? `${this.state.assetData.name} (${this.state.assetData.asset_code})` : "-"}</th>
                                    </tr>
                                    <tr>
                                        <td>Asset Type </td><th>{this.state.assetData ? `${this.state.assetData.asset_type_name} ` : "-"}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-body">
                            <div>
                                {this.state.activityViewData
                                    ? <AssetActivityView 
                                       activityId={this.state.activityViewData.asset_activity_id}
                                       counterlog={this.state.counterlog}
                                        />
                                    : null}
                            </div>
                        </div>
                        <div className="modal-footer d-flex justify-content-between row">
                            <div className="col text-start">

                                <div className="col text-end">
                                    <button type="button" className="btn btn-secondary mx-2" data-bs-dismiss="modal" >Close</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    
    render                              =   ()  =>  {
        return (<Ax>
           {this.uploadActivityModalJsx()}
           {this.viewActivityModalJsx()}
        </Ax>)
    }
    
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app.acl_info.permissions,
    };
};


export default connect(mapStateToProps, null, null, { forwardRef: true })(Activity);

