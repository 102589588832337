import React from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import Ax from "../../../components/hoc/Ax";
import tapIcon from "../../../services/TapIcon";
import ChangeAssetStatus from "./ChangeAssetStatus"
import InactivateAsset from './InactivateAsset';


class AssetHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            assetDetail            :       null,
            tabScreen               :       null
        }
        this.ChangeAssetStatusModalRef    =   React.createRef();
        this.InactivateAssetModalRef      =   React.createRef(); 
    }

    
    componentDidMount() {
        this.setState({assetDetail : this.props.assetDetail,tabScreen : this.props.tabScreen ? this.props.tabScreen : null});
       
    }

    componentWillReceiveProps(nextProps) {
    
        if(nextProps.assetDetail !== this.state.assetDetail ) {
            this.setState({assetDetail : nextProps.assetDetail})
        }
        if(nextProps.tabScreen !== this.state.tabScreen ) {
            this.setState({tabScreen : nextProps.tabScreen ? nextProps.tabScreen : null})
        }
    }

    changeStatusModalInit                       =   ()  =>  {
        if(this.state.assetDetail){
            this.ChangeAssetStatusModalRef.current.changeStatusModalInit(this.state.assetDetail.asset_id);
        }  
    }

    inactiveAssetInit            =       ()      =>  {
        if(this.state.assetDetail){
            this.InactivateAssetModalRef.current.inactiveAssetModalInit(this.state.assetDetail.asset_id,true);
        }
    }

    activateAssetModalInit          =       ()           =>      {
        if (this.state.assetDetail) {
            this.InactivateAssetModalRef.current.inactiveAssetModalInit(this.state.assetDetail.asset_id, false)
        }
    }

    render() {
            
        return (
            <Ax>
                {this.state.assetDetail ?
                    <div className="text-end mt15">
                        <Link to={{ pathname: `/assets/logsheet/${this.state.assetDetail.asset_id}`, state: { assetDetail: this.state.assetDetail } }}>   <button type="button" className="btn btn-primary">
                            <tapIcon.FontAwesomeIcon icon={tapIcon.faTachometerAlt} className="img-fluid mr3" alt="item-action" />
                            LogSheet</button></Link>
                        <button type="button" className="btn btn-secondary" disabled={!this.state.assetDetail} data-bs-toggle="dropdown" role="button" aria-expanded="false">
                            <tapIcon.FontAwesomeIcon icon={tapIcon.faChartBar} className=" mr3" />
                            Report</button>
                        <ul className="dropdown-menu dropdown-menu-end" style={{ cursor: "pointer" }}>
                            <li><Link className="dropdown-item" to={{ pathname: `/assets/service_checksheet/${this.state?.assetDetail?.asset_id}` }}>Service Checksheet</Link></li>
                            <li><Link className="dropdown-item" to={{ pathname: `/report/shiftwise_asset_running_with_ot`, search: "?" + new URLSearchParams({ asset_id: this.state?.assetDetail?.asset_id, asset_name: this.state.assetDetail?.name, asset_code: this.state.assetDetail?.asset_code }).toString() }}>Shiftwise Asset Running With OT</Link></li>
                            <li><Link className="dropdown-item" to={{ pathname: `/report/daily_status_report`, search: "?" + new URLSearchParams({ asset_id: this.state?.assetDetail?.asset_id, asset_name: this.state.assetDetail?.name, asset_code: this.state.assetDetail?.asset_code }).toString() }}>Daily Progress Report</Link></li>
                            <li><Link className="dropdown-item" to={{ pathname: `/report/asset_invoice_calculator`, search: "?" + new URLSearchParams({ asset_id: this.state?.assetDetail?.asset_id, asset_name: this.state.assetDetail?.name, asset_code: this.state.assetDetail?.asset_code, asset_type_id: this.state.assetDetail?.asset_type_id, asset_tap_id: this.state.assetDetail?.tap_id }).toString() }}>Invoice Calculation Report</Link></li>
                            <li><Link className="dropdown-item" to={{ pathname: `/report/item_wise_consumption`, search: "?" + new URLSearchParams({ asset_id: this.state?.assetDetail?.asset_id, asset_name: this.state.assetDetail?.name, asset_code: this.state.assetDetail?.asset_code }).toString() }}>ItemWise Consumption</Link></li>
                            <li><Link className="dropdown-item" to={{ pathname: `/report/item_wise_requisition`, search: "?" + new URLSearchParams({ asset_id: this.state?.assetDetail?.asset_id, asset_name: this.state.assetDetail?.name, asset_code: this.state.assetDetail?.asset_code }).toString() }}>ItemWise Purchase Requisition</Link></li>
                            <li><Link className="dropdown-item" to={{ pathname: `/report/inventory_forecasting_report`, search: "?" + new URLSearchParams({ asset_id: this.state?.assetDetail?.asset_id, asset_name: this.state.assetDetail?.name, asset_code: this.state.assetDetail?.asset_code }).toString() }}>Inventory Forecasting Report</Link></li>

                        </ul>
                        <button type="button" className="btn btn-secondary" disabled={!this.state.assetDetail} data-bs-toggle="dropdown" role="button" aria-expanded="false">
                            <tapIcon.FontAwesomeIcon icon={tapIcon.faHistory} className="img-fluid mr3" alt="item-action" />
                            History</button>
                        <ul className="dropdown-menu dropdown-menu-end" style={{ cursor: "pointer" }}>
                        <li><Link className="dropdown-item" to={{ pathname: `/assets/demand/${this.state?.assetDetail?.asset_id}` }}>Item Demand</Link></li>
                             {this.props.permissions && this.props.permissions.group_modules && this.props.permissions.group_modules.includes('isc')
                            ? <li><Link className="dropdown-item" to={{ pathname: `/assets/allocation/${this.state?.assetDetail?.asset_id}` }}>Item Allocation</Link></li> : null}
                            <li><Link className="dropdown-item" to={{ pathname: `/assets/activity/${this.state?.assetDetail?.asset_id}` }}>Asset Activity</Link></li>
                            {this.props.permissions && this.props.permissions.group_modules && this.props.permissions.group_modules.includes('isc')
                            ? <li><Link className="dropdown-item" to={{ pathname: `/assets/consumption/${this.state?.assetDetail?.asset_id}` }}>Item Consumption</Link></li> : null}
                            <li><Link className="dropdown-item" to={{ pathname: `/assets/downtime/${this.state?.assetDetail?.asset_id}` }}>Downtime</Link></li>
                            <li><Link className="dropdown-item" to={{ pathname: `/assets/expense_history/${this.state?.assetDetail?.asset_id}` }}>Expense</Link></li>
                            <li><Link className="dropdown-item" to={{ pathname: `/assets/fuelfeedin/${this.state?.assetDetail?.asset_id}` }}>Fuel FeedIn</Link></li>
                            <li><Link className="dropdown-item" to={{ pathname: `/assets/invoice_calculation/${this.state?.assetDetail?.asset_id}` }}>Invoice Calculation</Link></li>
                            {this.props.permissions && this.props.permissions.group_sub_modules.find(s => s.sub_module == "iam-rental")
                                ? <li><Link className="dropdown-item" to={{ pathname: `/assets/rental_transaction/${this.state?.assetDetail?.asset_id}` }}>Commercial Transaction</Link></li>
                                : null}
                            <li><Link className="dropdown-item" to={{ pathname: `/assets/productivity/${this.state?.assetDetail?.asset_id}` }}>Productivity</Link></li>
                            <li><Link className="dropdown-item" to={{ pathname: `/assets/execution_history/${this.state?.assetDetail?.asset_id}` }}>Service Checksheet</Link></li>
                            <li>   <a role="button"
                                onClick={() => {
                                    if (this.props.permissions.permissions.includes('iam-asset-site-linking-view')) {
                                        return this.props && this.props.parentProps && this.props.parentProps.history.push({ pathname: `/assets/site_transition/${this.state?.assetDetail?.asset_id}` })
                                    }
                                }}
                                className={['dropdown-item', this.props.permissions.permissions.includes('iam-asset-site-linking-view') ? '' : 'disabled'].join(' ')}
                                title={!this.props.permissions.permissions.includes('iam-asset-site-linking-view') ? 'You do not have permission to perform this action' : ''}
                                style={{ "pointerEvents": "all" }}
                            >Site Transition</a></li>
                            {this.props.permissions && this.props.permissions.group_sub_modules.find(s => s.sub_module == "iam-task-workflow")
                                ? <li><Link className="dropdown-item" to={{ pathname: `/assets/task/${this.state?.assetDetail?.asset_id}` }}>Task</Link></li>
                                : null}
                            <li><Link className="dropdown-item" to={{ pathname: `/assets/ticket/${this.state?.assetDetail?.asset_id}` }}>Ticket</Link></li>
                        </ul>
                        <button type="button" className="btn btn-secondary" disabled={!this.state.assetDetail} data-bs-toggle="dropdown" role="button" aria-expanded="false">
                            <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid mr3" alt="item-action" /></button>
                        <ul className="dropdown-menu dropdown-menu-end" style={{ cursor: "pointer" }}>

                            {this.state.assetDetail && this.state.assetDetail.status == 2
                                ? <li>
                                    <a role="button"
                                        onClick={() => {
                                            if (this.state.assetDetail && this.state.assetDetail.can_access == true && this.props?.permissions?.permissions?.includes('iam-asset-add')) {
                                                return this.activateAssetModalInit()
                                            }
                                        }}
                                        className={['dropdown-item', this.state.assetDetail && this.state.assetDetail.can_access == true && this.props?.permissions?.permissions?.includes('iam-asset-add') ? '' : 'disabled'].join(' ')}
                                        title={this.state.assetDetail && this.state.assetDetail.can_access == false && !this.props?.permissions?.permissions?.includes('iam-asset-add') ? 'You do not have permission to perform this action' : ''}
                                        style={{ "pointerEvents": "all" }}
                                    >Activate Asset</a>
                                </li>
                                : <Ax>
                                    <li>
                                        <a role="button"
                                            onClick={() => {
                                                if (this.state.assetDetail && this.state.assetDetail.can_access == true) {
                                                    return this.changeStatusModalInit()
                                                }
                                            }}
                                            className={['dropdown-item', this.state.assetDetail && this.state.assetDetail.can_access == true ? '' : 'disabled'].join(' ')}
                                            title={this.state.assetDetail && this.state.assetDetail.can_access == false ? 'You do not have permission to perform this action' : ''}
                                            style={{ "pointerEvents": "all" }}
                                        >Change Asset Status</a>
                                    </li>
                                    <li><Link className="dropdown-item" to={{ pathname: `/assets/asset_setting/${this.state?.assetDetail?.asset_id}` }}>Asset Setting</Link></li>
                                    <li><Link className="dropdown-item" to={{ pathname: `/assets/settings/${this.state?.assetDetail?.asset_id}` }}>SCS Setting</Link></li>

                                    {/* {this.props.permissions.permissions.includes('iam-asset-site-linking-view')
                                        ? <li><Link className="dropdown-item" to={{ pathname: `/assets/site_transition/${this.state?.assetDetail?.asset_id}` }}>Site Transition</Link></li>
                                        : null} */}

                                    {this.state.tabScreen && this.state.tabScreen == "details" ?
                                        <li>
                                            <a role="button"
                                                onClick={() => {
                                                    if (this.state.assetDetail && this.state.assetDetail.can_access == true && this.props?.permissions?.permissions?.includes('iam-asset-delete')) {
                                                        return this.inactiveAssetInit()
                                                    }
                                                }}
                                                className={['dropdown-item', this.state.assetDetail && this.state.assetDetail.can_access == true && this.props?.permissions?.permissions?.includes('iam-asset-delete') ? '' : 'disabled'].join(' ')}
                                                title={this.state.assetDetail && this.state.assetDetail.can_access == false && !this.props?.permissions?.permissions?.includes('iam-asset-delete') ? 'You do not have permission to perform this action' : ''}
                                                style={{ "pointerEvents": "all" }}
                                            >Remove Asset</a>
                                        </li>
                                        : null}
                                </Ax>}
                            {/* <li><Link className="dropdown-item" to={{ pathname: `/assets/asset_setting/${this.state?.assetDetail?.asset_id}` }}>Asset Settings</Link></li> */}
                        </ul>

                        {this.state?.assetDetail?.cl_attribute == "Y" ?
                            <Link to="/measurable_asset">  <button type="button" className="btn btn-secondary">
                                <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-action" /></button>
                            </Link>
                            :
                            <Link to="/non_measurable_asset">  <button type="button" className="btn btn-secondary">
                                <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-action" /></button>
                            </Link>
                        }
                    </div> : null}

                <ChangeAssetStatus ref={this.ChangeAssetStatusModalRef} afterChangeStatus={this.props.afterChangeStatus} />
                <InactivateAsset ref={this.InactivateAssetModalRef} afterChangeStatus={this.props.afterChangeStatus} />

            </Ax>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app && state.app.acl_info ? state.app.acl_info : null,
    };
};


export default connect(mapStateToProps, null, null, { forwardRef: true })(AssetHeader);
