import React from "react";  
import AppBaseComponent from "../../components/AppBaseComponent";
import uuid from "react-uuid";
import { Modal } from "bootstrap";
import Ax from "../../components/hoc/Ax";
import { connect } from "react-redux";
import Loader from "../../components/ui/Loader/Loader";
import HttpAPICall from "../../services/HttpAPICall";
import TapApiUrls from "../../services/TapApiUrls";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

class ExpenseDetailModal extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.state                      =   {
            showMore                        :   true,
            modalLoading                    :   false,
            detailData                      :   {},
        };

        this.expenseDetailModalId       =   uuid();
        this.expenseUrl                 =   TapApiUrls.IAM + "/expense";

    }

    componentDidMount() {
        this.expenseDetailModalInit     =   new Modal(document.getElementById(this.expenseDetailModalId), { keyboard: false, backdrop: false });
        
    }

    showDetailModal                     =   (transation_id, type = null)  =>  {

        this.expenseDetailModalInit.show();
        this.setState({modalLoading     :   true})
        if(type === "Ticket") {
            this.setState({
                showMore           :   false    
            })
        }

        HttpAPICall.withAthorization("GET", `${this.expenseUrl}/detail/${transation_id}`, this.props.access_token, null, null, (response) => {
            const resp                  =   response.data.data;
            this.setState({
                detailData   :   resp
            });
            

        }).then(() => this.setState({modalLoading   : false}))

    }

    expenseDetailJsx                    =   ()  =>  {
        return (
            <Ax>
                <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                    <tbody>
                        <tr>
                            <td style={{ width: "25%" }}>Sitedsfhhjvdsndsvfsdfvsdnfgjdf</td>
                            <th>{this.state.detailData?.site?.site_name}</th>
                        </tr>
                        <tr>
                            <td style={{ width: "25%" }}>Expense Details</td>
                            <th>{this.state.detailData?.details}</th>
                        </tr>
                        <tr>
                            <td style={{ width: "25%" }}>Expense Amount in ₹</td>
                            <th>{this.state.detailData?.amount}</th>
                        </tr>
                        <tr>
                            <td style={{ width: "25%" }}>Payee</td>
                            <th>{this.state.detailData?.payee}</th>
                        </tr>
                        <tr>
                            <td style={{ width: "25%" }}>Payee Name</td>
                            <th>{this.state.detailData?.payee_name}</th>
                        </tr>
                        <tr>
                            <td style={{ width: "25%" }}>Wallet</td>
                            <th>{this.state.detailData?.walletData?.wallet_name}</th>
                        </tr>
                        <tr>
                            <td style={{ width: "25%" }}>Reference Number</td>
                            <th>{this.state.detailData?.reference_number}</th>
                        </tr>
                        <tr>
                            <td style={{ width: "25%" }}>Document</td>
                            <th>{this.state.detailData?.invoice_url ? <Link to={this.state.detailData.invoice_url} target="_blank">View Document</Link> : ""}</th>
                        </tr>
                        {
                        this.state.showMore 
                        ?
                        <Ax>
                        <tr>
                            <td style={{ width: "25%" }}>Section</td>
                            <th>{this.state.detailData?.section?.component}</th>
                        </tr>
                        <tr>
                            <td style={{ width: "25%" }}>Sub-Section</td>
                            <th>{this.state.detailData?.sub_section?.subcomponent}</th>
                        </tr>
                        </Ax>
                        :
                        ''
                        }
                        <tr>
                            <td style={{ width: "25%" }}>Notes</td>
                            <th>{this.state.detailData?.notes}</th>
                        </tr>
                    </tbody>
                </table>
                {
                this.state.showMore 
                ?
                <Ax>
                <div className="tab_content_wrapper mt-2">
                    <span className="content_heading">Ticket Details</span>
                </div>
                <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                    <tbody>
                        <tr>
                            <td style={{ width: "25%" }}>Ticket ID</td>
                            <th>{this.state.detailData?.ticket?.activity_id}</th>
                        </tr>
                        <tr>
                            <td style={{ width: "25%" }}>Ticket Type</td>
                            <th>{this.state.detailData?.ticket?.ticket_type}</th>
                        </tr>
                        <tr>
                            <td style={{ width: "25%" }}>Ticket Header</td>
                            <th>{this.state.detailData?.ticket?.header}</th>
                        </tr>
                    </tbody>
                </table>
                </Ax>
                :
                ''
                }
                <div className="tab_content_wrapper mt-2">
                    <span className="content_heading">Expense Head BreakUp</span>
                </div>
                <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                    <thead className="table-secondary">
                        <tr>
                            <th scope="col" style={{width:"5%"}}>S.No.</th>
                            <th scope="col" style={{width:"50%"}}>Expense Head BreakUp</th>
                            <th scope="col" className="text-end" style={{width:"45%"}}>Expense Head Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        this.state.detailData?.expense_heads?.length > 0 && this.state.detailData?.expense_heads.map((head, i) => {
                            return (
                                <tr key={i}>
                                    <td>{i+1}</td>
                                    <td>{head.expense_head}</td>
                                    <td className="text-end">{head.head_amount}</td>
                                </tr>  
                            )
                        })
                    }  
                    </tbody>
                </table>
                {
                this.state.showMore 
                ?
                <Ax>
                <div className="tab_content_wrapper mt-2">
                    <span className="content_heading">Asset Details</span>
                </div>
                <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                    <thead>
                        <tr>
                            <th className='text-center' style={{ width: "5%" }}>S.No</th>
                            <th style={{ width: "35%" }}>Asset Name</th>
                            <th style={{ width: "25%" }}>Asset Code</th>
                            <th style={{ width: "25%" }}>Asset Type</th>
                            <th style={{ width: "20%" }}>Counterlog</th>
                            <th className='text-end' style={{ width: "15%" }}>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.detailData?.assets && this.state.detailData?.assets.map((asset, i) => {
                            return (
                                <tr key={i + 1}>
                                    <td>{i + 1}</td>
                                    <td>{asset.name}</td>
                                    <td>{asset.asset_code}</td>
                                    <td>{asset.asset_type_name}</td>
                                    <td>
                                        {this.state.detailData?.counterlog && this.state.detailData.counterlog?.display && this.state.detailData.counterlog.display?.length > 0
                                            ? this.state.detailData.counterlog.display.map(counterlog => {
                                                return (<div>
                                                    {counterlog.label ? counterlog.label : ''} : <b>{counterlog.reading} {counterlog.unit}</b>
                                                </div>)
                                            })
                                            : ""}
                                    </td>
                                    <td className='text-end'>{this.state.detailData?.amount ? Number(this.state.detailData?.amount) / this.state.detailData.assets.length : ""}</td>
                                </tr>
                            )
                            })
                        }
                    </tbody>
                </table>
                </Ax>
                :
                ""
                }
                <div className="tab_content_wrapper mt-2">
                    <span className="content_heading">Transaction Details</span>
                </div>
                <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                    <tbody>
                        <tr>
                            <td style={{ width: "25%" }}>Transaction ID</td>
                            <th>{this.state.detailData?.transaction_id}</th>
                        </tr>
                        <tr>
                            <td style={{ width: "25%" }}>Date of Transaction</td>
                            <th>{this.state.detailData?.expense_date_display}</th>
                        </tr>
                        <tr>
                            <td style={{ width: "25%" }}>Executed By</td>
                            <th>{this.state.detailData?.user?.full_name}</th>
                        </tr>
                        <tr>
                            <td style={{ width: "25%" }}>Date of Entry</td>
                            <th>{this.state.detailData?.created_at_display}</th>
                        </tr>
                    </tbody>
                </table>
            </Ax>
        )
    }

    render  ()  {
        return (
            <div className="modal fade" id={this.expenseDetailModalId} tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchFundTransferModalLabel">Expense Details</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        {
                            this.state.modalLoading
                            ?
                            <Loader/>
                            :
                            <div className="modal-body">
                               { this.expenseDetailJsx() }
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps           =   (state) =>  {
    return {
      access_token            :   state.auth.access_token,
    };
  };
  
  export default connect(mapStateToProps, null, null, { forwardRef: true })(ExpenseDetailModal);     