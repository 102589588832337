import React from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import AppBaseComponent from '../../../../../components/AppBaseComponent';
import AssetService from '../../../../../services/AssetService';
import Ax from '../../../../../components/hoc/Ax';
import TapIcon from '../../../../../services/TapIcon';
import swal from 'sweetalert';
import TapApiUrls, { IAM_API_BASE_URL, IAM_API_BASE_URL_2 } from '../../../../../services/TapApiUrls';
import { toast } from 'react-toastify';
import HttpAPICall from '../../../../../services/HttpAPICall';



class TaskSavedStep extends AppBaseComponent {

    constructor(props) {
        super(props);
        this.state      =   {
            editMode    :    "N"
        }

        this.refFlashMsg                =   React.createRef();
        this.task_delete_url            =   IAM_API_BASE_URL + '/counterlog/activity_task_step';
      
    }


    deleteTaskInit      =       (transaction_id,step_id,level)      =>      {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
             dangerMode: true,
            buttons: ["No", "Yes"],
        }).then(willDelete => {
            if (willDelete) {
                let params = { transaction_id: transaction_id, step_id: step_id }
               console.log('case 1')
                HttpAPICall.withAthorization('DELETE', this.task_delete_url, this.props.access_token, { ...params }, null, (response) => {
                    let respData = response.data;
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    
                    if (this.props.completed_steps && this.props.completed_steps.length == 1) {
                        if (this.props.onRequestClose) {
                            this.props.onRequestClose('delete');
                            console.log('case 2')
                        }
                    } else {
                        if (this.props.onModalRefresh) {
                            this.props.onModalRefresh();
                            console.log('case 3')
                        }
                       


                    }
                }).then();
            }
        });
    }

    

    startingReadingsJsx             =   (step, taskDetail, asset)  =>  {
        let assetMeters                 =   AssetService.avaialbleAssetMeters(asset);
        let {detail}                    =   {...step}; 
        return <Ax>
            <tr >
                <td style={{ width: "26%" }}>Date & Time</td>
                <th>{detail ? detail.start_cl_date_time_display : ''}</th>
            </tr>
            {assetMeters.map((meter, k) => {
                let input_state_key     =   AssetService.getTaskStartingReadingInputKey(meter.key);
                return <Ax>
                    <tr key={k}>
                        <td style={{ width: "26%" }}>{meter.label}</td>
                        <th>{detail && detail[input_state_key] ? detail[input_state_key] : ''}</th>
                    </tr>
                   
                </Ax>
                
            })}
        
        </Ax>
    }

    startingTimeJsx         =       (step, taskDetail)      =>      {
        let {detail}                    =   {...step}; 
        return (<tr>
            <td>Task Start Time </td>
            <th>{taskDetail && taskDetail.task_start_at_display ? taskDetail.task_start_at_display : "-"}</th>

        </tr>)
    }

    operatorJsx                     =   (step, taskDetail)  =>  {
        let {detail}                    =   {...step};
        return <tr>
            <td>Operator</td>
            <th>{detail ? detail.operators.map(o => o.display_full_name).join(', ') : ''}</th>
        </tr>
    }

    attachmentJsx                   =   (step, taskDetail)  =>  {
        let {detail}                    =   {...step};
        return <tr>
            <td>Attachment</td>
            <th>{detail && detail.attachment_name ? detail.attachment_name : ''}</th>
        </tr>
    }

    materialJsx                     =   (step, taskDetail)  =>  {
        let {detail}                    =   {...step};
        return <tr>
            <td>Material</td>
            <th>{detail && detail.material_name ? detail.material_name : ''}</th>
        </tr>
    }

    productivityJsx                 =   (step, taskDetail) => {
        let {detail}                    =   {...step};
        return <Ax>
            <tr>
                <td>Net Productivity</td>
                <th>{detail && detail.production ? detail.production : ''}</th>
            </tr>
            <tr>
                <td>Productivity Units</td>
                <th>{detail && detail.production ? detail.production_unit : ''}</th>
            </tr>
            {detail.production_all_aa && detail.production_all_aa.length > 0 && detail.production_all_aa.map((attribute, pk) => <Ax key={pk}>
                <tr>
                    <td>{attribute.name}</td>
                    <th>{detail && detail.production_additional_attributes && detail.production_additional_attributes[attribute.key] ? detail.production_additional_attributes[attribute.key] : "-"}</th>
                </tr>

            </Ax>)}
        </Ax>
    }

    workstationFromJsx              =   (step, taskDetail) => {
        let {detail}                    =   {...step};
        return <Ax>
             <tr>
                <td>Workstation From</td>
                <th>{detail && detail.workStationFrom ? detail.workStationFrom.name : ''}</th>
            </tr>
          
        </Ax>
    }

    workstationToJsx                =   (step, taskDetail) => {
        let {detail}                    =   {...step};
        return <Ax>
            <tr>
                <td>Workstation To</td>
                <th>{detail && detail.workStationTo ? detail.workStationTo.name : ''}</th>
            </tr>
        </Ax>
    }

    workstationJsx                  =   (step, taskDetail) => {
        let {detail}                    =   {...step};
        return <Ax>
            <tr>
                <td>Workstation</td>
                <th>{detail && detail.workStation ? detail.workStation.name : ''}</th>
            </tr>
        </Ax>
    }

    hgExecutionByJsx                  =   (step, taskDetail) => {
        let {detail}                    =   {...step};
        return <Ax>
            <tr>
                <td>Execution By</td>
                <th>{detail.execution_by_name ? detail.execution_by_name : ""}</th>
            </tr>
            <tr>
                <td>Supplier Vendor</td>
                <th>{detail.supplier_vendor ? <span>{detail.supplier_vendor.name} {detail.supplier_vendor.code && `(${detail.supplier_vendor.code})`}</span> : ""}</th>
            </tr>
        </Ax>
    }

    unloadedWeightJsx               =   (step, taskDetail) => {
        let {detail}                    =   {...step};
        return <Ax>
            <tr>
                <td>Unloaded Weight</td>
                <th>{detail && detail.unloaded_weight ? detail.unloaded_weight : ''} {detail && detail.unloaded_weight_unit ? detail.unloaded_weight_unit : ''}</th>
            </tr>
         
        </Ax> 
    }

    loadedWeightJsx                 =   (step, taskDetail) => {
        let {detail}                    =   {...step};
        return <Ax>
             <tr>
                <td>Loaded Weight</td>
                <th>{detail && detail.loaded_weight ? detail.loaded_weight : ''} {detail && detail.loaded_weight_unit ? detail.loaded_weight_unit : ''}</th>
            </tr>
            
        </Ax> 
    }

    productivityRemarkJsx           =   (step, taskDetail) => {
        let {detail}                    =   {...step};
        return <Ax>
             <tr>
                <td>Productivity Remark</td>
                <th>{detail && detail.productivity_remark ? detail.productivity_remark : ''}</th>
            </tr>
        </Ax> 
    }

    counterlogReadingsJsx           =   (step, taskDetail, asset)   =>  {
        let assetMeters                 =   AssetService.avaialbleAssetMeters(asset);
        let {detail}                    =   {...step}; 
        return <Ax>
             <tr>
                <td>CounterLog Date & Time</td>
                <th>{detail ? detail.cl_date_time_display : ''}</th>
            </tr>
            {assetMeters.map((meter, k) => {
                let input_state_key = AssetService.getTaskCounterLogReadingInputKey(meter.key);
                return <tr>
                    <td>{meter.label}</td>
                    <th>{detail && detail[input_state_key] ? detail[input_state_key] : ''}</th>
                </tr>
            })}
           
        </Ax>
    }
    closingReadingsJsx              =   (step, taskDetail, asset) => {
        let assetMeters                 =   AssetService.avaialbleAssetMeters(asset);
        let {detail}                    =   {...step}; 
        return <Ax>
              <tr>
                <td>Closing CounterLog Date & Time</td>
                <th>{detail ? detail.end_cl_date_time_display : ''}</th>
            </tr>
            {assetMeters.map((meter, k) => {
                let input_state_key = AssetService.getTaskClosingReadingInputKey(meter.key);
                return <tr>
                    <td>{meter.label}</td>
                    <th>{detail && detail[input_state_key] ? detail[input_state_key] : ''}</th>
                </tr>

            })}
          
        </Ax>
    }

    closingRemarkJsx                =   (step, taskDetail)  =>  {
        let {detail}                    =   {...step};
        return <Ax>
             <tr>
                <td>Closing Remark</td>
                <th>{detail && detail.closing_remarks ? detail.closing_remarks : ''}</th>
            </tr>
        </Ax>
    }

    activityCountJsx                =   (step, taskDetail)  =>  {
        let {detail}                    =   {...step};
        return <Ax>
             <tr>
                <td>Activity Type</td>
                <th>{detail && detail.activity_type_display ? detail.activity_type_display : ''}</th>
            </tr>
            <tr>
                <td>No. of Session or Trip</td>
                <th>{detail && detail.activity_count ? detail.activity_count : ''}</th>
            </tr>
        </Ax>
    }

    gateInTimeJsx                =   (step, taskDetail)  =>  {
        let {detail}                    =   {...step};
        return <Ax>
             <tr>
                <td>Gate-In TimeStamp</td>
                <th>{detail && detail.ncc_gate_in_time_display ? detail.ncc_gate_in_time_display : ''}</th>
            </tr>
            
        </Ax>
    }

    qualityCheckJsx                =   (step, taskDetail)  =>  {
        let {detail}                    =   {...step};
        return <Ax>
             <tr>
                <td>Quality Check Result</td>
                <th>{detail && detail.ncc_qc_pass_display ? detail.ncc_qc_pass_display : ''}</th>
            </tr>
            
        </Ax>
    }

    qualityCheckTimeJsx                =   (step, taskDetail)  =>  {
        let {detail}                    =   {...step};
        
        return <Ax>
             <tr>
                <td>Quality Check Time</td>
                <th>{detail && detail.ncc_qc_time_display ? detail.ncc_qc_time_display : ''}</th>
            </tr>
            
        </Ax>
    }

    pouringLocationJsx                =   (step, taskDetail)  =>  {
        let {detail}                    =   {...step};
        
        return <Ax>
             <tr>
                <td>Pouring Location</td>
                <th>{detail && detail.ncc_pouring_workstation ? detail.ncc_pouring_workstation.name : ''}</th>
            </tr>
            
        </Ax>
    }

    pouringTimeJsx                =   (step, taskDetail)  =>  {
        let {detail}                    =   {...step};
        
        return <Ax>
             <tr>
                <td>Pouring Start Time</td>
                <th>{detail && detail.ncc_pouring_start_time_display ? detail.ncc_pouring_start_time_display : ''}</th>
            </tr>
            <tr>
                <td>Pouring End Time</td>
                <th>{detail && detail.ncc_pouring_end_time_display ? detail.ncc_pouring_end_time_display : ''}</th>
            </tr>
            
        </Ax>
    }

    pouringQtyJsx                =   (step, taskDetail)  =>  {
        let {detail}                    =   {...step};
        
        return <Ax>
             <tr>
                <td>Pouring Quantity</td>
                <th>{detail && detail.ncc_pouring_qty ? detail.ncc_pouring_qty : ''}</th>
            </tr>
           
        </Ax>
    }

    taskCompleteTimeJsx             =   (step, taskDetail)  =>  {
        let {detail}                    =   {...step}; 
       
        return <Ax>
             <tr>
                <td>Task Complete Date & Time</td>
                <th>{detail && detail.task_close_at_display  ? detail.task_close_at_display : ''}</th>
            </tr>
        </Ax>
    }

    
    handleByJsx                =   (step, taskDetail)  =>  {
        let {detail}                    =   {...step};
        
        return <Ax>
             <tr>
                <td>Handle By</td>
                <th>{detail && detail.hg_handle_by ?  detail.hg_handle_by : ''}</th>
            </tr>
            <tr>
                <td>Area of Work</td>
                <th>{detail && detail.hg_area_of_work_display ? detail.hg_area_of_work_display : ''}</th>
            </tr>
            
        </Ax>
    }

    momentHumanize(eventDuration, unit) {
        var eventMDuration = moment.duration(eventDuration, unit);
        var eventDurationArray = [];
       
        if (eventMDuration.days() > 0) {
            eventDurationArray.push(eventMDuration.days() + ' Days');
            eventMDuration.subtract(eventMDuration.days(), 'Days')
        }
        if (eventMDuration.hours() > 0) {
            eventDurationArray.push(eventMDuration.hours() + ' Hours');
            eventMDuration.subtract(eventMDuration.hours(), 'Hours')
        }
        if (eventMDuration.minutes() > 0) {
            eventDurationArray.push(eventMDuration.minutes() + ' Min');
        }
        return eventDurationArray.length === 1 ? eventDurationArray[0] : 
        eventDurationArray.join(' ')
    }

    analysisJsx                 =       ()          =>      {
        let taskDetail = this.props.taskData;
        let startingReadingDetail   =   this.props.completed_steps && this.props.completed_steps.find(ps => ps.fields.find(fs => fs.field_key == 'starting_readings'));
        let closingReadingDetail    =   this.props.completed_steps && this.props.completed_steps.find(ps => ps.fields.find(fs => fs.field_key == 'closing_readings'));
        let unloadedStep  =   this.props.completed_steps && this.props.completed_steps.find(ps => ps.fields.find(fs => fs.field_key == 'unloaded_weight'));
        let loadedStep    =   this.props.completed_steps && this.props.completed_steps.find(ps => ps.fields.find(fs => fs.field_key == 'loaded_weight'));

        let unloadedDetail  =   unloadedStep && unloadedStep.detail ? unloadedStep.detail : null;
        let loadedDetail    =   loadedStep && loadedStep.detail ? loadedStep.detail : null;
        
        var task_start_at = taskDetail && taskDetail.task_start_at ? moment(taskDetail.task_start_at) : "";//now
        var task_close_at = taskDetail && taskDetail.task_close_at ? moment(taskDetail.task_close_at) : "";
        var ncc_gate_in_time = taskDetail && taskDetail.ncc_gate_in_time ? moment(taskDetail.ncc_gate_in_time) : ""
        let daydiffenence           =   task_start_at && task_close_at ? task_close_at.diff(task_start_at, 'days') : '';
        let hourDiffernce           =   task_start_at && task_close_at ? task_close_at.diff(task_start_at, 'hours') : '';
        let minuteDiffernce         =   task_start_at && task_close_at ? task_close_at.diff(task_start_at, 'minutes') : '';

        let gateEntrydaydifference           =   ncc_gate_in_time && task_close_at ? task_close_at.diff(ncc_gate_in_time, 'days') : '';
        let  gateEntryhourDiffernce           =   ncc_gate_in_time && task_close_at ? task_close_at.diff(ncc_gate_in_time, 'hours') : '';
        let  gateEntryminuteDiffernce         =   ncc_gate_in_time && task_close_at ? task_close_at.diff(ncc_gate_in_time, 'minutes') : '';

        let starting_reading_detail = startingReadingDetail && startingReadingDetail.detail ? startingReadingDetail.detail : null;
        let closing_reading_detail  =  closingReadingDetail && closingReadingDetail.detail ? closingReadingDetail.detail : null;
        let assetMeters                 =   AssetService.avaialbleAssetMeters(this.props.asset);
     
       
        return ( <Ax>
            <Ax>
            {closing_reading_detail && (loadedDetail && unloadedDetail) ?   <div className="tab_content_wrapper fw-bold my-2">Analysis </div> : null}
                <table className="table table-bordered bg-white">
                    <tbody>
                        {taskDetail && taskDetail.task_in_progress == 'N'
                            ? <Ax>
                                <tr>
                                    <td style={{ width: "26%" }}>Total Task Duration  </td>
                                    <th>{minuteDiffernce ? this.momentHumanize(minuteDiffernce, 'minutes') : '-'}</th>
                                </tr>
                                {taskDetail && taskDetail.ncc_gate_in_time && taskDetail.task_close_at &&  <tr>
                                    <td style={{ width: "26%" }}>Adnani Gate Entry Time to Task Complete Time  </td>
                                    <th>{gateEntryminuteDiffernce ? this.momentHumanize(gateEntryminuteDiffernce, 'minutes') : '-'}</th>
                                </tr>}
                                {starting_reading_detail && closing_reading_detail && assetMeters.map((meter, k) => {
                                    let input_state_starting_key = AssetService.getTaskStartingReadingInputKey(meter.key);
                                    let input_state_closing_key = AssetService.getTaskClosingReadingInputKey(meter.key);
                                    return <tr>
                                        <td>{meter.label}</td>
                                        <th>{Number(parseFloat(closing_reading_detail[input_state_closing_key]).toFixed(2)) - Number(parseFloat(starting_reading_detail[input_state_starting_key]).toFixed(2))}</th>
                                    </tr>

                                })}
                                {loadedDetail && unloadedDetail &&
                                    <tr>
                                        <td style={{ width: "33%" }}>Weight Difference  </td>
                                        <th>{Number(loadedDetail.loaded_weight) - Number(unloadedDetail.unloaded_weight)}</th>
                                    </tr>}
                           
                            </Ax> : null}

                    </tbody>
                </table>
            </Ax>
        </Ax>
    )
    }

    additionalAttributeFields       =   (additional_attribute_detail,taskDetail)       =>      {
        let additional_attribute_data   =   taskDetail ? taskDetail.additional_attribute_data : [];
       let saved_data                      =   additional_attribute_data.find(aad => aad.key == additional_attribute_detail.key);
       
       return(<tr>
        <td>{additional_attribute_detail.name}</td>
        <th>{saved_data ? saved_data.value_display : ''}</th>
    </tr>)
   }

    editTaskForm                    =   (step,transaction_id)          =>      {
       
        if(this.props.onEditTaskForm){
            this.props.onEditTaskForm(step,transaction_id)
           
        }
    }
    
    renderTaskSteps                 =   ()  =>  {
        let taskData                    =   this.props.taskData;
        let asset                       =   this.props.asset;
        let { activity }                =   { ...this.props.taskData };
       
        return this.props.completed_steps.map((step, sk) => {
            let { detail, fields } = { ...step };
            fields.sort((a, b) => a.order_no - b.order_no);
            let form_field_keys = fields && fields.length > 0 ? fields.map(f => f.field_key) : [];

            return <div key={sk}>
                <div className="tab_content_wrapper fw-bold my-2">
                    {step.name} 
                    <div className='float-end'>
                        {this.props.editMode == 'N' && step.can_edit == 'Y' ? <TapIcon.FontAwesomeIcon icon={TapIcon.faPencilAlt} color="primary_color" className="mx-2" onClick={() => { this.editTaskForm(step, taskData.transaction_id); }} /> : null}
                        {taskData && taskData.completed_step_level && taskData.completed_step_level == step.level && step.can_delete == 'Y' && this.props.editMode == 'N' ? <TapIcon.FontAwesomeIcon icon={TapIcon.faTrashAlt} color="primary_color" className="mx-3" onClick={() => this.deleteTaskInit(taskData.transaction_id, step.id, step.level)} /> : null}
                    </div>
                </div>
                {step.level == 1 ?
                    <table className="table mt-2 mb-0 table-hover table-bordered">
                        <tbody>
                            <tr>
                                <td style={{ width: "26%" }}>Activity Name</td>
                                <th>{taskData && taskData.activity && taskData.activity.activity_name ? taskData.activity.activity_name : "-"} </th>
                            </tr>


                        </tbody>
                    </table> : null}

                <table className="table mt-0 mb-0 table-hover table-bordered">
                    <tbody>
                        {fields.map((field, k) => {
                            return <Ax key={k}>
                                {field.field_key == 'starting_readings' && this.startingReadingsJsx(step, taskData, asset)}
                                {field.field_key == 'task_start_time' && !form_field_keys.includes('starting_readings') && this.startingTimeJsx(step, taskData)}
                                {field.field_key == 'operator' && this.operatorJsx(step, taskData)}
                                {field.field_key == 'productivity' && this.productivityJsx(step, taskData)}
                                {field.field_key == 'attachment' && this.attachmentJsx(step, taskData)}
                                {field.field_key == 'material' && this.materialJsx(step, taskData)}
                                {field.field_key == 'workstation_from' && this.workstationFromJsx(step, taskData)}
                                {field.field_key == 'workstation_to' && this.workstationToJsx(step, taskData)}
                                {field.field_key == 'workstation' && this.workstationJsx(step, taskData)}
                                {field.field_key == 'hg_execution_by' && this.hgExecutionByJsx(step, taskData)}
                                {field.field_key == 'loaded_weight' && this.loadedWeightJsx(step, taskData)}
                                {field.field_key == 'unloaded_weight' && this.unloadedWeightJsx(step, taskData)}
                                {field.field_key == 'productivity_remark' && this.productivityRemarkJsx(step, taskData)}
                                {field.field_key == 'counterlog_readings' && this.counterlogReadingsJsx(step, taskData, asset)}
                                {field.field_key == 'closing_remarks' && this.closingRemarkJsx(step, taskData)}
                                {field.field_key == 'activity_count' && this.activityCountJsx(step, taskData)}
                                {field.field_key == 'ncc_gate_in_time' && this.gateInTimeJsx(step,taskData)}
                                {field.field_key == 'ncc_qc_pass' && this.qualityCheckJsx(step,taskData)}
                                {field.field_key == 'ncc_qc_time' && this.qualityCheckTimeJsx(step,taskData)}
                                {field.field_key == 'ncc_pouring_location' && this.pouringLocationJsx(step,taskData)}
                                {field.field_key == 'ncc_pouring_qty' && this.pouringQtyJsx(step,taskData)}
                                {field.field_key == 'ncc_pouring_time' && this.pouringTimeJsx(step,taskData)}
                                {field.field_key == 'hg_handle_by_area_of_work' && this.handleByJsx(step,taskData)}
                                {field.field_key == 'closing_readings' && this.closingReadingsJsx(step, taskData, asset)}
                                {field.field_key == 'task_complete_time' && !form_field_keys.includes('closing_readings') && this.taskCompleteTimeJsx(step)}
                                {field.is_additional_attribute == 'Y' && field.additional_attribute && this.additionalAttributeFields(field.additional_attribute, taskData)}
                            </Ax>
                        })}


                        <tr>
                            <td className="text-start" style={{ width: "26%" }}>Entry By</td>
                            <th>{step.detail && step.detail.step_submit_user ? step.detail.step_submit_user.full_name : "-"}</th>

                        </tr>
                        <tr>
                            <td className="text-start" style={{ width: "26%" }}>Entry Timestamp</td>
                            <th>{step.detail && step.detail.step_submit_at_display ? step.detail.step_submit_at_display : "-"}</th>

                        </tr>
                    </tbody>
                </table>
            </div>
        });
    }

    render                          =   ()  =>  {
        let taskData                    =   this.props.taskData;
        let asset                       =   this.props.asset;
        let { activity }                =   { ...this.props.taskData };
        return taskData && <Ax>
            {taskData && this.props.showCard && this.props.showCard == true ?
                <Ax>
                    <table className="table table-bordered bg-white">
                        <thead className="table-secondary">
                            <tr>
                                <th style={{ width: "26%" }}>ID</th>
                                <th style={{ width: "26%" }}>Date</th>
                                <th style={{ width: "26%" }}>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td scope="col">{taskData.transaction_id ? taskData.transaction_id : "-"}</td>
                                <td scope="col">{taskData.task_date_display ? taskData.task_date_display : "-"}</td>
                                <td scope="col">{taskData.task_in_progress && taskData.task_in_progress == 'Y' ? "Inprogress" : "Closed"}</td>
                            </tr>
                        </tbody>
                    </table>
                </Ax>
                : null}
            {this.renderTaskSteps()}
            {this.analysisJsx()}
        </Ax>
    }

}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        access_token            :   state.auth.access_token,
    }
};

export default connect(mapStateToProps, null, null, {forwardRef:true})(TaskSavedStep);