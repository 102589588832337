import React from 'react';
import { connect } from 'react-redux';
import Ax from '../../../components/hoc/Ax';
import Loader from '../../../components/ui/Loader/Loader';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import Status from '../../../components/ui/Status';
import ItemViewInList from '../includes/ItemViewInList';
import TapIcon from '../../../services/TapIcon';
import { toast } from 'react-toastify';

import ItemDetailsModal from '../../items/itemDetails/ItemDetailsModal';
import { Modal } from 'bootstrap';
import AppBaseComponent from '../../../components/AppBaseComponent';
import CurrencyFormatter from '../../../services/CurrencyFormatter';
import { Link } from 'react-router-dom'
import TicketDetailModal from '../../transactions/ticketTransaction/TicketDetailModal';
import DateService from '../../../services/DateService';
import AllocationDetailItemWise from '../allocation/AllocationDetailItemWise';

class ItemDemandDetail extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        this.state                      =       {
            demandViewData         :       null,
            view_loading                :       false,
            itemDetails                 :       null,
        };
       
        this.itemDetailsRef                  =    React.createRef();
        this.itemWiseAllocationRef          =   React.createRef();
        this.ticketDetailRef        =   React.createRef();
        this.detailUrl              =   INVENTORY_API_BASE_URL_2 + '/item_demand/detail/__transaction_id';
    }

    componentDidMount() {
        if(this.props && this.props.demandId){
            this.getAllocationViewDetails(this.props.demandId)
        }
        if(this.props && this.props.demandData){
            this.setState({demandViewData : this.props.demandData})
        }
        this.viewItemModal             =   new Modal(document.getElementById('viewItemModal'), {keyboard: false, backdrop :false});
     }
 
     componentWillReceiveProps(nextProps){
         if(nextProps.demandId !== this.props.demandId){
            this.getAllocationViewDetails(nextProps.demandId)
         }
         if(nextProps.demandData !== this.props.demandData){
             this.setState({demandViewData : nextProps.demandData})
         }
         
     }
    

    getAllocationViewDetails             =    (transaction_id)        =>  {
        let detailUrl                       =   this.detailUrl.replace('__transaction_id', transaction_id);
        this.setState({view_loading : true})
         HttpAPICall.withAthorization('GET',  detailUrl, this.props.access_token, {}, {}, (resp) => {
             let respData = resp.data;
            
             this.setState({
                 demandViewData        : respData,
                 allocationLoaded          :  false,
             });
            
         }).then(() => this.setState({view_loading: false}));
        
     }

       
    viewItemModalInit                    =   (item)  =>  { 
        if(item){
            this.itemDetailsRef.current.modalInit(item)
        }
    }

    allocationDetailInit        =       (item_id='',itemName,warehouse_name,warehouse_id,demand_id)        =>      {
        
        if (this.props.demandData) {
            this.itemWiseAllocationRef.current.modalInit(item_id, itemName, warehouse_name, warehouse_id, demand_id, false,true)

        }
    }

    consumptionDetailInit        =       (item_id='',itemName,warehouse_name,warehouse_id,demand_id)        =>      {
        
        if (this.props.demandData) {
            this.itemWiseAllocationRef.current.modalInit(item_id, itemName, warehouse_name, warehouse_id, demand_id,true, false)

        }
    }

    
    render() {
        let indent = this.state.demandViewData;
        
        return (<Ax>
              {this.state.view_loading ? <Loader />
                : indent ?
                <div>
                <table className="table table-bordered bg-white" >
                    <thead className="table-secondary">
                        <tr>
                            <th style={{ width: "33%" }}>ID</th>
                            <th style={{ width: "33%" }}>Date</th>
                            <th style={{ width: "33%" }}>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td scope="col">{indent.transaction_id ? indent.transaction_id : "-"}</td>
                            <td scope="col">{indent.transaction_date  ? DateService.dateTimeFormat(indent.transaction_date,'DD-MMM-YYYY') : "-"}</td>
                            <td scope="col" className="align-middle">
                                {indent.status ? indent.status.name : ''}
                            </td>  </tr>
                    </tbody>
                </table>
                <table className="table table-hover table-bordered  table-responsive bg-white mb-0">
                    <tbody>
                        <tr>
                            <td style={{ width: "33%" }} >Warehouse</td>
                            <td colSpan={2}>{indent.warehouse ? <div><b>{indent.warehouse.name} ({indent.warehouse.code})</b> <br />
                                {indent.warehouse ?
                                    <div className='pt-1'>Address : {indent.warehouse.address1 ? indent.warehouse.address1 + ", " : ""}
                                        {indent.warehouse.address2 ? indent.warehouse.address2 + ", " : ""}
                                        {indent.warehouse.landmark ? indent.warehouse.landmark + ", " : ""}
                                        {indent.warehouse.city ? indent.warehouse.city + ". " : "-"} <br />
                                        Destination of Supply : <b>{indent.warehouse.state_name}</b> <br />
                                        GSTIN  : {indent.warehouse.gstin ? indent.warehouse.gstin : "-"} <br />
                                    </div> : "-"}</div> : "-"} <br />
                            </td>
                        </tr>
                        <tr>
                            <td scope="col">Title</td>
                            <th scope="col">{indent.title ? indent.title : "-"}</th>
                        </tr>
                        <tr>
                            <td style={{ width: "33%" }} >Demand For</td>
                            <th>{indent.demand_for && indent.demand_for == "asset" ? "Asset" : indent.demand_for && indent.demand_for == "workstation" ? "Workstation" : indent.demand_for && indent.demand_for == "employee" ? "Employee" : indent.demand_for && indent.demand_for == "sub_assembly" ? "Sub Assembly" : "-"} </th>
                        </tr>
                        {indent.demand_for ?
                            <tr>
                                <td style={{ width: "33%" }} >{indent.demand_for && indent.demand_for == "asset" ? "Asset" : indent.demand_for && indent.demand_for == "workstation" ? "Workstation" : indent.demand_for && indent.demand_for == "employee" ? "Employee" : indent.demand_for && indent.demand_for == "sub_assembly" ? "Sub Assembly" : "-"}</td>
                                <th>
                                    {indent.demand_for == "asset" && indent.asset ? indent.asset.name : indent.demand_for == "workstation" && indent.workstation ? indent.workstation.workstation_name : indent.demand_for == "employee" && indent.employee ? indent.employee.name : indent.demand_for == "sub_assembly" && indent.sub_assembly ? indent.sub_assembly.name : "-"}
                                </th>
                            </tr> : null}
                        <tr>
                            <td style={{ width: "33%" }}>Ticket</td>
                            <td colSpan={2} >{indent.ticket ? <Status color={indent.ticket.status_color_code}> <Link onClick={() => this.getTicketDetail(indent.ticket.activity_id)}>{indent.ticket.activity_id} : {indent.ticket.header}</Link> </Status> : "-"}</td>
                        </tr>
                        <tr>
                            <td scope="col">Tag Users</td>
                            <td scope="col">{indent && indent.tagged_users && indent.tagged_users.length > 0 ? indent.tagged_users.map(u => <div>{u.full_name}</div>) : ""}</td>
                        </tr>
                        {indent.additional_attribute_data && indent.additional_attribute_data.length > 0
                            ? indent.additional_attribute_data.map((attr) => {
                                return (<tr key={attr.key}>
                                    <td scope="col" className='text-capitalize'>{attr.name ? attr.name : "-"}</td>
                                    <th scope="col">{attr.value_display ? attr.value_display : "-"}</th>
                                </tr>)
                            }) : null}
                    </tbody>
                </table>
                <table className="table  table-sm table-bordered table-responsive bg-white my-3" id="dataTable">
                    <thead className="table-secondary">
                        <tr className="text-end">
                            <th scope="col" style={{ width: "5%" }} className="text-center"><input type='checkbox' onChange={() => this.props.checkAllItem ? this.props.checkAllItem() : null} id="all_check_allocation" /></th>
                            <th scope="col" className="text-center" style={{ width: "31%" }}>Item Details</th>
                            <th scope="col" className="text-center" style={{ width: "8%" }}>Demand Qty</th>
                             <th scope="col" className="text-end" style={{ width: "8%" }}>Allocated</th>
                            <th scope="col" className="text-end" style={{ width: "8%" }}>Consumed</th>
                            <th scope="col" className="text-end" style={{ width: "8%" }}>Cancelled  </th>
                            <th scope="col" className="text-end" style={{ width: "8%" }}>Pending </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            indent && indent?.items && indent?.items?.length > 0 ? indent?.items?.map((i, k) => {
                                return (<tr key={k} className="text-end">
                                    <td className='text-center'>
                                        {k + 1} <br /><br/>
                                        <input
                                            type='checkbox'
                                            name="foo"
                                            value={i?.item_profile?.id}
                                            data-pendingQty={i.pending_qty}
                                            className="allocation_item_id_checkbox"
                                            data-warehouseId={indent.warehouse_id}
                                            data-purchaseQty={i?.purchase_qty??0}
                                            data-itemData={i?.item_profile}
                                            data-transactionId={indent.transaction_id}
                                            data-statusLevel={indent.status_data && indent.status_data.level}
                                            data-statusIds={indent.status_data && indent.status_data.id}
                                        />
                                    </td>
                                    <td className="text-start">{i.item_profile ? <div className='row'>
                                        <div className='col-sm-12'>
                                            <ItemViewInList item={i.item_profile} remark={i.remark} viewItemModal={() => this.viewItemModalInit(i.item_profile)} />
                                        </div>
                                        {/* <div className='col-sm-2 text-end'>
                                            <TapIcon.imageIcon icon={TapIcon.ListActionIcon} alt="action-icon" className="img-fluid" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                            <ul className="dropdown-menu">
                                                <li><a href="#" className="dropdown-item" onClick={() => this.viewConsumptionModalInit(i.item_profile, k)}>Consumption History</a></li>
                                                <li><a href="#" className="dropdown-item" onClick={() => this.viewTransactionLedgerModalInit(i.item_profile, k)}>Item Transaction Ledger</a></li>
                                                {this.props.permissions.includes('isc-item-Interchangeable-edit') &&  <li><a href="#" className="dropdown-item" onClick={() => this.viewInterchangeableModalInit(i, k)}>Interchange Item</a></li>}
                                            </ul>
                                        </div> */}
                                    </div> : null}</td>
                                    <td>{i.qty ? i.qty : 0}</td>
                                    <td className="link-primary cursor_pointer" onClick={() => this.allocationDetailInit(i?.item_profile?.id,i.item_profile.name,indent.warehouse?.name,indent.warehouse.id,i.demand_item_id)}>{i.allocated_qty ? i.allocated_qty : 0}</td>
                                    <td className="link-primary cursor_pointer" onClick={() => this.consumptionDetailInit(i?.item_profile?.id,i.item_profile.name,indent.warehouse?.name,indent.warehouse.id,i.demand_item_id)} >{i.consume_qty ? i.consume_qty : 0}</td>
                                    <td>{i.cancelled_qty ? i.cancelled_qty : 0}</td>
                                    <td>{i.pending_qty ? i.pending_qty : 0}</td>
                                </tr>);


                            })
                                : <tr ><td className='text-center' colSpan={10}>No Record</td></tr>
                        }

                    </tbody>
                </table>
                <div className="row align-items-center ">
                    <div className="col-sm-6">
                        <label htmlFor="notes" className="form-label">Notes</label>
                        <div>{indent.notes ? indent.notes : "-"}</div>

                    </div>
                    <div className=" col-sm-6">

                        <table className="table  table-borderless">
                            <tbody>

                                <tr className="subtotal_bg_color">
                                    <th>Total Quantity</th>
                                    <td className='float-end'>{indent.total_qty ? indent.total_qty : 0} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                    
            </div>
                    : null}
                    <ItemDetailsModal ref={this.itemDetailsRef}  /> 
                      {(this.props.demandData) && <AllocationDetailItemWise ref={this.itemWiseAllocationRef}/>}  

        </Ax>)

    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        default_currency        :   state.app && state.app.default_currency ? state.app.default_currency : 'INR',
    };
};

export default connect(mapStateToProps)(ItemDemandDetail);