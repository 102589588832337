import React from 'react';
import AppBaseComponent from "../../../components/AppBaseComponent";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from "moment";
import {Chart} from "react-google-charts";
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import TapSelect from "../../../components/ui/TapSelect";
import HttpAPICall from '../../../services/HttpAPICall';
import tapIcon from "../../../services/TapIcon";
import UpdateMyDashboardForm from "../../settings/configurationGeneral/myDashboard/UpdateMyDashboardForm";

class TicketWidget extends AppBaseComponent {

    constructor(props) {
        super(props);
        this.state                      =   {
            iam_user_sites                  :   [],
            loading                         :   false,
            data                            :   null,
            remark_day                      :   0,
            remark_time                     :   '',
            ticketFormData                  :   {filter_site_id : '',search_ticket:''}
        }
        this.updateMyDashboardRef      =   React.createRef();
    }

    componentDidMount() {
        this.initilaizeFormFilter(this.props);
        if (localStorage.getItem('ticketWidget')) {
            let ticketData = JSON.parse(localStorage.getItem('ticketWidget'));
            if (ticketData) {
                let lastRefreshTime = moment(ticketData.time).format()
                let nextDay = moment(lastRefreshTime).add(1, 'days').format()
                lastRefreshTime = moment(lastRefreshTime).valueOf()
                nextDay = moment(nextDay).valueOf()

                let latestDataTime = moment(nextDay - lastRefreshTime).valueOf()
                if (latestDataTime > 86400000) {
                    localStorage.removeItem('ticketWidget')
                    setTimeout(() => {
                        this.getTicketListing();
                    }, 1000 * (this.props.indexProps ? this.state.indexProps : 8));
                    
                } else {
                    this.setState({
                        data: ticketData.data,
                        remark_time: ticketData.time,
                        remark_day: ticketData.remark_aging
                    })
                }

            } else {
                setTimeout(() => {
                    this.getTicketListing();
                }, 1000 * (this.props.indexProps ? this.state.indexProps : 8));
            }
        } else {
            setTimeout(() => {
                this.getTicketListing();
            }, 1000 * (this.props.indexProps ? this.state.indexProps : 8));
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.iam_user_sites !== this.props.iam_user_sites || nextProps.dashboard_filter_site_id !== this.props.dashboard_filter_site_id || nextProps.clearDashboardFilter !== this.props.clearDashboardFilter) {
            this.initilaizeFormFilter(nextProps);
        }
    }

    initilaizeFormFilter               =   (props)  =>  {
        if(props.iam_user_sites.length > 0) {
            this.setState({
                iam_user_sites                  :   props.iam_user_sites
                    ? props.iam_user_sites.map((s) => { return {value: s.id, label: `${s.site_name} (${s.site_code})`}})
                    : [],
            }, () => {
                
                 if(this.props.dashboard_filter_site_id || props.clearDashboardFilter == "yes"){
                   
                    this.setState({
                        ticketFormData                  :   {
                            filter_site_id       :      props.dashboard_filter_site_id,
                            search_ticket       :       ''
                        }
                        
                    },() =>  this.getTicketListing())
                }
                
            });
        }
    }

    getTicketListing                =   (reload=false)  =>  {
        this.setState({loading: true});
        let params          =   {...this.state.ticketFormData ,reload : reload ? 'reload' : false};
        HttpAPICall.withAthorization('GET',process.env.REACT_APP_IAM_API_SERVER + '/group/ticket_remark_ageing_data', this.props.access_token, params, {} , (response) => {
            {this.setState({data : response.data.data,remark_time : response.data.time,remark_day : response.data.remark_aging})}
            if(!this.state.ticketFormData.filter_site_id && !this.state.ticketFormData.search_ticket){
                localStorage.setItem('ticketWidget',JSON.stringify(response.data));
            }
            if(reload !== false){
                localStorage.setItem('ticketWidget',JSON.stringify(response.data));
            }
        }).then(() => this.setState({loading: false}));
    }

    pinMyDashboardHandler = (widgetData=null) => {
        this.updateMyDashboardRef.current.updateMyDashboardModalInit(widgetData,{key:"ticket",name:"Ticket"})
    }

    allTicketOptions                   =       [{value: 'my_ticket', label: 'My Ticket (Created by me)'},
                                                {value: 'my_tagged_tickets', label: ' My Tagged Tickets'},]


    render                      =   ()  =>  {;
        let allStatus           =   this.state.data && this.state.data.map(st => st.key).join(',');
        let inLimitFilter       =   "0-" + this.state.remark_day;
        let beyondLimitFilter   =   (this.state.remark_day + 1) + "-1000";
         
        let filterObj           =   {};
        if(this.state.ticketFormData){
            if(this.state.ticketFormData.filter_site_id){
                filterObj           =   {...filterObj , 'search_site_ids[]' : this.state.ticketFormData.filter_site_id}
            }
            if(this.state.ticketFormData.search_ticket){
                filterObj           =   {...filterObj , 'search_ticket' : this.state.ticketFormData.search_ticket}
            }
        }

        let widgetData = this.props.widget_keys && this.props.widget_keys.length > 0 && this.props.widget_keys.find(st => {return("ticket" == st.key)}) ? this.props.widget_keys.find(st => {return(st.key == "ticket")})  : null;
                                
        return (<div className="card mt-3">
            <div className="card-body">
                <div className="row">
                    <div className="col-5">
                        <h6 className="fw-bold primary_color">Ticket</h6>
                    </div>
                    <div className="col-3">
                        <TapSelect
                            isSearchable={true}
                            isClearable={true}
                            placeholder="All Tickets"
                            containerHeight="33px"
                            fontSize="93%"
                            options={this.allTicketOptions}
                            value={this.allTicketOptions.find(r => r.value ===  this.state.ticketFormData.search_ticket)}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'ticketFormData', 'search_ticket',() => this.getTicketListing())}
                        />
                    </div>
                    <div className="col-3 p0">
                        <TapSelect
                            options={this.state.iam_user_sites}
                            isSearchable={true}
                            isClearable={true}
                            placeholder="All Sites"
                            containerHeight="33px"
                            fontSize="93%"
                            value={this.state.iam_user_sites.find(r => r.value ===  this.state.ticketFormData.filter_site_id)}
                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'ticketFormData', 'filter_site_id',() => this.getTicketListing() )}
                        />
                    </div>
                    <div className="col-1 px-0 mx-0 text-center">
                        <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-2 btn btn-light btn-block" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false"/>
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li className="dropdown-item" style={{cursor:"pointer"}} onClick={() => this.getTicketListing(true)}>Refresh</li>
                            <li className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => this.pinMyDashboardHandler(widgetData)}>{!widgetData ? "Pinned to My Dashboard" : "Unpinned from My Dashboard"}</li>
                        </ul>
                    </div>
                </div>
                <div className="row mt-2 align-items-center">
                    <div className="col-6">
                        <table className="table table-hover table-bordered table-borderless bg-white my-2">
                            <thead className="table-secondary">
                            <tr className="text-center">
                                <th style={{width:"25%"}} className="text-start" >Type</th>
                                <th style={{width:"15%"}}>Count</th>
                                <th style={{width:"30%"}}>Remark within {this.state.remark_day} Days</th>
                                <th style={{width:"30%"}}>Remark beyond {this.state.remark_day} Days</th>
                            </tr>
                            </thead>
                            <tbody>
                            { this.state.loading
                            ? (<tr>
                                <td className="text-center" colSpan="4"><Loader/></td>
                            </tr>)
                            : <Ax> { this.state.data
                                    ? (<Ax>{this.state.data.map((s,k) => {
                                        let obj             =   {...filterObj, 'search_ticket_status' : s.key};
                                        let inLimitObj      =   {...obj, remark_ageing_days : inLimitFilter};
                                        let beyondLimitObj  =   {...obj, remark_ageing_days : beyondLimitFilter};
                                        return <tr className="text-center" key={k}>
                                            <td className="fw-bold text-start">{s.status}</td>
                                            <td><Link to={{pathname: "/ticket" , search: "?" + new URLSearchParams(obj).toString() }}>{s.total}</Link></td>
                                            <td><Link to={{pathname: "/ticket" , search: "?" + new URLSearchParams(inLimitObj).toString() }}>{s.active}</Link></td>
                                            <td><Link to={{pathname: "/ticket" , search: "?" + new URLSearchParams(beyondLimitObj).toString() }}>{s.inactive}</Link></td>
                                        </tr>
                                    })}
                                    <tr className="text-center">
                                        <td className="fw-bold text-start">Total</td>
                                        <td><Link to={{pathname: "/ticket" , search: "?" + new URLSearchParams({...filterObj, search_ticket_status : allStatus}).toString()}}>
                                            {this.state.data.map(s => s.total).reduce((a, b) => a + b, 0)}
                                        </Link></td>
                                        <td><Link to={{pathname: "/ticket" , search: "?" + new URLSearchParams({...filterObj, search_ticket_status : allStatus, remark_ageing_days : inLimitFilter}).toString()}}>
                                            {this.state.data.map(s => s.active).reduce((a, b) => a + b, 0)}
                                            </Link></td>
                                        <td><Link to={{pathname: "/ticket" , search: "?" + new URLSearchParams({...filterObj, search_ticket_status : allStatus, remark_ageing_days : beyondLimitFilter}).toString()}}>
                                            {this.state.data.map(s => s.inactive).reduce((a, b) => a + b, 0)}
                                            </Link></td>
                                    </tr></Ax>) 
                                    : null
                                }
                              </Ax>
                            }
                            </tbody>
                        </table>
                    </div>
                    <div className="col-6 p0">
                   <div className="my-1">
                           {this.state.loading
                            ? <Loader />
                            : (   <Chart
                       width={'100%'}
                       height={'100%'}
                       chartType="BarChart"
                       loader={`<div>Loading Chart</div>`}
                       data={[['Ticket Type',`Withing ${this.state.remark_day} Days`,`Beyond ${this.state.remark_day} Days`]].concat(this.state.data?.map((d) =>
                        { return [d.status, parseInt(d.active),parseInt(d.inactive)]; }))}
                       options={{
                           legend: { position: 'top' },
                           colors: ['#2A974E', '#EC4E4E'],
                           isStacked:true,
                           chartArea: {width: '100%', left:'70', height: '80%' }
                       }}
                       rootProps={{ 'data-testid': '1.5' }}
                   />)}
                   </div>
                    </div>
                    <div className="col-sm-12 text-muted fs11">
                        Report as of {this.state.remark_time}
                        <div className="float-end link-primary">
                            <Link target="_blank" to={{ pathname: "/report/ticket_board_list"}}>View Ticket Board</Link>
                        </div>
                    </div>
                </div>
            </div>
            <UpdateMyDashboardForm
                    ref={this.updateMyDashboardRef}
               />
        </div>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        iam_user_sites          :   state.app && state.app.user_sites ? state.app.user_sites : [],
        widget_keys             :   state.app && state.app.user_info && state.app.user_info.widget_keys && state.app.user_info.widget_keys.length > 0 ? state.app.user_info.widget_keys : [],
    }; 
};


export default connect(mapStateToProps)(TicketWidget);