import AppBaseComponent from '../../../../components/AppBaseComponent';
import { connect } from 'react-redux';
import {Link} from "react-router-dom";
import ApplicationLayout from '../../../../layouts/ApplicationLayout';
import tapIcon from "../../../../services/TapIcon";
import React from "react";
import { Collapse } from 'bootstrap';
import { Helmet } from 'react-helmet';
import Details from './Details';
import Documents from './Documents';
import SCSSetting from './scsSetting/SCSSetting';
import HttpAPICall from '../../../../services/HttpAPICall';
import TapApiUrls from '../../../../services/TapApiUrls';
import Loader from '../../../../components/ui/Loader/Loader';
import * as actions from '../../../../store/actions/index'
import { DisplayListPagination, DisplaySearchCriteria } from '../../../../components/TapUi';
import AddScsSetting from './scsSetting/AddScsSetting';
import Setting from './setting/Setting';


class AssetMatrixView extends AppBaseComponent {
    
    allTabs                             =   ["details", "documents","scs_setting","add","edit","setting"]
    
    constructor(props) {
        super(props);
        
        let tab                         =   props.match.params.tab;
        
        if(!this.allTabs.includes(tab)) {
            //Toaset error Message (No Asset Page FOund) :-
            //Redirecct to Asst List
        }
        
        this.state                      =   {
            enc_matrix_id                    :   props.match.params.id,
            tabScreen                        :   props.match.params.tab,
            link_load                        :   false,
            searchedElems                   :       null,
            basic_details_loading           :       false,
            assetBasicDetail              :       null,
        };
        
    }
    
    componentDidMount                   =   ()  =>  {
        this.getAssetMatrixDetail(this.props.match.params.id)
    }

    componentWillReceiveProps(nextProps) {
      
        if(nextProps.match.params.tab !== this.state.tabScreen ) {
            this.setState({tabScreen:nextProps.match.params.tab,enc_matrix_id:nextProps.match.params.id})
        } 
        if(nextProps.match.params.id !== this.props.match.params.id ) {
            this.getAssetMatrixDetail(nextProps.match.params.id)
        } 
    }

    getAssetMatrixDetail                =     (id)    =>  {
        this.setState({basic_details_loading : true})
        let params = {get_fuel_type : "Y",get_section : "Y"}
        HttpAPICall.withAthorization('GET', TapApiUrls.IAM + '/asset_matrix/' + id,this.props.access_token,params)
            .then((response) => {
                let respData                =   response.data;
                this.setState({
                    assetBasicDetail         :    respData.data,
                });
            }).then(() => {
            this.setState({basic_details_loading: false});
        });
    }

   assetListingHandler                     =   (collapse = true)  =>  {
        collapse && new Collapse(document.getElementById('assetListing'));
        // if(document.getElementById('pageTbl')) {
        //     document.getElementById('pageTbl').scroll({ top: 0, behavior: 'smooth' });
        // }
    }

    linkAssetHandler                =       (id)        =>      {
        this.props.history.push('/asset_matrix/details/' + id)
        this.assetListingHandler()
        this.setState({ 
           tabScreen : this.state.tabScreen , enc_matrix_id : id
        });
    }

    goToAddAsset        =       ()      =>      {
        this.props.history.push({ pathname: `/add_asset/${this.state.enc_matrix_id}`, state: { matrixId: this.state.enc_matrix_id ,routedFrom:'Asset Matrix View'} })
    }

    goToAssetList       =       ()      =>      {
        let obj = {};

        obj['assetMatrix[]'] = this.state.enc_matrix_id;
        this.props.history.push({ pathname: "/measurable_asset", search: "?" + new URLSearchParams(obj).toString() })

    }
    
    loadTabContentJsx                   =   ()  => {
        switch (this.state.tabScreen) {
            case 'details':
                return <Details matrix_enc_id = {this.state.enc_matrix_id} assetMatrixDetail={this.state.assetBasicDetail}/>;
            case 'documents':
                return <Documents matrix_enc_id = {this.state.enc_matrix_id}/>;
            case 'scs_setting':
                return <SCSSetting matrix_enc_id = {this.state.enc_matrix_id} parentProps={this.props} assetMatrixDetail={this.state.assetBasicDetail} />;
            case 'setting':
                return <Setting matrix_enc_id = {this.state.enc_matrix_id} parentProps={this.props} assetMatrixDetail={this.state.assetBasicDetail} />;
            case 'add':
                return <AddScsSetting matrix_enc_id = {this.state.enc_matrix_id} assetMatrixDetail={this.state.assetBasicDetail} tabScreen={this.state.tabScreen} parentProps={this.props}/>;
            case 'edit':
                    return <AddScsSetting matrix_enc_id = {this.state.enc_matrix_id} assetMatrixDetail={this.state.assetBasicDetail} tabScreen={this.state.tabScreen} parentProps={this.props}/>;
            default:
                return <Details matrix_enc_id = {this.state.enc_matrix_id}/>;
        }
    }
    
    pageTitleBarJsx                         =   ()  =>  {
        
        return (<div className="page_title row m0">
            <div className="col-12 asset_collapse">
                <button type="button" onClick={this.assetListingHandler} className="btn btn-primary asset_collapse_button" style={{ height: "73%" }}>
                    <tapIcon.FontAwesomeIcon icon={tapIcon.faCaretRight} />
                </button>
                <h3 style={{ marginLeft: "36px" }}>Asset Matrix ID :  {this.state.assetBasicDetail && this.state.assetBasicDetail.asset_matrix_unique_id ? this.state.assetBasicDetail.asset_matrix_unique_id : "-"}</h3>
                <div className="text-end mt15">
                    <button type="button" className="btn btn-secondary"  data-bs-toggle="dropdown" role="button" aria-expanded="false">
                        <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid mr3" alt="item-action" /></button>
                    <ul className="dropdown-menu dropdown-menu-end" style={{ cursor: "pointer" }}>
                        <li>
                            <a role="button"
                                onClick={() => this.goToAddAsset()}
                                className={'dropdown-item'}
                               style={{ "pointerEvents": "all" }}
                            >Add Asset</a>
                        </li>
                        <li>
                            <a role="button"
                                onClick={() => this.goToAssetList()}
                                className={'dropdown-item'}
                               style={{ "pointerEvents": "all" }}
                            >View Assets for this Matrix</a>
                        </li>
                    </ul>
                    <Link to="/asset_matrix">  <button type="button" className="btn btn-secondary">
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-action" /></button>
                    </Link>
                </div>
            </div>
        </div>);
    }
    
    assetListingJsx                     =   ()  =>  {
        
        return (<div id="assetListing" className="bg-white collapse" >
            <div className="page_title row m0">
                <div className="col-12 asset_collapse mr0 pr0">
                    <h3>Asset Matrix List</h3>
                    <div className='float-end'>
                        <button type="button" onClick={this.assetListingHandler} className="btn btn-primary" style={{ height: "71%",lineHeight : "26px", maxHeight: "90%!important",marginTop : "10px" }}>
                            <tapIcon.FontAwesomeIcon icon={tapIcon.faCaretLeft} />
                        </button>
                    </div>
                </div>
            </div>
            <div className="page_containt row"style={{height : "81vh"}} >
                <div className="pageTbl col-12" style={{paddingRight : "7px"}} >
                <DisplaySearchCriteria searchedElems={this.props.searchedElems} />
                    <table className="table table-bordered table-hover table-sm table-fixed bg-white">
                        <thead className="table-secondary" >
                            <tr>
                                <th scope="col" style={{ width: "15%" }}>Asset Matrix</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.asset_listing_loaded
                                ? <tr><td className="text-center" colSpan="1"><Loader /></td></tr>
                                : (this.props.matrix_listing && this.props.matrix_listing.length > 0
                                    ? this.props.matrix_listing.map((item, index) => {
                                        return (<tr><td><a onClick={() => this.linkAssetHandler(item.id)} role="button" className="link-primary">{item.asset_matrix_unique_id ? item.asset_matrix_unique_id : "-"}</a> <br />
                                            <small>Asset Type : {item.assetType.asset_type_name ?? "-"}</small>
                                            </td></tr>)
                                    })
                                    : null)
                            }
                        </tbody>
                    </table>
                    <DisplayListPagination
                        meta={this.props.matrix_meta}
                        onPageChange={(e) => this.props.onListingSuccess(e.selected + 1,this.props.matrix_param,this.props.searchedElems)}
                    />
                </div>
            </div>

        </div>);
    }

    assetMatrixCardJsx          =       ()      =>      {
       let detail = this.state.assetBasicDetail
        return (<table className="table table-hover table-bordered bg-white">
            <tbody>
                <tr>
                    <td className='text-center' >
                        <img src={detail && detail.image} className="img-fluid asset_matrix_card_image " alt={"card"} />
                    </td>
                </tr>
                <tr>
                    <td>Asset Matrix ID : <br />
                        <a className='link-primary'><b>{detail && detail.asset_matrix_unique_id ? detail.asset_matrix_unique_id : "-"}</b></a></td>
                </tr>
                <tr>
                    <td>Asset Type : <br />
                        <b>{detail && detail.assetType ? detail.assetType.asset_type_name : "-"}</b></td>
                </tr>
                <tr>
                    <td>Manufacturer : <br />
                        <b>{detail && detail.manufacturer? detail.manufacturer.manufacturer : "-"}</b></td>
                </tr>
                <tr>
                    <td>model :<br />
                        <b>{detail && detail.model_detail ? detail.model_detail.model : "-"}</b></td>
                </tr>
                <tr>
                    <td>Variant  : <br />
                        <b>{detail &&  detail.variant ? detail.variant : "-"}</b></td>
                </tr>
                <tr>
                    <td>Capacity  : <br />
                        <b>{detail &&  detail.capacity ? detail.capacity : "-"}</b></td>
                </tr>
                <tr>
                    <td>Counterlog  : <br />
                        <b>{detail &&  detail.assetType && detail.assetType.hour_meter == "yes" ? "Hour-Meter, " : ""} 
                           {detail &&  detail.assetType && detail.assetType.odo_meter == "yes" ? "Odo-Meter, " : ""}
                           {detail &&  detail.assetType && detail.assetType.operation_cycle == "yes" ? "Operation Cycle." : ""}
                         </b></td>
                </tr>
            </tbody>
        </table>)
    }


    render                              =   ()  =>  {
       
        return <ApplicationLayout>
            <Helmet><title>Asset Detail</title></Helmet>
            <div className='asset_collapse'>
                {this.pageTitleBarJsx()}
                <div className='asset_table'>
                    {this.assetListingJsx()}
                </div>
                <div className="container-fluid pl5">
                    {this.state.basic_details_loading ? <Loader />
                        : <div className=" row mt-1">
                            <div className="col-9 ">
                                {this.loadTabContentJsx()}
                            </div>
                            <div className="page_containt col-3 pl4 pr1" style={{ top: "0px" }}>
                                <div className="pageTbl">
                                    {this.assetMatrixCardJsx()}
                                </div>
                            </div>

                        </div>}
                </div>

            </div>

        </ApplicationLayout>
    }
    
    
}

const mapStateToProps = state => {
 
    return {
        access_token            :   state.auth.access_token,
        matrix_param            :   state.app && state.app.matrix_search_param ? state.app.matrix_search_param : {},
        matrix_listing_loaded   :   state.app && state.app.matrix_listing_loaded ? state.app.matrix_listing_loaded : false,
        matrix_listing          :   state.app && state.app.matrix_listing ? state.app.matrix_listing : [],
        matrix_meta             :   state.app && state.app.matrix_meta ? state.app.matrix_meta : null,
        matrix_total_count      :   state.app && state.app.matrix_total_count ? state.app.matrix_total_count : 0,
        searchedElems           :   state.app && state.app.matrixSearchedElems ? state.app.matrixSearchedElems : [],
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onListingLoading          :   (data) => dispatch(actions.assetMatrixListingDataInitilization()),
        onListingSuccess        :   (params,formSearchedElems) => dispatch(actions.assetMatrixListingDataLoading(params,formSearchedElems)),
    };
};

export default connect(mapStateToProps , mapDispatchToProps)(AssetMatrixView);