import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Modal } from "bootstrap";
import AppBaseComponent from '../../../components/AppBaseComponent';
import Ax from "../../../components/hoc/Ax";
import Loader from '../../../components/ui/Loader/Loader';
import uuid from "react-uuid";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import DateService from '../../../services/DateService';
import HttpAPICall from '../../../services/HttpAPICall';
import TapIcon from '../../../services/TapIcon';
import ItemViewInList from '../includes/ItemViewInList';
import ItemDetailsModal from '../../items/itemDetails/ItemDetailsModal';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import TapSelect from '../../../components/ui/TapSelect';
import InputItemSearch from '../../includes/ui/InputItemSearch';
import moment from 'moment';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import download from '../../../services/DownloadFile';
import InputEmployeeSearch from '../../includes/ui/InputEmployeeSearch';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import InputSubAssemblySearch from '../../includes/ui/InputSubAssemblySearch';
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import TapInputNumber from '../../../components/ui/TapInputNumber';
import InputTicketSearch from '../../includes/ui/InputTicketSearch';
import Status from '../../../components/ui/Status';
import ItemDemandDetail from './ItemDemandDetail';


class ItemDemandList extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initItemDemandSearchForm   =   {
            search_transaction_id            :       '',
            search_title                     :       '',
            search_warehouse_ids             :       [],
            date_range                       :       null,
            date_range_start                 :       null,
            date_range_end                   :       null,
            search_item_ids                  :       [],
            search_item_tags                 :       [],
            search_status_ids               :       [],
            search_demand_for               :         '',
            search_asset_ids                :        [],
            search_sub_assembly_ids         :        [],
            search_workstation_ids          :        [],
            search_employee_ids             :        [],
            search_approved_aging           :        '',
            search_voucher_aging            :        '',
            tagged_user_filter              :       '',
            tagged_user_ids                 :       [],
            'search_ticket_ids'             :   [],
        }

        this.purchaseSearchFormLabel        =       {
            search_transaction_id           :   'Transaction ID : ',
            search_title                    :   'Title : ',
            'search_ticket_ids'              :   'Ticket : ',
            search_warehouse_ids            :   'Warehouses : ',
            date_range                      :   'Date Range : ',
            search_item_ids                 :   'Items : ',
            search_item_tags                :   'Item Tags : ',
            search_status_ids               :   'Search Status : ',
            search_demand_for               :   'Demand For : ',
            search_asset_ids                :   'Assets : ',
            search_workstation_ids          :   'Workstation : ',
            search_employee_ids             :   'Employee : ',
            search_sub_assembly_ids             :   'Sub Assembly : ',
            search_approved_aging           :   'Approval Ageing : ',
            search_voucher_aging            :   'Voucher Ageing : ',
            tagged_user_filter              :   'User Tagging : ',
            tagged_user_ids                 :   'Tagged Users : ',
            searchedTickets                 :           [],
        }

        this.per_page                       =   25;

        this.updateCommentFormInit            =       {
            status_id                            :       '',
            notes                             :       ' ',
        }

        this.cancelItemFormInit               =       {
            notes                             :       '',
        }

        this.state                          =   {
            minimizeTable                       :   false,
            reportDownloading                   :   false,

            data                                :   [],
            meta                                :   null,
            listLoading                         :   true,
            listingPage                         :   1,
            totalData                           :   0,
            viewLoading                         :   false,
          ItemDemandDetail                :   null,
            openTransactionId                   :   '',
            searchedVendor                      :   [],
            searchedItem                        :   [],
            listFilterFormSubmitting            :   false,
            manufacturers                       :   [],
            formSearchedElems                   :   [],
            ItemDemandSearchForm            :   {...this.initItemDemandSearchForm},
            submittedItemDemandFilterForm   :   {...this.initItemDemandSearchForm},
            addCommentForm                  :       {...this.updateCommentFormInit},
            allAllowedStatus                :       [],
            pdfDownloading                  :   false,
            all_periods                         :       [],
            searchedItems                       :       [],
            allStatusOptions                    :        [],
            allItemTags                         :        [],
            searchedAssets                      :        [],
            searchedEmployees                   :        [],
            allWorkstations                     :        [],
            searchedSubassembly                   :   [],
            allIndentOptions                    :        [],
            all_warehouses                      :        [],
            userFilters                         :   [
                {value  :   'tagged_me', label : 'My Tagged Item Demand'},
                {value  :   'created_by_me', label : 'Item Demand Created By Me'},
            ],
            allUsers                            : [], 
            cancelledItemList                   :       [],
            cancelItemFormSubmitting             :       false,
            cancelledItemForm                 :       {...this.cancelItemFormInit},  
            searchedTickets                     :       []
        };

        this.myRef                         =   React.createRef();
        this.listFilterFormModalId          =   uuid();

        this.item_profilesRef                 =   React.createRef();

        this.fetchUrl                       =  INVENTORY_API_BASE_URL_2  + '/item_demand/list';
        this.formDataUrl                    =  INVENTORY_API_BASE_URL_2  + '/item_demand/form_data';
        this.detailUrl                      =  INVENTORY_API_BASE_URL_2 + '/item_demand/detail/__transaction_id';
    }

    componentDidMount() {
        setTimeout(() => this.initializeData(this.props),1000)
        this.listFilterFormModal            =   new Modal(document.getElementById(this.listFilterFormModalId),{keyboard:false,backdrop:false});
        this.releasedItemModal                            =   new Modal(document.getElementById('releasedItemModal'), {keyboard: false, backdrop :false});
       
    }

    initializeData                      =   (pr)  =>  {

         if(pr){
                let all_periods             =       [...pr.all_periods , {key : 'custom' , display : 'Custom Date Range'}] ;
               
                HttpAPICall.withAthorization('GET', this.formDataUrl, pr.access_token, null, null, (response) => {
                    let formData = response.data;
                    this.setState({
                        allWorkstations                    :       formData && formData.workstations ?  formData.workstations.map((ws) => {  return {value: ws.id, label:`${ws.workstation_name} `, workstation : ws} }) : [],
                        allIndentOptions            :   [{value:'asset',label:'Asset'},{value:'workstation',label:'Workstation'},{value:'employee',label:'Employee'},{value:'sub_assembly',label:'Sub Assembly'}],
                        all_warehouses                     :       pr.all_warehouses ? pr.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}) : [] ,
                        all_periods                        :       all_periods ,
                        allStatusOptions                   :       formData && formData.group_status_list && formData.group_status_list.length > 0 ?  formData.group_status_list.map((s) => { return {value: s.id, label: s.name}; }) : [],
                    allItemTags                        :       pr.item_tags && pr.item_tags.length > 0 ? pr.item_tags.map((s) => { return {value: s.id, label: s.name}; }) : [],
                        allUsers                     :   formData && formData.users ? formData.users.map((s) => { return {value: s.id, label: s.full_name,site_ids:s.linked_sites}; }) : [],
                    }, () => {
                        
                        if(pr && pr.location && pr.location.state && pr.location.state.ItemDemandId){
                           
                            this.setState({
                                minimizeTable : true,
                                viewLoading : true
                            })
                         }  
                    });
                }, (err) => {
    
                });
            }
        
        this.loadListingTblData(1,pr);
    }

    loadListingTblData                  =   (page = 1,pr=null)  =>  {
        this.setState({listLoading: true, listing_tbl_page: page});
        let params                      =   {page:page, per_page : this.per_page,...this.state.submittedItemDemandFilterForm};
        HttpAPICall.withAthorization('GET', this.fetchUrl, this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
            this.setState({
                data            :   respData.data,
                meta                :   {
                    from                        :   respData.from,
                    to                          :   respData.to,
                    total                       :   respData.total,
                    last_page                   :   respData.last_page,
                    per_page                    :   respData.per_page,
                    current_page                :   respData.current_page
                },
                // meta            :   respData.meta,
                 totalData       :   respData.total, 
            },() => {
                if(pr && pr.location && pr.location.state && pr.location.state.ItemDemandId){
                    this.viewItemDemandDetail(pr.location.state.ItemDemandId)
                 }
            });
        }).then(() => this.setState({listLoading: false}));
    }

    viewItemDemandDetail            =   (transaction_id, refresh=false)  =>  {
        this.setState({viewLoading : true,minimizeTable : true, openTransactionId : transaction_id});
        if(this.state.ItemDemandDetail && this.state.ItemDemandDetail.transaction_id == transaction_id && refresh == false) {
            this.setState({viewLoading: false});
        } else {
            let detailUrl                       =   this.detailUrl.replace('__transaction_id', transaction_id);
            //Get Detail of Purchase Return Detail :-
            HttpAPICall.withAthorization('GET', detailUrl, this.props.access_token, {}, {}, (resp) => {
                let respData = resp.data;
               
                this.setState({
                    ItemDemandDetail        :   resp.data,
                    allAllowedStatus     :   respData && respData.change_status_list && respData.change_status_list.length > 0 ? respData.change_status_list.map((s) => { return {value: s.id, label: s.name}; }) : [] ,
                    addCommentForm       :   {...this.updateCommentFormInit , status_id : respData.data && respData.data.status ? respData.data.status.id : ''}
                });
                
            }).then(() => this.setState({viewLoading: false}));
        }
    }

    closeItemDemandDetail           =   ()  =>  {
        this.setState({viewLoading : false,minimizeTable : false, openTransactionId: null});
    }

    
    listSearchModalInit             =   ()  =>  {
        this.listFilterFormModal.show();
    }

    viewItemModalInit                    =   (item)  =>  { 
        if(item){
            this.item_profilesRef.current.modalInit(item)
        }
    }

    submitItemDemandSearchForm                =       (e)          =>          {
        e &&  e.preventDefault()
        let serachFormKeys              =   Object.keys(this.state.ItemDemandSearchForm);
       
        let searchedElems               =   [];
        let searchParams                =   {};
        serachFormKeys.map((key)        =>  {
            
            let label                       =   this.purchaseSearchFormLabel[key];
            let value                       =   this.state.ItemDemandSearchForm[key];
           
            if(value   && value.length > 0) {
                searchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    if(key == 'search_transaction_id') {
                        show_val            =   this.state.ItemDemandSearchForm && this.state.ItemDemandSearchForm.search_transaction_id ? this.state.ItemDemandSearchForm.search_transaction_id : '';
                    }
                    if(key == 'search_title') {
                        show_val            =   this.state.ItemDemandSearchForm && this.state.ItemDemandSearchForm.search_title ? this.state.ItemDemandSearchForm.search_title  : '';
                    }
                    if(key == 'search_warehouse_ids') {
                        show_val            =   this.state.all_warehouses.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_item_ids') {
                        show_val            =   this.state.searchedItems ? this.state.searchedItems.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_item_tags') {
                        show_val            =   this.state.allItemTags.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_status_ids') {
                        show_val            =   this.state.allStatusOptions.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_ticket_ids') {
                        show_val            =   this.state.searchedTickets ? this.state.searchedTickets.map(s => s.value).join(', ') : '';
                    }
                    
                    if(key == 'search_asset_ids') {
                        show_val            =   this.state.searchedAssets ? this.state.searchedAssets.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_workstation_ids') {
                        show_val            =   this.state.allWorkstations.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_employee_ids') {
                        show_val            =   this.state.searchedEmployees ? this.state.searchedEmployees.map(s => s.label).join(', ') : '';
                    }
                    if(key == 'search_sub_assembly_ids') {
                        show_val            =   this.state.searchedSubassembly ? this.state.searchedSubassembly.map(s => s.label).join(', ') : '';
                    }
                    if(key == 'search_approved_aging') {
                        show_val            =   this.state.ItemDemandSearchForm && this.state.ItemDemandSearchForm.search_approved_aging ? this.state.ItemDemandSearchForm.search_approved_aging  : '';
                    }
                    if(key == 'search_voucher_aging') {
                        show_val            =   this.state.ItemDemandSearchForm && this.state.ItemDemandSearchForm.search_voucher_aging ? this.state.ItemDemandSearchForm.search_voucher_aging  : '';
                    }
                    if(key == 'tagged_user_filter') {
                        show_val            =   this.state.userFilters.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'tagged_user_ids') {
                        show_val            =   this.state.allUsers.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'date_range') {
                        let  start_range         =      moment(this.state.ItemDemandSearchForm.date_range_start).format('DD-MMM-YYYY');
                        let end_range            =      moment(this.state.ItemDemandSearchForm.date_range_end).format('DD-MMM-YYYY')
                        let display_custom      =      `Custom Date Range (${start_range} - ${end_range})`
                        show_val                 =     this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        this.setState({
            submittedItemDemandFilterForm   :   {...this.state.ItemDemandSearchForm},
            formSearchedElems           :   searchedElems
        }, () => {
            this.listFilterFormModal.hide()
            this.loadListingTblData(1);
        });
    }

    ItemDemandSearchClear                  =        ()             =>        {
        this.setState({
            formSearchedElems               :   [],
            searchedVendor                  :   [],
            searchedItem                    :   [],
            searchedAssets                :   [],
            searchedEmployees             :   [],
            searchedSubassembly             :   [],
            ItemDemandSearchForm            :   {...this.initItemDemandSearchForm},
            submittedItemDemandFilterForm     :   {...this.initItemDemandSearchForm}
        }, () => {
            this.loadListingTblData(1);
            this.listFilterFormModal.hide();
            this.props.history.push('/item_demand_list')
        });
    }

    updateComment           =           ()          =>      {
        this.myRef.current.scrollIntoView()
    }

    itemDemandAdd           =       ()  =>  {
        this.props.history.push('/item_demand_add')
    }

    ItemDemandClear                  =        ()             =>        {
        this.setState({
            formSearchedElems               :   [],
            searchedVendor                  :   [],
            searchedItem                    :   [],
            ItemDemandSearchForm            :   {...this.initItemDemandSearchForm},
            submittedItemDemandFilterForm     :   {...this.initItemDemandSearchForm}
        }, () => {
            this.listFilterFormModal.hide();
        });
    }

    submitUpdateCommentForm         =       (e)          =>      {
        e.preventDefault()
        this.setState({addCommentFormSubmitting : true})
        let frmData = {
             ...this.state.addCommentForm,
             transaction_id : this.state.ItemDemandDetail.transaction_id
           }
           HttpAPICall.withAthorization('PUT', INVENTORY_API_BASE_URL_2 + '/item_demand/comment', this.props.access_token, {}, { ...frmData }, (response) => {
               toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
               this.viewItemDemandDetail(this.state.ItemDemandDetail.transaction_id,true);
               this.loadListingTblData(1)
               this.setState({addCommentForm : {...this.updateCommentFormInit}})
           }).then(() => this.setState({ addCommentFormSubmitting : false }));
    }

    openUpdatePurchaseScreen        =   ()      =>  {
        this.props.history.push({ pathname: `/item_demand_add`, state: { itemDemandData: this.state.ItemDemandDetail } })
    }
   
    //***********************DELETE PDF **************************** */
    deleteItemDemand              =           (transaction_id)              =>      {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('DELETE', INVENTORY_API_BASE_URL_2 + '/item_demand/delete/' + transaction_id, this.props.access_token, {}, {}, (response) => {
                        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                        this.setState({minimizeTable : false})
                        this.loadListingTblData(1)
                     }).then(() => {

                    });
                }
            });
    }


    checkAllItem() {
        let allocationItemForCheckbox      =   document.getElementsByClassName('allocation_item_id_checkbox')
        if(allocationItemForCheckbox && allocationItemForCheckbox.length > 0) {
            for (let i = 0; i < allocationItemForCheckbox.length; i++) {
                allocationItemForCheckbox[i].checked = document.getElementById('all_check_allocation').checked;
            }
        }
    }

   releasedItemModalInit       =       ()      =>      {
            this.setState({cancelledItemList : []} , () => {
            let cancelledItemList          =        this.state.cancelledItemList;
            var showErrorMessage                =   false;
            let allocationItemForCheckbox      =   document.getElementsByClassName('allocation_item_id_checkbox');
            if(allocationItemForCheckbox && allocationItemForCheckbox.length > 0) {
                for (let i = 0; i < allocationItemForCheckbox.length; i++) {
                    if(allocationItemForCheckbox[i].checked) {
                        let itemId = allocationItemForCheckbox[i].value;
                        let data = this.state.ItemDemandDetail.items.find(i => i.item_id == itemId);
                    let row                         =   {...cancelledItemList[i],...data };
                        cancelledItemList[i]            =   row;
                        let list = cancelledItemList.filter(Boolean)
                        this.setState({cancelledItemList   :   [...list],
                                    cancelledItemForm   :   {...this.cancelItemFormInit,
                                    notes : ''}}
                                    ,() => this.releasedItemModal.show())
                    
                    }
                }
            }
            if(this.state.cancelledItemList && this.state.cancelledItemList.length == 0 && !showErrorMessage){
                toast.error('Please select an Item for Cancellation', { position: toast.POSITION.TOP_RIGHT });
            }
       })
        
   }

   submitCancelItemForm            =       (e)      =>      {
    e.preventDefault()
    
    let items                   =        [...this.state.cancelledItemList];
      
    let itemsLists              =        items.map(i => {
        console.log(i,'jjj')
                                          
                                             return {cancel_qty : parseInt(i.new_released_qty),demand_item_id:i.demand_item_id};
                                            });
                                           
    let formData                =          {...this.state.cancelledItemForm, items : [...itemsLists],transaction_id : this.state.ItemDemandDetail.transaction_id}
    
      this.setState({ cancelItemFormSubmitting: true })
      HttpAPICall.withAthorization('PUT', INVENTORY_API_BASE_URL_2 + '/item_demand/cancel_item', this.props.access_token, {}, { ...formData }, (response) => {
          toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
          this.releasedItemModal.hide()
          this.setState({cancelledItemForm         :       {...this.cancelItemFormInit}})
          this.viewItemDemandDetail(this.state.ItemDemandDetail.transaction_id,true)
      }).then(() => this.setState({ cancelItemFormSubmitting: false }));
  }

  addToAllocation                  =   ()  =>  {
        
    //  this.props.addToConsumptionHandler
    let demand_item_ids                     =   [];
    let warehouse_ids                   =   [];
   
    let purchaseItemCollections      =   document.getElementsByClassName('allocation_item_id_checkbox');
    if(purchaseItemCollections && purchaseItemCollections.length > 0) {
        for (let i = 0; i < purchaseItemCollections.length; i++) {
            if(purchaseItemCollections[i].checked) {
                let selectedPurchaseData = this.state.ItemDemandDetail?.items?.find(data => data.item_id == purchaseItemCollections[i].value);
               demand_item_ids.push(selectedPurchaseData)
            }
        }
    }
    if (demand_item_ids.length == 0) {
        toast.error('Please select atleast one Item', { position: toast.POSITION.TOP_RIGHT });
    } else {
        
        let demandStateData                    =   {   
            demandData                  :   this.state.ItemDemandDetail,
            items                       :   demand_item_ids,
        };
         this.props.history.push({ pathname: `/allocation_list/add`,  state: { addAllocationFromDemand : demandStateData } });
    }
}

    addToConsumption                  =   ()  =>  {
            
        //  this.props.addToConsumptionHandler
        let demand_item_ids                     =   [];
        let warehouse_ids                   =   [];
    
        let purchaseItemCollections      =   document.getElementsByClassName('allocation_item_id_checkbox');
        if(purchaseItemCollections && purchaseItemCollections.length > 0) {
            for (let i = 0; i < purchaseItemCollections.length; i++) {
                if(purchaseItemCollections[i].checked) {
                    let selectedPurchaseData = this.state.ItemDemandDetail?.items?.find(data => data.item_id == purchaseItemCollections[i].value);
                demand_item_ids.push(selectedPurchaseData)
                }
            }
        }
        console.log(demand_item_ids)
        if (demand_item_ids.length == 0) {
            toast.error('Please select atleast one Item', { position: toast.POSITION.TOP_RIGHT });
        } else {
            
            let demandStateData                    =   {   
                demandData                  :   this.state.ItemDemandDetail,
                items                       :   demand_item_ids,
            };
            this.props.history.push({ pathname: `/consumption_list/add`,  state: { addConsumptionFromAllocation : {...this.state.ItemDemandDetail,items :   demand_item_ids,}} });
        }
    }

    cloneDemandScreenInit             =       ()      =>      {
        this.props.history.push({pathname: `/item_demand_add`, state: { itemDemandData : this.state.ItemDemandDetail  , clone : 'yes'}})
    }

    

    ItemDemandDetailJsx             =   ()  =>  {
        //let {ItemDemandDetail}         =   {...this.state};
       
        let indent = this.state.ItemDemandDetail;
        
        return (<div className="tab_content_header">

            {!this.state.viewLoading
                ? (<Ax>
                    {indent
                        ?
                        <div>
                            <ItemDemandDetail demandData={this.state.ItemDemandDetail} checkAllItem={this.checkAllItem} />
                            <hr />
                            
                                    <div>
                                        <div className="tab_content_wrapper" ref={this.myRef}>
                                            <span className="content_heading">Comments & History</span>
                                        </div>
                                        {this.state.allAllowedStatus
                                            ? <form onSubmit={this.submitUpdateCommentForm}>
                                                <div className="my-3 row">
                                                    <label className="col-sm-3 col-form-label">Change status to</label>
                                                    <div className="col-sm-5">
                                                        <TapSelect
                                                            changeEvent={(selectedOption) => {
                                                                this.tapSelectChange(selectedOption, 'addCommentForm', 'status_id');
                                                            }}
                                                            value={this.state.allAllowedStatus.find(s => s.value == this.state.addCommentForm.status_id)}
                                                            options={this.state.allAllowedStatus}
                                                            isSearchable={false}
                                                            isDisabled={indent.change_status == 'N' || !this.props.permissions.includes('isc_item_demand_change_status') ? true  :false}
                                                            placeholder="Please Select Status"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="my-2">
                                                    <label className="form-label">Comments</label>
                                                    <textarea
                                                        name="notes"
                                                        value={this.state.addCommentForm.notes}
                                                        onChange={(e) => this.formInputHandler(e, 'addCommentForm')}
                                                        className="form-control"
                                                        placeholder="Please Add a comment"
                                                        required
                                                        style={{ height: "100px" }}
                                                    />
                                                </div>
                                                <div className="col-12 text-end">
                                                    <button type="submit" disabled={this.state.addCommentFormSubmitting ? true : false} className="btn btn-primary mx-2">
                                                        Update {this.state.addCommentFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin ms-2" />) : ''}
                                                    </button>
                                                </div>
                                            </form>
                                            : null}
                                    </div>
                                    <hr />
                                    <div className="my-1">
                                        {
                                            indent && indent.comments && indent.comments.length > 0 && indent.comments.map((c, k) => {
                                                return (<Ax key={k}><div className="row">
                                                    {c.msg ? <div className="col-sm-8" dangerouslySetInnerHTML={{ __html: c.msg.split("|").join("<br />") }} /> : <div className='col-sm-8'>-</div>}
                                                    <div className="col-sm-4 text-end">
                                                        <TapIcon.imageIcon icon={TapIcon.CalenderIcon} style={{"margin-right" : "5px"}} className="img-fluid pr-5 fs12 " alt="item-action" />
                                                        <span>{DateService.dateTimeFormat(c.created_at,'DD-MMM-YYYY hh:mm A')}</span>
                                                    </div>
                                                    <div className="col-sm-12 my-1 text-sm">{c.notes}</div>
                                                </div><hr /></Ax>);
                                            })
                                        }
                                    </div>
                                
                        </div>
                        : null}
                </Ax>)
                : <Loader />}

        </div>)
    }

    // this.state.minimizeTable
    purchaseListingTableJsx             =   ()  =>  {
        return <Ax>
             <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.ItemDemandSearchClear} />
            <table className="table table-bordered table-fixed bg-white table-sm">
                <thead className="table-secondary" >
                    <tr className='text-center'>
                    {this.state.minimizeTable 
                      ? <th>Item Demand</th>
                      : <Ax>
                            <th style={{ width: "5%" }}>S.No</th>
                             <th style={{ width: "10%" }}>Transaction ID</th>
                            <th style={{ width: "10%" }} className='text-center'>Date</th>
                            <th style={{ width: "17%" }}>Warehouse</th>
                            <th style={{ width: "13%" }}>Item Demand for</th>
                            <th style={{ width: "6%" }} className='text-center'>Total Quantity</th>
                            <th style={{ width: "8%" }} className='text-center'>Status</th>
                            <th style={{ width: "5%" }}>Action</th>
                        </Ax>
                    }
                    </tr>
                </thead>
                <tbody>
                {this.state.listLoading 
                    ? <tr><td colSpan="10"><Loader /></td></tr>
                    : (this.state.data.length > 0 ? this.state.data.map((item,index) =>  <tr className={['text-center', this.state.openTransactionId == item.transaction_id ? 'bg-light' : ''].join(' ')} key={index}>
                        {this.state.minimizeTable 
                            ? <td className='text-start'>
                                <Link className="text-capitalize link-primary" onClick={() => this.viewItemDemandDetail(item.transaction_id)}>{item.transaction_id}</Link>
                                <div className='float-end text-xs'>{DateService.dateTimeFormat(item.transaction_date,'DD-MM-YYYY')}</div>
                                <div className="mt-1">
                                    <span class="text-sm">{item.warehouse?.name ?? "-"}</span>
                                    <em className="float-end text-sm text-muted">{item.status ? item.status.name : "-"}</em>
                                </div> 
                            </td>
                            : <Ax>
                                    <td>{this.state.meta ? this.state.meta.from + index : index}</td>
                                    <td><Link className="" role="button" onClick={() => this.viewItemDemandDetail(item.transaction_id)}>{item.transaction_id}</Link></td>
                                    <td>{DateService.dateTimeFormat(item.transaction_date,'DD-MMM-YYYY')}</td>
                                    <td>{item.warehouse ? item.warehouse.name : ''}</td>
                                     <td>{item.asset ? <div><small className='form-text'>{item.demand_for == 'asset' ? 'Asset : ' : ''}</small> {item.asset.name} ({item.asset.asset_code})</div>
                                                    : item.workstation ? <div><small className='form-text'>{item.demand_for == 'workstation' ?  'Workstation : ' : ''}</small> {item.workstation.workstation_name}  </div> 
                                                    : item.sub_assembly ? <div><small className='form-text'>{item.demand_for == 'sub_assembly' ?  'Sub Assembly : ' : ''}</small> {item.sub_assembly.name}  </div> 
                                                    : item.employee ? <div><small className='form-text'>{item.demand_for == 'employee' ?  'Employee : ' : ''}</small> {item.employee.name}  </div> : ''  } </td>
                                    <td>{item.total_qty}</td>
                                    <td>{item.status ? item.status.name : ''}</td>
                                    <td className="text-center">
                                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                            <TapIcon.FontAwesomeIcon icon={TapIcon.faEllipsisV} />
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><Link className="dropdown-item" role="button" onClick={() => this.viewItemDemandDetail(item.transaction_id)}>View</Link></li>
                                        </ul>
                                    </td>
                                </Ax>
                            }
                        </tr> ): <tr><td colSpan={11} className='text-center'>No Record</td></tr>)
                    }
                </tbody>
            </table>
            <DisplayListPagination meta={this.state.meta} onPageChange={(e) => this.loadListingTblData(e.selected + 1)} />
        </Ax>
    }

    ItemDemandListjsx               =   ()  =>  {
        let {ItemDemandDetail}      =   this.state;
        return <Ax>
            <div className="page_title row m0">
                <div className={this.state.minimizeTable ? "col-sm-3" : "col-sm-12"}>
                    <h3>Item Demand</h3>
                    <div className="text-end mt15">
                    {!this.state.minimizeTable && <button type="button" className="btn btn-secondary"  disabled={!this.props.permissions.includes('isc_item_demand_add')} onClick={this.itemDemandAdd}>New Demand</button>}
                        {/* {!this.state.minimizeTable && <button type="button" className="btn btn-secondary" onClick={this.purchaseDownloadHandler}>
                            <TapIcon.FontAwesomeIcon icon={TapIcon.faDownload} />
                            {this.state.reportDownloading && <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />}
                        </button>} */}
                        <button type="button" className="btn btn-secondary" onClick={this.listSearchModalInit}>
                            <TapIcon.imageIcon icon={TapIcon.SearchIcon} />
                        </button>
                    </div>
                </div>
                {this.state.minimizeTable && <div className="col-sm-9">
                    <h3>Item Demand : {ItemDemandDetail && ItemDemandDetail.transaction_id ? ItemDemandDetail.transaction_id : ""}</h3>
                    <div className="text-end mt15">
                        <button type="button" disabled={(this.state.ItemDemandDetail && this.state.ItemDemandDetail.can_edit == 'N') || !this.props.permissions.includes('isc_item_demand_edit') || this.state.viewLoading ? true : false} className="btn btn-secondary" onClick={() => this.openUpdatePurchaseScreen(this.state.ItemDemandDetail?.transaction_id)}>
                            Edit
                        </button>
                        <button disabled={this.state.viewLoading} type="button" className="btn btn-secondary" onClick={() => this.updateComment()} >Update Status </button>
                        <button disabled={this.state.viewLoading} type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                            <TapIcon.imageIcon icon={TapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li>
                                <a role="button" href
                                    onClick={() => {
                                        if (this.props.permissions.includes('isc_item_demand_add')) {
                                            return this.cloneDemandScreenInit();
                                        }


                                    }}
                                    className={['dropdown-item',   this.props.permissions.includes('isc_item_demand_add') ? '' : 'disabled'].join(' ')}
                                            title={!this.props.permissions.includes('isc_item_demand_add') ? 'This action cannot be performed' : ''}
                                    style={{ "pointerEvents": "all" }}
                                >Clone</a>
                            </li>
                            {this.props.permissions.includes('isc-allocation-add') && <li><a
                                className={
                                    (this.state?.ItemDemandDetail?.status?.level !== 3 && this.state?.ItemDemandDetail?.status?.level !== 4) ? "dropdown-item disabled" : "dropdown-item"} role="button"
                                disabled={(this.state.ItemDemandDetail && this.state?.ItemDemandDetail?.status?.level !== 3 && this.state?.ItemDemandDetail?.status?.level !== 4) ? true : false} onClick={() => this.addToAllocation()}>Add to Allocation</a></li>
                            }
                            {this.props.permissions.includes('isc-consumption-add') && <li><a
                                className={
                                    (this.state?.ItemDemandDetail?.status?.level !== 3 && this.state?.ItemDemandDetail?.status?.level !== 4) ? "dropdown-item disabled" : "dropdown-item"} role="button"
                                disabled={(this.state.ItemDemandDetail && this.state?.ItemDemandDetail?.status?.level !== 3 && this.state?.ItemDemandDetail?.status?.level !== 4) ? true : false} onClick={() => this.addToConsumption()}>Add to Consumption</a></li>
                            }
                            <li><a className={!this.props.permissions.includes('isc_item_demand_item_cancel') ? "dropdown-item disabled" : "dropdown-item"} role="button" disabled={!this.props.permissions.includes('isc_item_demand_item_cancel') ? true : false} href="#" onClick={() => this.releasedItemModalInit(this.state.ItemDemandDetail?.transaction_id)}>Cancel Item</a></li>
                            <li><a className={!this.props.permissions.includes('isc_item_demand_delete') ? "dropdown-item disabled" : "dropdown-item"} role="button" disabled={!this.props.permissions.includes('isc_item_demand_delete') ? true : false} href="#" onClick={() => this.deleteItemDemand(this.state.ItemDemandDetail?.transaction_id)}>Delete</a></li>
                        </ul>
                        <button type="button" className="btn btn-secondary" onClick={this.closeItemDemandDetail}>
                            <TapIcon.imageIcon icon={TapIcon.CloseIcon} />
                        </button>
                    </div>
                </div>}
            </div>
            <div className='container-fluid pl5'>
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-sm-3' : 'col-sm-12'].join(' ')} >{this.purchaseListingTableJsx()}</div>
                    {this.state.minimizeTable && <div className="tap_border_left col-sm-9 p-2 pageTbl bg-white" >{this.ItemDemandDetailJsx()}</div>}
                </div>
            </div>
        </Ax>
    }

    listFilterFormModalJsx = () => {
        return <div className="modal fade" id={this.listFilterFormModalId} tabIndex="-1">
            <div className="modal-dialog modal-lg">-
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Item Demand Search</h5>
                    </div>
                    <div className="modal-body">
                        <form id={this.listFilterFormModalId + 'Form'} onSubmit={this.submitItemDemandSearchForm}>
                        <Ax>
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Transaction ID</label>
                                            <div className="col-sm-4">
                                                <input
                                                    name="search_transaction_id"
                                                    type="text"
                                                    value={this.state.ItemDemandSearchForm.search_transaction_id}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "ItemDemandSearchForm")}
                                                    placeholder="Please enter Transaction ID"
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>
                                            <label className="col-sm-1 col-form-label col-form-label-sm  ">Title</label>
                                            <div className="col-sm-5">
                                                <input
                                                    name="search_title"
                                                    type="text"
                                                    value={this.state.ItemDemandSearchForm.search_title}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "ItemDemandSearchForm")}
                                                    placeholder="Please enter Title"
                                                    style={{ height: "30px", fontSize: "93%" }}
                                                />
                                            </div>


                                        </div>

                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Status</label>
                                            <div className="col-sm-10">
                                                <TapSelect
                                                    options={this.state.allStatusOptions}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'ItemDemandSearchForm', 'search_status_ids')}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    isMulti={true}

                                                    value={this.state.allStatusOptions.filter(s => this.state.ItemDemandSearchForm.search_status_ids.includes(s.value))}
                                                    placeholder="Select Status"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>
                                        </div>

                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Warehouse</label>
                                            <div className="col-sm-10">
                                                <TapSelect
                                                    options={this.state.all_warehouses}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'ItemDemandSearchForm', 'search_warehouse_ids')}
                                                    value={this.state.all_warehouses.filter(u => this.state.ItemDemandSearchForm?.search_warehouse_ids?.includes(u.value))}
                                                    isSearchable={true}
                                                    isMulti={true}
                                                    isClearable={true}
                                                    placeholder="Select Warehouses"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>
                                        </div>
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm ">Item</label>
                                            <div className="col-sm-10">
                                                <InputItemSearch
                                                    placeholder="Search Item"
                                                    isMulti={true}
                                                    changeEvent={(selectedOption) => {
                                                        this.tapSelectChange(selectedOption, 'ItemDemandSearchForm', 'search_item_ids');
                                                        this.setState({
                                                            searchedItems: selectedOption ? selectedOption : null
                                                        }, () => { });
                                                    }}
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                    value={this.state.searchedItems.length > 0 ? this.state.searchedItems.map(a => { a.label = a.display_label; return a; }) : []}
                                                />
                                            </div>

                                        </div>
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Item Tags</label>
                                            <div className="col-sm-10">
                                                <TapSelect
                                                    options={this.state.allItemTags}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'ItemDemandSearchForm', 'search_item_tags')}
                                                    value={this.state.allItemTags.filter(u => this.state.ItemDemandSearchForm?.search_item_tags?.includes(u.value))}
                                                    isSearchable={true}
                                                    isMulti={true}
                                                    isClearable={true}
                                                    placeholder="Select Item Tags"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>
                                        </div>
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm ">Demand For</label>
                                            <div className="col-sm-10">
                                                <TapSelect
                                                    changeEvent={(selectedOption) => {
                                                        this.tapSelectChange(selectedOption, 'ItemDemandSearchForm', 'search_demand_for', () => {
                                                            this.setState({
                                                                searchedAssets: [],
                                                                searchedEmployees: [],
                                                                searchedSubassembly :   [],

                                                                ItemDemandSearchForm: {
                                                                    ...this.state.ItemDemandSearchForm,
                                                                    search_asset_ids: [],
                                                                    search_employee_ids: [],
                                                                    search_workstation_ids: [],
                                                                    search_sub_assembly_ids :   []
                                                                }
                                                            })
                                                        });
                                                    }}
                                                    options={this.state.allIndentOptions}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                    value={this.state.allIndentOptions.find(s => s.value == this.state.ItemDemandSearchForm.search_demand_for)}
                                                    placeholder="Select Demand For"
                                                />
                                            </div>

                                        </div>
                                        {this.state.ItemDemandSearchForm && this.state.ItemDemandSearchForm.search_demand_for ?
                                            <div className="row my-3 align-items-center">
                                                <label className="col-sm-2 col-form-label col-form-label-sm">
                                                    {this.state.ItemDemandSearchForm.search_demand_for == "asset" ? "Asset" :
                                                        this.state.ItemDemandSearchForm.search_demand_for == "workstation" ? "Workstation" :
                                                            this.state.ItemDemandSearchForm.search_demand_for == "employee" ? "Employee" : 
                                                             this.state.ItemDemandSearchForm.search_demand_for == "sub_assembly" ? "Sub Assembly" : null}</label>
                                                <div className="col-sm-10">
                                                    {this.state.ItemDemandSearchForm.search_demand_for == "asset"
                                                        ? <Ax>
                                                            <InputAssetSearch
                                                                changeEvent={(selectedOption) => {
                                                                    this.tapSelectChange(selectedOption, 'ItemDemandSearchForm', 'search_asset_ids');
                                                                    this.setState({ searchedAssets: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                                                }}
                                                                placeholder="Search Asset"
                                                                isMulti={true}
                                                                containerHeight="30px"
                                                                fontSize="93%"
                                                                value={this.state.searchedAssets.length > 0 ? this.state.searchedAssets.map(a => { a.label = a.display_label; return a; }) : []}
                                                            />


                                                        </Ax>
                                                        : this.state.ItemDemandSearchForm.search_demand_for == "workstation"
                                                            ? <Ax>

                                                                <TapSelect
                                                                    changeEvent={(selectedOption) => {
                                                                        this.tapSelectChange(selectedOption, 'ItemDemandSearchForm', 'search_workstation_ids');
                                                                    }}
                                                                    options={this.state.allWorkstations}
                                                                    isSearchable={true}
                                                                    isClearable={true}
                                                                    isMulti={true}
                                                                    containerHeight="30px"
                                                                    fontSize="93%"
                                                                    value={this.state.allWorkstations.filter(m => this.state.ItemDemandSearchForm.search_workstation_ids.includes(m.value))}
                                                                    placeholder="Please Select Workstation"
                                                                />

                                                            </Ax>
                                                            : this.state.ItemDemandSearchForm.search_demand_for == "employee"
                                                                ? <Ax>

                                                                    <InputEmployeeSearch
                                                                        placeholder="Please Select Employee"
                                                                        changeEvent={(selectedOption) => {
                                                                            this.tapSelectChange(selectedOption, 'ItemDemandSearchForm', 'search_employee_ids');
                                                                            this.setState({ searchedEmployees: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                                                        }}
                                                                        isMulti={true}
                                                                        containerHeight="30px"
                                                                        fontSize="93%"
                                                                        value={this.state.searchedEmployees.length > 0 ? this.state.searchedEmployees.map(a => { a.label = a.label; return a; }) : []}
                                                                    />
                                                                </Ax> : this.state.ItemDemandSearchForm.search_demand_for == 'sub_assembly' 
                                                                ?  <InputSubAssemblySearch
                                                                changeEvent={(selectedOption) => {
                                                                    this.tapSelectChange(selectedOption, 'ItemDemandSearchForm', 'search_sub_assembly_ids');
                                                                    this.setState({ searchedSubassembly: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                                                }}
                                                                placeholder="Search Sub Assembly"
                                                                isMulti={true}
                                                                containerHeight="30px"
                                                                fontSize="93%"
                                                                value={this.state.searchedSubassembly.length > 0 ? this.state.searchedSubassembly.map(a => { a.label = a.label; return a; }) : []}
                                                            /> : null
                                                    }

                                                </div>
                                            </div> : null}
                                       
                                <div className="row my-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm">Ticket</label>
                                    <div className="col-sm-10">
                                        <InputTicketSearch
                                            containerHeight="30px"
                                            fontSize="93%"
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'ItemDemandSearchForm', 'search_ticket_ids');
                                                this.setState({ searchedTickets: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                            }}
                                            isMulti={true}
                                            menuPlacement="top"
                                            value={this.state.searchedTickets.length > 0 ? this.state.searchedTickets.map(a => { a.label = a.display_label; return a; }) : []}
                                            search_exclude_ticket_status={[0,26, 10]}
                                        />
                                    </div>
                                </div>
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm  ">Tagged User</label>
                                            <div className="col-sm-10">
                                                <TapSelect
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    isMulti={true}
                                                    placeholder="Tagged User"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                    options={this.state.allUsers}
                                                    value={this.state.allUsers.filter(s => this.state.ItemDemandSearchForm.tagged_user_ids.includes(s.value))}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'ItemDemandSearchForm', 'tagged_user_ids')}
                                                />
                                            </div>
                                        </div>
                                       
                                        <div className="row my-3 align-items-center">
                                            <label className="col-sm-2 col-form-label col-form-label-sm ">Transaction Period</label>
                                            <div className="col-sm-10">
                                                <PeriodSelectionWithCustom
                                                    periods={this.state.all_periods ? this.state.all_periods : []}
                                                    value={this.state.ItemDemandSearchForm.date_range}
                                                    startDate={this.state.ItemDemandSearchForm.date_range_start}
                                                    endDate={this.state.ItemDemandSearchForm.date_range_end}
                                                    onSelectPeriod={(period, startDate, endDate) => {
                                                        this.setState({
                                                            ItemDemandSearchForm
                                                                : {
                                                                ...this.state.ItemDemandSearchForm,
                                                                date_range: period,
                                                                date_range_start: startDate ? startDate : null,
                                                                date_range_end: endDate ? endDate : null
                                                            }
                                                        });
                                                    }}
                                                    className="form-control-sm"
                                                    containerHeight="30px"
                                                    fontSize="93%"
                                                />
                                            </div>
                                        </div>

                                    </Ax>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" disabled={this.state.listFilterFormSubmitting} data-bs-dismiss="modal">Close</button>
                        <button type="submit" className="btn btn-primary" form={this.listFilterFormModalId + 'Form'} disabled={this.state.listFilterFormSubmitting} >Save {this.state.listFilterFormSubmitting && <TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />}</button>
                    </div>
                </div>
            </div>
        </div>
    }

    releasedItemModalJsx               =        ()             =>        {
       
        return (
            <div className="modal fade" id="releasedItemModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Cancel Item</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.submitCancelItemForm}>
                            <div className="modal-body">
                                {this.state.cancel_item_loading ? <Loader />
                                    : <Ax>
                                        <table className="table table-bordered table-responsive bg-white ">
                                            <thead className="table-secondary">
                                                <tr className="text-end">
                                                    <th scope="col" style={{ width: "5%" }} className="text-center">S.No</th>
                                                    <th scope="col" className="text-start">Item Details</th>
                                                    <th scope="col" style={{ width: "12%" }}>Qty</th>
                                                    <th scope="col" style={{ width: "14%" }}>Cancelled Qty</th>
                                                    <th scope="col" style={{ width: "14%" }}>Pending Qty</th>

                                                    <th scope="col" style={{ width: "12%" }}>Cancel</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.cancelledItemList.map((i, k) => {
                                                    return <tr className="text-end" key={k}>
                                                        <td className="text-center align-middle">{k + 1}</td>

                                                        <td className='text-start'>{i.item_profile ? <div><span className="fw-bold">{i.item_profile.name}</span><br />
                                                            <div className="form-text"><b>Code: </b>{i.item_profile.item_code}</div>
                                                            <div className="form-text"><b>Mfr: </b>{i.item_profile?.manufacturer?.name ?? '-'} (MPN : {i.item_profile?.manufacturer_part_no ?? "-"} )</div>
                                                            <div className="form-text"> {
                                                                i.item_profile?.tags?.length > 0
                                                                    ? (i.item_profile?.tags.map((item, k) => <span key={k} className="badge bg-secondary">{item.name}</span>))
                                                                    : '-'
                                                            }</div>
                                                            <div className="form-text"><b>Remark: </b>{i.remark ?? "-"}</div>
                                                        </div>
                                                            : null}</td>
                                                        <td>{i.qty ? i.qty : 0}</td>
                                                        <td>{i.released_qty ? i.released_qty : 0}</td>
                                                        <td>{i.pending_qty ? i.pending_qty : 0}</td>

                                                        <td className="text-end">
                                                             <TapInputNumber
                                                              name="new_released_qty"
                                                            value={this.state.cancelledItemList[k]?.new_released_qty}
                                                            onChange={(e) => this.dynamicInputHandlerByKey(null, k, 'cancelledItemList',null,"new_released_qty",e)}
                                                            placeholder="Cancel Qty"
                                                            required={true}
                                                            max={i.pending_qty}
                                                        /></td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                        <div className="row align-items-center ">
                                            <div className="col-sm-6">
                                                <label htmlFor="notes" className="form-label">Notes</label>
                                                <div> <textarea
                                                    name="notes"
                                                    value={this.state.cancelledItemForm.notes}
                                                    onChange={(e) => this.formInputHandler(e, 'cancelledItemForm')}
                                                    className="form-control"
                                                    style={{ height: "100px" }}
                                                /></div>

                                            </div>
                                        </div>
                                    </Ax>}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.cancelItemFormSubmitting}
                                    onClick={() => { this.setState({ cancelledItemList: [] }) }}>Close  {this.state.cancelItemFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.cancelItemFormSubmitting}>Save  {this.state.cancelItemFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        );
    }


    render                              =   ()  =>  {
        return <ApplicationLayout>
            <Helmet><title>Item Demand Listing</title></Helmet>
            {this.ItemDemandListjsx()}
            <ItemDetailsModal ref={this.item_profilesRef} />
            {this.listFilterFormModalJsx()}
            {this.releasedItemModalJsx()}
        </ApplicationLayout>;
    }

}



const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        item_tags               :   state.app && state.app.item_tags ? state.app.item_tags : [],
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []

    };

//         all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
//         all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
//         isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
//         permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []

};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ItemDemandList);