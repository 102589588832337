import React from 'react';
import AppBaseComponent from '../../../components/AppBaseComponent';
import {connect} from "react-redux";
import Ax from "../../../components/hoc/Ax";
import AssetCard from "../../includes/assetCard/AssetCard";
import {Link} from "react-router-dom";
import Loader from "../../../components/ui/Loader/Loader";
import tapIcon from "../../../services/TapIcon";
import HttpAPICall from "../../../services/HttpAPICall";
import {Modal} from "bootstrap";
import {toast} from "react-toastify";
import swal from "sweetalert";
import errorValidation from "../../../services/ErrorValidation";
import { Helmet } from 'react-helmet';
import TapSelect from '../../../components/ui/TapSelect';
import { ORG_API_BASE_URL_2 } from '../../../services/TapApiUrls';

class AssetDocument extends AppBaseComponent {

    constructor(props) {
        super(props);
        this.addAssetDocumentInitial     =   {
            name                    :         "",
            number                  :         "",
            file                    :         null,
            file_name               :         null,
            base64_document         :         null
        }
        this.state                  =        {
            asset_id                :        null,
            assetDocumentList       :        [],
            listing_loading         :        false,
            other                   :           'N',
            addAssetDocumentForm    :       {...this.addAssetDocumentInitial},
            documentTypes           :       [],
            formDataLoaded          :       false,
            formDataLoading         :       false,
           
        }

        this.documentTypeUrl        =   ORG_API_BASE_URL_2  + '/setting/doc_types';
    }

    id = this.props.asset_enc_id

    componentDidMount() {
           this.getDocumentList(this.props.asset_enc_id)
        this.addAssetDocumentModal          =        new Modal(document.getElementById('addAssetModal'), {keyboard: false, backdrop :false});
    }

    getDocumentList             =         (id)       => {
        this.setState({listing_loading : true})
        HttpAPICall.withAthorization('GET', process.env.REACT_APP_IAM_API_SERVER + '/asset/documents/' + id,
            this.props.access_token)
            .then((response) => {
                let respData                =   response.data;
                this.setState({
                    assetDocumentList     :    respData,
                });
            }).then(() => {
            this.setState({listing_loading: false});
        });
    }

    addDocumentModalInit                 =   ()  =>  {
        //Initialize Search Form :-
        this.addAssetDocumentModal.show();
        let form = document.getElementById("addDocumentModalForm");
        form.reset();
        this.setState({other : 'N'})
        if(!this.state.formDataLoaded){
            this.loadDocTypesFromApi()
        }
    }

    loadDocTypesFromApi                 =   ()  =>  {
        this.setState({formDataLoading: true})
        HttpAPICall.withAthorization('GET', this.documentTypeUrl, this.props.access_token , {}, {}, (response) => {
            this.setState({
                documentTypes   :   response.data.map(s => {return({value : s , label : s})}),
                formDataLoaded  :   true,
            });
        }, (error) => {
            toast.error(error?.msg, { position: toast.POSITION.TOP_RIGHT });
        }).then(() => this.setState({formDataLoading: false}))
    }

    handleFileChange = async  (e) => {
        let file = e.target.files[0];
        let filename = file.name
        const base64 = await this.convertBase64(file);
        this.setState({addAssetDocumentForm: { ...this.state.addAssetDocumentForm,base64_document : base64,file_name:filename}})
    }

    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result.replace("data:", "").replace(/^.+,/, ""));
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    addAssetDocument               =      (event)    =>   {
        event.preventDefault()
        let frmData = {
                asset_id          :   this.id,
                name              :   this.state.addAssetDocumentForm.name,
                number            :   this.state.addAssetDocumentForm.number,
                file_name         :   this.state.addAssetDocumentForm.file_name,
                base64_document   :   this.state.addAssetDocumentForm.base64_document
            };

        HttpAPICall.withAthorization('POST', process.env.REACT_APP_IAM_API_SERVER + '/asset/document' ,
            this.props.access_token, null, {...frmData}, (response) => {
                toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                this.addAssetDocumentModal.hide();
                this.getDocumentList(this.id)
                this.setState({ 
                    addAssetDocumentForm    :       {...this.addAssetDocumentInitial},
                     formDataLoaded         :       false,         
                })  
        });
    }

    //function todelete
    deleteDocument                   =   (id)  =>  {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('DELETE', process.env.REACT_APP_IAM_API_SERVER + '/asset/document/' + id, this.props.access_token, {},{} ,(response) => {
                        toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                        this.getDocumentList(this.id)
                    }).then(() => {

                    });
                }
            });
    }


    //function for document modal
    addDocumentModalJsx                  =   ()  =>  {
        return (
            <div className="modal fade" id="addAssetModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Add Asset Document</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        {this.state.formDataLoading ? <Loader />
                            : <form onSubmit={this.addAssetDocument} id="addDocumentModalForm">
                                <div className="modal-body">
                                    <div className="row align-items-center">
                                        <div className="col-md-3">
                                            <label className="form-label require">Document Type</label>
                                        </div>
                                        <div className="col-sm-7">
                                            {this.state.other == "Y"
                                                ? <input
                                                    name="name"
                                                    type="text"
                                                    value={this.state.addAssetDocumentForm.name}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    onChange={(e) => this.formInputHandler(e, "addAssetDocumentForm")}
                                                    placeholder="Please enter Type Name"
                                                    required={true}
                                                // autoFocus={true}
                                                />
                                                : <TapSelect
                                                    options={this.state.documentTypes}
                                                    changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addAssetDocumentForm', 'name')}
                                                    value={this.state.documentTypes.find(u => this.state.addAssetDocumentForm.name == u.value)}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    placeholder="Select Document Type"
                                                    autoCompelete="off"
                                                    required={true}
                                                    autoFocus={true}
                                                />}
                                        </div>
                                        <div className="col-sm-2">
                                            <input
                                                name="other"
                                                type="checkbox"
                                                value={this.state.other}
                                                onChange={(e) => { this.setState({ other: this.state.other == 'N' ? 'Y' : 'N' }) }}
                                                checked={this.state.other == 'Y'}
                                                className="form-check-input"
                                                id="other"
                                            />
                                            <label className="form-check-label mx-2" htmlFor="other">New </label>
                                        </div>
                                        {/* <div className="col-md-8">
                                   <input
                                       name="name"
                                       type="text"
                                       value={this.state.addAssetDocumentForm.name}
                                       onChange={(e) => this.formInputHandler(e,"addAssetDocumentForm")}
                                       className="form-control"
                                       autoComplete="off"
                                       placeholder="Enter Document Name"
                                   />
                               </div> */}

                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-sm-3">
                                            <label className="form-label">Document Number</label>
                                        </div>
                                        <div className="col-md-7">
                                            <input
                                                name="number"
                                                type="text"
                                                value={this.state.addAssetDocumentForm.number}
                                                onChange={(e) => this.formInputHandler(e, "addAssetDocumentForm")}
                                                className="form-control"
                                                autoComplete="off"
                                                placeholder="Enter Document Number"
                                            />
                                        </div>

                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-3">
                                            <label className="form-label require">Document</label>
                                        </div>
                                        <div className="col-md-7">
                                            <input
                                                name="file"
                                                type="file"
                                                value={this.state.addAssetDocumentForm.file}
                                                onChange={(e) => {
                                                    this.formInputHandler(e, "addAssetDocumentForm");
                                                    this.handleFileChange(e)
                                                }}
                                                className="form-control"
                                                autoComplete="off"
                                                required={true}
                                                placeholder="Upload Document"
                                            />
                                        </div>

                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <button type="submit" className="btn btn-primary">Save</button>
                                </div>
                            </form>}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        let  id = this.props.asset_enc_id
        return (
            <Ax>
                <Helmet><title>Asset Document</title></Helmet>
                <div className="page_containt">
                    <div className="pageTbl p-3" style={{ height: "100%" }}>
                        <div className="tab_content_wrapper mt-2">
                            <span className="content_heading">Asset Documents</span>
                            <div className="float-end">
                                <button className="btn btn-primary" onClick={this.addDocumentModalInit}>Add Document
                                </button>
                            </div>
                        </div>
                        {this.state.listing_loading
                            ? <tr>
                                <td className="text-center" colSpan="8"><Loader /></td>
                            </tr>
                            : <table className="table table-bordered mt-3">
                                <thead className="table-secondary">
                                    <tr>
                                        <th style={{ width: "5%" }}>S.No</th>
                                        <th style={{ width: "31%" }}>Document Name</th>
                                        <th style={{ width: "31%" }}>Document Number</th>
                                        <th style={{ width: "11%" }} className="text-center">View</th>
                                        <th style={{ width: "11%" }} className="text-center">Download</th>
                                        <th style={{ width: "11%" }} className="text-center">Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.assetDocumentList && this.state.assetDocumentList.length !== 0 ? this.state.assetDocumentList.map((item, index) => {
                                        return <tr>
                                            <td scope="col">{index + 1}</td>
                                            <td scope="col" className="text-capitalize">{item.name}</td>
                                            <td scope="col" className="text-capitalize">{item.number}</td>
                                            <td scope="col" className="text-center">
                                                <a role="button"
                                                    target='_blank'
                                                    href={item.file}
                                                    className={[this.props?.permissions?.permissions?.includes('iam-asset-doc-view') ? '' : 'disabled'].join(' ')}
                                                    title={!this.props?.permissions?.permissions?.includes('iam-asset-doc-view') ? 'You do not have permission to perform this action' : ''}
                                                    style={{ "pointerEvents": "all" }}
                                                > <tapIcon.FontAwesomeIcon icon={tapIcon.faEye} /></a>

                                            </td>
                                            <td scope="col" className="text-center">
                                                <a role="button"
                                                    target='_blank'
                                                    href={item.file_download}
                                                    className={[this.props?.permissions?.permissions?.includes('iam-asset-doc-download') ? '' : 'disabled'].join(' ')}
                                                    title={!this.props?.permissions?.permissions?.includes('iam-asset-doc-download') ? 'You do not have permission to perform this action' : ''}
                                                    style={{ "pointerEvents": "all" }}
                                                ><tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /></a>

                                            </td>
                                            <td className="text-center">
                                                <a role="button"
                                                    onClick={() => {
                                                        if (this.props.permissions.permissions.includes('iam-asset-doc-delete')) {
                                                            return this.deleteDocument(item.id)
                                                        }
                                                    }}
                                                    className={[this.props.permissions.permissions.includes('iam-asset-doc-delete') ? '' : 'disabled'].join(' ')}
                                                    title={!this.props.permissions.permissions.includes('iam-asset-doc-delete') ? 'You do not have permission to perform this action' : ''}
                                                    style={{ "pointerEvents": "all" }}
                                                ><tapIcon.FontAwesomeIcon icon={tapIcon.faTrashAlt} color={"blue"} /></a>

                                            </td>
                                        </tr>
                                    }) : <tr>
                                        <td className="text-center" colSpan="5">No Asset Documents</td>
                                    </tr>}
                                </tbody>
                            </table>}
                    </div>
                </div>
                {this.addDocumentModalJsx()}
            </Ax>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
        permissions             :       state.app && state.app.acl_info ? state.app.acl_info : null
    }
}

export default connect(mapStateToProps)(AssetDocument);
