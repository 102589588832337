import React from 'react';
import { connect } from 'react-redux';
import AppBaseComponent from '../../../components/AppBaseComponent';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls from '../../../services/TapApiUrls';
import { Modal } from 'bootstrap';
import Ax from "../../../components/hoc/Ax";
import Loader from "../../../components/ui/Loader/Loader";
import moment from 'moment';
import TapIcon from '../../../services/TapIcon';
import { toast } from 'react-toastify';
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import TapAnalogClock from '../../../services/TapAnalogClock';

class UpdateAttendenceModal extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.attendenceFormInit         =   {
            employee_ids                     :   [],
            feed_date                       :   '',
            feed_date_time_display          :   moment(new Date()),
            shift_id                        :   '',
            note                         :   '',
            status          :           "1",
            check_in        :           "",
            check_out       :           "",
            check_in_display        :           moment().startOf('day'),
            check_out_display        :           "",
            check_in_date   :           new Date(),
            check_out_date   :           new Date(),
        };

        this.state                      =   {
            date                            :   moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
            date_display                    :   '',
            shiftData                       :   null,
            saveFormSubmitting              :   false,
            loadingCounterlogFormData       :   false,
            access_token                    :   '',
            employeeData                    :   null,
            updateAttendenceForm            :   {...this.attendenceFormInit},
            statusList                      :   [],
        }
    }

    componentDidMount() {
        this.updateAttendenceModal      =   new Modal(document.getElementById('updateAttendenceModal'), {keyboard: false, backdrop: false});
    }
    
    updateAttendenceInit                =   (date,employeeData) => {
       
        if(employeeData) {
            this.setState({loadingCounterlogFormData : true})
          this.updateAttendenceModal.show();
          let attendance    =   !Array.isArray(employeeData) && employeeData && employeeData.attendance  ? employeeData.attendance : null;
          let shiftData     =   !Array.isArray(employeeData) && employeeData.roster_working_data && employeeData.roster_working_data.shifts && employeeData.roster_working_data.shifts.length > 0 ? employeeData.roster_working_data.shifts[0] : []
       
            this.setState({
                employeeData   :   employeeData, 
                shiftData      :   shiftData, 
                date           :   date,
                date_display   :   moment(date, 'YYYY-MM-DD').format('DD-MMM-YYYY'),
                updateAttendenceForm            :   {
                    ...this.attendenceFormInit,
                    employee_ids                :   Array.isArray(employeeData) && employeeData.length > 0 ? employeeData.map(emp => emp.enc_id) : [employeeData.enc_id],
                    check_in_date                    :  attendance && attendance.check_in ? attendance.check_in  :  moment(date, 'YYYY-MM-DD').toDate(),
                    check_out_date                    :  attendance && attendance.check_out ? attendance.check_out  :  moment(date, 'YYYY-MM-DD').toDate(),
                    check_in                      :       attendance && attendance.check_in ? attendance.check_in : '',
                    check_in_display              :       attendance && attendance.check_in ? moment(attendance.check_in).toDate() : '',
                    check_out                      :       attendance && attendance.check_out ? attendance.check_out : '',
                    check_out_display              :       attendance && attendance.check_out ? moment(attendance.check_out).toDate() : '',
                    note                          :       attendance && attendance.note ? attendance.note : '',
                    status                      :       attendance && attendance.status ? attendance.status.key : 1,
                } 
    
            })
         
            HttpAPICall.withAthorization('GET', TapApiUrls.HRM_URL + '/attendance/form_data', this.props.access_token, null, null, (response) => {
                let respData = response.data;
                this.setState({
                    statusList      :   respData && respData.status && respData.status.length > 0 ? respData.status : []
                })
            }).then(() => this.setState({ loadingCounterlogFormData: false, }));
        }
    }
    
    
    submitUpdateAttendenceForm       =   (e) =>  {
        e.preventDefault();
         let frmData = {
            ...this.state.updateAttendenceForm,
            date:this.state.date,
            check_in : this.state.updateAttendenceForm.status == 2 ? null : this.state.updateAttendenceForm.check_in,
            check_out : this.state.updateAttendenceForm.status == 2 ? null : this.state.updateAttendenceForm.check_out ,
        }
         
        this.setState({saveFormSubmitting: true})
        HttpAPICall.withAthorization('PUT', TapApiUrls.HRM_URL + '/attendance/add_update' , this.props.access_token, {}, {...frmData}, (resp) => {
            this.aftersubmitUpdateAttendenceForm(resp);
        }).then(() => this.setState({ saveFormSubmitting: false }));
        
    }
    
    aftersubmitUpdateAttendenceForm  =   (response)  =>  {
        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
        if(this.props.afterSubmitAttendence){
            let employeeData = this.state.employeeData && Array.isArray(this.state.employeeData) ? this.state.employeeData : [this.state.employeeData];
            this.props.afterSubmitAttendence(this.state.date, employeeData);
        }
        this.updateAttendenceModal.hide();
    }

    handleCheckInChange = (value) => {
        
        const hour = value && value.hour ? value.hour : 0;
        const minute = value && value.minute ? value.minute : 0;
        const baseDate = this.state.date;
        const [year, month, day] = baseDate.split("-").map(Number);
    
        const checkInTime = new Date(year, month - 1, day, hour, minute); 
        this.setState(
            {
                updateAttendenceForm: {
                    ...this.state.updateAttendenceForm,
                    check_in_display: checkInTime,
                    check_in : moment(checkInTime).format('YYYY-MM-DD HH:mm:ss')
                },
            },
            () => {
                
            }
        );
    };

    handleCheckOutChange = (value) => {
        
        const baseDate = new Date(this.state.updateAttendenceForm.check_out_date);

        // Extract the time values
        if(baseDate){
            const hour = value && value.hour ? value.hour : 0;
            const minute = value && value.minute ? value.minute : 0;

            // Set the time components to the base date
            const checkOutTime = new Date(baseDate);
            checkOutTime.setHours(hour, minute, 0, 0);
            this.setState(
                {
                    updateAttendenceForm: {
                        ...this.state.updateAttendenceForm,
                        check_out_display: checkOutTime,
                        check_out : moment(checkOutTime).format('YYYY-MM-DD HH:mm:ss')
                    },
                },
                () => {
                    
                }
            );
        }else{
            toast.error('Please select checkout Date First')
        }
    };

      render                              =   ()  =>  {
        
        return (
            <div className="modal fade" id="updateAttendenceModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <form className="bg-white p-1" onSubmit={this.submitUpdateAttendenceForm} id="updateAttendence">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="searchItemModalLabel">{this.state.editCounterlog ? "Edit" : "Update"} Attendence for : {this.state.date_display} {this.state.shiftData ? this.state.shiftData.shift_name : ''} {this.state.shiftData ? <span>{this.state.shiftData.shift_start}</span> : ''}</h5>
                                {this.state.employeeData && !Array.isArray(this.state.employeeData) && <div className='text-end'>
                                    <h5 className="modal-title">Site : {this.state.employeeData && this.state.employeeData.site ? this.state.employeeData.site.site_name : "-"}</h5>
                                </div>}
                            </div>
                            {this.state.loadingCounterlogFormData
                                ? <Loader />
                                : <Ax>
                                    <div className='p-1 bg-light border-bottom'>
                                        <table className='table table-sm mb-0 table-borderless text-sm'>
                                            <tbody>
                                                <tr>
                                                    <td style={{ width: "26%" }}> Employee Name (Code)   </td>
                                                    <th>{this.state.employeeData && Array.isArray(this.state.employeeData) ? this.state.employeeData.map(emp => emp.display_full_name).join(', ') : this.state.employeeData?.display_full_name}</th>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="modal-body">
                                        <div className="row align-items-center">
                                            <label className="col-sm-3 col-form-label  require">Attendence Status </label>
                                            <div className="col-sm-4">

                                                {
                                                    this.state.statusList && this.state.statusList.length > 0
                                                        ? (this.state.statusList.map((cat, k) => {

                                                            return (
                                                                <div key={k} className="form-check form-check-inline">
                                                                    <input
                                                                        name="status"
                                                                        type="radio"
                                                                        value={cat.id}
                                                                        onChange={(e) => this.formInputHandler(e, 'updateAttendenceForm')}
                                                                        checked={this.state.updateAttendenceForm.status == cat.id}
                                                                        className="form-check-input"
                                                                        required={true}
                                                                        id={cat.id}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={cat.id}>{cat.name}</label>
                                                                </div>);
                                                        }))
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        {this.state.updateAttendenceForm.status == 1 && <Ax>
                                            <div className="row align-items-center my-3">
                                                <label className="col-sm-3 col-form-label">Check - In</label>
                                                <div className="col-sm-4">

                                                    <DatePicker
                                                        selected={
                                                            this.state.updateAttendenceForm.check_in_date
                                                                ? moment(this.state.updateAttendenceForm.check_in_date, 'YYYY-MM-DD').toDate()
                                                                : false
                                                        }
                                                        name="check_in_date"
                                                        onChange={(value, event) => this.formDateHandler('check_in_date', value, 'updateAttendenceForm')}
                                                        maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                                                        dateFormat="dd-MMM-yyyy"
                                                        className="form-control form-control-sm"
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        autoComplete="off"
                                                        scrollMonthYearDropdown
                                                        required={true}
                                                        disabled={true}
                                                        placeholderText={`Please Enter Check-In Date`}
                                                    />
                                                </div>
                                                <div className="col-sm-5">
                                                    <TapAnalogClock
                                                        initialValue={this.state.updateAttendenceForm.check_in_display}
                                                        onTimeChange={this.handleCheckInChange}
                                                        placeholder="Please Enter Check-In Time"
                                                    />

                                                </div>
                                            </div>
                                            <div className="row align-items-center my-3">
                                                <label className="col-sm-3 col-form-label">Check - Out</label>
                                                <div className="col-sm-4">
                                                 
                                                    <DatePicker
                                                        selected={
                                                            this.state.updateAttendenceForm.check_out_date
                                                                ? moment(this.state.updateAttendenceForm.check_out_date, 'YYYY-MM-DD').toDate()
                                                                : false
                                                        }
                                                        name="check_out_date"
                                                        onChange={(value, event) => {
                                                            const existingTime = moment(this.state.updateAttendenceForm.check_out_date).format('HH:mm:ss');
                                                            const updatedDate = moment(value).format('YYYY-MM-DD'); // Extract the selected date

                                                            // Combine the selected date with the existing time
                                                            const updatedDateTime = `${updatedDate} ${existingTime}`;

                                                            // Update only the date part of check_out_date
                                                            this.setState(
                                                                {
                                                                    updateAttendenceForm: {
                                                                        ...this.state.updateAttendenceForm,
                                                                        check_out_date: updatedDateTime,
                                                                    },
                                                                },
                                                                () => {
                                                                    this.formDateHandler(
                                                                        "check_out",
                                                                        moment(updatedDateTime, "YYYY-MM-DD HH:mm:ss").toDate(),
                                                                        "updateAttendenceForm",
                                                                        "YYYY-MM-DD HH:mm:ss"
                                                                    );
                                                                }
                                                            );
                                                        }}
                                                        dateFormat="dd-MMM-yyyy"
                                                        className="form-control form-control-sm"
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        autoComplete="off"
                                                        scrollMonthYearDropdown
                                                        required={true}
                                                        maxDate={new Date()}
                                                        placeholderText={`Please Enter Check-Out Date`}
                                                    />

                                                </div>
                                                <div className="col-sm-5">
                                                    <TapAnalogClock
                                                        initialValue={this.state.updateAttendenceForm.check_out_display}
                                                        onTimeChange={this.handleCheckOutChange}
                                                        placeholder="Please Enter Check-Out Time"
                                                    />
                                                    {/* <DatePicker
                                                        onChange={(value, event) => {
                                                            // Extract date part from check_out_date
                                                            const checkOutDate = moment(this.state.updateAttendenceForm.check_out_date, 'YYYY-MM-DD').toDate();

                                                            // Extract time part from selected time
                                                            const selectedTime = moment(value).toDate();

                                                            // Set time components of checkOutDate to match the selected time
                                                            checkOutDate.setHours(selectedTime.getHours());
                                                            checkOutDate.setMinutes(selectedTime.getMinutes());
                                                            checkOutDate.setSeconds(selectedTime.getSeconds());

                                                            this.setState(
                                                                {
                                                                    updateAttendenceForm: {
                                                                        ...this.state.updateAttendenceForm,
                                                                        check_out_display: checkOutDate,
                                                                    },
                                                                },
                                                                () => {
                                                                    this.formDateHandler(
                                                                        "check_out",
                                                                        checkOutDate,
                                                                        "updateAttendenceForm",
                                                                        "YYYY-MM-DD HH:mm:ss"
                                                                    );
                                                                }
                                                            );
                                                        }}
                                                        selected={this.state.updateAttendenceForm?.check_out_display ? this.state.updateAttendenceForm.check_out_display : ''}
                                                        className="form-control form-control-sm"
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeFormat="HH:mm"
                                                        timeIntervals={5}
                                                        timeCaption="Hours"
                                                        dateFormat="HH:mm"
                                                        autoComplete={"off"}
                                                        placeholderText={'Please Enter Check-Out Date'}
                                                    /> */}

                                                </div>
                                            </div>
                                        </Ax>}
                                        <div className="row align-items-center my-3">
                                            <label className="col-sm-3 col-form-label">Notes </label>
                                            <div className="col-sm-9">
                                                <textarea
                                                    name="note"
                                                    value={this.state.updateAttendenceForm.note}
                                                    onChange={(e) => this.formInputHandler(e, 'updateAttendenceForm')}
                                                    className="form-control"
                                                    style={{ height: "80px" }}

                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary mx-2" disabled={this.state.saveFormSubmitting} data-bs-dismiss="modal" onClick={() => { this.setState({ copy_reading: 'N' }) }}>Close</button>
                                        <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting} form='updateAttendence'>Save {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                                    </div>
                                </Ax>
                            }
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        group_permission        :   state.app.acl_info
    };
};


export default connect(mapStateToProps, null, null, { forwardRef: true })(UpdateAttendenceModal);