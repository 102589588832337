import React from 'react';
import {connect} from "react-redux";
import {Modal} from "bootstrap";
import { toast } from 'react-toastify';
import HttpAPICall from "../../../services/HttpAPICall";
import Loader from "../../../components/ui/Loader/Loader";
import tapIcon from "../../../services/TapIcon"
import TapSelect from '../../../components/ui/TapSelect';
import AppBaseComponent from '../../../components/AppBaseComponent';
import TapIcon from '../../../services/TapIcon';
import { ORG_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import Ax from "../../../components/hoc/Ax";
import swal from 'sweetalert';
import * as actions from '../../../store/actions/index';

class OrganizationProfile extends AppBaseComponent {

    constructor(props) {
        super(props);
          
        this.updateForm             =   {
            name                    :   '',
            file                    :   "",
            base64_document         :   null,
            file_name               :   "",
            detail                  :   ""
        }
          this.state                =          {
            list_loading            :         false,
            orgInfoData             :         null,
            updateProfileForm       :         {...this.updateForm},
            actionType              :          "",
            saveFormSubmitting      :         false,
            addForm                 :         false,
            listing_tbl_page        :          1,
            listing_loading         :          false,
            listingMeta             :          null,
            company_listing         :          [],
            totalListingCount       :          0
        };

        this.companyListingUrl  =   ORG_API_BASE_URL_2  + '/company/listing';
        this.companyUrl         =   ORG_API_BASE_URL_2  + '/company';

        this.infoUrl   =   ORG_API_BASE_URL_2  +   '/info';

    }

    componentDidMount() {
        this.organizationUpdateModal         =    new Modal(document.getElementById('organizationUpdateModal'), {keyboard: false, backdrop :false});
        
        this.initilaizePage(this.props);
        this.loadListingTblData(1);
    }
    
    componentWillReceiveProps(nextProps) {
        this.initilaizePage(nextProps);
    }
    
    initilaizePage                      =   (pr)  =>  {
        this.setState({orgInfoData : pr.orgInfoData});
    }

    loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {page:page,};
        HttpAPICall.withAthorization('GET', this.companyListingUrl, this.props.access_token, params, {}, (resp) => {
            let respData = resp.data;

            this.setState({
                company_listing      :         respData.data,
                listingMeta          :          respData.meta,
            
            totalListingCount       :           respData.total,
            })
       
        }).then(() => this.setState({listing_loading: false}));
    }
     

    updateProfileInit           =   (action,addForm = false,name='',id = '',detail='')   =>  {
        this.organizationUpdateModal.show()
        
        let form = document.getElementById(`organizationUpdateForm`)
        form.reset();

       
        this.setState({
            actionType          :       action,
            addForm             :       addForm,
            updateProfileForm   :       {
                                        ...this.updateForm,
                name       :      name,
                id              :      id,
                detail          :       detail
            }
        })
    }

    handleFileChange = async  (e) => {
        let file = e.target.files[0];
        let filename = file.name
        const base64_document = await this.convertBase64(file);
        this.setState({updateProfileForm : { ...this.state.updateProfileForm ,base64_document : base64_document,file_name:filename}})
    }

    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result.replace("data:", "").replace(/^.+,/, ""));
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    submitUpdateForm        =       (e)     =>      {
        e.preventDefault()
        
      
        let frmData;
        if(this.state.actionType == "editName"){
            frmData  =  {
                id              :    this.state.updateProfileForm.id,
                name      :    this.state.updateProfileForm.name,
                detail    :  this.state.updateProfileForm.detail
            }
        }else if(this.state.actionType == "editLabel"){
            frmData  =  {
                id                :    this.state.updateProfileForm.id,
                base64_document   :   this.state.updateProfileForm.base64_document,
                file_name         :   this.state.updateProfileForm.file_name
            }
        }else{
            frmData =       {
                name             : this.state.updateProfileForm.name,
                detail    :  this.state.updateProfileForm.detail,
                base64_document        : this.state.updateProfileForm.base64_document,
                file_name              : this.state.updateProfileForm.file_name
            }
        }

        
          
        if (this.state.updateProfileForm && !this.state.updateProfileForm.id && this.state.addForm == false) {

            this.setState({ saveFormSubmitting: true })
            HttpAPICall.withAthorization('PUT', this.infoUrl,
                this.props.access_token, null, { ...frmData }, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.organizationUpdateModal.hide();
                    // this.loadListingTblData()
                    this.props.applicationInitLoading(this.props.access_token);
                    this.setState({ updateProfileForm: { ...this.updateForm } })
                }).then(() => this.setState({ saveFormSubmitting: false }))
        } else if (this.state.updateProfileForm && !this.state.updateProfileForm.id && this.state.addForm == true) {
            this.setState({ saveFormSubmitting: true })
            HttpAPICall.withAthorization('POST', this.companyUrl,
                this.props.access_token, null, { ...frmData }, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.organizationUpdateModal.hide();
                    this.loadListingTblData(this.state.listing_tbl_page);
                    this.setState({ updateProfileForm: { ...this.updateForm } })
                }).then(() => this.setState({ saveFormSubmitting: false }))
        } else {
          
            this.setState({ saveFormSubmitting: true })
            HttpAPICall.withAthorization('PUT', this.companyUrl,
                this.props.access_token, null, { ...frmData }, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.organizationUpdateModal.hide();
                    this.loadListingTblData(this.state.listing_tbl_page);
                    this.setState({ updateProfileForm: { ...this.updateForm } })
                }).then(() => this.setState({ saveFormSubmitting: false }))
        }

    }


    deleteFile          =       ()          =>      {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('PUT', this.infoUrl, this.props.access_token, {remove_image : "Y" },{} ,(response) => {
                        toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                        // this.loadListingTblData()
                        this.props.applicationInitLoading(this.props.access_token);
                    }).then(() => {

                    });
                }
            });
    }

    deleteCompanyFile          =       (id)          =>      {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('DELETE', this.companyUrl, this.props.access_token, {remove_image : "Y",id:id },{} ,(response) => {
                        toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                        this.loadListingTblData(this.state.listing_tbl_page);
                    }).then(() => {

                    });
                }
            });
    }

    
    organizationUpdateModalJsx               =        ()             =>        {
       
        return (
            <div className="modal fade" id="organizationUpdateModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">{this.state.addForm ? "Add" : "Update"} Profile</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <form id="organizationUpdateForm" onSubmit={this.submitUpdateForm}>
                            <div className="modal-body">
                                {this.state.actionType == "editName" || this.state.addForm ? <Ax>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-3 col-form-label " >Name</label>
                                    <div className="col-sm-9">
                                        <input
                                            name="name"
                                            type="text"
                                            value={this.state.updateProfileForm.name}
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "updateProfileForm")}
                                            placeholder="Please enter Name"
                                            
                                        />
                                    </div>
 
                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-3 col-form-label " >Detail</label>
                                    <div className="col-sm-9">
                                        <textarea
                                            name="detail"
                                            value={this.state.updateProfileForm.detail}
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "updateProfileForm")}
                                            placeholder="Please enter Detail"
                                            rows={3}
                                            
                                        />
                                    </div>
 
                                </div>
                                </Ax>: null}
                                {this.state.actionType == "editLabel" || this.state.addForm ? <div className="row my-3 align-items-center">
                                    <label className="col-sm-3 col-form-label  " >Logo</label>
                                    <div className="col-sm-9">
                                        <input
                                            name="file"
                                            type="file"
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => {
                                                this.handleFileChange(e)}}
                                         
                                            placeholder="Please upload file"
                                            
                                        />
                                    </div>

                                </div>: null}
                                
                            </div> 
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" disabled={this.state.saveFormSubmitting ? true : false}>Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.saveFormSubmitting ? true : false}>Save {this.state.saveFormSubmitting ? (<TapIcon.FontAwesomeIcon icon={TapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }


    render() {
      
        return (
        
            <section className="page_containt " style={{ top: "1px" }}>
                <div className="pageTbl bg-white">
                    <div className=" row bg-white p-2">
                        <div className="col-sm-12">
                            <div className="tab_content_wrapper mt-2"><span className="content_heading">Organization</span>

                            </div>
                            <table className="table table-hover table-bordered my-4 ">
                                <thead className="table-secondary">
                                    <tr className="text-center">
                                        <th scope="col" style={{ width: "10%" }}>S.No</th>
                                        <th scope="col" className="text-start" >Name</th>
                                        <th scope="col" className="text-start" style={{ width: "25%" }} >Detail</th>
                                        <th scope="col" className="text-start" style={{ width: "15%" }}>Logo</th>
                                        <th scope="col" style={{ width: "10%" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.list_loading ? <tr><td className='text-center' colSpan={4}><Loader /></td></tr>
                                        :
                                        <tr>
                                            <td className='text-center'>1</td>
                                            <td>{this.state.orgInfoData && this.state.orgInfoData.group_name ? this.state.orgInfoData.group_name : "-"}</td>
                                            <td>{this.state.orgInfoData && this.state.orgInfoData.detail ? this.state.orgInfoData.detail : "-"}</td>
                                            <td>{this.state.orgInfoData && this.state.orgInfoData.image ?
                                                <img src={this.state.orgInfoData.image_path} alt="profile_img" style={{ height: "50px" }} />
                                                : "-"}</td>
                                            <td className="text-center">
                                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                    <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a className="dropdown-item" role="button" onClick={() => this.updateProfileInit('editName', false, this.state?.orgInfoData?.group_name,'',this.state?.orgInfoData?.detail)} >Edit</a></li>
                                                    <li><a className="dropdown-item" role="button" onClick={() => this.updateProfileInit('editLabel')} >Edit Logo</a></li>
                                                    <li><a className="dropdown-item" role="button" onClick={() => this.deleteFile()} >Delete Logo</a></li>
                                                </ul>
                                            </td>
                                        </tr>}
                                </tbody>
                            </table>
                            <div className="tab_content_wrapper mt-2"><span className="content_heading">Additional Organization</span>
                                <div className="text-end "><button className="btn btn-primary" onClick={() => this.updateProfileInit(null, true)}>Add</button></div>
                            </div>
                            <table className="table table-hover table-bordered my-4 ">
                                <thead className="table-secondary">
                                    <tr className="text-center">
                                        <th scope="col" style={{ width: "10%" }}>S.No</th>
                                        <th scope="col" className="text-start" >Name</th>
                                        <th scope="col" className="text-start" style={{ width: "25%" }}>Detail</th>
                                        <th scope="col" className="text-start" style={{ width: "15%" }}>Logo</th>
                                        <th scope="col" style={{ width: "10%" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.listing_loading
                                        ? (<tr><td colSpan="4"><Loader /></td></tr>)
                                        : (this.state.company_listing.length > 0
                                            ? (this.state.company_listing.map((company, index) => {
                                               
                                                return (<tr key={index} >

                                                    <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                                    <td>{company.name ? company.name : "-"}</td>
                                                    <td>{company.detail ? company.detail : "-"}</td>
                                                    <td>{company.logo && company.logo.file ?
                                                        <img src={company.logo.file} alt="profile_img" style={{ height: "50px" }} />
                                                        : "-"}</td>
                                                    <td className="text-center">
                                                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                            <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                        </button>
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li><a className="dropdown-item" role="button" onClick={() => this.updateProfileInit('editName', false, company.name, company.id,company?.detail)} >Edit</a></li>
                                                            <li><a className="dropdown-item" role="button" onClick={() => this.updateProfileInit('editLabel', false, '', company.id)} >Reload</a></li>
                                                            <li><a className="dropdown-item" role="button" onClick={() => this.deleteCompanyFile(company.id)} >Delete Logo</a></li>
                                                        </ul>
                                                    </td>

                                                </tr>)
                                            }))
                                            : (<tr><td colSpan="4" className="text-center">No Records</td></tr>)
                                        )
                                    }

                                </tbody>
                            </table>
                            {/* <table className="table table-hover table-bordered my-4 ">
                            <thead className="table-secondary">
                                <tr className="text-center">
                                    <th scope="col" style={{ width: "10%" }}>S.No</th>
                                    <th scope="col" className="text-start" style={{ width: "20%" }}>Lable</th>
                                    <th scope="col" className="text-start">Detail</th>
                                    <th scope="col" style={{ width: "10%" }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                               {this.state.list_loading ? <tr><td className='text-center' colSpan={4}><Loader/></td></tr>
                               : <Ax>
                               <tr>
                                    <td className='text-center'>1</td>
                                    <td>Organization Name</td>
                                    <td>{this.state.orgInfoData && this.state.orgInfoData.name ? this.state.orgInfoData.name : "-" }</td>
                                    <td className="text-center">
                                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                            <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><a className="dropdown-item" role="button" onClick={() => this.updateProfileInit('editName')} >Edit</a></li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-center'>2</td>
                                    <td>Organization Logo</td>
                                    <td>
                                    {this.state.orgInfoData && this.state.orgInfoData.image ? 
                                        <img src={this.state.orgInfoData.image_path} alt="profile_img" style={{height:"50px"}}/> 
                                    : "-" }
                                   </td>
                                    <td className="text-center">
                                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                            <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><a className="dropdown-item" role="button" onClick={() => this.updateProfileInit('editLabel')} >Edit</a></li>
                                            <li><a className="dropdown-item" role="button" onClick={() => this.deleteFile()} >Delete</a></li>
                                        </ul>
                                    </td>
                                </tr>
                               </Ax>}
                            </tbody>
                        </table> */}
                        </div>
                    </div>
                    {this.organizationUpdateModalJsx()}
                </div></section>

        );
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        orgInfoData             :   state.app.group_info ? state.app.group_info : null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        applicationInitLoading  :   (access_token)  =>  dispatch(actions.applicationInitLoading(access_token)),
    };
};

export default connect(mapStateToProps,mapDispatchToProps)(OrganizationProfile);