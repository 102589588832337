import {connect} from "react-redux";
import {Modal} from "bootstrap";
import { Link } from 'react-router-dom';
import swal from "sweetalert";
import ReactPaginate from "react-paginate";
import AppBaseComponent from '../../components/AppBaseComponent';
import HttpAPICall from "../../services/HttpAPICall";
import Loader from "../../components/ui/Loader/Loader";
import tapIcon from "../../services/TapIcon";
import Ax from "../../components/hoc/Ax"
import React from "react";
import TapApiUrls, { INVENTORY_API_BASE_URL_2 } from "../../services/TapApiUrls";
import InputItemSearch from '../includes/ui/InputItemSearch';
import { toast } from "react-toastify";


class InterchangeableItemModal extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initInterchangeable    =               {
            item_id_2               :               null,
            notes                   :               ''
        }
        this.state                  =           {
            itemId                  :               this.props.item_enc_id,
            itemName                :               this.props.itemName,
            listing_tbl_page        :               1,
            listingData             :               [],
            listingMeta             :               [],
            totalListingCount       :               0,
            listing_loading         :               false,
            searchedItem            :               null,
            editFormSubmiting       :               false,
            edit                    :               false,
            addInterchangeableForm  :               {...this.initInterchangeable},
            notes                   :               '',
            id                      :               ''
        }
        this.itemUrl                =   INVENTORY_API_BASE_URL_2 + '/item'; 
    }

    componentDidMount() {
       if(this.props.item_enc_id){
        this.loadListingTblData(this.state.listigetInterchangeableListingng_tbl_page,this.props.item_enc_id);
       }
        this.linkInterchangeableModal           =   new Modal(document.getElementById('linkInterchangeableModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.item_enc_id !== this.props.item_enc_id){
            this.loadListingTblData(1,nextProps.item_enc_id);
            this.setState({itemId : nextProps.item_enc_id,itemName : nextProps.itemName})
        }
    }

    loadListingTblData                  =   (page = 1,id)  =>  {
        this.setState({ listing_loading: true, listing_tbl_page: page });
        HttpAPICall.withAthorization('GET', this.itemUrl + '/interchangeable_item/' + id, this.props.access_token, {}, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                listingData     : respData.data,
                listingMeta     : respData.meta,
                totalListingCount: respData.meta.total,
                notes               :   respData.notes,
                id                  :   respData.id,
            })
        }).then(() => this.setState({ listing_loading: false }))
    }
    
    closeModal                              =   ()  =>  {
        this.setState({addInterchangeableForm :  {...this.initInterchangeable},searchedItem : null})
    }

    linkInterchangeableInit                 =   ()  =>  {
        this.setState({edit:false})
        this.linkInterchangeableModal.show();
    }

   
    editInterchangeable                 =       (id)      =>  {
      this.setState({edit : true})
      this.linkInterchangeableModal.show();
     
      this.setState({
          addInterchangeableForm   :   {
              id                   :    this.state.id,
              notes                :    this.state.notes
          },
         
      })
    }
  
    deLinkItem                          =       (id)         =>   {
        swal({
            title: "DeLink Item",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    let frmData = {
                        item_id_1 : this.state.itemId,
                        item_id_2 : id
                    }
                    HttpAPICall.withAthorization('PUT', this.itemUrl + '/unlink_interchangeable_item', this.props.access_token, {},{...frmData} ,(response) => {
                        toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                       this.loadListingTblData(1,this.state.itemId)
                    }).then(() => {

                        });
                }
            });
    }
    
    submitlinkItemForm                  =       (e)      =>  {
        e.preventDefault();
        this.setState({ editFormSubmiting: true })
        if(this.state.itemId == this.state.addInterchangeableForm.item_id_2){
            toast.error("Both Items cannot be same,Please Select another item for Interchangeable",{position: toast.POSITION.TOP_RIGHT});
            this.setState({ editFormSubmiting: false })
        }else{
            if(this.state.edit === true){
                let frmData = {
                    ...this.state.addInterchangeableForm
                }
                HttpAPICall.withAthorization('PUT', this.itemUrl + '/note_interchangeable_item', this.props.access_token, {}, { ...frmData}, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.linkInterchangeableModal.hide()
                    this.loadListingTblData(1,this.state.itemId)
                    this.setState({addInterchangeableForm : {...this.initInterchangeable},searchedItem : null})
                }).then(() => this.setState({ editFormSubmiting: false }));  
            }else{
                let frmData = {
                    item_id_1 : this.state.itemId,
                    ...this.state.addInterchangeableForm
                }
                HttpAPICall.withAthorization('POST', this.itemUrl + '/add_interchangeable_item', this.props.access_token, {}, { ...frmData}, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.linkInterchangeableModal.hide()
                    this.loadListingTblData(1,this.state.itemId)
                    this.setState({addInterchangeableForm : {...this.initInterchangeable},searchedItem : null})
                }).then(() => this.setState({ editFormSubmiting: false }));  
            }
        }
    }

    linkInterchangeableModalJsx       =    ()       =>  {
        return (
            <div className="modal fade" id="linkInterchangeableModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addAssetModalLabel">Link Interchangeable with Item</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={this.submitlinkItemForm} id="addModalForm">
                            <div className="modal-body">
                               {this.state.edit == false && <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-md-4">
                                        <label className="form-label">Item Search</label>
                                    </div>
                                    <div className="col-md-8">
                                        <InputItemSearch
                                            required={true}
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'addInterchangeableForm', 'item_id_2');
                                                this.setState({
                                                    searchedItem: selectedOption ? selectedOption : null
                                                }, () => {
                                                });
                                            }}
                                            disabled = {this.state.edit === true ? true : false}
                                            value={this.state.searchedItem ? { ...this.state.searchedItem, label: this.state.searchedItem.display_label } : null}
                                        />
                                    </div>

                                </div>}
                                <div className="row align-items-center mb-3 mt-3">
                                    <div className="col-md-4">
                                        <label className="form-label">Notes</label>
                                    </div>
                                    <div className="col-md-8">
                                        <textarea
                                            name="notes"
                                            className="form-control"
                                            value={this.state.addInterchangeableForm.notes}
                                            onChange={(e) => this.formInputHandler(e, 'addInterchangeableForm')}
                                            style={{ height: "80px" }}
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" disabled={this.state.editFormSubmiting} data-bs-dismiss="modal" onClick={this.closeModal}>Close</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.editFormSubmiting} >Save {this.state.editFormSubmiting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : null}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render                                  =     ()           =>     {
      
        return (<Ax>
           
            <section className="item_view_container bg-white">

                <div className="tab_content_header">
                    <div className="tab_content_wrapper"><span className="content_heading">Interchangeable Items</span>
                        <div className="float-end"><button className="btn btn-primary" disabled={!this.props?.permissions?.includes('isc-item-Interchangeable-edit')} onClick={this.linkInterchangeableInit}>Link Interchangeable Item</button></div>
                    </div>
                    <table className="table table-hover table-bordered table-responsive bg-white my-3 table-sm">
                        <thead className="table-secondary">
                            <tr className="text-center">
                                <th style={{ "width": '7%' }}>S.No</th>
                                <th className="text-start">Item Details</th>
                                <th style={{ width: "11%" }} className="text-end">Purchase Price</th>
                                {/* <th style={{ width: "35%" }} className="text-start">Remarks</th> */}
                                <th style={{ width: "7%" }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.listing_loading
                                ? (<tr><td colSpan="5"><Loader /></td></tr>)
                                : (this.state.listingData.length > 0
                                    ? (this.state.listingData.map((item, k) => {
                                        return (<tr key={k} className="text-center">
                                            <td>{k + 1}</td>
                                            <td className="text-start"><Ax>
                                                <b>{item.name} </b> <br />
                                                <small>Code : <b> {item.item_code} </b></small> <br />
                                                <small>Mfr : <b> {item.manufacturer?.name ?? '-'} </b>  (MPN : <b> {item.manufacturer_part_no ?? '-'}</b>)</small>
                                            </Ax></td>
                                            <td className="text-end">{item.purchase_price ? item.purchase_price : "-"}</td>
                                            {/* <td className="text-start">{item.notes ? item.notes : "-"}</td> */}
                                            <td scope="row" className="text-center">
                                                <tapIcon.imageIcon icon={tapIcon.ListActionIcon} className="img-fluid pr10 pl10" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                <ul className="dropdown-menu" style={{ cursor: "pointer" }}>
                                                   
                                                    <li>
                                                        <Link role="button"
                                                            onClick={() => {
                                                                if (this.props?.permissions?.includes('isc-item-Interchangeable-edit')) {
                                                                    return this.deLinkItem(item.id)
                                                                }
                                                            }}
                                                            className={['dropdown-item cursor_pointer', this.props?.permissions?.includes('isc-item-Interchangeable-edit') ? '' : 'disabled'].join(' ')}
                                                            title={!this.props?.permissions?.includes('isc-item-Interchangeable-edit') ? 'You do not have permission to perform this action' : ''}
                                                            style={{ "pointerEvents": "all" }}
                                                        >DeLink</Link>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>);
                                    }))
                                    : (<tr><td colSpan="5" className="text-center">No Records</td></tr>)
                                )
                            }
                        </tbody>
                    </table>
                    <div className="m0">
                        <span>{this.state.listingMeta ? `Showing ${this.state.listingMeta.from || 0} to ${this.state.listingMeta.to || 0} of ${this.state.totalListingCount} entries` : ``}</span>
                        <span className="text-end">
                            <ReactPaginate
                                previousLabel={"Prev"}
                                nextLabel={"Next"}
                                pageCount={this.state.listingMeta?.last_page ?? 0}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={0}
                                onPageActive={this.state.listingMeta?.current_page ?? 1}
                                onPageChange={(e) => this.getAddAttrListing(e.selected + 1)}
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination justify-content-end"
                                pageClassName="page-item"
                                nextClassName="page-item"
                                previousClassName="page-item"
                                pageLinkClassName="page-link"
                                nextLinkClassName="page-link"
                                previousLinkClassName="page-link"
                                activeClassName="active"
                            />
                        </span>
                    </div>
                    {this.state.listingData && this.state.listingData.length > 0 &&
                        <div className="mt-3">
                            <div className="tab_content_wrapper"><span className="content_heading">Notes</span>
                                <div className="float-end">
                                    <button className="btn btn-primary" disabled={!this.props.permissions.includes('isc-item-Interchangeable-edit')} onClick={() => this.editInterchangeable()}>
                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faPencilAlt} alt="item-action" />
                                    </button>
                                </div>
                            </div>
                            <div className="p-2">
                                {this.state.notes ? this.state.notes : "-"}
                            </div>
                        </div>}
                </div>
                {this.linkInterchangeableModalJsx()}
            </section>
        </Ax>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :        state.auth.access_token,
        permissions             :        state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps)(InterchangeableItemModal);