import React from 'react';
import { toast } from 'react-toastify';
import swal from "sweetalert"
import { Modal } from 'bootstrap';
import { connect } from 'react-redux';
import Ax from "../../components/hoc/Ax";
import HttpAPICall from '../../services/HttpAPICall';
import TapApiUrls from '../../services/TapApiUrls';
import ApplicationLayout from '../../layouts/ApplicationLayout';
import AppBaseComponent from '../../components/AppBaseComponent';
import { Helmet } from 'react-helmet';
import TapIcon from '../../services/TapIcon';
import Loader from '../../components/ui/Loader/Loader';
import {Link} from "react-router-dom";
import Status from '../../components/ui/Status';
import moment from 'moment';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../components/TapUi';
import TapSelect from '../../components/ui/TapSelect';
import InputEmployeeSearch from '../includes/ui/InputEmployeeSearch';


class SiteProductivityListing extends AppBaseComponent {

    constructor(props) {
        super(props);
        
        this.list_url                      =        TapApiUrls.IAM + '/site_productivity/list';
        this.list_formdata_url             =        TapApiUrls.IAM + '/site_productivity/list_form_data';
        this.detail_url                    =        TapApiUrls.IAM + '/site_productivity/detail';
        this.delete_url                    =        TapApiUrls.IAM + '/site_productivity/delete';

        this.productivitySearchInitState         =   {
            'search_transaction_id'         :   '',
            'search_activity_ids'             :    [],
            'search_activity_code'          :    '',
            'search_units'                   :    [],
            'search_material_ids'           :    [],
            'search_attachment_ids'           :    [],
            'site_group_ids'                :    [],
            'search_site_ids'                :    [],
            'search_employee_ids'            :    [],
            'search_workstation_ids'            :    [],
            'search_account_code_ids'            :    [],
            'date_range'                    :   '',
            'date_range_start'              :   null,
            'date_range_end'                :   null,
            'entry_date_range'              :   '',
            'entry_date_range_start'        :   null,
            'entry_date_range_end'          :   null,
          
        };

        this.productivitySearchFormLabel         =   {
            'site_group_ids'                 :   'Site Groups : ',
            'search_site_ids'                :   'Site : ',
            'search_activity_ids'              :   'Activity : ',
            'search_activity_code'          :   'Activity Code: ',
            'search_units'                   :    'Production Unit : ',
            'search_material_ids'            :    'Material : ',
            'search_attachement_ids'         :    'Attachment : ',
            'search_employee_ids'            :    'Employee : ',
            'search_workstation_ids'         :    'Workstation : ',
            'search_account_code_ids'        :    'Account Code : ',
            'search_transaction_id'          :   'Transaction ID : ',
            'date_range'                     :   'Transaction Period : ',
            'entry_date_range'               :   'Date of Entry : ' ,
        };
        this.state                      =     {
            minimizeTable                :     false,
            listing_tbl_page             :     1,
            listing_loading              :     false,
            totalListingCount            :     0,
            listingMeta                  :     null,
            listingData                  :      [],
            productivityData            :      null,
            productivitySearchForm      :          {...this.productivitySearchInitState},
            formSearchedElems           :          [],
            productivitySearchParams    :          null,
            iam_group_sites             :           [],
            allActivitesList            :           [],
            allMaterialList             :           [],
            allProductionUnitList       :           [],
            allSiteGroupsList           :           [],
            all_periods                 :           [],
            allWorkstations             :           [],
            allAttachments              :           [],
            allAccountCodesList         :           [],
            searchEmployees              :           []
        }
         
    }
    
    
    componentDidMount() {
        this.loadListingTblData();
        if(this.props.location && this.props.location.state && this.props.location.state.Id){
            this.viewProductivityDetail(this.props.location.state.Id)
        }

        this.searchFormDataInit()
        this.initalizeData(this.props)
        this.productivitySearchModal     =   new Modal(document.getElementById('productivitySearchModal'), {keyboard: false, backdrop :false});   
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.all_periods !== this.props.all_periods) {
            this.initalizeData(nextProps)      
        }
        // if(nextProps.iam_asset_types !== this.props.iam_asset_types) {
        //      this.searchFormDataInit(nextProps)
        // } 
            
    }

    loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
      
        HttpAPICall.withAthorization('GET',  this.list_url, this.props.access_token, {page:page,...this.state.productivitySearchParams}, {}, (resp) => {
            let respData            =       resp.data;
           this.setState({
                listingData         :       respData.data,
                listingMeta             :           respData.meta,
                totalListingCount       :           respData.meta.total,
           })
        }).then(() => this.setState({listing_loading: false}));
    }

    initalizeData                =   (props)  =>  {
        
        let all_periods             =              [...props.all_periods, { key: 'custom', display: 'Custom Date Range' }];
        
        this.setState({
            iam_group_sites         :       props.iam_group_sites ? props.iam_group_sites.map((s) => { return { value: s.id, label: `${s.site_name} (${s.site_code})` } }) : [],
            all_periods             :       all_periods,
            allSiteGroupsList       :       props.site_groups && props.site_groups.length > 0 ? props.site_groups.map(oc => { return ({ value: oc.id, label: oc.name, linked_sites: oc.site_ids }) }) : [],
            
        },() => {
            
        })
    }

    searchFormDataInit                =   ()  =>  {
        
        HttpAPICall.withAthorization('GET', this.list_formdata_url, this.props.access_token, {})
            .then((response) => {
               let respData = response.data
              
              
                this.setState({
                    allActivitesList        :       respData && respData.activity_data && respData.activity_data.length > 0 ? respData.activity_data.map((td) => { return {value: td.id, label: td.activity_name}}) : [],
                    allProductionUnitList   :       respData && respData.uom && respData.uom.length > 0 ? respData.uom.map((td) => { return {value: td.id, label: td.measuring_unit}}) : [],
                    allMaterialList         :       respData && respData.materials && respData.materials.length > 0 ? respData.materials.map(a => { return ({ value: a.id, label: a.material_name }) }) : [],
                    allWorkstations         :       respData && respData.workstation && respData.workstation.length > 0 ? respData.workstation.map(a => { return ({ value: a.id, label: a.workstation_name }) }) : [],
                    allAccountCodesList     :       respData && respData.account_codes && respData.account_codes.length > 0 ? respData.account_codes.map(a => { return ({ value: a.id, label: a.name }) }) : [],
                    allAttachments         :       respData && respData.attachments && respData.attachments.length > 0 ? respData.attachments.map(a => { return ({ value: a.id, label: a.name }) }) : [],
            
                },() => {
                   
                })
                   
            }) 
    }

   addSiteProductivityInit         =       ()          =>      {
        this.props.history.push('/site_productivity/add')
   }
    
   viewProductivityDetail       =       (id)        =>      {
       if (id) {
           this.setState({ minimizeTable: true })
           this.getConfigurationDetail(id)
       }
   }

   getConfigurationDetail             =    (id)        =>  {
    //    if(transaction_id !== this.state.purchaseViewHeader){
        this.setState({view_loading : true})
        HttpAPICall.withAthorization('GET',  this.detail_url + '/' + id, this.props.access_token, {}, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                productivityData        : respData.data,   
            });
           
        },(err) => {
            if(err){
                this.setState({minimizeTable : false})
            }
        }).then(() => this.setState({view_loading: false}));
       //}
    }



    closeViewDetails           =      ()      =>    {
        this.setState({minimizeTable  :  false})
    }

    deleteProductivityInit     =       ()      =>      {
        
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('DELETE', this.delete_url +'/' + this.state?.productivityData?.id, this.props.access_token, {},{} ,(response) => {
                        toast.success(response.data.msg, {position: toast.POSITION.TOP_RIGHT});
                        this.setState({minimizeTable:false})
                        this.loadListingTblData(1)
                        
                    }).then(() => {

                        });
                }
        });
      
    }

    editProductivityInit       =           ()      =>      {
        this.props.history.push({ pathname: `/site_productivity/add`, state: { productivityData: this.state.productivityData } })
    }

    productivitySearchModalInit        =       ()      =>      {
        this.productivitySearchModal.show()
    }

    productivitySearchClear                     =   ()  =>  {
        this.setState({
            formSearchedElems           :   [],
            productivitySearchParams    :   null,
            searchedAssets              :   [],
            productivitySearchForm      :   {...this.productivitySearchInitState}
        }, () => {
            this.productivitySearchModal.hide();
            this.loadListingTblData(1)
        });
    }

    productivitySearchFormSubmit               =   (event= null)  =>  {
        event && event.preventDefault();
        //Get All Keys :-
        let serachFormKeys              =   Object.keys(this.state.productivitySearchForm);
        let searchedElems               =   [];
        let productivitySearchParams           =   {};
        serachFormKeys.map((key)        =>  {
            let label                       =   this.productivitySearchFormLabel[key];
            let value                       =   this.state.productivitySearchForm[key];
            if(value && value !== "Invalid date" && value.length > 0) {
                productivitySearchParams[key]      =   value;
                if(label) {
                    let show_val            =   value;
                    
                    if(key == 'site_group_ids') {
                        show_val            =   this.state.allSiteGroupsList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ')
                    }
                    if(key == 'search_site_ids') {
                        show_val            =   this.state.iam_group_sites.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_activity_ids') {
                        show_val            =  this.state.allActivitesList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_units') {
                        show_val            =  this.state.allProductionUnitList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_material_ids') {
                        show_val            =  this.state.allMaterialList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_workstation_ids') {
                        show_val            =  this.state.allWorkstations.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_attachment_ids') {
                        show_val            =  this.state.allAttachments.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_account_code_ids') {
                        show_val            =  this.state.allAccountCodesList.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_transaction_id') {
                        show_val            =  this.state.productivitySearchForm && this.state.productivitySearchForm.search_transaction_id ? this.state.productivitySearchForm.search_transaction_id : '';
                    }
                    if(key == 'search_activity_code') {
                        show_val            =  this.state.productivitySearchForm && this.state.productivitySearchForm.search_activity_code ? this.state.productivitySearchForm.search_activity_code : '';
                    }
                    if(key == 'search_employee_ids') {
                        show_val            =   this.state.searchEmployees ? this.state.searchEmployees.map(s => s.label).join(', ') : '';
                    }
                   
                    
                    if(key == 'date_range') {
                       let  start_range          =       moment(this.state.productivitySearchForm.date_range_start).format('DD-MMM-YYYY');
                       let  end_range            =       moment(this.state.productivitySearchForm.date_range_end).format('DD-MMM-YYYY')
                       let  display_custom       =       `Custom Date Range (${start_range} - ${end_range})`
                       show_val                  =       this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                    }
                    if(key == 'entry_date_range') {
                        let  start_range          =      moment(this.state.productivitySearchForm.entry_date_range_start).format('DD-MMM-YYYY');
                        let  end_range            =     moment(this.state.productivitySearchForm.entry_date_range_end).format('DD-MMM-YYYY')
                        let  display_custom       =     `Custom Date Range (${start_range} - ${end_range})`
                        show_val                  =     this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                     }
                    
                    searchedElems.push(label + `<b>${show_val}</b>`);
                }
            }
        });
        //Generate Html :-
        this.setState({
            formSearchedElems           :       searchedElems,
            productivitySearchParams      :       productivitySearchParams
        }, () => {
            this.productivitySearchModal.hide();
            this.loadListingTblData(1)
        });
    }
    
    
   configurationListjsx            =       ()      =>    {
       
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Site Productivity</h3>
                {!this.state.minimizeTable && <div className="text-end mt15">
                     <button type="button" onClick={this.addSiteProductivityInit} disabled={this.state.listing_loading || !this.props.permissions.includes('site-productivity-add')}  className="btn btn-primary" >
                        Add
                    </button>
                    <button type="button" className="btn btn-secondary" onClick={this.productivitySearchModalInit} disabled={this.state.listing_loading} >
                        <TapIcon.imageIcon icon={TapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                    </button>
                    
                </div>}
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Site Productivity : {this.state.productivityData?.activity_name} ({this.state.productivityData?.activity_code})</h3>
                            <div className="text-end mt15">
                                <button  type="button" className="btn btn-secondary"  disabled={!this.props.permissions.includes('site-productivity-edit')} onClick={this.editProductivityInit}>
                                    Edit
                                </button>
                                <button  type="button" className="btn btn-secondary" disabled={!this.props.permissions.includes('site-productivity-delete')} onClick={this.deleteProductivityInit}>
                                    Delete
                                </button>
                                
                                <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                    <TapIcon.imageIcon icon={TapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }

        </div>
        <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.siteTableJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4"><div className="bg-white p-2" style={{ height: "100%" }}>{this.state.view_loading ? <Loader /> : this.configurationViewDetailJsx()}</div></div> : null}
                </div>
            </div>
        </Ax>);
    }

    siteTableJsx            =   ()   =>   {
        return (<Ax>
            <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.productivitySearchClear}/>
            <table className="table table-bordered table-fixed  bg-white table-sm">
            <thead className="table-secondary" >
                {this.state.minimizeTable
                    ? (<tr>
                        <th scope="col" style={{ width: "15%" }}>Site Productivity</th>
                    </tr>)
                    : (
                        <tr  className="text-center">
                            <th scope="col" style={{ width: "5%" }}>S.No</th>
                            <th scope="col" style={{ width: "10%" }}>Transaction ID</th>
                            <th scope="col" style={{ width: "10%" }}>Transaction Date</th>
                            <th scope="col" style={{ width: "18%" }}>Activity Name</th>
                            <th scope="col" style={{ width: "18%" }}>Activity Code</th>
                            <th scope="col" style={{ width: "18%" }}>Sites</th>
                            <th scope="col" style={{ width: "13%" }} className="text-center">Production</th>
                            <th scope="col" style={{ width: "8%" }} className="text-center">Action</th>

                        </tr>)}
            </thead>
            <tbody>
                {this.state.listing_loading
                    ? (<tr><td colSpan="7"><Loader /></td></tr>)
                    : (this.state.listingData && this.state.listingData.length > 0
                        ? (this.state.listingData.map((item, index) => {
                            return (<tr key={index} >
                                {
                                    this.state.minimizeTable
                                        ? (
                                            <Ax>
                                                <td style={{ width: "25%" }} onClick={() => this.viewProductivityDetail(item.id)} >
                                                    <div className="text-capitalize link-primary cursor_pointer">{item.transaction_id ? item.transaction_id : "-"}</div>
                                                    <div className="mt-1">
                                                        <small className="text-capitalize ">{item.activity_name ? item.activity_name : "-"} </small>
                                                        <small className="float-end">{item.site_detail ? item.site_detail.site_name : ""}</small>
                                                    </div>
                                                </td>
                                            </Ax>
                                        )
                                        : (<Ax>
                                            <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                            <td className='text-center link-primary cursor_pointer' onClick={() => this.viewProductivityDetail(item.id)}>{item.transaction_id ? item.transaction_id : ""}</td>
                                            <td className="text-center">{item.transaction_date_display ? item.transaction_date_display : ""}</td>
                                            <td className='text-capitalize '>{item.activity_name ? item.activity_name : "-"}</td>
                                            <td className='text-capitalize '>{item.activity_code ? item.activity_code : "-"}</td>
                                            
                                            {/* <td>{item.uom ? item.uom : ""}</td> */}
                                            <td>{item.siteData ? item.siteData.site_name : ""}</td>
                                            <td className="text-center">{item.production ? item.production : ""} {item.uom_name ? item.uom_name : ""}</td>
                                            <td className="text-center">
                                                <TapIcon.imageIcon icon={TapIcon.ListActionIcon} alt="action-icon" className="img-fluid px-3" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a className="dropdown-item" role="button" onClick={() => this.viewProductivityDetail(item.id)}>View</a></li>
                                                </ul>
                                            </td>
                                          
                                        </Ax>
                                        )}
                            </tr>)
                        }))
                        : (<tr><td colSpan="7" className="text-center">No Records</td></tr>)
                    )
                }
            </tbody>
        </table>
        <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>

        )
    }

    configurationViewDetailJsx      =       ()      =>      {
        let data = this.state.productivityData;
        let formula                     =       data && data.configData && data.configData.productivity_formula ? data.configData.productivity_formula : ""
            if(data && data.additionalAttributes && data.additionalAttributes.length > 0){
                data.additionalAttributes.forEach(production => {
                    if (formula && formula.includes(production.formula_key)) {
                        formula = formula.replace(production.formula_key, production.name)
                    }
                })
            }
        return (<div className='tab_content_header'>
            {this.state.view_loading ? <Loader /> :
                data ? (<div>
                    <div className="tab_content_wrapper"> <span className="content_heading">Basic Details</span></div>
                    <table className="table table-hover table-bordered  table-responsive bg-white my-2 ">
                        <tbody>
                            <tr>
                                <td style={{ "width": "25%" }}>Transaction ID</td>
                                <th>{data.transaction_id ? data.transaction_id : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ "width": "25%" }}>Transaction Date</td>
                                <th>{data.transaction_date_display ? data.transaction_date_display : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ "width": "25%" }}>Activity Name</td>
                                <th>{data.activity_name ? data.activity_name : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ "width": "25%" }}>Activity Code</td>
                                <th>{data.activity_code ? data.activity_code : ""}</th>
                            </tr>
                           
                            <tr>
                                <td style={{ "width": "25%" }}>Site</td>
                                <th>{data.siteData ? data.siteData.site_name : ""}</th>
                            </tr>
                           
                            <tr>
                                <td style={{ "width": "25%" }}>No of Trips/Sessions</td>
                                <th>{data.trip ? data.trip : ""}</th>
                            </tr>

                            {data.materialData && <tr>
                                <td style={{ "width": "25%" }}>Material</td>
                                <th>{data.materialData.material_name}</th>
                            </tr>}

                            {data.attachmentData && <tr>
                                <td style={{ "width": "25%" }}>Attachment</td>
                                <th>{data.attachmentData.name}</th>
                            </tr>}

                            {data.workstationData && <tr>
                                <td style={{ "width": "25%" }}>WorkStation</td>
                                <th>{data.workstationData.workstation_name}</th>
                            </tr>}

                            {data.employees && data.employees.length > 0 && <tr>
                                <td style={{ "width": "25%" }}>Employee</td>
                                <th>{data.employees.map(em => {return(<div>{em.display_full_name}</div>)})}</th>
                            </tr>}
                            {data && data.additionalAttributes && data.additionalAttributes.length > 0
                                ? data.additionalAttributes.map((attr) => {
                                    return (<tr key={attr.key}>
                                        <td scope="col" className='text-capitalize'>{attr.name ? attr.name : "-"}</td>
                                        <th scope="col">{attr.value_display ? attr.value_display : "-"}</th>
                                    </tr>)
                                }) : null}
                            <tr>
                                <td style={{ "width": "25%" }}>Productivity Formula</td>
                                <th>{formula ? formula : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ "width": "25%" }}>Production</td>
                                <th>{data.production ? <span>{data.production} {data.uom_name && data.uom_name}</span> : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ "width": "25%" }}>Accounting Code</td>
                                <th>{data.accountCode ? data.accountCode.name : ""}</th>
                            </tr>
                    
                            <tr>
                                <td style={{ "width": "25%" }}>Target Type</td>
                                <th className='text-capitalize'>{data && data.configData.target_type ? data.configData.target_type : ""}</th>
                            </tr>
                            {data && data.configData && data.configData.target_type && <Ax>
                                <tr>
                                <td style={{ "width": "25%" }}>Total Productivity Target</td>
                                <th className='text-capitalize'>{data.configData && data.configData.total_productivity_target ? data.configData.total_productivity_target : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ "width": "25%" }}>Target Per Day</td>
                                <th className='text-capitalize'>{data.configData && data.configData.target_per_day ? data.configData.target_per_day : ""}</th>
                            </tr>
                            <tr>
                                <td style={{ "width": "25%" }}>Cost Per Unit Target</td>
                                <th className='text-capitalize'>{data.configData && data.configData.cost_per_unit_target ? data.configData.cost_per_unit_target : ""}</th>
                            </tr> </Ax>}

                            <tr>
                                <td style={{ "width": "25%" }}>Remarks</td>
                                <th>{data.remarks ? data.remarks : ""}</th>
                            </tr>

                        </tbody>
                    </table>
                    <div className="tab_content_wrapper"> <span className="content_heading">Transaction Details</span></div>
                        <table className="table my-3 table-hover table-bordered">
                            <tbody>
                                <tr>
                                    <td style={{ "width": "25%" }}>Transaction ID</td>
                                    <th style={{ "width": "66%" }}>{data.transaction_id ? data.transaction_id : ""}   </th>
                                </tr>
                                <tr>
                                    <td style={{ "width": "25%" }}>Date of Transaction</td>
                                    <th style={{ "width": "66%" }}>{data.transaction_date_display ? data.transaction_date_display : '-'}   </th>
                                </tr>

                                <tr>
                                    <td>Created By</td>
                                    <th>{data.createdBy ? data.createdBy.full_name : "-"}</th>
                                </tr>
                                <tr>
                                    <td style={{ "width": "25%" }}>Date of Entry</td>
                                    <th style={{ "width": "66%" }}>{data.created_at_display ? data.created_at_display : '-'}   </th>
                                </tr>
                                <tr>
                                    <td>Updated By</td>
                                    <th>{data.lastUpdatedBy ? data.lastUpdatedBy.full_name : "-"}</th>
                                </tr>
                                <tr>
                                    <td style={{ "width": "25%" }}>Date of Updation</td>
                                    <th style={{ "width": "66%" }}>{data.updated_at_display ? data.updated_at_display : '-'}   </th>
                                </tr>
                                {/* <tr>
                                    <td style={{ "width": "25%" }}>Date of Update</td>
                                    <th style={{ "width": "66%" }}>{data.created_at_display ? data.created_at_display : '-'}   </th>
                                </tr> */}
                            </tbody>
                        </table>

                </div>) : null}

        </div>)
    }

    productivitySearchModalJsx         =   ()  =>  {
        return (
            <div className="modal fade" id="productivitySearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="productivitySearchModalLabel">Productivity Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body">
                            <form onSubmit={this.productivitySearchFormSubmit} id="productivitySearchForm">

                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Transaction ID</label>
                                    <div className="col-sm-4">
                                        <input
                                            type="text"
                                            name="search_transaction_id"
                                            className="form-control form-control-sm"
                                            placeholder="Transaction ID"
                                            autoComplete="off"
                                            value={this.state.productivitySearchForm.search_transaction_id}
                                            onChange={(e) => this.formInputHandler(e, "productivitySearchForm")}
                                        />
                                    </div>
                                    <label className="col-sm-1 col-form-label col-form-label-sm text-end">Code</label>
                                    <div className="col-sm-5">
                                        <input
                                            type="text"
                                            name="search_activity_code"
                                            className="form-control form-control-sm"
                                            placeholder="Activity Code"
                                            autoComplete="off"
                                            value={this.state.productivitySearchForm.search_activity_code}
                                            onChange={(e) => this.formInputHandler(e, "productivitySearchForm")}
                                        />
                                    </div>

                                </div>

                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Activity Name</label>
                                    <div className="col-sm-4">
                                        <TapSelect
                                            options={this.state.allActivitesList}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'productivitySearchForm', 'search_activity_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allActivitesList.filter(s => this.state.productivitySearchForm.search_activity_ids.includes(s.value))}
                                            placeholder="Select Activity"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                    <label className="col-sm-1 col-form-label col-form-label-sm text-end">UOM</label>
                                    <div className="col-sm-5">
                                        <TapSelect
                                            options={this.state.allProductionUnitList}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'productivitySearchForm', 'search_units')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allProductionUnitList.filter(s => this.state.productivitySearchForm.search_units.includes(s.value))}
                                            placeholder="Unit of Measurement"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Site Group</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allSiteGroupsList}
                                            changeEvent={(selectedOption) => {

                                                this.setState({
                                                    productivitySearchForm: {
                                                        ...this.state.productivitySearchForm,
                                                        site_group_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(o => o.value) : [],
                                                        search_site_ids: selectedOption && selectedOption.length > 0 ? selectedOption.map(s => s.linked_sites).flat() : []
                                                    }
                                                })
                                            }}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allSiteGroupsList.filter(s => this.state.productivitySearchForm.site_group_ids.includes(s.value))}
                                            placeholder="Select Site Group"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>

                                </div>
                                <div className="row mb-3">

                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Site</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.iam_group_sites}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'productivitySearchForm', 'search_site_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.iam_group_sites.filter(s => this.state.productivitySearchForm.search_site_ids.includes(s.value))}
                                            placeholder="Select Site"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>


                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Material</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allMaterialList}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'productivitySearchForm', 'search_material_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allMaterialList.filter(s => this.state.productivitySearchForm.search_material_ids.includes(s.value))}
                                            placeholder="Select Material"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Attachment</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allAttachments}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'productivitySearchForm', 'search_attachment_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allAttachments.filter(s => this.state.productivitySearchForm.search_attachment_ids.includes(s.value))}
                                            placeholder="Select Attachment"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Workstation</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allWorkstations}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'productivitySearchForm', 'search_workstation_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allWorkstations.filter(s => this.state.productivitySearchForm.search_workstation_ids.includes(s.value))}
                                            placeholder="Select Workstations"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Accounting Code</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allAccountCodesList}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'productivitySearchForm', 'search_account_code_ids')}
                                            isSearchable={true}
                                            isClearable={true}
                                            isMulti={true}
                                            value={this.state.allAccountCodesList.filter(s => this.state.productivitySearchForm.search_account_code_ids.includes(s.value))}
                                            placeholder="Select Accounting Codes"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                                <div className="row my-2">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end">Employees</label>
                                    <div className="col-sm-10">
                                        <InputEmployeeSearch
                                            changeEvent={(selectedOption) => {

                                                this.tapSelectChange(selectedOption, 'productivitySearchForm', 'search_employee_ids');
                                                this.setState({
                                                    searchEmployees: selectedOption && selectedOption.length > 0 ? selectedOption : []
                                                });
                                            }}
                                            isMulti={true}
                                            containerHeight="30px"
                                            fontSize="93%"
                                            placeholder="Select Employee"
                                            value={this.state.searchEmployees.length > 0 ? this.state.searchEmployees.map(a => { a.label = a.label; return a; }) : []}
                                        />
                                    </div>

                                </div>




                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end ">Date of Entry</label>

                                    <div className="col-sm-10">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.all_periods ? this.state.all_periods : []}
                                            value={this.state.productivitySearchForm.entry_date_range}
                                            startDate={this.state.productivitySearchForm.entry_date_range_start}
                                            endDate={this.state.productivitySearchForm.entry_date_range_end}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({
                                                    productivitySearchForm
                                                        : {
                                                        ...this.state.productivitySearchForm,
                                                        entry_date_range: period,
                                                        entry_date_range_start: startDate ? startDate : null,
                                                        entry_date_range_end: endDate ? endDate : null
                                                    }
                                                });
                                            }}
                                        />

                                    </div>

                                </div>

                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm text-end ">Transaction Period</label>

                                    <div className="col-sm-10">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.all_periods ? this.state.all_periods : []}
                                            value={this.state.productivitySearchForm.date_range}
                                            startDate={this.state.productivitySearchForm.date_range_start}
                                            endDate={this.state.productivitySearchForm.date_range_end}
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({
                                                    productivitySearchForm
                                                        : {
                                                        ...this.state.productivitySearchForm,
                                                        date_range: period,
                                                        date_range_start: startDate ? startDate : null,
                                                        date_range_end: endDate ? endDate : null
                                                    }
                                                });
                                            }}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />

                                    </div>

                                </div>
                            </form>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-primary" form="productivitySearchForm" >Search</button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    render                              =   ()  =>  {
      
        return (<ApplicationLayout>
        <Helmet><title>Site Productivity - Configuration</title></Helmet>
             {this.configurationListjsx()}
             {this.productivitySearchModalJsx()} 
           
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        iam_group_sites         :   state.app && state.app.group_sites ? state.app.group_sites : [],
        site_groups             :   state.app && state.app.site_groups ? state.app.site_groups : [],
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : [],
    };
};

export default connect(mapStateToProps)(SiteProductivityListing);